import axios from 'axios';
import instanceAll from './CommonAxiosInstance';

// Get mnenu services Api :
export const GetMenuService = async (body) => {
    console.log("Body of getMenu: " + body);
    
    // try {
    //     return axios.post("https://qaapi.wehouse.in/get_menu", body,
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Accept': 'application/json'
    //             }
    //         }).then(response => {
    //             console.log('get_Menu_response:', response)
    //             if (response.status === 200) {
    //                 return {
    //                     status: "Success",
    //                     menuData: response.data,
    //                 }
    //             }
    //         })

    // } catch (error) {
    //     console.log(error)
    // }

    // Adding baseURL to get_menu Api services:

try {
    let response = await  instanceAll.post("/get_menu",body);
    // console.log("get_menu:::",response);
       if (response?.status === 200)
          {
              return{
                  status:"success",
                  menuData:response.data,
              }
          }
        else {
            console.log("Something Went Wrong");
        }
  }catch(err){
      console.log(err);
  }
}
