import { message } from 'antd';
import axios from 'axios';
import instanceAll from './CommonAxiosInstance';

// Signup Api :
export const postSignupService= async (body)=>
{
 try{
    return axios.post('https://qaapi.wehouse.in/signup',body,
    {
        headers:{
            'Content-Type': 'application/json',
        }
    }).then(function (response)
    {
        console.log('signup response =>',response)
        if (response.status===200)
        {
            return{
                status:"success",
                data:response.data
            }
        }
    })

 }catch(err){
  console.log('err')
  return{status:"error"}
 }

//  Adding baseURL to signup Api service:

// try {
//     let response = await  instanceAll.post("/signup",body);
//     console.log("signUpRes:::",response);
//        if (response?.status===200)
//           {
//               return{
//                   status:"sucess",
//                   data:response.data,
//               }
//           }
//         else {
//             console.log("Something Went Wrong");
//             message.error("something")
//         }
//   }catch(err){
//       console.log(err);
//   }
  }

// Check email Api:
export const emailSignupService= async (body)=>
{
//  try{
//     return axios.post('https://qaapi.wehouse.in/check-email',body,
//     {
//         headers:{
//             'Content-Type': 'application/json',
//         }
//     }).then(function (response)
//     {
//         console.log("response :" , response)
//         if (response.status===200)
//         {
//             return{
//                 status:"success",
//                 data:response.data,
//             }
//         }       
//     })

//  }catch(err){
//   console.log('err')
//   return{status:"error"}

//  }

//  Adding baseURL to check-email  Api service:

try {
    let response = await  instanceAll.post("/check-email",body);
    console.log("signUpRes:::",response);
       if (response?.status===200)
          {
              return{
                  status:"success",
                  data:response.data,
              }
          }
        else {
            console.log("Something Went Wrong");
            message.error("something")
        }
  }catch(err){
      console.log(err);
  }

}


// Check Mobile Api
export const mobileSignupService= async (body)=>
{
//  try{
//     return axios.post('https://qaapi.wehouse.in/checkMobile',body,
//     {
//         headers:{
//             'Content-Type': 'application/json',
//         }
//     }).then(function (response)
//     {
//         console.log('response for mobile==>',response)
//         if (response.status===200)
//         {
//             return{
//                 status:"success",
//                 data:response.data,
//             }
//         }
       
//     })

//  }catch(err){
//   console.log('err')
//   return{status:"error"}
//  }


//  Adding baseURL to checkMobile Api service:

try {
    let response = await  instanceAll.post("/checkMobile",body);
    console.log("signUpRes:::",response);
       if (response?.status===200)
          {
              return{
                  status:"success",
                  data:response.data,
              }
          }
        else {
            console.log("Something Went Wrong");
            message.error("something")
        }
  }catch(err){
      console.log(err);
  }
}
// Otp Services Api:
export const OTPSerivce= async (body)=>{
    // try{
    //     console.log('otp--->',otp);
    //     return axios.post('http://10.12.51.197/otp-verify',otp).
    //     then(function(response){
    //         console.log('response of otp:', response)
    //         if(response.status === 200){
    //             return{
    //                 status:"success",
    //                 data:response.data
    //             }
    //         }

    //     })
    // }catch(err){
    //     console.log(err)
    //     return{status:"error"}
    // }

    //  Adding baseURL to otp-verify  Api service:

    try {
        let response = await  instanceAll.post("/otp-verify",body);
        console.log("Otp-verify:::",response);
           if (response?.status===200)
              {
                  return{
                      status:"success",
                      data:response.data,
                  }
              }
            else {
                console.log("Something Went Wrong");
                message.error("something")
            }
      }catch(err){
          console.log(err);
      }

}