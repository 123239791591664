import { Select } from 'antd';
import React from 'react';
import { imageAssets } from '../../../../../Assets/AssetsConstants/AssetsConstants';
import "./MyLeadsAdvanceFilter.scss";

const statusOptions = [
    {
        label: "All", value: 9

    },
    {
        label: "Potential", value: 2
    },
    {
        label: "Discarded", value: 3

    },
    {
        label: "Cold", value: 4

    },
    {
        label: "Hot", value: 5

    },
    {
        label: "Future", value: 6

    },
    {
        label: "Lost", value: 7

    },
    {
        label: "Won", value: 8

    }

]

const servicesOptions = [
    { label: "Residential Constrution", value: "Residential Constrution" },
    { label: "Commercial Construction", value: "Commercial Construction" },
    { label: "Architecture Services", value: "Architecture Services" },
    { label: "Interior & smart Homes", value: "Interior & smart Homes" },
    { label: "Project Management", value: "Project Management" }
]
const areaOptions = [
    { label: "Hyderabad", value: "Hyderabad" },
    { label: "Chennai", value: "Chennai" }
]

const { Option } = Select;

const MyLeadsAdvanceFilter = ({
    advancedFilterData,
    setAdvancedFilterData,
    onApplyFilter,
    selectionRange,
    setShowAdvancedFilterModal,
    showAdvancedFilterModal,
    resetFilter
}) => {

    const statusFilterHandler = (value) => {
        if (value.includes(9)) {
            setAdvancedFilterData(data => ({ ...data, status: [2, 3, 4, 5, 6, 7, 8] }))
        }
        else {
            setAdvancedFilterData(data => ({ ...data, status: value }))
        }
    }

    return (
        <div className='myLeadsWrapper'>
            <div className='row'>
                <div className='col'>
                    <label>Status</label>
                    <Select
                        options={statusOptions}
                        style={{ width: "100%" }}
                        className="form-control"
                        mode="tags"
                        allowClear
                        placeholder={"select Status"}
                        onChange={(value) => { statusFilterHandler(value) }}
                        value={advancedFilterData.status}
                    />

                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <label>Services</label>
                    <Select
                        options={servicesOptions}
                        style={{ width: "100%" }}
                        className="form-control"
                        mode="tags"
                        allowClear
                        placeholder={"select Services"}
                        onChange={(value) => { setAdvancedFilterData(data => ({ ...data, service: value })) }}
                        value={advancedFilterData.service}
                    />

                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <label>Area</label>
                    <Select
                        options={areaOptions}
                        style={{ width: "100%" }}
                        className="form-control"
                        mode="tags"
                        allowClear
                        placeholder={"select City"}
                        onChange={(value) => { setAdvancedFilterData(data => ({ ...data, area: value })) }}
                        value={advancedFilterData.area}
                    />
                </div>

            </div>
            <div className='row'>
                <div className='col'>
                    <label>Source</label>
                    <Select
                        // options={sourceOption}
                        style={{ width: "100%" }}
                        className="form-control"
                        mode="tags"
                        allowClear
                        placeholder={"select Source"}
                        onChange={(value) => { setAdvancedFilterData(data => ({ ...data, source: value })) }}
                        value={advancedFilterData.source}
                    >
                        <Option value="Google">
                            <img src={imageAssets.GoogleLogo} alt="googlelogo" />
                            Google
                        </Option>
                        <Option value="Wehouse">
                            <img src={imageAssets.FavIcon} style={{ height: "30px" }} alt="googlelogo" />
                            Wehouse
                        </Option>
                        <Option value="Wehouse Website">
                            <img src={imageAssets.wehouseFav} style={{ height: "30px" }} alt="googlelogo" />
                            Wehouse Website
                        </Option>
                    </Select>
                </div>
            </div>

            <div className='footerBtns'>
                <button type="button" className="btn btn-link btn-sm " onClick={()=>resetFilter()}>Reset Filter</button>
                <button type="button" className='btn btn-sm whBtnPrimary' onClick={() => { onApplyFilter(selectionRange[0], null, 1); setShowAdvancedFilterModal(false) }}>Filter</button>
            </div>
        </div>
    )
}

export default MyLeadsAdvanceFilter