import { Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { getActivityStates, updateAppointmentStateService, updateLeadState } from '../../../../../../Service/MyLeadsService';
import { dateFormatter } from '../../../../../../Utilities/helpers';
import LeadDetails from '../../../MyLeads/LeadDetails/LeadDetails';



const GenericCallDetailsModal = ({ selectedCall, handleCancel }) => {
    console.log("call details", selectedCall);

    const [options, setOptions] = useState([]);
    const [selectedState, setSelectedState] = useState(null);

    const getCallStates = async () => {
        try {
            const response = await getActivityStates({ activityTypeId: 1 });
            console.log('response', response);
            if (response.status === "success") {
                const options = response.data.map((value) => ({ label: value.activityStateName, value: value.activityStateId }));
                setOptions(options);
            }
            else {
                setOptions([]);
            }

        } catch (error) {
            console.log("error", error);
            setOptions([]);
        }
    }
    const updateState = async () => {
        try {
            console.log("inside the update state");
            const response = await updateAppointmentStateService({ activityId: selectedCall?.activityId, activityState: selectedState })
            if (response.status === "success") {
                toast.success("successfully updated state");
                handleCancel();
            }
            else {
                toast.error("Failed to update state");
            }
        } catch (error) {
            console.log("error ", error);
            toast.error("Failed to update state");
        }

    }
    const handleSelect = (e) => {
        // console.log('value', e)
        setSelectedState(e);
    }

    console.log("options", options);


    useEffect(() => {
        getCallStates();
        setSelectedState(selectedCall?.activityState);
    }, [selectedCall])




    return (
        <div>
            <div className='row'>
                <LeadDetails selectedUser={selectedCall} type="generic" />
                <div className='col-3'>
                    <Select
                        options={options}
                        value={selectedState}
                        style={{ width: "100%" }}
                        placeholder="select status"
                        onChange={handleSelect}
                    />
                </div>
            </div>
            <div className='row'>
                <label>Sales Executive Comment</label>
                <div class="fW500">{selectedCall?.remarks?.replace(/(<([^>]+)>|&nbsp;)/ig, '\n')}</div>
            </div>

            <div className='row'>
                <label>Title</label>
                <div class="fW500">{selectedCall?.activityName}</div>
            </div>

            <div className='row'>
                <label>Description</label>
                <div class="fW500">{selectedCall?.activityDescription?.replace(/(<([^>]+)>|&nbsp;)/ig, '\n')}</div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <div className='row'>
                        <div className='col-6'>
                            <label>Date</label>
                            <div class="fW500">{dateFormatter(selectedCall?.appointmentDate, "D MMM, YYYY")}</div>
                        </div>
                        <div className='col-6'>
                            <label>Time</label>
                            <div class="fW500">{moment(selectedCall?.appointmentTime, "hh:mm a").format("hh:mm a")}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modalFooter'>
                <button type="button" className="btn cancelBtn" onClick={() => handleCancel()}>cancel</button>
                <button type="button" className="btn saveBtn" onClick={() => updateState()}>Save</button>

            </div>
        </div >
    )
}

export default GenericCallDetailsModal

