import { takeEvery,put } from "@redux-saga/core/effects";
import { DropDownServices } from "../../Service/DropDownServices";
import { getCategoriesListFailuer, getCategoriesListSuccess } from "../Slices/getCategoriesListSlice";

export const GETCATEGORIESLIST =()=>({type:"/categories"});

export function* getCategoriesList(action){
    const response = yield DropDownServices.getCategoriesListService();
    console.log("getCategoriesList::",response);
    if(response.status === "success" && response.data.isgetcategories){
        yield put(getCategoriesListSuccess(response.data.categories))
    }else{
        yield put(getCategoriesListFailuer(response.data.message))
    }
}

function* getCategoriesListWatcher(){
    yield takeEvery(GETCATEGORIESLIST().type , getCategoriesList)
}

export default getCategoriesListWatcher;