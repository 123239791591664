import { takeEvery, call, put } from 'redux-saga/effects';
import wehouseUsersApi from '../../Service/WehouseUsersServices';
import { getWeHouseUserByIdFailure, getWeHouseUserByIdSuccess, wehouseUserLoader } from '../Slices/WeHouseUserSlice';


export function* getWeHouseUserById(action) {
    // console.log("inside the get we house user  by id saga", action);

    const response = yield (wehouseUsersApi.getWeHouseUser(action.payload));
    // console.log(response);

    if (response?.status === "success") {
        yield put(getWeHouseUserByIdSuccess(response.data.wehouseUser));
    }
    else if (response?.status === "error") {
        yield put(getWeHouseUserByIdFailure(response?.error))
    }

}


function* WeHouseUserByIdWatcher() {
    // console.log("inside the customer by id watcher");
    yield takeEvery(wehouseUserLoader().type, getWeHouseUserById);
}

export default WeHouseUserByIdWatcher;