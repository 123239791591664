import { Avatar, Card} from 'antd';
import React from 'react';
import "./AppointmentsCard.css";
import moment from 'moment';

const AppointmentsCard = ({ details, poistion, handleModal }) => {
    // console.log("appointment details", details, poistion);
    return (
        <div className='appointmentCard'>
            <span>
                {moment(details?.appointmentDate).format("Do MMM,YYYY")}<br />
                {moment((details.appointmentTime || details?.appointmentStartTime), "HH:mm:ss").format("hh:mm a")}
            </span>
            <Card className={`cardItem ${details?.leadStatus}`} onClick={() => handleModal(details)}>
                <div className='apCardDetails'>
                    <div>
                        <Avatar className={details?.status}>
                            {details?.leadName?.charAt(0)}
                        </Avatar>
                    </div>
                    <div>
                        <div className='leadNameTitle'>
                            <span>{details?.leadName}</span> -
                            <span> {details?.taskTitle}</span>
                        </div>
                        <div clasaName="">
                            <button className={`btn btn-sm ${details?.meetingType}-active`}>{details?.meetingType === "Schedule Meeting" ? "Meeting" : "Call"}</button>
                        </div>
                    </div>
                    <div className='appointmentStatus'>
                        <span className={`${details?.appointmentState}`}>{details?.appointmentState}</span>
                    </div>
                </div>

            </Card>
        </div>
    )
}

export default AppointmentsCard;

        {/* <div>
                        <button  className="btn whBtnPrimary rescheduleBtn" onClick={() => handleModal(details)}>Reschedule</button>
                        <button  className="btn btn-light threeDots">
                            <i class="far fa-ellipsis-v"></i>
                        </button>
                    </div> */}