import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import { AiOutlineClose, AiOutlinePoweroff, AiOutlineSearch } from 'react-icons/ai';
import { imageAssets } from '../../Assets/AssetsConstants/AssetsConstants';
import '../../Styles/MainNavbar.css';
import SearchData from './SerachbarData';
import { Tooltip ,Popover} from 'antd';
import Signout from './Signout';
// import profile from '../../Assets/images/hippiimage.jpeg';

function MainNavbar({ userName }) {
    const [searchInput, setSearchInput] = useState([]);
    const [enterWord, setEnterWord] = useState("");
    const [check, setCheck] = useState("home");

    // landingpage navigation:
    const history = useHistory()

    // 

    console.log("userName:",userName);
    
    const homeBtn = () => {
        history.push('/landing')
    }

    // function for homeHandler:
    const homeHandler = () => {
        setCheck('home')
    }

    // function for nottificationsHandler:
    const nottificationsHandler = () => {
        setCheck('nottification')
    }

    // function for chatHandler:
    const chatHandler = () => {
        setCheck('chat')
    }
    
    // function for settingsHandler:
    const settingsHandler = () => {
        setCheck('setting')
    }

    const SearchHandler = (e) => {
        var searchWord = e.target.value;
        setEnterWord(searchWord)
        var FilteredData = SearchData.filter((val) => {
            // return val.name.toLowerCase().includes(searchWord.toLowerCase())
        })
        if (searchWord === "") {
            setSearchInput([]);
        }
        else {
            setSearchInput(FilteredData)
        }
    }
    const focusHandler=()=>{
        setCheck('search')
    }

    const closeInput = () => {
        setSearchInput([]);
        setEnterWord("")
    }

    return (
        <div>
            <nav class="navbar navbar-expand-sm bg-light navbar-light fixed-top topNavbar">
                <div class="container-fluid">
                    {/* <img src={navLogo} className="whLogo" onClick={homeBtn} alt="NavLogo..." /> */}
                    <div class="whtopNavLogo">
                    <img src={imageAssets.WehouseLogo} className="whLogo" style={{height:"50px"}} onClick={homeBtn} alt="NavLogo..." />
                    </div>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="mynavbar">
                        <div className="serach-layout me-auto">
                            <div className="serachInput">
                                {/* <div>
                                    <input type="text"
                                        placeholder="Search"
                                        value={enterWord}
                                        className={`searchField ${check === "search" ? 'SearchIcon' : ""}`}
                                        onChange={SearchHandler}
                                        onFocus={focusHandler}
                                    />
                                    
                                    {enterWord && <AiOutlineClose size="18px" className="clearIcon" onClick={closeInput} />}
                                </div> */}
                            </div>
                        </div>
                        <ul class="navbar-nav ">
                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    <div className={`navIcon ${check === 'home' ? 'ActiveIcon' :""}`} onClick={homeHandler}>
                                        {/* <img
                                    src={homeIcon}
                                    alt="home..."
                                    style={{ height: "20px", marginTop: "8px" }}/> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" height="20"><path d="M23.121,9.069,15.536,1.483a5.008,5.008,0,0,0-7.072,0L.879,9.069A2.978,2.978,0,0,0,0,11.19v9.817a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V11.19A2.978,2.978,0,0,0,23.121,9.069ZM15,22.007H9V18.073a3,3,0,0,1,6,0Zm7-1a1,1,0,0,1-1,1H17V18.073a5,5,0,0,0-10,0v3.934H3a1,1,0,0,1-1-1V11.19a1.008,1.008,0,0,1,.293-.707L9.878,2.9a3.008,3.008,0,0,1,4.244,0l7.585,7.586A1.008,1.008,0,0,1,22,11.19Z" /></svg>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    <div className={`navIcon ${check === 'nottification' ? 'ActiveIcon' :""}`} onClick={nottificationsHandler}>
                                        {/* <img
                                    src={nottification}
                                    alt="nottification..."
                                    style={{ height: "20px", marginTop: "8px" }}/> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" height="20"><path d="M22.555,13.662l-1.9-6.836A9.321,9.321,0,0,0,2.576,7.3L1.105,13.915A5,5,0,0,0,5.986,20H7.1a5,5,0,0,0,9.8,0h.838a5,5,0,0,0,4.818-6.338ZM12,22a3,3,0,0,1-2.816-2h5.632A3,3,0,0,1,12,22Zm8.126-5.185A2.977,2.977,0,0,1,17.737,18H5.986a3,3,0,0,1-2.928-3.651l1.47-6.616a7.321,7.321,0,0,1,14.2-.372l1.9,6.836A2.977,2.977,0,0,1,20.126,16.815Z" /></svg>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    <div className={`navIcon ${check === 'chat' ? 'ActiveIcon' :""}`} onClick={chatHandler}>
                                        {/* <img
                                    src={chat}
                                    alt="chat..."
                                    style={{ height: "20px", marginTop: "8px" }}/> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" height="20"><path d="M24,11.247A12.012,12.012,0,1,0,12.017,24H19a5.005,5.005,0,0,0,5-5V11.247ZM22,19a3,3,0,0,1-3,3H12.017a10.041,10.041,0,0,1-7.476-3.343,9.917,9.917,0,0,1-2.476-7.814,10.043,10.043,0,0,1,8.656-8.761A10.564,10.564,0,0,1,12.021,2,9.921,9.921,0,0,1,18.4,4.3,10.041,10.041,0,0,1,22,11.342Z" /><path d="M8,9h4a1,1,0,0,0,0-2H8A1,1,0,0,0,8,9Z" /><path d="M16,11H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z" /><path d="M16,15H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z" /></svg>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    <div className={`navIcon ${check === 'setting' ? 'ActiveIcon' :""}`} onClick={settingsHandler}>
                                        {/* <img
                                    src={settings}
                                    alt="settings..."
                                    style={{ height: "20px", marginTop: "8px" }}/> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" height="20"><path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" /><path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" /></svg>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div className="profile-layout">
                            <img src="https://voltuswave.com/wp-content/uploads/2022/06/profile-avatar-2.png" className="blankAvatarImg" />
                            <Popover content={<Signout/>} trigger="click">
                                 <img src={imageAssets.angleDown} style={{height:"15px",marginLeft:"10px"}}/>
                            </Popover>
                        </div>
                        <div className="userNameTitle">
                            {/* <Tooltip title={userName}> */}
                                <p>{userName.split("@")[0]}</p>
                            {/* </Tooltip> */}
                        </div>
                     
                    </div>
                </div>
            </nav>



{/* react bootstrap */}
            {/* 

            <Navbar className="navbar topNavbar" collapseOnSelect expand="lg" bg="white" variant="white">
                    <img src={navLogo} className="nav-logo" onClick={homeBtn} alt="NavLogo..." />
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <div className="serach-layout">
                        <div className="serach-input">
                            <input type="text"
                                placeholder="Search"
                                value={enterWord}
                                className="search-field"
                                onChange={SearchHandler}
                            />
                            {enterWord === "" ? <AiOutlineSearch size="20px" className="search-icon" />
                                : <AiOutlineClose size="30px" className="search-icon" onClick={closeInput} />}
                        </div>
                    </div>
                    <Nav className="me-auto">
                        <Nav.Link to="/">
                            <div className="nav-icon" onClick={iconClick}>
                                <img
                                    src={homeIcon}
                                    alt="home..."
                                    style={{ height: "20px", marginTop: "8px" }}
                                />
                            </div>
                        </Nav.Link>
                        <Nav.Link to="/">
                            <div className="nav-icon" onClick={iconClick}>
                                <img
                                    src={nottification}
                                    alt="nottification..."
                                    style={{ height: "20px", marginTop: "8px" }}

                                />
                            </div>
                        </Nav.Link>
                        <Nav.Link to="/">
                            <div className="nav-icon" onClick={iconClick}>
                                <img
                                    src={chat}
                                    alt="chat..."
                                    style={{ height: "20px", marginTop: "8px" }}

                                />
                            </div>
                        </Nav.Link>
                        <Nav.Link to="/">
                            <div className="nav-icon" onClick={iconClick}>
                                <img
                                    src={settings}
                                    alt="settings..."
                                    style={{ height: "20px", marginTop: "8px" }}
                                />
                            </div>
                        </Nav.Link>
                        <div className="profile-layout">
                            <img src={profile} className="profile-picture" />
                        </div>

                        <div className="off-icon-layout" onClick={landingBtn}>
                            <AiOutlinePoweroff className="off-icon" />
                            <Link to="/signin" >SignOut</Link>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className="data-result">
                {searchInput.length > 1 && searchInput.map((val, key) => {
                    return <a><p>{val.name}</p></a>
                })}
            </div> */}
        </div>
    )
}

export default MainNavbar;