import React, { useEffect, useRef, useState } from 'react'
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import Searchbar from '../Pages/Admin/Searchbar';
import { Dropdown, Modal, Menu, Radio, Popover } from 'antd';
import MyLeadsAdvanceFilter from '../Pages/Admin/salesExecutive/MyLeads/MyLeadsAdvanceFilter/MyLeadsAdvanceFilter';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './contentNavbar.css'
import { imageAssets } from '../Assets/AssetsConstants/AssetsConstants';
import moment from 'moment'
import AdvancedFilter from '../Pages/SalesCoordinator/Dashboard/advancedFilter';
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfYear,
    startOfMonth,
    endOfMonth,
    endOfYear,
    addMonths,
    addYears,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
    addWeeks
} from "date-fns";
import { BiFilterAlt, BiSearch } from "react-icons/bi";
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import { allocateService, junkService } from '../Service/RawLeadsService';
import { toast } from 'react-toastify';
const {confirm} = Modal;
const ContentNavbar = ({
    leadsData,
    setSearchInput,
    menu,
    onApplyFilter,
    showDateFilter,
    setShowDateFilter,
    selectionRange,
    setSelectionRange,
    roleType,
    advancedFilterData,
    content,
    showAdvancedFilterModal,
    setShowAdvancedFilterModal,
    selectedFilterData,
    setSelectedFilterData,
    count,
    listType,
    removeFilterData,
    resetFilter,
    fileUploaderModal,
    uploadContent,
    enterWord,
    setEnterWord,
    closeInput,
    getMyLeadsBySearch,
    viewToggler,
    setCheck,
    check,
    showCardView,
    showListView,
    selectedIds,
    setCheckedItems,
    setSelectAllStatus,
    currentPage,
    checkedItems
}) => {
console.log(selectedIds,"selectedIds---->")
    console.log("selectionRange---", selectionRange, count, selectedFilterData);
    const [scorllX, setScorllX] = useState(0)
    const [scorllEnd, setScorllEnd] = useState(false)
    const [isSearchField, setIsSearchField] = useState(false);
    

    let keyRef = useRef()
    let popupRef = useRef()

    console.log("isSearchField:", isSearchField);
    /*handler of popup visisbility */
    useEffect(() => {
     
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleClickOutside = e => {
        console.log("eee--", e.target);
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            setShowDateFilter(false);
        }
    };

    /*handle for show and hide search field */
    const showSearchField = (event) => {
        setIsSearchField(true)
        event.stopPropagation();

    }

    const hideSearchField = (event) => {
        setIsSearchField(false)
        closeInput()
    }

    // //function for list view:
    // const showListView = () => {
    //     setCheck('list')
    // }

    // //function for card view:
    // const showCardView = () => {
    //     setCheck('card')
    // }

    //function for list view:
    const filterHandler = () => {
        setCheck('filter')

    }

    //function for card view:
    const settingsHandler = () => {
        setCheck('setting')
    }

    /* handler for select date range */
    const handleSelectDate = (item) => {
        console.log("ranges---", item);
        setSelectionRange([item.selection])
    }
    const startDate = selectionRange ? moment(selectionRange[0].startDate).format("DD MMM YYYY") : null
    console.log("dateeee--", startDate);
    const endDate = selectionRange ? moment(selectionRange[0].endDate).format("DD MMM YYYY") : null
    console.log("dateeee--", endDate);

    console.log("showDateFilter--", showDateFilter);
    console.log("con-advancedFilterData--", advancedFilterData);

    console.log("selectedFilterData--", selectedFilterData);
    console.log("content--showAdvancedFilterModal--", showAdvancedFilterModal);

    /*handler of popup visibility */
    const handleVisibleChange = (newVisible) => {
        console.log("newVisible--", newVisible);
        setShowAdvancedFilterModal(newVisible)
        if(showAdvancedFilterModal){
            setCheck('')
        }else{
            setCheck("filter")
        }
    }

    /* data sliding onclick of prev & next btn */
    const sildeData = (scrollOffset) => {
        console.log("prevvv--", scrollOffset);
        keyRef.current.scrollLeft += scrollOffset
        /* checking scorll has ended or not */
        if (Math.floor(keyRef.current.scrollWidth - keyRef.current.scrollLeft) <= keyRef.current.offsetWidth) {
            setScorllEnd(true)
        }
        else {
            setScorllEnd(false)
        }

    }
    /* check for scroll end */
    const scorllCheck = () => {
        setScorllX(keyRef.current.scrollLeft)
        if (Math.floor(keyRef.current.scrollWidth - keyRef.current.scrollLeft) <= keyRef.current.offsetWidth) {
            setScorllEnd(true)
        }
        else {
            setScorllEnd(false)
        }
    }

    // function for show daterange selector:
    const showDateRangeSelector = () => {
        setShowDateFilter(!showDateFilter)
        setCheck('date')
    }

    /* check width of the scrolling */
    useEffect(() => {
        if (keyRef.current && keyRef?.current?.scrollWidth === keyRef?.current?.offsetWidth) {
            setScorllEnd(true)
        }
        else {
            setScorllEnd(false)
        }

    }, [keyRef?.current?.scrollWidth, keyRef?.current?.offsetWidth])


    console.log("selectedFilterData--", selectedFilterData,"listType---",listType);
    
    const allocateLead = async (type) => {
        try {
            if (type === "Allocate") {
                
                    const payload = selectedIds.map((id) => ({
                        userId: localStorage.getItem("user_Id"),
                        leadId: id, 
                        option: 1,
                        status: "Raw", 
                    }));
             
                    const response = await allocateService(payload);
    console.log(response,"responseData")
                    if (response?.status === "success" && response?.allocatedPersons?.length > 0) {
                        const allocatedPersons = response?.allocatedPersons.map(person => person.allocatedPerson);                      
                        
                        if (allocatedPersons) {
                           toast.success(`Allocated to ${allocatedPersons}`)
                        } 
                    } 
                    onApplyFilter(selectionRange[0], null, 1) 
                    setCheckedItems((prev) => ({ ...prev, [currentPage]: [] })); 
            setSelectAllStatus((prev) => ({ ...prev, [currentPage]: false }));
            } 

        } catch (error) {
            toast.error("Failed to allocate lead");
        }
    };
    
    const handleDelete = async () => {
        let reason;
        confirm({
    
            title: 'Are you sure you want to delete lead?',
            icon: null,
            content:(
                <div>
                <input type='text' id="reason" style={{width:"100%"}} placeholder='Reason for deletion' onChange={(e)=>{
                    reason=e.target.value
                }}/>
                </div>

            ),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'Cancel',
            centered: true,
            okButtonProps: {
                style: {
                    backgroundColor: '#00AC69',
                    color: 'white',
                    borderColor: '#00AC69',
                    borderRadius: '8px', 
                    fontSize: '16px', 
                    height: '40px',
                    padding: '0 24px',
                }
            },
            cancelButtonProps: {
                style: {
                    color: '#FF9494',
                    borderColor: '#FF9494',
                    borderRadius: '8px', 
                    fontSize: '16px', 
                    height: '40px',
                    padding: '0 24px'
                }
            },
            onOk: async () => {
              
               const payload = selectedIds.map((id) => ({
                userId: localStorage.getItem("user_Id"),
                leadId: id, 
                remarks:reason,
                status: "Raw", 
            }));
                try{
                    const response = await junkService(payload);
                    console.log(response,"deleted response")
                    if (response.status === "success") {
                        toast.success("Successfully Deleted the Lead");
                    }
                    onApplyFilter(selectionRange[0], null, 1) 
                    setCheckedItems((prev) => ({ ...prev, [currentPage]: [] })); 
                    setSelectAllStatus((prev) => ({ ...prev, [currentPage]: false }));
                }catch(error){
                    toast.error("Failed to Delete the Lead");

                }
               
            },
            onCancel() {
                
            },
        })
        
    };
  
    const isAnyCheckboxChecked =  checkedItems!==undefined &&  checkedItems[currentPage]?.length > 0;
    

    return (

        <>
            <div className="contentNavbar">
                <div className='leftDefaultBtns'>
                    <div className='filterWrapper'>
                        {/* <BiFilterAlt size={20} color="rgb(120, 116, 116)" /> */}
                        <span>{listType}</span>
                        <span>{count}</span>
                    </div>

                    {/* <div className="searchIconContainer" >
                        {isSearchField ?
                        <div className="serachInput">
                            <div>
                                <input type="text"
                                    placeholder="Search"
                                    value={enterWord}
                                    className="searchField"
                                    onChange={setEnterWord}
                                />
                                <AiOutlineSearch size="20px" className="searchIcon" onClick={hideSearchField} />
                                {enterWord && <AiOutlineClose size="18px" className="clearIcon" onClick={closeInput} />}
                            </div>
                        </div>
                        :
                            <button type="button" class="btn btnDefault" onClick={showSearchField} >
                                <img src={imageAssets.search} />
                            </button>
                        }

                    </div> */}
                    <Searchbar closeInput={closeInput} enterWord={enterWord} setEnterWord={setEnterWord} check={check} setCheck={setCheck} />

                    {scorllX !== 0 && (
                        <button onClick={() => sildeData(-50)} className="arrow">
                            <i class="fa fa-angle-left" aria-hidden="true" />
                        </button>
                    )}

                    <div className="tagsFilterData">
                        <ul className="" ref={keyRef} onScroll={scorllCheck}>
                            {selectedFilterData?.map((i, key) => (
                                <>
                                    <li>
                                        <span>{i}</span>
                                        <img src={imageAssets.cancel} alt=""
                                            onClick={() => removeFilterData(i)} />
                                    </li>
                                </>
                            ))}
                            {selectedFilterData?.length > 3 &&
                                <button onClick={resetFilter}>Clear Filter</button>


                            }
                        </ul>
                    </div>
                    {!scorllEnd && (
                        <button onClick={() => sildeData(50)} className="arrow">
                            <i class="fa fa-angle-right" aria-hidden="true" />
                        </button>
                    )}
                </div>
                <div className='rightActionBtns' >
                    <div className='btn-container'>
                        {checkedItems &&<>
                    <button  className={`allocate-btn ${isAnyCheckboxChecked ? 'active' : 'disabled'}`}  onClick={() => allocateLead("Allocate")} disabled={!isAnyCheckboxChecked}>Allocate</button>
                    <button   className={`delete-btn ${isAnyCheckboxChecked ? 'active' : 'disabled'}`} onClick={handleDelete} disabled={!isAnyCheckboxChecked}>Delete</button>
                    </>}</div>
                    {(listType === "TKP" || listType === "SW" || listType === "AP" || listType === "IP" || 
                      listType === "TKP Approvals" || listType === "SW Approvals" || listType === "AP Approvals" || listType === "IP Approvals"
                      || listType === "ALL TKP" || listType === "ALL SW" || listType === "ALL AP" || listType === "ALL IP")? " " : 
                       <button className={`btn dateSelector ${check === "date" ? "ActiveBtn" : ""}`} onClick={showDateRangeSelector}> {`${startDate} - ${endDate}`}
                       <img src={imageAssets.calendar} className="calendarIcon" />
                   </button> 
                     
                    }
                   

                    <div class="groupBtns">
                        
                     {(listType === "TKP" || listType === "SW" || listType === "AP" || listType === "IP"  ||
                     listType === "TKP Approvals" || listType === "SW Approvals" || listType === "AP Approvals" || listType === "IP Approvals"
                     || listType === "ALL TKP" || listType === "ALL SW" || listType === "ALL AP" || listType === "ALL IP") 
                      
                     ? " " : 
                     <button type="submit" className={`btn applyFilterBtn ${check === "filterBtn" ? "ActiveBtn" : ""}`} onClick={() => onApplyFilter(selectionRange[0], null, 1)}>Apply Filter</button>
                        }
                     
                        <button type="button" class="btn toggleBtn" >
                            <div className={`toggleBtnIcon tkp-toggle-btn-list ${check === "list" ? "ActiveIcon" : ""}`} onClick={showListView}>
                                <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M7,6H23a1,1,0,0,0,0-2H7A1,1,0,0,0,7,6Z" /><path d="M23,11H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" /><path d="M23,18H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" /><circle cx="2" cy="5" r="2" /><circle cx="2" cy="12" r="2" /><circle cx="2" cy="19" r="2" /></svg>
                            </div>
                            </button>
                            <button type="button" class="btn toggleBtn" >
                            {/* <div className="vLine"></div> */}

                            <div className={`toggleBtnIcon tkp-toggle-btn-list ${check === "card" ? "ActiveIcon" : ""}`} onClick={showCardView}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24     24" width="" height="20"><g id="_01_align_center" data-name="01 align center"><path d="M11,11H0V3A3,3,0,0,1,3,0h8ZM2,9H9V2H3A1,1,0,0,0,2,3Z" /><path d="M24,11H13V0h8a3,3,0,0,1,3,3ZM15,9h7V3a1,1,0,0,0-1-1H15Z" /><path d="M11,24H3a3,3,0,0,1-3-3V13H11ZM2,15v6a1,1,0,0,0,1,1H9V15Z" /><path d="M21,24H13V13H24v8A3,3,0,0,1,21,24Zm-6-2h6a1,1,0,0,0,1-1V15H15Z" /></g></svg>
                            </div>
                        </button>
                        <Popover title="Advanced Filter" arrowPointAtCenter trigger={"click"} placement="bottomLeft" overlayClassName="customPopover" content={content} visible={showAdvancedFilterModal} onVisibleChange={handleVisibleChange} >
                            <button type="button" className={`btn btnDefault ${check === "filter" ? "ActiveIcon" : ""}`} >
                                <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M1,4.75H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2ZM7.333,2a1.75,1.75,0,1,1-1.75,1.75A1.752,1.752,0,0,1,7.333,2Z" /><path d="M23,11H20.264a3.727,3.727,0,0,0-7.194,0H1a1,1,0,0,0,0,2H13.07a3.727,3.727,0,0,0,7.194,0H23a1,1,0,0,0,0-2Zm-6.333,2.75A1.75,1.75,0,1,1,18.417,12,1.752,1.752,0,0,1,16.667,13.75Z" /><path d="M23,19.25H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2ZM7.333,22a1.75,1.75,0,1,1,1.75-1.75A1.753,1.753,0,0,1,7.333,22Z" /></svg>
                            </button>
                        </Popover>


                        {listType === "Leads" ?
                            <Popover title="More Options" arrowPointAtCenter trigger={"click"} placement="bottomLeft" overlayClassName="customPopover" content={uploadContent}>
                                <button type="button" className={`btn btnDefault ${check === "setting" ? "ActiveIcon" : ""}`} onClick={settingsHandler} >
                                    <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" /><path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" /></svg>
                                </button>
                            </Popover> :

                            <button type="button" className={`btn btnDefault ${check === "setting" ? "ActiveIcon" : ""}`} onClick={settingsHandler}>
                                <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" /><path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" /></svg>
                            </button>
                        }
                    </div>
                </div>
            </div>
            <div className='popup'>
                {showDateFilter &&
                    <div className='date-range' ref={popupRef}>
                        <div className="date-filter" >
                            <DateRangePicker
                                ranges={selectionRange}
                                onChange={(e) => handleSelectDate(e)}
                                months={2}
                                direction="horizontal"
                                inputRanges={[]}
                                staticRanges={[
                                    ...defaultStaticRanges,
                                    {
                                        label: "This Year",
                                        range: () => ({
                                            startDate: startOfYear(new Date()),
                                            endDate: endOfDay(new Date())
                                        }),
                                        isSelected(range) {
                                            const definedRange = this.range();
                                            return (
                                                isSameDay(range.startDate, definedRange.startDate) &&
                                                isSameDay(range.endDate, definedRange.endDate)
                                            );
                                        }
                                    },
                                    {
                                        label: "Last Year",
                                        range: () => ({
                                            startDate: startOfYear(addYears(new Date(), -1)),
                                            endDate: endOfYear(addYears(new Date(), -1))
                                        }),
                                        isSelected(range) {
                                            const definedRange = this.range();
                                            return (
                                                isSameDay(range.startDate, definedRange.startDate) &&
                                                isSameDay(range.endDate, definedRange.endDate)
                                            );
                                        }
                                    },
                                ]}

                            // showSelectionPreview={true}
                            // moveRangeOnFirstSelection={false}
                            />
                        </div>
                    </div>
                }
            </div>
        </>

    )
}

export default ContentNavbar

