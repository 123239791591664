import axios from 'axios'
import instanceAll from './CommonAxiosInstance';

export const sendOTPService=async(body)=>{
    try{
    console.log("body in instance file",body)
    const response=await instanceAll.post("/send-otp",body)
    console.log("response",response )
    if(response?.status===200){
        
            
        return{
            status:"success",
            data:response.data,
        }
      
    }
    else {
        console.log("Something Went Wrong");
    }
}

catch(error){
    return (
        {
            status: "Failed",
            message: error
        }
    )
}
}