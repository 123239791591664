import angleLeft from '../images/angle-left.svg';
import apps from '../images/apps.svg';
import angleDown from '../images/angle-down.svg';
import angleRight from '../images/angle-right.svg';
import appSort from '../images/apps-sort.svg';
import bell from '../images/bell.svg';
import building1 from '../images/building.png';
import building2 from '../images/building.svg';
import calendar from '../images/calendar.svg';
import comment from '../images/comment.svg';
import edit1 from '../images/edit.png';
import edit2 from '../images/edit.svg';
import form from '../images/form.svg';
import graph from '../images/graph.svg';
import head from '../images/head.png';
import hidePswd from "../images/hide-pswd.svg";
import hippiImage from '../images/hippiimage.jpeg';
import home from '../images/home.svg';
import layoutFuild from "../images/layout-fluid.svg";
import listcheck from "../images/list-check.svg";
import List from "../images/list.svg";
import mapMarker from "../images/map-marker.svg";
import menuDots from "../images/menu-dots.svg";
import noData from "../images/no-data.png";
import profile from "../images/profile.png";
import profile1 from "../images/profile1.png";
import settingsSlider from "../images/settings-sliders.svg";
import settings from "../images/settings.svg";
import showPswd from "../images/show-pswd.svg";
import sideLogo from "../images/sidelogo.jpeg";
import timeQuarter from "../images/time-quarter-to.svg";
import trash from "../images/trash.svg";
import userAddSp from "../images/user-add-sp.svg";
import userAdd from "../images/user-add.svg";
import wehouseSignInBg from "../images/wehouse-signin-bg.jpg";
import GoogleLogo from "../images/google-logo-sm.png";
import TimeQuarter from '../images/time-quarter-to.svg';
import listCheck from '../images/list-check.svg';
import TimeDelete from '../images/time-delete.svg';
import Refresh from '../images/refresh.svg';
import WehouseLogo from '../images/wh-logo-1.svg';
import UpArrow from '../images/arrow-upward.png';
import DownArrow from '../images/down-arrow.png';
import wLogo from '../images/wLogo.svg';
import Hyderabad from '../images/hyderabad.jpg';
import wehouseFav from "../images/favIcon.png";
import FavIcon from '../images/favIcon.png';
import project from '../images/bg.jpg';
import cancel from '../images/cancel.png';
import Main from "../images/pic3.png";
import main2 from '../images/pic2.png';
import main3 from "../images/pic1.png";
import main4 from "../images/pic4.png";
import area1 from "../images/pic11.png";
import area2 from "../images/pic12.png";
import area3 from "../images/pic13.png";
import area4 from "../images/pic14.png";
import search from "../images/search.svg";
import noMileStone from "../images/noMilestone.png";
import handMoney from "../images/hand.png";
import marker from "../images/marker.png";
import housemarker from "../images/housemarker.jpg";

export const imageAssets = {
    angleLeft,
    apps,
    angleDown,
    angleLeft,
    angleRight,
    appSort,
    bell,
    building1,
    building2,
    calendar,
    comment,
    edit1,
    edit2,
    form,
    graph,
    FavIcon,
    head,
    hidePswd,
    hippiImage,
    home,
    layoutFuild,
    listcheck,
    List,
    mapMarker,
    menuDots,
    noData,
    profile,
    profile1,
    settingsSlider,
    settings,
    showPswd,
    sideLogo,
    timeQuarter,
    trash,
    userAddSp,
    userAdd,
    wehouseSignInBg,
    GoogleLogo,
    TimeQuarter,
    listCheck,
    TimeDelete,
    Refresh,
    WehouseLogo,
    UpArrow,
    DownArrow,
    wLogo,
    Hyderabad,
    wehouseFav,
    project,
    cancel,
    Main,
    main2,
    main3,
    main4,
    area1,
    area2,
    area3,
    area4,
    search,
    noMileStone,
    handMoney,
    marker,
    housemarker
}