import axios from "axios";
import instanceAll from './CommonAxiosInstance';

// Add customers Api:
export const AddCustomerService = async (body) => {
    // Adding baseURL to addCustomer Api services:
    try {
        let response = await instanceAll.post("/addCustomer", body);
        //   console.log("addCustomer Res:::",response);
        if (response?.status === 200) {
            return {
                status: "success",
                data: response.data,
            }
        }
        else {
            console.log("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
    }

}

// Get Customers List Api:
export const GetCustomersListService = async (body) => {
    // Adding baseURL to get_customers_list Api services:

    try {
        console.log("get customer list", body);
        let response = await instanceAll.post("/get_customers_list", body);
        // console.log("get_customers_list Res:::",response);
        if (response?.status === 200 && response.data.getcustomerslist) {
            return {
                status: "success",
                data: response.data,
                count: response.data.count
            }
        }
        else {
            console.log("Something Went Wrong");
            return (
                {
                    status: "Failed"
                }
            )
        }
    } catch (err) {
        console.log(err);
        return (
            {
                status: "Failed"
            }
        )
    }

}

//  Get Previous Data for Customer Api :
export const GetCustomersDataByIdService = async (body) => {
    // Adding baseURL to get_customers_list Api services:

    try {
        let response = await instanceAll.post("/get-customer-by-id", body);
        //   console.log("/get-customer-by-id Res:::",response);
        if (response?.status === 200) {
            return {
                status: "success",
                data: response.data,
            }
        }
        else {
            console.log("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
    }
}

//  Update Customer Api :
export const UpdtaeCustomersDataService = async (body) => {
    // Adding baseURL to get_customers_list Api services:

    try {
        let response = await instanceAll.post("/update-customer", body);
        //   console.log("update-customer Res:::",response);
        if (response?.status === 200 && response?.data?.updatedCustomer) {
            return {
                status: "success",
                data: response.data,
                customerId: response.data.updateCustomerData.user_id
            }
        }
        else {
            console.log("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
    }
}


// Get project list Api:
export const GetProjectsListService = async (body) => {
    // Adding baseURL to get_projects_list Api services:

    try {
        let response = await instanceAll.post("/get_projects_list", body);
        // console.log("get_projects_list Res:::",response);
        if (response.status === 200) {
            return {
                status: "success",
                data: response.data,
                count: response.data.count
            }
        }
        else {
            console.log("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
    }
}

export const addProjectService = async (body) => {
    console.log("body  project", body);
    try {
        let response = await instanceAll.post("/add-project", body);
        if (response.status === 200 && response.data.isProjectAdded) {
            return ({
                status: "success",
                message: "successfully added project",
                projectId: response?.data.projectId,
                projectType: response?.data.projectType,
                customerId: response?.data.customerId
            })
        }
        else {
            return (
                {
                    status: "failed",
                    message: "failed to add project"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )

    }
}

export const getProjectByCustomerId = async (body) => {
    try {
        console.log("body in get project by customer id", body);
        const response = await instanceAll.post("/get-project-by-id", body);
        console.log("response", response);
        if (response.status === 200 && response.data.isGetProjectDetails) {
            return (
                {
                    status: "success",
                    data: response.data.projectDetails[0],
                    message: "successfully get the project data"
                }
            )
        }
        else {
            return (
                {
                    status: 404,
                    data: [],
                    messag: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )

    }
}

export const updateProjectService = async (body) => {
    try {
        console.log("inside the update project service", body);
        const response = await instanceAll.post("/update-customer-project", body);
        if (response.status === 200 && response.data.isProjectUpdated) {
            return (
                {
                    status: "success",
                    message: "successfully updated project",
                    projectId: response.data.projectId,
                    projectType: response.data.projectType,
                    projectAmount:response.data.projectAmount
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    message: "Failed to update project"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )

    }
}

export const createPaymentInstallementsService = async (body) => {
    try {
        console.log("payment service", body);
        const response = await instanceAll.post("/create-payment-details", body);
        if (response.status === 200 && response.data.isGetPayments) {
            return (
                {
                    status: "success",
                    paymentDetails: response.data.paymentDetails
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    paymentDetails: []
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "Failed",
                message: error
            }
        )
    }

}

export const updatePaymentInstallementsService = async (body) => {
    try {
        console.log("body", body);
        const response = await instanceAll.post("/update-payment-installements", body);
        if (response.status === 200 && response.data.isPaymentsUpdated) {
            return (
                {
                    status: "success",
                    message: "successfully Updated"
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    message: "Failed to update"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: "Failed to update"
            }
        )

    }
}
export const getPaymentInstallementsService = async (body) => {
    try {
        const response = await instanceAll.post("/get-payment-details", body);
        console.log("getPaymentInstallementsService :",response);

        if (response.status === 200 && response.data.isGetPaymentDetails) {
            return (
                {
                    status: "success",
                    paymentDetails: response.data.paymentDetails
                }
            )

        }
        else {
            return (
                {
                    status: "failed",
                    paymentDetails: []
                }
            )
        }

    } catch (error) {
        return (
            {
                status: 'failed',
                message: error
            }
        )

    }
}