import instanceAll from "./CommonAxiosInstance";

const pcProjectListService = async (body) => {
    try {
        const response = await instanceAll.post("/get-pc-project-list", body);
        if (response.status === 200 && response.data.isGetPCProjectList) {
            return (
                {
                    status: "success",
                    projectList: response.data.projectList,
                    count: response.data.count,
                    message: "successfully Fetch the PC Project list"
                }

            )
        }
        else {
            return (
                {
                    status: "Failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "Failed",
                message: error
            }
        )
    }
}
const getMilestonesData = async (body) => {
    const response = await instanceAll.post("/get-milestons-pc", body);
    console.log("response-mile--", response);
    if (response && response.data?.status === 200 && response.data?.isGetMilestones) {
        return response.data
    }
    else {
        return response.data
    }
}
const getMilestoneTasks = async (body) => {
    const response = await instanceAll.post("/get-milestons-task-pc", body);
    console.log("response-TAKS--", response);
    if (response && response.data?.status === 200 && response.data?.hasMilestoneTasks) {
        return response.data
    }
    else {
        return response.data
    }
}
const updateMilestoneStatus = async (body) => {
    const response = await instanceAll.post("/update-milestons-status", body);
    console.log("response___", response);
    if (response && response.data?.status === 200 && response.data?.isMilestoneStatusChanged) {
        return response.data
    }
    else {
        return response.data
    }
}
const addMilestoneTask = async (body) => {
    const response = await instanceAll.post("/add-crct-task", body);
    console.log("response_task__", response);
    if (response && response.data?.status === 200 && response.data?.isTaskAdded) {
        return response.data
    }
    else {
        return response.data
    }
}

const pcPaymentRequest = async (body) => {
  try {
    const response = await instanceAll.post('/pc-payment-request', body);
    console.log('pcPaymentRequest response :', response);
    if (response && response.status === 200) {
        return (
            {
                status: "success",
                data: response.data
            }
        )

    } else {
        return (
            {
                status: "failed",
                data: response.data
            }
        )
    }
  } catch (error) {
    return(
        {
            status:-1,
            message: error
        }
    )
  }
}

const getpaymentRequest = async (body)=>{
    try {

        const response = await instanceAll.post('/get-payment-request',body);
        console.log('get payment resquest :',response);
       if (response && response.status === 200 ) {
             return (
                {
                    status:"success",
                    data:response.data
                }
             )
       } else {
        return (
            {
                status:"failed",
                data:response.data
            }
         )
       }
        
    } catch (error) {
        return(
            {
                status:-1,
                message:error
            }
        )
    }

}
const addSubTaskDescriptionServices = async (body) => {
    try {
        const response = await instanceAll.post("/add-subtask-description", body);
        console.log("response-----",response);
        if (response && response.data?.status === 200 && response.data?.isSubtaskContentAdded) {
            return response.data
        }
        else {
            return response.data
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}
const updateSubTaskDescriptionServices = async (body) => {
    try {
        const response = await instanceAll.post("/update-subtask-description", body);
        console.log("response-----",response);
        if (response && response.data?.status === 200 && response.data?.isSubtaskContentAdded) {
            return response.data
        }
        else {
            return response.data
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}
const changeSubtaskStatus = async (body) => {
    try {
        const response = await instanceAll.post("/update-milestone-task-status", body);
        console.log("response---ch--",response);
        if (response && response.data?.status === 200 && response.data?.isMilestoneStatusChanged) {
            return response.data
        }
        else {
            return response.data
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}
const getCrctSubtasks= async (body) => {
    try {
        const response = await instanceAll.post("/get-crct-subtask", body);
        console.log("response---ch--",response);
        if (response && response.data?.status === 200 && response.data?.isGetCrctSubtasks) {
            return response.data
        }
        else {
            return response.data
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}
const addBlockResumeDesc= async (body) => {
    try {
        const response = await instanceAll.post("/add-block-resume-desc", body);
        console.log("response---ch--",response);
        if (response && response.data?.status === 200 && response.data?.addedBlockResumeDesc) {
            return response.data
        }
        else {
            return response.data
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}
const getBlockResumeDesc= async (body) => {
    try {
        const response = await instanceAll.post("/get-block-resume-desc", body);
        console.log("response---ch--",response);
        if (response && response.data?.status === 200 && response.data?.getBlockResumeDesc) {
            return response.data
        }
        else {
            return response.data
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}
const getOngoingProjectData= async (body) => {
    try {
        const response = await instanceAll.post("/get-project-milestone-status-details", body);
        console.log("response---on--",response);
        if (response && response.data?.status === 200 && response.data?.getBlockResumeDesc) {
            return response.data
        }
        else {
            return response.data
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}
const taskBlockResumeData= async (body) => {
    try {
        const response = await instanceAll.post("/get-task-blockunblock-desc", body);
        console.log("response---:on--",response);
        if (response && response.data?.status === 200 && response.data?.getBlockResumeDesc) {
            return response.data
        }
        else {
            return response.data
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}
const deleteSubTaskDescriptionServices = async (body) =>{
    console.log(body,"body")
    try {
        const response = await instanceAll.post("/delete-subtask-description", body);
        console.log("response-----", response);
        if (response && response.data?.status === 200 && response.data?.isSubtaskDeleted) {
            return response.data;
        } else {
            return response.data;
        }
    } catch (error) {
        return {
            status: "failed",
            message: error
        };
    }
}
const getMilestoneTasksByDate = async(body)=>{
    console.log(body,"body")
    try {
        const response = await instanceAll.post("/tasks-by-milestone-date", body);
        console.log("response-----", response);
        if (response && response.data?.status === 200 && response.data?.isSubtaskDeleted) {
            return response.data;
        } else {
            return response.data;
        }
    } catch (error) {
        return {
            status: "failed",
            message: error
        };
    }
}

const sendBlockMails=async(body)=>{
 
    try{
        console.log("body in instance file block mail",body)
        const response=await instanceAll.post("/send-block-mail",body)
        console.log("response",response )
        if(response?.status===200){
            
                
            return{
                status:"success",
                
            }
          
        }
        else {
            console.log("Something Went Wrong");
        }
    }
    
    catch(error){
        return (
            {
                status: "Failed",
                message: error
            }
        )
    }
}
const getDrawingSections  = async()=>{
  
    try {
        const response = await instanceAll.get("/get-drawing-sections");
        console.log("response-----", response);
        if (response && response.data?.status === 200) {
            return response.data;
        } else {
            return response.data;
        }
    } catch (error) {
        return {
            status: "failed",
            message: error
        };
    }
}
const getDrawingsByUuid  = async(body)=>{
    console.log(body,"body")
  
    try {
        const response = await instanceAll.post("/get-drawings-by-uuid",body);
        console.log("response-----", response);
        if (response) {
            return response;
        } 
    } catch (error) {
        return {
            status: "failed",
            message: error
        };
    }
}
const pcServices = {
    pcProjectListService,
    getMilestonesData,
    getMilestoneTasks,
    updateMilestoneStatus,
    addMilestoneTask,
    pcPaymentRequest,
    getpaymentRequest,
    addSubTaskDescriptionServices,
    updateSubTaskDescriptionServices,
    changeSubtaskStatus,
    getCrctSubtasks,
    addBlockResumeDesc,
    getBlockResumeDesc,
    getOngoingProjectData,
    taskBlockResumeData,
deleteSubTaskDescriptionServices,
getMilestoneTasksByDate,
sendBlockMails,
getDrawingSections,
getDrawingsByUuid
}

export default pcServices;