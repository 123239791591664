import React from 'react';
import "./NoData.css";
import { imageAssets } from '../../Assets/AssetsConstants/AssetsConstants';


const NoData = ({message, type}) => {
    return (
        <div className="noDataFoundBox1" style={{paddingRight: type === 'milestone' && "400px"}}>
            <img src={type === 'milestone' ? imageAssets.noMileStone :  imageAssets.noData} className="no-data-img" />
            <span>{message?message:type === "milestone" ? "Select an item to read" : "No Data Found"}</span>
        </div>
    )
}

export default NoData