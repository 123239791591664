import { takeEvery, call, put,all } from 'redux-saga/effects';
import { DropDownServices } from '../../Service/DropDownServices';
import { getdesignationFailure, getdesignationsSuccess } from '../Slices/DesignationsListSlice';
import { getRolesSuccess } from '../Slices/RolesListSLice';
import { getStatusListSuccess} from '../Slices/getStatusListSlice';

export const DESIGNATIONLISTTYPE=()=>({type:"/designationList"});


export function* getDesignationListGenerator(action) {
    // console.log(action);
   const response= yield all ([DropDownServices.getDesignationListService(),DropDownServices.getAssociatedRolesListService(),DropDownServices.getStatusListService()]);
   console.log("getDesignationListGenerator",response);
//    if(response[0]?.status === "success"){
     yield put (getdesignationsSuccess(response[0]?.status == 'success' ? response[0].data : []));
     yield put (getRolesSuccess(response[1]?.status == 'success' ? response[1].data : []));
     yield put (getStatusListSuccess(response[2]?.status == 'success' ? response[2].data : []))
//    }
//    else{
//        console.log("something went wrong from get designation generation")
//    }

}


function* designationListWatcher() {
    // console.log("inside the customer by id watcher");
    yield takeEvery(DESIGNATIONLISTTYPE().type, getDesignationListGenerator);
}

export default designationListWatcher;