import { takeEvery, call, put } from 'redux-saga/effects';
import { DropDownServices } from '../../Service/DropDownServices';
import { getStatusListSuccess,getStatusListFailure } from '../Slices/getStatusListSlice';

export const getStatusListType=(type)=>({type:"/getStatusList",payload:type});

export function* getStatusList(action){
    console.log("get status list",action);
    const response = yield DropDownServices.getStatusListService({type:action.payload.type});
    console.log("getStatusList:",response);
    if(response && response.status === "success"){
        yield put(getStatusListSuccess(response.data))
    }else{
        yield put(getStatusListFailure(response.data.message))
    }
}

function* getStatusListWatcher(){
    yield takeEvery(getStatusListType().type,getStatusList)
}

export default getStatusListWatcher;