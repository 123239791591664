import moment from 'moment';
import React from 'react';


const logContentWrapper = (
    {
        logDetails
    }
) => {

    return (
        <div className='logContentWrapper'>
            <div className='imgWrapper'>
                <img src="https://wehouse-images.s3.ap-south-1.amazonaws.com/Images/Profile-Image/4e377326-4abb-11ed-b0c5-0a45a85dffcc/884c1ee0-696e-4fc4-a407-28295b233705.jpg"
                    alt="" class="blankAvatarImg"
                />

            </div>
            <div className='contentWrapper'>
                <div>
                    <h4 className='col-7'>
                        {logDetails?.changedName}
                        <span>{logDetails?.designation}</span>
                    </h4>
                    <span>{moment(logDetails?.logTime).utc().format("DD MMM YYYY , hh:mm A")}</span>
                </div>
                <p>
                    {logDetails?.description}
                </p>
            </div>
        </div>
    )
}

export default logContentWrapper