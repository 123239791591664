import {takeEvery,call,put,takeLatest} from 'redux-saga/effects';
import { postSigninService } from '../../Service/SigninService';
import { getArchitectPartnersLoader } from '../Slices/architectPartnersListSlice';
import { customerLoader } from '../Slices/CustomersSlice';
import { getInteriorPartnersLoader } from '../Slices/interiorPartnersListSlice';
import { loginFailure, loginLoader, loginSuccess } from '../Slices/LoginSlice';
import { TurnkeyPartnersLoader } from '../Slices/turnkeyPartnersListSlice';
import { wehouseUserLoader } from '../Slices/WeHouseUserSlice';
import { getArchitectPartnersList } from './architectPartnersList';
import { getCustomerById } from './CustomerById';
import { DESIGNATIONLISTTYPE, getDesignationListGenerator } from './DesignationList';
import { getCategoriesList, GETCATEGORIESLIST } from './getCategoriesList';
import { GETEDUCATIONALLIST, getEducattionalList } from './getEducationalList';
import { getPartnerManagerList, GETPARTNERMANAGERSLIST } from './getpartnerManagersList';
import { getSkillTypeList, GETSKILLTYPELIST } from './getSkillTypeList';
import { getStatusList, getStatusListType } from './getStatusList';
import { getInteriorPartnersList } from './interiorPartnersList';
import { getAssociatedRolesgenerator, ROLESLISTTYPE } from './RolesList';
import TurnkeyPartnersWatcher, { getTurnkeyPartners } from './turnkeyPartnersList';
import { getWeHouseUserById } from './WeHouseUserById';
import { getYerasOfExperienceListGenerator, YEARSOFEXPERIENCELIST } from './YearsOfExperienceList';


function* loginGenerator(action){
    // console.log("inside the login generator",action);
    // alert('check')
    const response= yield call(postSigninService,action.payload.data);
    console.log('SignIn response',response);
    if(response?.status === "success" && response.data.isSignin === true && response.data.UserId){
        action.payload.routehandler(response.data.UserId,action.payload.data.userName,response.data.token);
        yield put(loginSuccess(response.data.UserId));
  
    } 
    else if(response?.status === "failed" && response.data.isSignin === false){
        yield put(loginFailure(response.data.message))
    }
    else if(response?.status === "failed" && response.data.status === -1 && response.data.message.severity === "ERROR"){
        yield put(loginFailure("User doesn't exist"))
    }

}


function* loginWatcher(){
    // console.log("inside the login watcher");
    // alert("login watcher");
    yield takeEvery(loginLoader().type,loginGenerator)
    console.log("inside the login watcher");
    // yield takeEvery(loginLoader().type,loginGenerator);
    // yield takeEvery(customerLoader().type,getCustomerById);
    // yield takeEvery(wehouseUserLoader().type,getWeHouseUserById);
    // yield takeEvery(DESIGNATIONLISTTYPE().type,getDesignationListGenerator);
    // yield takeEvery(TurnkeyPartnersLoader().type,getTurnkeyPartners);
    // yield takeEvery(YEARSOFEXPERIENCELIST().type,getYerasOfExperienceListGenerator);
    // yield takeEvery(ROLESLISTTYPE().type,getAssociatedRolesgenerator);
    // // yield takeEvery(getArchitectPartnersLoader().type,getArchitectPartnersList);
    // yield takeEvery("APLIST",getArchitectPartnersList);
    // yield takeEvery(getInteriorPartnersLoader().type,getInteriorPartnersList);
    // yield takeEvery(getStatusListType().type,getStatusList);
    // yield takeEvery(GETSKILLTYPELIST().type,getSkillTypeList);
    // yield takeEvery(GETCATEGORIESLIST().type,getCategoriesList);
    // yield takeEvery(GETEDUCATIONALLIST().type,getEducattionalList);
    // yield takeEvery(GETPARTNERMANAGERSLIST().type,getPartnerManagerList);
    // yield takeEvery(customerLoader().type,getCustomerById);
}

export default loginWatcher