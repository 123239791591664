import React, { useEffect, useState } from "react"
import "simplebar/src/simplebar.css"
import { myLeadsAppointments } from "../../../../Service/MyLeadsService"
import "./MyAppointments.css"
import moment from "moment"
import AppointmentDetailsModal from "./AppointmentDetailsModal/AppointmentDetailsModal"
import AppointmentsDetails from "./AppointmentsDetails/AppointmentsDetails"
import Loader from "../../../../Components/Loader"
import DatePicker from "../../../../Components/CommonComponents/dateRangePicker"
import { Modal } from "antd"
import { subDays } from "date-fns"
import GenericActivity from "../GenericActivity/GenericActivity"

const Appointments = () => {
   const [mainScreen, setMainScreen] = useState("main")
    const [date, setDate] = useState(new Date())
    const [appointmentsData, setAppointmentsData] = useState([])
    const [appointmentsModalVisible, setAppointmentsModalVisible] = useState(false)
    const [selectedAppointment, setSelectedAppointment] = useState(null)
    const [apiLoader, setApiLoader] = useState(false)
    const [selectRange, setSelectRange] = useState([
        {
            startDate: subDays(new Date(), 7),
            endDate: subDays(new Date(), 0),
            key: "selection"
        }
    ])

    const handleDate = (date) => {
        console.log("onchange date", new Date(date))
        setDate(new Date(date))
    }

    const handleAppointmentData = async () => {
        try {
            setApiLoader(true)
            const body = {
                userId: Number(window.localStorage.getItem("user_Id")),
                appointmentType: "Schedule Meeting",
                appointmentFromDate: selectRange[0].startDate
                    ? moment(selectRange[0].startDate).format("YYYY-MM-DD")
                    : null,
                appointmentToDate: selectRange[0].endDate ? moment(selectRange[0].endDate).format("YYYY-MM-DD") : null
            }
            console.log("body", body)
            const response = await myLeadsAppointments(body)
            if (response.status === "success") {
                setApiLoader(false)
                setAppointmentsData(response.data)
            } else {
                setApiLoader(false)
                setAppointmentsData([])
            }
        } catch (error) {
            setAppointmentsData([])
            setApiLoader(false)
        }
    }
    const closeModal = () => {
        setAppointmentsModalVisible(false)
        // handleReset();
    }
    const handleModal = (details) => {
        console.log("details", details)
        setSelectedAppointment(details)
        setAppointmentsModalVisible(true)
    }

    useEffect(() => {
        handleAppointmentData()
    }, [selectRange])
    console.log("appointments data", appointmentsData, moment(date.toISOString()).format("YYYY-MM-DD"))

    return (
        <div>
            {apiLoader ? (
                <Loader />
            ) : (
                <>
                    {(mainScreen == "main" && (
                        <div className="mainContent">
                            <div className="contentHeader">
                                <span className="headerTitle">My Appointments</span>
                            </div>
                            <div className="appointment-wrapper appointmentsWrapper">
                                <div className="leftBlock">
                                    <DatePicker
                                        date={selectRange}
                                        handler={setSelectRange}
                                        className="verticalCalendar"
                                    />
                                </div>
                                <AppointmentsDetails
                                    appointmentsData={appointmentsData}
                                    date={selectRange}
                                    handleModal={handleModal}
                                    type={"myappointments"}
                                />
                            </div>
                            <Modal
                                visible={appointmentsModalVisible}
                                onOk={closeModal}
                                onCancel={closeModal}
                                footer={null}
                                className="customModal"
                                title={"Appointment Details"}
                            >
                                <AppointmentDetailsModal
                                    selectedAppointment={selectedAppointment}
                                    type={"myappointments"}
                                    setMainScreen={setMainScreen}
                                    closeModal={closeModal}
                                />
                            </Modal>
                        </div>
                    )) ||
                        (mainScreen == "genericScreen" && (
                            <GenericActivity
                                selectedUser={selectedAppointment}
                                type={"myappointments"}
                                mainScreen={mainScreen}
                                setMainScreen={setMainScreen}
                            />
                        ))}
                </>
            )}
        </div>
    )
}

export default Appointments

// console.log("date", date.toString().split(" "), date.toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" }));
// const formatDate = moment(date.toISOString()).format("YYYY-MM-DD");
// console.log("date", formatDate);
