import { DatePicker, Upload,Modal } from 'antd'
import React,{useEffect, useState} from 'react'
import moment from 'moment';
import "./AddTask.css";
import { disabledDate } from '../../../../../Utilities/helpers';


const AddTask = (
    {
        leadStatus,
        handleLeadActivities,
        setLeadStatus,
   
        
    }
) => {
 
    const [previewImage, setPreviewImage] = useState(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
   console.log("lead status----",leadStatus);

    const disabledCustomized = (current) => {
        console.log("current",current);
        return current && current.isBefore(moment(leadStatus?.taskStartDate).add(1, 'day'));
    }

    const handleFileChange = (event) => {
        if (event && event.target && event.target.files) {
            const newFiles = Array.from(event.target.files);
            setLeadStatus(prevState => ({
                ...prevState,
                uploadedFiles: [...(prevState.uploadedFiles || []), ...newFiles]
            }));
        }
    };
 const handleDeleteImage = (index) =>{
    setLeadStatus(prevState => ({
        ...prevState,
        uploadedFiles: prevState.uploadedFiles.filter((_, i) => i !== index)
    }))
 }

 const handlePreviewImage = (file) => {
    setPreviewImage(file instanceof File ? URL.createObjectURL(file) : file.imageLink);
    setIsPreviewOpen(true);
};

const closePreview = () => {
    setIsPreviewOpen(false);
    setPreviewImage(null);
};

    return (
        <>
            <div className='row'>
                <div className='col-6'>
                    <label> Start Date</label>
                    <DatePicker
                        onChange={handleLeadActivities("taskStartDate")}
                        format={"YYYY-MM-DD"}
                        value={leadStatus?.taskStartDate ? moment(moment(leadStatus?.taskStartDate).format("YYYY-MM-DD")) : ""}
                        disabledDate={disabledDate}
                    />
                </div>
                <div className='col-6'>
                    <label>Due Date</label>
                    <DatePicker
                        onChange={handleLeadActivities("taskEndDate")}
                        format={"YYYY-MM-DD"}
                        value={leadStatus?.taskEndDate && moment(moment(leadStatus?.taskEndDate).format("YYYY-MM-DD"))}
                        disabled={leadStatus?.taskStartDate ? false : true}
                        disabledDate={disabledCustomized}
                    />
                </div>
            </div>
            <div className='row'>
                <label>Upload Images</label>
                 <div className="upload-container">
                 <div className="uploaded-images">


                {leadStatus?.uploadedFiles?.map((file, index) => (
                    
                    <div key={index} className="image-preview">
                        <img 
                    src={file instanceof File ? URL.createObjectURL(file) : file.imageLink} 
                    alt={`Uploaded ${index + 1}`} 
                            className="uploaded-image" 
                        />
                        <div className="image-overlay">
                                <button className="icon-button view-button"  onClick={() => handlePreviewImage(file)}>
                                    <i className="fas fa-eye"></i>
                                </button>
                                <button 
                                    className="icon-button delete-button" 
                                    onClick={() => handleDeleteImage(index)}
                                >
                                    <i className="fas fa-trash"></i>
                                </button>
                            </div>
                    </div>
               
                ))}
            </div>
                        <input 
                            type="file" 
                            multiple 
                            onChange={handleFileChange} 
                            style={{ display: 'none' }} 
                            id="file-upload"
                        />
                        <label htmlFor="file-upload" style={{ width: '100%', height: '100%' }}>
                            <div className="upload-area">
                                <div className="upload-instruction">
                                    <i className="fas fa-images upload-doc-icon"></i>
                                    <p>
                                        <span className="upload-link">Click to upload</span> 
                                    </p>
                                 
                                </div>
                            </div>
                        </label>
                        {isPreviewOpen && (
                    <div className="preview-modal" onClick={closePreview}>
                        <div className="preview-content" onClick={(e) => e.stopPropagation()}>
                            <span className="close-button" onClick={closePreview}>&times;</span>
                            <img src={previewImage} alt="Preview" className="preview-image" />
                        </div>
                    </div>
                )}
                    </div>
             
            </div>
        </>
    )
}

export default AddTask;