import React, { useState } from 'react'
import ApCreateProfile from './ApCreateProfile/ApCreateProfile';


function AddArchitechPartner({type,userId,toggleHandler}) {

// const tabItems = [
//   {
//     id: 1,
//     title: 'Details',
//     content: <ApCreateProfile type={type} userId={userId} toggleHandler={toggleHandler}/>
//   },
//   {
//     id: 2,
//     title: 'Address',
//     content: 'Address content',
//   },
//   {
//     id: 3,
//     title: 'Bank Details',
//     content: 'Bank Details content',
//   },
//   {
//     id: 4,
//     title: 'Project Portfolio',
//     content: 'Project Portfolio content',
//   },
// ];
//     const [active, setActive] = useState(1);
    
    return (
        <div>
            {/* <div className="addTkp-navbar">
                <div className="button-group">
                    {tabItems.map(({id,title}) => (
                        <button className='button'
                            key={title}
                            active={active === id}
                            onClick={() => setActive(id)}
                            style={{borderBottom : active === id  && '2px solid orange',
                            color: active === id && 'orange',

                        }}
                        >{title}</button>
                    ))}
                </div>
            </div>
            <div>
                {tabItems.map(({id,content}) => {
                    return active === id  ? content  : "" ;
                })}
            </div> */}

          {/* no need tabs that why using component directly */}
          <ApCreateProfile type={type} userId={userId} toggleHandler={toggleHandler}/>
        </div>
    )
}

export default AddArchitechPartner;