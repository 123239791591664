import React, { useState } from 'react';
import { BrowserRouter as Router, NavLink } from 'react-router-dom'
import { IoMdAdd } from 'react-icons/io';
import { BiLeftArrowAlt } from 'react-icons/bi';
import './SkilledWorkers.css';
import AddSkilledWorkers from './addSkilledWorkers/AddSkilledWorkers';
import SwList from './SwLIst/SwList';
import { Tooltip } from 'antd';

function SkilledWorkers() {
  const [toggleBtn, setToggleBtn] = useState(false);
  const [type, setType] = useState("");
  const [userId, setuserId] = useState('');
  const [partnerExecutive, setPartnerExecutive] = useState("partnerExecutive")

  const toggleHandler = (type) => {
    setType(type)
    // console.log('type:', type);
  }

  return (
    <div className="mainContent">
      <div className="contentHeader">
        {(type === "edit" || type === "add") ?
          <div>
            {(type === "edit" ? <span class="headerTitle">Skilled Worker Details</span>
              :
              <span class="headerTitle"> Add Skilled Worker</span>
            )}
          </div>
          :
          <span class="headerTitle">Skilled Workers</span>
        }
        <button type="button" className="btn whBtnPrimary">
          {(type === "edit" || type === "add") ?
            <Tooltip title="Skilled Worker List">
              <span className="btnText" onClick={() => { toggleHandler(" ") }}>
                <NavLink to="#">
                  <BiLeftArrowAlt size="20px" className="" />
                  Back
                </NavLink>
              </span>
            </Tooltip> :
            <span className="btnText" onClick={() => { toggleHandler("add") }}>
              <IoMdAdd size="20px" className="" />
              Skilled Worker
            </span>
          }
        </button>
      </div>
      <div className="whContentBlock">
        {(type === "edit" || type === "add") ? 
        <AddSkilledWorkers type={type} userId={userId} toogleHandler={toggleHandler} /> 
        : <SwList partnerExecutive={partnerExecutive} typeHandler={toggleHandler} userId={setuserId} />}
      </div>
    </div>
  )
}

export default SkilledWorkers;