import instanceAll from '../../CommonAxiosInstance'

// get tKP dashboard count API:
const getTkpLeadsCountService=async ()=>{
    try{
        const response = await instanceAll.post("/get-tkp-dashboard-leads");
        // console.log("getRejectedlist:",response);
        if(response && response.status === 200){
            return{
                status :"success",
                data:response.data
            }
        }else{
            return {
                status:"failed",
                data:response.data
            }
        }
    }catch(error){
         console.log("error:",error);
    }

}

// get tKP dashboard count API:
const getTkpLeadsCountByDateRangeService=async (body)=>{
    try{
        const response = await instanceAll.post("/get-tkp-dashboard-leads-by-date-range",body);
        // console.log("getRejectedlist:",response);
        if(response && response.status === 200){
            return{
                status :"success",
                data:response.data
            }
        }else{
            return {
                status:"failed",
                data:response.data
            }
        }
    }catch(error){
         console.log("error:",error);
    }

}

export const TKPDashboardServices = {
       getTkpLeadsCountService,
       getTkpLeadsCountByDateRangeService
}