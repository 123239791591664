import React, { useState } from 'react';
import './CreateUser.css';
import profile from '../../../../Assets/images/profile.png';
import { imageAssets } from '../../../../Assets/AssetsConstants/AssetsConstants';

function CreateUser() {
  const [image, setImage] = useState(imageAssets.profile);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [designation, setDesignation] = useState("");
  const [reportingManager, setReportingManager] = useState("");
  const [associatedRolesToUsers, setAssociatedRolesToUsers] = useState("");

  const imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result)
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }

  const submitHandler = (e) => {
    e.preventDefault();
    let userData = {
      image: image,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      password: password,
      designation: designation,
      reportingManager: reportingManager,
      associatedRolesToUsers: associatedRolesToUsers
    }

    console.log("usersData:", userData)
    alert("user data successfully submitted")
  }

  return (
    <div>
      <div className="createUser-layout">
        <form>
          <div className="row">
            <div className="col-md-4">
              <div className="user-picture">
                <div className="user-picture-head">
                  <p>User Picture</p>
                </div>
                <div className="user-picture-data">
                  <div className="image-holder">
                    <img src={image} alt="..." id="img" className="image" />
                  </div>
                  <input type="file" name="image-upload" id="input" accept="image/*" className="user-image" onChange={imageHandler} />
                  <label htmlFor="input" className="uploadLabelImg">
                    <span className="img-name">Upload new image</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="User-details">
                <div className="user-details-head">
                  <p className="user-details-text">User Details</p>
                  <div className="user-details-btns">
                    <button type="submit"  className="user-details-saveBtn">Active</button>
                    <button type="submit" onClick={submitHandler} className="user-details-activeBtn">Save</button>
                  </div>
                </div>
                <div className="User-details-data">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group-left">
                        <label>First Name</label>
                        <div className="form-field">
                          <input type="text"
                            value={firstName}
                            name="firstName"
                            onChange={(e) => { setFirstName(e.target.value) }}
                            className="input-field"
                            style={{ width: "450px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group-right">
                        <label>Last Name</label>
                        <div className="form-field">
                          <input type="text"
                            value={lastName}
                            name="lastName"
                            onChange={(e) => { setLastName(e.target.value) }}
                            className="input-field"
                            style={{ width: "450px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group-left">
                        <label>Mobile Number</label>
                        <div className="form-field">
                          <input type="number"
                            value={phoneNumber}
                            name="phoneNumber"
                            onChange={(e) => { setPhoneNumber(e.target.value) }}
                            className="input-field"
                            style={{ width: "450px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group-right">
                        <label>Email address</label>
                        <div className="form-field">
                          <input type="email"
                            value={email}
                            name="email"
                            onChange={(e) => { setEmail(e.target.value) }}
                            className="full-input-field"
                            style={{ width: "450px" }}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group-left">
                        <label>Password</label>
                        <div className="form-field">
                          <input type="password"
                            value={password}
                            name="password"
                            onChange={(e) => { setPassword(e.target.value) }}
                            className="input-field"
                            style={{ width: "450px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group-right">
                        <label>Designation</label>
                        <div className="form-field">
                          <select value={designation}       
                             className="input-field"
                             style={{width:"450px"}}
                             onChange={(e)=>{setDesignation(e.target.value)}}>
                            <option name="user">User</option>
                            <option name="female"></option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="full-form-group">
                      <label>Reporting Manager</label>
                      <div className="form-field">
                      <select value={reportingManager}
                        className="input-field"
                        style={{width:"935px"}}
                         onChange={(e)=>{setReportingManager(e.target.value)}}>
                            <option name="projectManager">Project Manager</option>
                            <option name="female"></option>
                          </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="full-form-group">
                      <label>Associated Roles To Users</label>
                      <div className="form-field">
                        <input type="text"
                          value={associatedRolesToUsers}
                          name="birthday"
                          onChange={(e) => {setAssociatedRolesToUsers(e.target.value) }}
                          className="input-field"
                          style={{ width: "935px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateUser;