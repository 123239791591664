import {HiOutlineChevronDoubleRight,HiOutlineChevronDoubleLeft} from 'react-icons/hi';
const textItemRender = (current, type, element) => {
    console.log("check", type);
    if (type === 'prev') {
        return <button>Prev</button>;
    }
    if (type === 'next') {
        return <button>Next</button>;
    }
    if(type === "jump-prev"){
        return <HiOutlineChevronDoubleLeft size={"10"} />
    }
    if(type === "jump-next"){
        return <HiOutlineChevronDoubleRight size={"10"} />
    }
    return element;
};



export const pagination = {
    textItemRender
}