import React, { useState } from 'react';
import './TkpApprovals.css'
import { Rating } from 'react-simple-star-rating';

const TkpApprovalsModal = ({ selectedUser,politenessRating,PRHandler,
    hospitalityRating,HRHandler,siteMaintanenaceRating,SMRHandler,
    willingRating,WRHandler,overallRating,OARHandler }) => {

   
    const userId = selectedUser.UserId;

    console.log('data:', userId, selectedUser);

    console.log("ratings ::", "politenessRating :", politenessRating,
        "hospitalityRating", hospitalityRating,
        "siteMaintanenaceRating", siteMaintanenaceRating,
        "willingRating", willingRating,
        "overAllRating", overallRating
    );

    console.log("politenessRating:",politenessRating);

    return (
        <div>
            <div className="ProfileHandler">
                <img src={selectedUser.imageUrl} alt="" className="cardBlankAvatarImg" />
                <span className="userName">{selectedUser.FirstName + " " + selectedUser.LastName}</span>
                <div className={`btn rounded-pill userStatus ${selectedUser.Status === "Pending" && "Pending" || 
                 selectedUser.Status === "Processing" && "Processing"
                }`}>
                 {selectedUser.Status}
                </div>
            </div>
            <div className="data-row">
                <div className="row">
                    <div className="col">
                        <label>Aadhar Card</label>
                        <p>{selectedUser.Aadhar}</p>
                    </div>
                    <div className="col">
                        <label>Years of Experience</label>
                        <p>{selectedUser.Years_of_Experience}</p>

                    </div>
                    <div className="col">
                        <label>Educational Qualification</label>
                        <p>{selectedUser.Educational_qualification}</p>
                    </div>
                </div>
            </div>
            <div className="data-row">
                <div className="row">
                    <div className="col">
                        <label>Team Members Strength</label>
                        <p>{selectedUser.Team_members_strength}</p>
                    </div>
                    {/* <div className="col" >
                        <label>Category</label>
                        <p>null</p>
                    </div> */}
                </div>
            </div>
            <div className="data-row">
                <div>
                    <label>Remarks</label>
                    <p>{selectedUser.Remarks}</p>
                </div>
            </div>
            <hr />
            <div className="data-row">
                <div className="col mt-4">
                    <label>Rating-Based on *</label>
                    <div className="row rating">
                        <div className="col">
                            <div className="d-flex">
                                <span className="text">Politeness (Language & Communication)</span>
                                <Rating allowHalfIcon size='25px' onClick={(rate) => { PRHandler(rate) }} ratingValue={politenessRating} />
                            </div>
                            <div className="d-flex">
                                <span className="text">Hospitality</span>
                                <Rating allowHalfIcon size='25px' onClick={(rate) => {HRHandler(rate) }} ratingValue={hospitalityRating} />
                            </div>
                            <div className="d-flex">
                                <span className="text">Site Maintenance</span>
                                <Rating allowHalfIcon size='25px' onClick={(rate) => { SMRHandler(rate) }} ratingValue={siteMaintanenaceRating} />
                            </div>
                            <div className="d-flex">
                                <span className="text">Willingness to Carry Wehouse</span>
                                <Rating allowHalfIcon size='25px' onClick={(rate) => { WRHandler(rate) }} ratingValue={willingRating} />
                            </div>
                        </div>
                        <div className="col">
                            <div className='overallScore'>
                                <span>Overall Score</span>
                                <span className='rating'>{overallRating}</span>
                                <span>out of start rating</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TkpApprovalsModal