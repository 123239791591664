import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { toast } from 'react-toastify';
import NoData from '../../../../../Components/NoData/NoData';
import { createPaymentInstallementsService, getPaymentInstallementsService, updatePaymentInstallementsService } from '../../../../../Service/SeCustomersServices';
import "./PaymentInstallments.css";
import SimpleBarReact from 'simplebar-react';
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import Loader from '../../../../../Components/Loader';
import { MdModeEditOutline } from "react-icons/md";

function PaymentInstallments({
  toggleHandler,
  handleTabs,
  projectDetailsForPayments
}) {
  console.log("project data", projectDetailsForPayments);
  const [varypaymentDetails, setVaryPaymentDetails] = useState([]);
  const [oldpaymentDetails, setOldPaymentDetails] = useState([]);
  const [openedRow, setOpenedRow] = useState([]);
  const [totals, setTotals] = useState({ total: 0, newtotal: 0 });
  const [percentages, setPercentages] = useState({ percentage: 100, newPercentage: 100 })
  let apiServiceSelector={
     "add":createPaymentInstallementsService,
      "edit":getPaymentInstallementsService
  }
  const createPaymentInstallements = async (type) => {
    // alert("ok");
    console.log("data",);
    try {
      const { projectId, projectType } = projectDetailsForPayments;
      let body = {
        projectId: projectId,
        projectType: projectType
      }
      console.log('project details body', body);
      const response = await apiServiceSelector[type](body);
      console.log("response",response);
      if (response.status === 'success') {
        setTotals({
          total: Number(response.paymentDetails[0].totalAmount).toFixed(2),
          newtotal: Number(response.paymentDetails[0].totalAmount).toFixed(2)
        })
        setVaryPaymentDetails(response.paymentDetails?.map((value) => Object.assign({}, value)));
        setOldPaymentDetails(response.paymentDetails?.map((value) => Object.assign({}, value)));
      }
      else {
        setVaryPaymentDetails([]);
        setOldPaymentDetails([]);
      }

    } catch (error) {
      toast.error("Failed to Get Payment Installments");
    }
  }
  const handleOpenedRow = (e, poisition, typeOfField) => {
    console.log("value", e.target, e.currentTarget);
    if (typeOfField === 'close') {
      // alert("ok");
      e.stopPropagation();
      setOpenedRow([]);
    }
    else if (e.target !== e.currentTarget) {
      e.stopPropagation();
      // e.preventDefault();
      // alert("ok1");
      setOpenedRow([{
        poisition: poisition,
        typeOfField: typeOfField
      }])
    }
    else{
      e.stopPropagation();
    }


  }
  const OverallPercentageCalculation = (newtotal) => {
    console.log("total",newtotal);
    // console.log("overallpercent");
    // let percentage = varypaymentDetails?.reduce((total, currentObject, arrayIndex) => {
    //   console.log("total", total);
    //   return (total + Number(currentObject?.percentage))
    // }, 0)
    // console.log("percentage", percentage);
    // setPercentages((values) => ({ ...values, newPercentage: percentage}))
    let percentage =(((newtotal-totals?.total))/(totals?.total))*100
    console.log("percentage",percentage);
    setPercentages((values) => ({ ...values, newPercentage: percentage.toFixed(2)}))


  }
  const totalCalculation = () => {
    console.log("total calculation");
    let total = varypaymentDetails?.reduce((total, currentObject, arrayIndex) => {
      // console.log("total", Number(currentObject?.amount_payable));
      return (total + Number(currentObject?.amountPayble))
    }, 0)
    console.log("total", total.toFixed(2));
    // setNewTotal(total);
    OverallPercentageCalculation(total.toFixed(2))
    setTotals((values) => ({ ...values, newtotal: total.toFixed(2) }));


  }
  const paymentCalculation = (e, index) => {
    // e.nativeEvent.stopImmediatePropagation();
    console.log("inside the payment calculation", index, e);
    const { name, value } = e.target;
    console.log("inside the payment calculation", name, value);
    let temp = varypaymentDetails;
    // console.log("temp", temp);
    let percentage = parseInt(((((value) / (totals?.total)) * 100)*100))/100;
    // let percentage = (((value) / (totals?.total)) * 100).toFixed(2)
    console.log("percentage",percentage);
    temp[index].amountPayble = value;
    temp[index].percentage = percentage;


    console.log("after change", temp);
    setVaryPaymentDetails([...temp]);
    // totalCalculation(temp);



  }


  const updatePayments = async (typeOfButton) => {
    try {
      let percentages = [];
      let amounts = [];
      varypaymentDetails?.map((values) => {
        percentages.push(Number(values?.percentage));
        amounts.push(Number(values?.amountPayble));
      })
      const { projectId, projectType } = projectDetailsForPayments;
      const body = {
        projectId: projectId,
        projectPercentages: percentages,
        projectAmounts: amounts,
        total: Number(totals?.total)
      }
      const response = await updatePaymentInstallementsService(body);
      if (response.status === "success") {
        toast.success("Successfully updated the Payment Installments");
        if(typeOfButton === "save&close"){
          toggleHandler("");
        }
      }
      else {
        toast.error("Failed to Update Payment installements");
      }

    } catch (error) {
      toast.error("Failed to Update Payment installements");

    }
  }

const apiHitHandler=()=>{
  if(projectDetailsForPayments?.type === "add"){
    // alert("ok add");
    createPaymentInstallements(projectDetailsForPayments?.type);
  }
  else if(projectDetailsForPayments?.type === "edit"){
    // getPaymentInstallements();
    createPaymentInstallements(projectDetailsForPayments?.type);
  }
}

  useEffect(() => {
    
    // getPaymentInstallements();
    apiHitHandler();
  }, [projectDetailsForPayments])

  useEffect(() => {

    totalCalculation();
    // OverallPercentageCalculation();

  }, [varypaymentDetails])
  
  // installmentId : val.installment_id,
  // paymentTemplateId : val.payment_template_id,
  // totalAmount: val.total_amount,
  // milestoneName: val.milestone_name,
  // projectNo:val.project_no,
  // percentage: val.percentage,
  // amountPayble: val.amount_payable,
  // status: val.status,
  console.log("total",totals,openedRow);

  return (
    <div className="contentBody detail">
      <div className='col-md-12' style={{ margin: 'auto' }}>
        <div className="whCard paymentInstallment">
          <div className="">
            <div className="cardHeader">
              <span>Payment Installments</span>
              <div className="d-flex customer-details-btn">
                <Tooltip title="Customer Details">

                  <button className="btn whBtnPrimary" onClick={() => { setOpenedRow([]); handleTabs("Project") }}>
                    <BiLeftArrowAlt size="25px" className="" />
                    Back
                  </button>
                </Tooltip>

                <button
                  className="btn saveBtnclose"
                  onClick={() => updatePayments("save")}
                  disabled={(totals?.newtotal - totals?.total) === 0 ? false : true}
                >
                  Save
                </button>
                <button
                  className="btn saveBtnclose"
                  onClick={() => updatePayments("save&close")}
                  disabled={(totals?.newtotal - totals?.total) === 0 ? false : true}
                >
                  Save & close
                </button>
              </div>
            </div>
          </div>

            <div className="whAppTable">
              <div className='tableList'>
                <div className="tableHead">
                  <div className='tableRow'>
                  <div className="listItem installmentNum text-center">INSTALLMENT NO</div>
                  <div className="listItem milestone">PAYMENT MILESTONES({varypaymentDetails?.length})</div>
                  <div className="listItem percentage">
                    <span>
                    PERCENTAGE(100%)
                    </span>
                    <span>  
                      {
                        (percentages?.newPercentage)  > 0 ?
                        <BsArrowUp color='green'/>:
                        (percentages?.newPercentage ) <0 ?
                        <BsArrowDown color='red'/> :null


                      }
                      <span>
                        {(percentages?.newPercentage)}%
                      </span>
                    </span>
                    
                  </div>
                  <div className="listItem amount">
                    <span>
                      AMOUNT(
                        {/* <i class="fa fa-inr"></i> */}
                    {varypaymentDetails?.[0]?.totalAmount?.toLocaleString('en-IN', 
                                {
                                  // maximumFractionDigits: 2,
                                 style: 'currency',
                                 currency: 'INR'
                                 })
                      })
                    </span>
                    <span>
                      {
                        (totals?.newtotal - totals?.total).toFixed(2) > 0 ?
                        <BsArrowUp color='green'/>:
                        (totals?.newtotal - totals?.total).toFixed(2) <0 ?
                        <BsArrowDown color='red' /> :null

                      }
                    {(totals?.newtotal - totals?.total).toFixed(2)}
                    </span>
                  </div>
                  </div>
                </div>
                {varypaymentDetails?.length === 0 ? <Loader/> :
                <div>
                <SimpleBarReact style={{ maxHeight: 520 }}> 
                    {
                    varypaymentDetails?.map((item, index) => {
                      return <div>
                        <div className="tableRow" onClick={(e) => handleOpenedRow(e, index, "close")}>
                          <div className="listItem installmentNum text-center">{index + 1}</div>
                          <div className="listItem milestone">{item?.milestoneName}</div>
                          <div className="listItem percentage">
                            <p >{`${item?.percentage}%`}</p>
                            {/* } */}
                          </div>
                          <div className="listItem amount">
                            {openedRow[0]?.poisition === index && openedRow[0]?.typeOfField === "amount" ?
                              <input
                                type="number"
                                value={item?.amountPayble}
                                className="form-control"
                                onChange={(e) => { paymentCalculation(e, index) }}
                                onWheel={(e)=>e.currentTarget.blur()}
                                // onKeyDown={(e)=>{paymentCalculation(e,index)}}
                                onClick={(e) => e.stopPropagation()}
                              /> 
                              :
                              <div className='editAmount'onClick={(e) => handleOpenedRow(e, index, "amount")} >
                                <span>
                                  <i class="fa fa-inr"> </i> {"\t"}
                                  {
                                    
                                  Number(item?.amountPayble).toLocaleString('en-IN', 
                                  {
                                    // maximumFractionDigits: 2,
                                  //  style: 'currency',
                                  currency: 'INR'
                                  })}
                                </span>
                                <div className='editPencil'>
                                  <MdModeEditOutline color='orange' />
                                </div>
                                  
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    })
                    }
                  

                  </SimpleBarReact>
                    <div class="whAppTable">
                      <div class="tableList">
                        <div class="tableRow">
                          <div class="listItem installmentNum text-center"></div>
                          <div class="listItem milestone"></div>
                          <div class="listItem percentage text-right">Total Cost</div>
                          <div class="listItem totalAmount amount">
                            <i class="fa fa-inr"></i>
                          <span>{
                            
                                    
                              Number( varypaymentDetails?.[0]?.totalAmount).toLocaleString('en-IN', 
                              {
                                // maximumFractionDigits: 2,
                              //  style: 'currency',
                              currency: 'INR'
                              })
                              }
                         
                          </span></div>
                        </div>
                      </div>
                    </div>

        
                  <div className='row total-cost'>
                      <p style={{textAlign: "center", color:"#777"}}>Note: Above Tentative dates will be mutually agreed as per the achievement of Milestones</p>
                  </div>
                  
                </div>
                }
              </div>
            </div>
         
        </div>
      </div>
    </div>
  )
}

export default PaymentInstallments;


// const PaymentInstallment = [
//   {
//     installmentNo: 0,
//     mileStone: "Advance amount on caution deposit of contract agreement",
//     percentsge: 1,
//     amount: "50,000"
//   },
//   {
//     installmentNo: 1,
//     mileStone: "Advance amount on caution deposit of contract agreement",
//     percentsge: 1,
//     amount: "50,000"
//   }, {
//     installmentNo: 2,
//     mileStone: "Advance amount on caution deposit of contract agreement",
//     percentsge: 1,
//     amount: "50,000"
//   }, {
//     installmentNo: 3,
//     mileStone: "Advance amount on caution deposit of contract agreement",
//     percentsge: 1,
//     amount: "50,000"
//   },

// ]
// const closeOpenedRow=(e,poistion,typeOfField)=>{
  //   console.log("inside the closed opened row",e);
  //   if(typeOfField === 'close'){
  //     // e.stopPropagation();
  //     setOpenedRow([]);
  //   }

  // }

  {/* {openedRow[0]?.poisition === index && openedRow[0]?.typeOfField === 'percentage' ?
                            <input
                              type="number"
                              value={item?.percentage}
                              className="form-control"
                            /> : onClick={(e) => handleOpenedRow(e,index, "percentage")}*/}

     // console.log(" payments details", openedRow);
  // console.log("old payments details", oldpaymentDetails);
  // console.log("new payments details", varypaymentDetails);
  // console.log("total", totals?.total);
  // console.log("new total", totals?.newtotal); 
  // {console.log("caluculate diff",totals?.newtotal-totals?.total)}
  // console.log("check ",vales);
      // console.log("check ",percentages);
      // console.log("check ",amounts);
  
        // const differnceAmountCalculation=(type,amount)=>{
  //     if(type === "percentage"){
  //       let value=(amount).toFixed(2);
  //       return{value:value,  arrowType:value > 0 ? "positive":"negative"}
  //     }
  //     else if (type === "total"){
  //       let value=(amount).toFixed(2);
  //       return{value:value,  arrowType:value > 0 ? "positive":"negative"}
  //     }

  // }

  // e.preventDefault();
    // console.log('poistion typeOfField', poisition, typeOfField);
    // if (poisition === openedRow[0]?.poisition && typeOfField === openedRow[0]?.typeOfField) {
    //   setOpenedRow([]);
    // }

  // setTotal(Number(response.paymentDetails[0].total_amount))
        // setNewTotal(Number(response.paymentDetails[0].total_amount))

      // const [total, setTotal] = useState("");
  // const [newTotal, setNewTotal] = useState("");
  
  