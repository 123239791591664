import instanceAll from './CommonAxiosInstance';

export const getRawLeads = async (body) => {
    console.log("inside the get raw leads", body);
    try {
        const response = await instanceAll.post("/get_leads_list", body);
        console.log("response :::", response.data);
        if (response.status === 200) {
            return ({
                status: "success",
                rawleads: response.data.leads,
                count: response.data.count
            })
        }

    }
    catch (error) {
        console.log("inside the catch block of raw leads");
        return ({
            status: "failed",

        })
    }
}


export const allocateService = async (body) => {
    console.log("inside the allocate leads");
    try {

        const response = await instanceAll.post("/allocate-customer", body);
        console.log('response', response);

        if (response?.status === 200 && response?.data?.allocations?.length > 0) {
            const allocatedPersons = response.data.allocations
                .filter(allocation => allocation.isCustomerallocated)
                .map(allocation => ({
                    allocatedPerson: allocation.allocatedPerson,
                    leadId: allocation.leadId
                }));

            if (allocatedPersons.length > 0) {
                return {
                    status: "success",
                    allocatedPersons
                };
            } else {
                return {
                    status: "failed",
                    message: "No customers allocated"
                };
            }
        } else {
            return {
                status: "failed",
                message: "No allocations found or invalid response"
            };
        }
    }
    catch (error) {
        console.log("inside the catch block of allocate lead");
        return ({
            status: "failed",

        })
    }
}

export const junkService = async (body) => {
    console.log('inside the junk service ', body);
    try {
        const response = await instanceAll.post("/junk-customer", body);
        console.log("junk response", response);
        if (response?.status === 200) {
            return ({
                status: "success",

            })
        }
        else {
            return ({
                status: "failed"
            })
        }
    }
    catch (error) {
        console.log('inside the catch block of junk service raw leads');
        return ({
            status: "failed"
        })
    }
}

export const filterRawData = async (body) => {
    console.log("inside the filter raw data", body);
    try {
        const response = await instanceAll.post("/get-filter-rawleads", body);
        if (response.status === 200 && response?.data?.getfilterList) {
            return ({
                status: "success",
                data: response?.data.leads,
                count: response?.data.count
            })
        }
        else {
            return ({
                status: "failed",

            })
        }
    }
    catch (error) {
        return ({
            status: "failed"
        })
    }

}

export const addRawLead = async (body) => {
    console.log("add raw leads", body);
    try {
        const response = await instanceAll.post("/add-raw-lead", body);
        if (response.status === 200 && response.data.isRawLeadAdded) {
            return (
                {
                    status: "success",

                }
            )
        }
        else {
            return (
                {
                    status: "failed"
                }
            )
        }

    }
    catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}
export const rawLeadsAdvancedFilter = async (body) => {
    console.log("inside the filter raw data", body);
    try {
        const response = await instanceAll.post("/rawLeads-advanced-date-filter", body);
        console.log("response---", response);
        if (response?.data?.status === 200 && response?.data?.getRawLeadsFilterList) {
            return ({
                status: "success",
                data: response?.data.leadsData,
                count: response?.data.count,
                message: response?.data.message
            })
        }
        else {
            return ({
                status: "failed",

            })
        }
    }
    catch (error) {
        return ({
            status: "failed"
        })
    }

}

export const rawLeadsSearch = async (body) => {
    try {
        const response = await instanceAll.post("/get-rawLeads-by-search", body);
        console.log("rawLeadsSearch:", response?.data);
        if (response && response.status === 200) {
            return ({
                status: "success",
                data: response?.data,
                count: response?.data.count

            })
            
        } else {
            return ({
                status: "failed",
                data: "No data found"
            })
        }

    } catch (error) {
        return ({
            message: error
        })
    }
}
export const uploadExcelDataService = async (body) => {
    try {
        const response = await instanceAll.post("/upload-excel-data", body);
        if (response.status === 200 && response.data.isUploaded) {
            return (
                {
                    status: 'success',
                    message: "Successfully Added the Excel Data"
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    message: "something went wrong"
                }
            )

        }

    } catch (error) {
        return ({
            status: "failed",
            message: "something went wrong"

        })

    }
}

export const allocationCommitService = async (body) => {
    try {
        const response = await instanceAll.post("/allocation-commit", body);
        if (response.status === 200 && response.data.isAllocationCommitted) {
            return (
                {
                    status: "success",
                    message: "Successfully Allocated"
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}