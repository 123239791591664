import { Select } from 'antd';
import React from 'react';
import { imageAssets } from '../../../../Assets/AssetsConstants/AssetsConstants';


const { Option } = Select;
function AdvancedFilter({salesExecutivesList,resetFilter,applyFilter,advancedFilterData,setAdvancedFilterData}) {

    const onchangeHandler=(value)=>{
        console.log('search:', value);
    }

    return (    
        <div >
            <div className='row'>
                <div className='col'>
                    <label>Sales Executive</label>
                    <Select
                        value={advancedFilterData.salesExecutive}
                        placeholder="Select Sales Executive"
                        onChange={(value) => setAdvancedFilterData(data => ({...data , salesExecutive :value}))}
                        allowClear
                        style={{ width: "100%" }}
                        disabled={salesExecutivesList.length <= 0}
                    >
                        {salesExecutivesList?.map((item,index) => (
                            console.log('sales executive list',item,index),
                      <Option value={item._userid} key={index}>{item._firstname +" " + item._lastname}</Option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <label>Source</label>
                    <Select
                        placeholder="Select Source"
                        onChange={onchangeHandler}
                        allowClear
                        style={{ width: "100%" }}
                    >
                        <Option value="Google">
                            <img src={imageAssets.GoogleLogo} alt="googlelogo" />
                            Google</Option>
                        <Option value="Wehouse">
                            <img src={imageAssets.FavIcon} style={{ height: "30px" }} alt="googlelogo" />
                            Wehouse</Option>
                    </Select>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <label>City</label>
                    <Select
                        placeholder="Select City"
                        onChange={onchangeHandler}
                        allowClear
                        style={{ width: "100%" }}
                    >
                        <Option value="hyderabad">Hyderabad</Option>
                        <Option value="chennai">Chennai</Option>
                    </Select>
                </div>
            </div>
            <div className='footerBtns'>
                <button type="button" class="btn btn-link btn-sm" onClick={resetFilter}>Reset Filter</button>
                <button className='btn btn-sm whBtnPrimary' onClick={() => applyFilter('filter')}>Filter</button>
            </div>
        </div>
    )
}

export default AdvancedFilter;