import React, { useState, useEffect } from 'react';
import './Projects.css';
import { BrowserRouter as Router, Route, NavLink, Switch, useHistory } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';
import { BiLeftArrowAlt } from 'react-icons/bi';
import ProjectList from './ProjectList/ProjectList';
import Addcustomers from '../SeCustomers/createCustomers/Addcustomers';



function Projects() {
  const [changeBtn, setChangeBtn] = useState(false);
  const [active, setActive] = useState(1);
  const history =useHistory();


  const chnageHandler = () => {
    // setChangeBtn(!changeBtn)
    history.push("/landing/salesexecutive/customers",{type:"add"});
  }

  const NavItems = [
    {
      id: 1,
      title: 'Customer Details',
    },
    {
      id: 2,
      title: 'Project Details',
    },
    {
      id: 3,
      title: 'Payment Installments',
    }
  ]

  return (
    <div className="mainContent">
      <div className="contentHeader">
      {changeBtn ? <span class="headerTitle">
          {NavItems.map(({ id, title }) => (
            active === id ? title + " " + "Creation" : ""
          ))}
        </span>
          : <span class="headerTitle">Project List</span>
        }
          <button type="button" className="btn whBtnPrimary" onClick={chnageHandler}>
          {changeBtn ?
            <div>
              <span className="btnText"> <NavLink to="#">
                <BiLeftArrowAlt size="25px" className="" />
                Back 
              </NavLink></span>
            </div>
            : <span className="btnText"> <NavLink to="#" >
              <IoMdAdd size="22px" />
               New Project
            </NavLink></span>}
        </button>
      </div>
      <div className="whContentBlock">
        { changeBtn ?
        <Addcustomers/>
        :
          <ProjectList/>
        }
      </div>
      


    </div>
  )
}

export default Projects;