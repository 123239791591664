import instanceAll from './CommonAxiosInstance'

const PMOProjectList = async (body) => {
    try {
        const response = await instanceAll.post("/pmo-projects-list", body);
        if (response.status === 200 && response.data.isGetPmoProjectList) {
            return (
                {
                    status: "success",
                    projectList: response.data.projectList,
                    count: response.data.count,
                    message: "successfully Fetch the PMO Project list"
                }
            )
        }
        else {
            return (
                {
                    status: "Failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "Failed",
                message: error
            }
        )

    }

}

const projectAllocationOrDeAllocation = async (body) => {
    try {
        console.log("project details", body);
        const response = await instanceAll.post("/project-allocation", body);
        if (response.status === 200 && response.data.isProjectAllocated) {
            return (
                {
                    status: "success",
                    message: "successfully Allocated",
                    projectCoordinator: response.data.projectCoordinator,
                    projectManager: response.data.projectManager
                }
            )
        }
        else {
            return (
                {
                    status: "Failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "Failed",
                message: error
            }
        )
    }
}

const addingProjectDetailsService = async (body) => {
    try {
        const response = await instanceAll.post("/adding-project-details", body);
        if (response.status === 200 && response.data.isProjectDetailsAdded) {
            return (
                {
                    status: "success",
                    message: "successfully added the Project details"
                }
            )
        }
        else {
            return (
                {
                    status: "Failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "Failed",
                message: error
            }
        )
    }
}

const getProjectDetailsByProjectId = async (body) => {
    console.log("bodyOUT: " + JSON.stringify(body));
    
    try {
        const response = await instanceAll.post("/get-project-by-projectid", body);
        console.log("api-res---", response);
        if (response.status === 200 && response.data.isGetProjectData) {
            return (
                {
                    status: "success",
                    projectData: response.data.projectData
                }
            )
        }
        else {
            return (
                {
                    status: "Failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "Failed",
                message: error
            }
        )
    }
}

const updatePMOfProject = async (body) => {
    try {
        const response = await instanceAll.post("/update-pm-of-a-project", body);
        console.log("api-res-updt-pm---", response);
        if (response.status === 200 /*&& response.data.isGetProjectData*/) {
            return (
                {
                    status: "success",
                    projectData: response
                }
            )
        }
        else {
            return (
                {
                    status: "Failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "Failed",
                message: error
            }
        )
    }
}

const updatePCOfProject = async (body) => {
    try {
        const response = await instanceAll.post("/update-pc-of-a-project", body);
        console.log("api-res-updt-pc---", response);
        if (response.status === 200 /*&& response.data.isGetProjectData*/) {
            return (
                {
                    status: "success",
                    projectData: response
                }
            )
        }
        else {
            return (
                {
                    status: "Failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "Failed",
                message: error
            }
        )
    }
}

const getAllocationLogsHistory = async (body) => {
    try {
        const response = await instanceAll.post("/get-pc-pm-log-history", body);
        console.log("api-get-pc-pm-log-history---", response);
        if (response.status === 200 /*&& response.data.isGetProjectData*/) {
            return (
                {
                    status: "success",
                    projectData: response.data.result
                }
            )
        }
        else {
            return (
                {
                    status: "Failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "Failed",
                message: error
            }
        )
    }
}

const updateProjectDetailsById = async (body) => {
    try {
        const response = await instanceAll.post("/update-project-by-id", body);
        if (response.status === 200 && response.data.isProjectUpdated) {
            return (
                {
                    status: "success",
                    message: "Successfully Updated"
                }
            )
        }
        else {
            return (
                {
                    status: "Failed",
                    message: "Failed to Update"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "Failed",
                message: error
            }
        )

    }
}

const updateProjectDurationService = async (body) => {
    try {
        const response = await instanceAll.post("/update-project-durations", body);
        console.log("response", response);
        if (response.status === 200 && response.data.isUpdatedProjectDuration) {
            return (
                {
                    status: "success"
                }
            )
        }
        else {
            return (
                {
                    status: "failed"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed"
            }
        )

    }
}

const getMilestonesData = async (body) => {
    const response = await instanceAll.post("/get-milestons-pc", body);
    console.log("response-mile--", response);
    if (response && response.data?.status === 200 && response.data?.isGetMilestones) {
        return response.data
    }
    else {
        return response.data
    }
}
const getMilestoneTasks = async (body) => {
    const response = await instanceAll.post("/get-milestons-task-pc", body);
    console.log("response-TAKS--", response);
    if (response && response.data?.status === 200 && response.data?.hasMilestoneTasks) {
        return response.data
    }
    else {
        return response.data
    }
}
const updateMilestoneStatus = async (body) => {
    const response = await instanceAll.post("/update-milestons-status", body);
    console.log("response___", response);
    if (response && response.data?.status === 200 && response.data?.isMilestoneStatusChanged) {
        return response.data
    }
    else {
        return response.data
    }
}
const addMilestoneTask = async (body) => {
    const response = await instanceAll.post("/add-milestone-task", body);
    console.log("response_task__", response);
    if (response && response.data?.status === 200 && response.data?.isTaskAdded) {
        return response.data
    }
    else {
        return response.data
    }
}

const addProjectImagesServices = async (body) => {
    try {
        const response = await instanceAll.post("/add-project-image", body);
        console.log("api-response--", response);
        if (response.status === 200 && response.data.isPrjImageAdded) {
            return (
                {
                    status: "success",
                    message: "Project Images are added successfully"
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    message: "No data found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}

const updateScheduleDate = async (body) => {
    console.log("data", body);
    try {
        const response = await instanceAll.post("/updated-schedule-dates", body);
        console.log("value", response);
        if (response.status === 200 && response.data.isScheduleDatesUpdated) {
            return (
                {
                    status: "success",
                    data: response.data.data

                }
            )
        }
        else {
            return (
                {
                    status: 'failed',
                    data: []
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                data: [],
                message: error,

            }
        )
    }
}

const logsScheduleSave = async (body) => {
    try {
        console.log("body", body);
        const response = await instanceAll.post("/logs-for-schedule-dates", body);
        if (response.status === 200 && response.data.isLogsSaved) {
            return (
                {
                    status: "success",
                    data: response.data.data
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    data: []
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}

const updateProjectImagesServices = async (body) => {
    try {
        const response = await instanceAll.post("/update-project-image", body);
        console.log("up-api-response--", response);
        if (response && response.data.status === 200 && response.data.isPrjImageUpdated) {
            return (
                {
                    status: "success",
                    data: response.data
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    data: response.data
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}
const deleteProjectImagesServices = async (body) => {
    try {
        const response = await instanceAll.post("/update-project-image", body);
        console.log("del-api-response--", response);
        if (response && response.data.status === 200 && response.data.isPrjImageUpdated) {
            return (
                {
                    status: "success",
                    data: response.data
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    data: response.data
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}

const getLogsService = async (body) => {
    try {
        console.log("body", body);
        let result = await instanceAll.post("/get-schedule-logs", body);
        if (result.status === 200 && result.data.isGetLogs) {
            return (
                {
                    status: "success",
                    data: result.data.data
                }
            )
        }
        else {
            return (
                {
                    status: "Failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "Failed",
                message: error
            }
        )
    }
}


const PMOServices = {
    PMOProjectList,
    projectAllocationOrDeAllocation,
    addingProjectDetailsService,
    getProjectDetailsByProjectId,
    updateProjectDetailsById,
    getMilestonesData,
    getMilestoneTasks,
    updateMilestoneStatus,
    addMilestoneTask,
    updateProjectDurationService,
    addProjectImagesServices,
    updateScheduleDate,
    logsScheduleSave,
    updateProjectImagesServices,
    deleteProjectImagesServices,
    getLogsService,
    updatePMOfProject,
    updatePCOfProject,
    getAllocationLogsHistory

}

export default PMOServices