import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const CustomersSlice = createSlice({
    name : "Customers",
    initialState : {
        customerDataById : {},
        loading:false,
        error:null
    } ,
    reducers : {
        customerLoader:(state,action)=>{
            state.loading=true;
        },
        getCustomerByIDSuccess : (state,action)=>{
            state.loading=false;
            state.customerDataById=action.payload;
            // toast.success()
            // console.log('action::',action.payload);
        },
        getCustomerByIDFailure:(state,action)=>{
            state.loading=false;
            state.error=action.payload;
            toast.error("Failed to get customer details");
        } 
    }

})

export const  {customerLoader,getCustomerByIDSuccess,getCustomerByIDFailure}=CustomersSlice.actions;

export default CustomersSlice.reducer;
