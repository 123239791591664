import React, { useEffect, useState } from 'react';
import { Divider, Slider, Steps } from 'antd';
import "./CustomSteps.css";
import DateWrapper from './DateWrapper/DateWrapper';
import {  Progress } from 'antd';

const { Step } = Steps

const CustomSteps = ({
  start,
  end,
  percentage
}) => {
  console.log("custom steps:--", start, end, percentage);
  const [currentStep, setCurrentStep] = useState(0);
  const updatePercentage = () => {
    console.log("percentage----", currentStep);
    if (percentage >= 0 && percentage <= 25) {
      setCurrentStep(0)
    }
    else if (percentage > 25 && percentage <= 50) {
      setCurrentStep(1);
    }
    else if (percentage > 50 && percentage <= 75) {
      setCurrentStep(2);
    }
  }
  useEffect(() => {
    // alert("Ok");
    updatePercentage();
  }, [percentage])
  return (
    <div className='customStepsWrapper'>
      <DateWrapper date={start} />
      <div className="stepWrapper">
        {/* <Steps current={currentStep}>
          <Step title="" description="" />
          <Step title="" description="" />
          <Step title="" description="" />
          <Step title="" description="" />

        </Steps> */}
        {/* <Slider disabled="true"/>
        <span>{currentStep}% completed</span> */}
        <Progress size="small" percent={percentage?.toFixed(2) || 0 }  format={percent => `${percent}% Completed`}/> 

      </div>
      <DateWrapper date={end} />
    </div>
  )
}

export default CustomSteps
