import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import PMOServices from '../../../../../Service/PMOServices';
import CustomSelect from "../../../../../Components/Select/CustomSelect";
import Options from '../../../../../Utilities/Options/Options';
import { GETPROJECTTYPELIST } from '../../../../../Features/Saga/getProjectTypeList';
import { useDispatch, useSelector } from 'react-redux';
import ImageUploading from "react-images-uploading";
import "./EditProjectDetails.css";
import moment from 'moment';
import { MdClear, MdModeEditOutline } from 'react-icons/md';



const EditProjectDetails = (
  {
    projectDetails,
    handleCancel,
    projectData,
    setProjectData,
    screenType,
    setGetPrjDetails,
    images,
    setImages
  }
) => {
  // const [projectData, setProjectData] = useState(projectDetails);
  const projectTypeList = useSelector((state) => state.dropDownListSlice.projectTypeList);
  const dispatch = useDispatch();
  // const [images, setImages] = useState(projectData?.imageDetails);
  const [imgAction, setImgAction] = useState()
  const [selectedImgData, setSelectedImgData] = useState()
  const imgRef = useRef([])
  const currImgAction = useRef()
  console.log("imgAction---", images, imgAction, images?.[0]?.dataURL?._image_urls);
  console.log("projectData--", imgRef, projectData);

  const fieldValidations = () => {
    let isValid = true;
    if (!projectData?.projectName) {
      // alert(1);
      isValid = false;
    }
    if (!projectData?.contractType) {
      // alert(2);
      isValid = false;
    }
    if (!projectData?.projectLocation) {
      // alert(3);
      isValid = false;
    }
    if (!projectData?.projectPincode || projectData?.projectPincode.length !== 6) {
      // alert(4);
      isValid = false;
    }
    if (!projectData?.projectCity) {
      // alert(5);
      isValid = false;
    }
    if (!projectData?.projectType) {
      // alert(6);
      isValid = false;
    }
    if (projectData?.projectType === "Others") {
      if (!projectData?.noOfFloors || !(projectData.noOfFloors > 0 && projectData.noOfFloors < 5)) {
        // alert(7);
        isValid = false;
      }
    }
    if (!projectData?.plotArea) {
      // alert(8);
      isValid = false;
    }
    if (!projectData?.slabArea) {
      // alert(9);
      isValid = false;
    }
    if (!projectData?.projectpenthouse) {
      // alert(14);
      isValid = false;
    }
    if (projectData?.projectpenthouse === "yes") {
      if (!projectData?.penthouseSlabArea) {
        // alert(10);
        isValid = false;
      }
    }
    if (!projectData?.projectBuildArea) {
      // alert(11);
      isValid = false;
    }
    if (!projectData?.totalCost) {
      // alert(12);
      isValid = false;
    }
    if (!projectData?.projectLatitude || !projectData?.projectLongtitude) {
      // alert(13);
      isValid = false;
    }
    return isValid

  }

  const updateProjectDetails = async () => {
    try {

      if (fieldValidations()) {
        console.log("validation",);
        let projectBody = {
          ...projectData,
          noOfFloors: projectData?.noOfFloors === "" ? null : projectData?.noOfFloors,
          projectCoordinates: String(projectData?.projectLatitude).concat(",").concat(projectData?.projectLongtitude),
          updatedId: Number(window.localStorage.getItem("user_Id"))
        }
        console.log("projectBody---", projectBody);
        const response = await PMOServices.updateProjectDetailsById(projectBody);
        console.log("response from images----", response, "prjid----", projectData?.projectId);
        if (response.status === "success") {
          toast.success("Sucessfully Updated Project");
          handleCancel(projectData?.projectId);
        }
        else {
          toast.error("Failed to Update");
        }
      }
      else {
        toast.error("Please Fill All details");
      }

    } catch (error) {
      console.log("error", error);
      toast.error("Failed to Update");
    }
  }
  const handleFields = (type) => (e) => {
    console.log('type', type, e);
    if (type === "city") {
      setProjectData((values) => ({ ...values, projectCity: e }));
    }
    else if (type === 'projectType') {
      setProjectData((values) => ({ ...values, projectType: e }));
    }
    else if (type === "projectpincode") {
      const { name, value } = e.target;
      setProjectData((values) => ({ ...values, [name]: value.slice(0, 6) }));
    }
    else if (type === "contractType") {
      setProjectData((values) => ({ ...values, contractType: e }));
    }
    else {
      const { name, value } = e.target;
      console.log('name', name);
      setProjectData((values) => ({ ...values, [name]: value }));
    }

  }
  useEffect(() => {
    if (projectData?.projectpenthouse === "No") {
      setProjectData((prevState) => ({ ...prevState, penthouseSlabArea: "" }))
    }
  }, [projectData?.projectpenthouse])

  useEffect(() => {
    if (projectData?.projectType !== "Others") {
      setProjectData((prevState) => ({ ...prevState, noOfFloors: "" }))
    }
  }, [projectData?.projectType])
  console.log("currImgAction.current---", currImgAction.current);

  const handleImageUpload = async (values, index) => {
    console.log("onchange", values, index, imgAction, selectedImgData);
    console.log("after-empty----", selectedImgData, imgAction);
    // setImages(values)
    console.log("projectData-:-", currImgAction, imgAction, images);

    let imageformData = new FormData();
    if (currImgAction.current && imgAction === 'add') {
      if (values.length >= 3) {
        imageformData.append("projectId", projectData?.projectUuid);
        imageformData.append("uploadDate", moment().format("YYYY-MM-DD"));
        if (values && values.length > 0) {
          console.log("iff--values-----", values, values.length - 1);
          if (index.length > 1) {
            for (let i = 0; i < values.length; i++) {
              imageformData.append("Files", values[i].file)

            }
          }
          else if (index.length === 1) {
            imageformData.append("Files", values[values.length - 1].file)
          }

        }
        else {
          imageformData.append("Files", null)
        }
        console.log("fi-file---", imageformData.getAll('Files'));
        const response = await PMOServices.addProjectImagesServices(imageformData)
        console.log("response---", response);
        if (response) {
          setGetPrjDetails(true)
          toast.success(response.message);
          currImgAction.current = false
          // setImages(projectData?.imageDetails)
        }
        else {
          toast.error(response.message);
        }
      }
      else {
        toast.error("Please select atleast 3 images")
      }

    }
    else if (currImgAction.current && imgAction === 'update') {
      // alert("hhh")
      imageformData.append("projectId", projectData?.projectUuid);
      imageformData.append("imgId", selectedImgData?._images_id);
      imageformData.append("uploadDate", moment().format("YYYY-MM-DD"));
      imageformData.append("imageName", null);
      if (values && values.length > 0) {
        for (let i = 0; i < values.length; i++) {

          if (values[i].file?.name) {                                                 //extracting new image data & appending them in formdata
            if (!imgRef.current.some(el => el.file.name === values[i].file.name)) {
              imgRef.current.push(values[i])
            }
            console.log("og-imgRef---", imgRef);
          }
        }

        imageformData.append("Files", imgRef.current[imgRef.current.length - 1].file)
      }
      imageformData.append("type", 1);
      console.log("filleeee----", imageformData.getAll('Files'));
      const response = await PMOServices.updateProjectImagesServices(imageformData)
      console.log("response-up--", response);
      if (response && response.data?.isPrjImageUpdated) {
        setGetPrjDetails(true)
        currImgAction.current = false
        toast.success(response.data?.message);
      }
      else {
        toast.error(response.data?.message);
      }

    }
    else if (currImgAction.current && imgAction === 'Deleted') {
      alert("del")
      console.log("delllll----");
      imageformData.append("projectId", projectData?.projectUuid);
      imageformData.append("imgId", selectedImgData?._images_id);
      imageformData.append("uploadDate", moment().format("YYYY-MM-DD"));
      imageformData.append("imageName", selectedImgData?._image_names);
      imageformData.append("Files", selectedImgData?._image_urls)
      imageformData.append("type", 2);

      const response = await PMOServices.deleteProjectImagesServices(imageformData)
      console.log("response-up--", response);
      if (response && response.data?.isPrjImageUpdated) {
        setGetPrjDetails(true)
        currImgAction.current = false
        toast.success(response.data?.message);
      }
      else {
        toast.error(response.data?.message);
      }
    }


  }
  useEffect(() => {
    console.log("imgAction----", imgAction);
    if (imgAction === 'deleted') {
      // alert("dlttttt")

      deleteSelectedImg()
    }
  }, [imgAction, selectedImgData])

  const handleCloseModal = () => {
    setImages(projectData?.imageDetails);
    handleCancel(projectData?.projectId);

  }

  useEffect(() => {
    dispatch(GETPROJECTTYPELIST());
  }, [])

  const deleteSelectedImg = async () => {
    // alert("inside dlt")
    let imageformData = new FormData();

    console.log("imgDDDTAT---", selectedImgData);
    imageformData.append("projectId", projectData?.projectUuid);
    imageformData.append("imgId", selectedImgData?._images_id);
    imageformData.append("uploadDate", moment().format("YYYY-MM-DD"));
    imageformData.append("imageName", selectedImgData?._image_names);
    imageformData.append("Files", selectedImgData?._image_urls)
    imageformData.append("type", 2);

    const response = await PMOServices.deleteProjectImagesServices(imageformData)
    console.log("response-up--", response);
    if (response && response.data?.isPrjImageUpdated) {
      setGetPrjDetails(true)
      toast.success(response.data?.message);
    }
    else {
      toast.error(response.data?.message);
    }
  }


  console.log("imgac-----", imgAction);
  console.log("selectedImgData-----", selectedImgData);


  console.log("selectedImgData-----", selectedImgData);
  console.log("project details", projectData);



  return (
    <div className='contentBody detail'>
      <div class="row">
        <div className='col'>
          <label>Project Name *</label>
          <input
            type="text"
            className="form-control"
            name="projectName"
            placeholder="Enter Project Name"
            value={projectData?.projectName}
            onChange={handleFields('projectname')}
            disabled={["PM", "PC"].includes(screenType) ? true : false}
          />
        </div>
        <div className='col'>
          <label>Contract Type *</label>
          <CustomSelect
            options={Options.contractTypeOptions}
            className={"form-control"}
            mode=""
            placeholder={"Select "}
            showArrow={true}
            value={projectData?.contractType}
            onChange={handleFields('contractType')}
            disabled={["PM", "PC"].includes(screenType) ? true : false}
          />
        </div>

      </div>
      <div class="row">
        <div class="col-6">
          <label>Project Location *</label>
          <input
            type="text"
            className="form-control"
            name='projectLocation'
            placeholder='Enter Location'
            value={projectData?.projectLocation}
            onChange={handleFields('projectlocation')}
            disabled={["PM", "PC"].includes(screenType) ? true : false}
          />
        </div>
        <div className="col">
          <div>
            <label>Latitude *</label>
          </div>
          <div>
            <input
              type="text"
              value={projectData?.projectLatitude}
              name="projectLatitude"
              onChange={handleFields('projectLatitude')}
              className="form-control"
              placeholder="Enter Project Latitude"
              disabled={["PM", "PC"].includes(screenType) ? true : false}
            />
          </div>
        </div>
        <div className="col">
          <div>
            <label>Longitude *</label>
          </div>
          <div>
            <input
              type="text"
              value={projectData?.projectLongtitude}
              name="projectLongtitude"
              onChange={handleFields('projectLongtitude')}
              className="form-control"
              placeholder="Enter Project Longtitude"
              disabled={["PM", "PC"].includes(screenType) ? true : false}
            />

          </div>
        </div>
      </div>
      <div className='row'>
        <div class="col">
          <label>PinCode *</label>
          <input
            type="text"
            className="form-control"
            name='projectPincode'
            placeholder='Enter Pincode'
            value={projectData?.projectPincode}
            onChange={handleFields('projectpincode')}
            disabled={["PM", "PC"].includes(screenType) ? true : false}
          />
        </div>
        <div class="col">
          <label>City *</label>
          <CustomSelect
            options={Options.cityOptions}
            className={"form-control"}
            mode=""
            placeholder={"Select city"}
            showArrow={true}
            value={projectData?.projectCity}
            onChange={handleFields('city')}
            disabled={["PM", "PC"].includes(screenType) ? true : false}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <label>Project Type *</label>
          <CustomSelect
            options={projectTypeList}
            className={"form-control"}
            mode=""
            placeholder={"Select Project Type"}
            showArrow={true}
            value={projectData?.projectType}
            onChange={handleFields('projectType')}
            disabled={["PM", "PC"].includes(screenType) ? true : false}

          />
        </div>
        <div className='col'>
          <div className='col'>
            <label>Number Of Floors *</label>
            <input
              type="text"
              className="form-control"
              name='noOfFloors'
              placeholder='No of floors between 0 and 5'
              value={projectData?.noOfFloors}
              onChange={handleFields('noOfFloors')}
              disabled={projectData?.projectType !== "Others" ? true : false}
            // disabled={["PMO"].includes(screenType) ? true : false}
            />
          </div>
        </div>

      </div>
      <div className='row'>
        <div class="col">
          <label>Plot Area(Sq.Yd)*</label>
          <input
            type="number"
            className="form-control"
            placeholder='Plot Area'
            name='plotArea'
            value={projectData?.plotArea}
            onChange={handleFields('projectplotsize')}
            disabled={["PM", "PC"].includes(screenType) ? true : false}
          />
        </div>
        <div class="col">
          <label>Slab Area(Sq.ft)*</label>
          <input
            type="number"
            className="form-control"
            placeholder='Slab Area'
            name='slabArea'
            value={projectData?.slabArea}
            onChange={handleFields('projectslabarea')}
            disabled={["PM", "PC"].includes(screenType) ? true : false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label>Penthouse</label>
          <select
            value={projectData?.projectpenthouse}
            onChange={handleFields('projectslabarea')}
            className="form-select"
            name="projectpenthouse"
            disabled={["PM", "PC"].includes(screenType) ? true : false}
          >
            <option hidden>Need Penthouse ?</option>
            <option value="yes">Yes</option>
            <option value="No">No</option>
          </select>

        </div>
        <div className="col">
          <label>Penthouse Area (sq.ft) </label>
          <div>
            <input
              type="number"
              value={projectData?.penthouseSlabArea}
              name="penthouseSlabArea"
              className="form-control"
              onChange={handleFields('penthouseSlabArea')}
              placeholder="Enter Penthouse Area"
              onWheel={(e) => e.currentTarget.blur()}
              disabled={(projectData?.projectpenthouse === "No" ||["PM", "PC"].includes(screenType) )? true : false}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div class="col">
          <label>Built Up Area(Sq.ft)*</label>
          <input
            type="number"
            className="form-control"
            placeholder='Built Up Area'
            name='projectBuildArea'
            value={projectData?.projectBuildArea}
            onChange={handleFields('projectbuiltarea')}
            disabled={["PM", "PC"].includes(screenType) ? true : false}
          />
        </div>
        <div class="col">
          <label>Total Cost *</label>
          <input
            type="number"
            className="form-control"
            placeholder='Total Cost'
            name='totalCost'
            value={projectData?.totalCost}
            onChange={handleFields('totalCost')}
            disabled={["PM", "PC"].includes(screenType) ? true : false}
          />
        </div>
      </div>
      <div >
        <div class="col">
          <label>Project Images*</label>
        </div>
        <ImageUploading
          multiple
          acceptType={["jpg", "gif", "png"]}
          value={images}
          onChange={handleImageUpload}
        >
          {(
            props

          ) => {
            console.log("values::", images, props);
            return (
              <div >
                <div className='fileContainer'>
                  {
                    // props.imageList
                    ((images?.length === 1 && !images?.[0]?.dataURL?._image_urls)? []:images)?.map((image, index) => {
                      if(image?.dataURL?._image_urls){  
                      return (
                        <div className='fileContent' key={index}>
                          <button className='clearBtn' >
                            {console.log("image.dataURL----", image.dataURL)}
                            <MdClear onClick={() => {
                              props.onImageRemove(index);
                              setSelectedImgData(image.dataURL)
                              setImgAction("deleted")
                              currImgAction.current = true

                            }} />
                          </button>
                          <img src={image.dataURL._image_urls || image.dataURL} height="60" width="85" />
                          <div className='editPencil'>
                            <MdModeEditOutline
                              color='orange'
                              size={15}
                              onClick={() => {
                                props.onImageUpdate(index);
                                setImgAction('update');
                                setSelectedImgData(image.dataURL)
                                currImgAction.current = true

                              }}
                            />
                          </div>


                        </div>
                        
                      )
                      }
                    })
                  }
                </div>
                <label>*Please upload atleast 3 images</label>
                <div className='uploadFiles' onClick={() => { props.onImageUpload(); setImgAction('add'); currImgAction.current = true }}{...props.dragProps}>
                  <span className='uploadText' >
                    <i class="fa fa-upload" aria-hidden="true"></i>
                    Click here to upload Image
                  </span>
                </div>
              </div>
            )
          }
          }



        </ImageUploading>

      </div>

      <div className='modalFooter'>
        <button
          type="button"
          className="btn cancelBtn"
          onClick={() => handleCloseModal()}
        >
          cancel
        </button>
        <button
          type="button"
          className="btn saveBtn"
          onClick={() => updateProjectDetails()}
        >
          Save
        </button>
      </div>
    </div>

  )
}

export default EditProjectDetails;

// {
//   projectData?.projectType === "Others" ? (

//   ) : null
// }


// {
//   projectData?.projectpenthouse === "No" ? null :
//   <div class="col">
//     <label>Penthouse Area(Sq.Yd)*</label>
//     <input
//       type="text"
//       className="form-control"
//       placeholder='Penthouse Size'
//       name='penthouseSlabArea'
//       value={projectData?.penthouseSlabArea}
//       onChange={handleFields('projectpenthouseslabarea')}
//       disabled={["PMO"].includes(screenType) ? true : false}
//     />
//   </div>
// }

// images?.map((obj,i)=>imageformData.append(`Files[${i}]`,obj));
        // imageformData.append("Files", (images ? images : []));
        // let imageBody = {
        //   projectId: projectData?.projectUuid,
        //   uploadDate: moment().format("YYYY-MM-DD"),
        //   Files:images ? images :[]
        // }
 // const fieldValidations = () => {
  //   if (projectData?.projectName && projectData?.projectType && projectData?.projectLocation && projectData?.projectCity && projectData?.projectPincode && projectData?.plotArea && projectData?.slabArea && projectData?.projectBuildArea) {
  //     let isValid = true;
  //     if (projectData?.projectpenthouse === "yes") {
  //       // alert(2);
  //       if (!projectData?.penthouseSlabArea) {
  //         // return false;
  //         isValid = false;
  //       }
  //     }
  //     if (projectData?.projectType === "Others") {
  //       if (!projectData?.noOfFloors) {
  //         // alert("inside")
  //         // return false;
  //         isValid = false;
  //       }
  //     }
  //     return isValid;

  //   }
  //   else {
  //     alert('1000')
  //     return false;
  //   }
  // }

        //   let imageformData = new FormData();
        //   imageformData.append("projectId", projectData?.projectUuid);
        //   imageformData.append("uploadDate", moment().format("YYYY-MM-DD"));
        //   console.log("images",images);
        //   if (images && images.length > 0) {
        //     for (let i = 0; i < images.length; i++) {
        //       imageformData.append("Files", images[i].file)
        //     }
        // }
        // else {
        //   imageformData.append("Files", null)
        // }
        // console.log("formadata ",imageformData.getAll("Files"));
        // images?.map((obj,i)=>imageformData.append(`Files[${i}]`,obj));
        // imageformData.append("Files", (images ? images : []));
        // let imageBody = {
        //   projectId: projectData?.projectUuid,
        //   uploadDate: moment().format("YYYY-MM-DD"),
        //   Files:images ? images :[]
        // }

        // const response = await Promise.all(
        //   [PMOServices.updateProjectDetailsById(projectBody), PMOServices.addProjectImagesServices(imageformData)]
        // );
