import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../Features";
import createSagaMiddleware from 'redux-saga';
import rootSaga from "../Features/Saga/Saga";


const sagaMiddleware=createSagaMiddleware();


const store =configureStore({
    reducer:rootReducer,
    middleware:(getdefaultmiddleware)=>getdefaultmiddleware({
        serializableCheck: false
      }).concat(sagaMiddleware)
});
sagaMiddleware.run(rootSaga);

export default store;

