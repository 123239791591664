import React, { useState } from 'react';
import { BrowserRouter as Router, NavLink } from 'react-router-dom'
import { IoMdAdd } from 'react-icons/io';
import { BiLeftArrowAlt } from 'react-icons/bi';
import './TurnkeyPartners.css';
import AddTkp from './AddTkp/AddTkp';
import TkpList from './tkpList/TkpList';
import { Tooltip } from 'antd';

function TurnkeyPartner() {
  const [type, setType] = useState('');
  const [userId, setUserId] = useState();
  const [partnerExecutive, setPartnerExecutive] = useState("partnerExecutive");

  {/*toggle handler for change addwehouse users to get wehouse users */ }
  const toggleHandler = (type) => {
    // console.log('type::', type);
    setType(type)
  }

  return (
    <div className="mainContent">
      <div className="contentHeader">
        {(type === "edit" || type === "add") ?
          <div>
             {(type === "edit" ? <span className="headerTitle">TurnKey Partner Details</span> :
              <span className="headerTitle">Add TurnKey Partners</span> 
              )}
          </div>
          :
          <span className="headerTitle">Turnkey Partners</span>
        }
        <button type="button" className="btn whBtnPrimary">
          {(type === "edit" || type === "add") ?
            <span className="btnText" onClick={() => { toggleHandler(" ") }}>
              <NavLink to="#">
                <BiLeftArrowAlt size="22px" className="" />
                <Tooltip title="Turnkey Partner">
                  <span>Back</span>
                </Tooltip>
              </NavLink>
            </span> :
            <span className="btnText" onClick={() => { toggleHandler("add") }}>
              <IoMdAdd size="20px" className="" />
              Turnkey Partner
            </span>}
        </button>
      </div>
      <div className="whContentBlock">
        {(type === "edit" || type === "add") ? <AddTkp type={type} userId={userId} settype={setType} /> : <TkpList partnerExecutive={partnerExecutive} typeHandler={toggleHandler} userId={setUserId} /> }
      </div>
    </div>
  )
}

export default TurnkeyPartner;