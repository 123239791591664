import React, { useState, useEffect } from 'react'
import { GetProjectsListService } from '../../../../../Service/SeCustomersServices';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { FaMedal } from 'react-icons/fa';
import Loader from '../../../../../Components/Loader';
import SimpleBarReact from 'simplebar-react';
import NoData from '../../../../../Components/NoData/NoData';
import Pagination from 'rc-pagination';
import locale from 'rc-pagination/es/locale/en_US';
import { pagination } from '../../../../../Utilities/Pagination/paginationUtilites';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { customerLoader } from '../../../../../Features/Slices/CustomersSlice';
import { Menu, Radio } from 'antd';
import ContentNavbar from '../../../../../Components/contentNavbar';
import moment from 'moment';
import { subDays } from 'date-fns';


function ProjectList() {
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState([]);
  const [toggleBtn, settoggleBtn] = useState(false);
  const [apiLoader, setApiLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(8);
  const [count, setCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(2);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate:subDays(new Date() , 7) ,
      endDate: subDays(new Date() , 0) ,
      key: 'selection'
    }
  ])
  const [check, setCheck] = useState('list');
  const [enterWord,setEnterWord]=useState('');
  const options = [
    {
      label: "Potential", value: 2
    },
    {
      label: "Discarded", value: 3

    },
    {
      label: "Cold", value: 4

    },
    {
      label: "Hot", value: 5

    },
    {
      label: "Future", value: 6

    },
    {
      label: "Lost", value: 7

    },
    {
      label: "Won", value: 8

    }
  ]
  const items = [
    {
      label: <Radio.Group value={filter} options={options} onChange={(e) => {

        setFilter(e.target.value);
        setCurrentPage(1);
      }} className="items-wrapper" />,
      key: "1"
    }


  ]
  const menu = (
    <Menu items={items} />
  );

  console.log("projectsData:::", data);
  console.log("projectsdata searchIput::", searchInput);

  const handleCurrentPage = (pageindex, perpage) => {
    console.log(pageindex, perpage);
    setCurrentPage(pageindex);
  }

  const handleUpdateProject = (index) => {
    console.log('inside the handle update project');
    dispatch(customerLoader({ userId: data[index]?.customerId }));
    history.push("/landing/salesexecutive/customers", { type: "updateproject", customerId: data[index]?.customerId });
  }

  const getProjectList = async (dateRange, activity, pageNo) => {
    let body;
    if (pageNo === 1) {
      body = {
        userId: Number(window.localStorage.getItem("user_Id")),
        startDate: dateRange.startDate ? moment(dateRange.startDate).format('YYYY-MM-DD') : null,
        endDate: dateRange.endDate ? moment(dateRange.endDate).format('YYYY-MM-DD') : null,
        pageIndex: 1,
        numberPerPage: numberPerPage,
        searchWord:enterWord,
      }
    }
    else {
      body = {
        userId: Number(window.localStorage.getItem("user_Id")),
        startDate: dateRange.startDate ? moment(dateRange.startDate).format('YYYY-MM-DD') : null,
        endDate: dateRange.endDate ? moment(dateRange.endDate).format('YYYY-MM-DD') : null,
        pageIndex: currentPage,
        numberPerPage: numberPerPage,
         searchWord:enterWord,
      }
    }

    const response = await GetProjectsListService(body);
    console.log('projects:::', response)
    if (response.status === 'success' && response.data.getprojectlist === true) {
      setApiLoader(false);
      setData(response.data.Projects_list);
      setCount(response.count);
      // toast.success('users data is rendered successfully')
      setSearchInput(response.data.Projects_list);
    } else {
      setApiLoader(false);
      setData([]);
      setCount(0);
      // toast.error('failed to fetch the users list')
    }
  }

  const searchHandler=(e)=>{
    setEnterWord(e.target.value)

  }

  //  getting project list  :
  useEffect(async () => {
    getProjectList(selectionRange[0], null, currentPage);
  }, [currentPage,enterWord])

  const closeInput=()=>{
    setEnterWord("")
  }

  return (

    <div >
      {apiLoader ?
        <Loader />
        :
        <div className="contentBody">
          <ContentNavbar
            leadsData={data}
            setSearchInput={setSearchInput}
            menu={menu}
            setShowDateFilter={setShowDateFilter}
            showDateFilter={showDateFilter}
            onApplyFilter={getProjectList}
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
            roleType="Projects"
            count={count}
            listType={"Projects"}
            check={check}
            setCheck={setCheck}
            enterWord={enterWord}
            setEnterWord={searchHandler}
            closeInput={closeInput}
          />

          {toggleBtn ?
            <SimpleBarReact style={{ maxHeight: 660 }}>
              <div>
                <div className="card-layout">
                  {/* condiation based displaying data */}
                  {/* {data.length ? */}
                  <div>
                    <Grid container spacing={1} className="test" >
                      {searchInput.map((data, index) => {
                        return <Grid item xl={3}>
                          <Card className="card">
                            <CardContent className="card-data">
                              <Grid>
                                <div className="card-data-profile-layout">
                                  <img className="card-data-profile" alt="card-data-profile...." />
                                  <div className="medal-layout">
                                    {<FaMedal size="20px" className="medal" />}
                                  </div>
                                </div>
                                <p className="card-data-profile-name">{data.FirstName}</p>
                                <p className="card-data-profile-years">5/5 years of experiance</p>
                                <p className="card-data-profile-medal">{data.category}</p>
                                <p className="card-data-profile-ratings">Ratings</p>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      })}
                    </Grid>
                  </div>
                </div>
              </div>
            </SimpleBarReact>

            :
            <div class="whAppTable">
              <div class="tableList">
                <div className="tableHead">
                  <div className="tableRow">
                    {/* <div className="project-head-data-cb"><input type="checkbox" /></div> */}
                    <div className="listItem sNo text-center">S.NO</div>
                    <div className="listItem projectName">PROJECT NAME</div>
                    <div className="listItem space">CONTRACT TYPE</div>
                    <div className="listItem space">PROJECT TYPE</div>
                    <div className="listItem space">PLOT AREA(Sq.yd)</div>
                    {/* <div className="listItem" >NO OF FLOORS</div> */}
                    <div className="listItem space">SLAB AREA(Sq.yd)</div>
                    <div className="listItem space">TOTAL COST</div>
                    <div className="listItem status">STATUS</div>

                  </div>
                </div>
                {
                  (data?.length === 0 || data === undefined) ?
                    <NoData /> :
                    <SimpleBarReact style={{ maxHeight: 660 }}>
                      {searchInput.map((item, index) => {
                        return <div className="tableRow" onClick={() => handleUpdateProject(index)}>
                          <div className="listItem sNo text-center" data-head="S.No">
                            <span>
                              {
                                ((currentPage - 1) * numberPerPage) + index + 1
                              }
                            </span>
                          </div>
                          <div className="listItem projectName" data-head="FULL NAME">
                            <div class="nameEmailItem">
                              <span>{item.projectName}</span>
                            </div>
                          </div>
                          <div className="listItem space" data-head="CONTRACT TYPE"> {item.ContractType}</div>
                          <div className="listItem space" data-head="TYPE OF PROJECT"> {item.TypeofProject}</div>
                          <div className="listItem space" data-head="DIMENSIONS"> {item.plotArea}</div>
                          {/* <div className="listItem" data-head="NO OF FLOORS"> {item.No_of_Floors}</div> */}
                          <div className="listItem space" data-head="SLAB AREA(SQ.FT)"> {item.Slab_Area}</div>
                          <div className="listItem space" data-head="TOTAL COST"> {item.TotalCost}</div>
                          <div className="listItem status" data-head="ACTIONS">
                            <div className="actionIcons">
                              <div className={`listItem status`} data-head="TOTAL COST">
                                <div class="btn rounded-pill activeBtn " data-head="Status"> {item?.Status}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      })}
                    </SimpleBarReact>
                }
              </div>
              {/* : null} */}
            </div>}
          <Pagination
            current={currentPage}
            pageSize={numberPerPage}
            total={count}
            hideOnSinglePage={true}
            showSizeChanger
            onChange={handleCurrentPage}
            className="pagination-data"
            locale={locale}
            itemRender={pagination.textItemRender}

          />
        </div>

      }
    </div>

  )
}

export default ProjectList;

