import { takeEvery,call,put } from "@redux-saga/core/effects";
import { DropDownServices } from "../../Service/DropDownServices";
import { getYearsOfExperienceFailure,getYearsOfExperienceSuccess } from "../Slices/YearsOfExperinceListSlice";

export const YEARSOFEXPERIENCELIST=()=>({type:"/yearsOfExperienceList"});

export function* getYerasOfExperienceListGenerator(action){
  const YearsOfExperienceResponse = yield DropDownServices.getYearsOfExperienceListService();
  // console.log("getYearsOfExperienceList::",YearsOfExperienceResponse);
  if(YearsOfExperienceResponse.status === "success"){
       yield put(getYearsOfExperienceSuccess(YearsOfExperienceResponse.data))
  }else{
    console.log("something went wrong from get Years Of Experience generation")

  }
}

function* getYearsOfExperienceListWatcher(){
    yield takeEvery(YEARSOFEXPERIENCELIST().type,getYerasOfExperienceListGenerator)
}

export default getYearsOfExperienceListWatcher;