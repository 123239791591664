import { createSlice } from "@reduxjs/toolkit";

const getpartnerManagersList=createSlice({
    name:'partnermanagers',
    initialState:{
        partnerManagers:[],
        error:null
    },
    reducers :{
        getPartnerManagersListSuccess :(state,action)=>{
            state.partnerManagers = action.payload
        },
        getPartnerManagersListFailure :(state,action)=>{
            state.error = action.payload
        }
    }
}) 

export const {getPartnerManagersListSuccess,getPartnerManagersListFailure} = getpartnerManagersList.actions;

export default getpartnerManagersList.reducer;