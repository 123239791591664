import React, { useState, useEffect } from 'react'
import { GetCustomersListService } from '../../../../../Service/SeCustomersServices';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { imageAssets } from '../../../../../Assets/AssetsConstants/AssetsConstants';
import Loader from '../../../../../Components/Loader';
import SimpleBarReact from 'simplebar-react';
import { customerLoader } from '../../../../../Features/Slices/CustomersSlice';
import Pagination from 'rc-pagination';
import NoData from "../../../../../Components/NoData/NoData";
import locale from 'rc-pagination/es/locale/en_US';
import { pagination } from '../../../../../Utilities/Pagination/paginationUtilites';
import { Menu, Radio } from 'antd';
import ContentNavbar from '../../../../../Components/contentNavbar';
import moment from 'moment';
import { Check } from '@material-ui/icons';
import { subDays } from 'date-fns';



function CustomerList({ typeHandler, userId }) {
  const [searchInput, setSearchInput] = useState([]);
  const [enterWord, setEnterWord] = useState("");
  const [data, setData] = useState([]);
  const [toggleBtn, setToggleBtn] = useState(false);
  const [apiLoader, setApiLoader] = useState(true);
  const [check, setCheck] = useState("list");
  const [filter, setFilter] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(8);
  const [count, setCount] = useState(0);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: subDays( new Date() , 7),
      endDate:  subDays( new Date() , 0),
      key: 'selection'
    }
  ])

  const options = [
    {
      label: "Potential", value: 2
    },
    {
      label: "Discarded", value: 3

    },
    {
      label: "Cold", value: 4

    },
    {
      label: "Hot", value: 5

    },
    {
      label: "Future", value: 6

    },
    {
      label: "Lost", value: 7

    },
    {
      label: "Won", value: 8

    }
  ]

  const items = [
    {
      label: <Radio.Group value={filter} options={options} onChange={(e) => {

        setFilter(e.target.value);
        setCurrentPage(1);
      }} className="items-wrapper" />,
      key: "1"
    }


  ]
  const menu = (
    <Menu items={items} />
  );

  // use dispatch :
  const dispatch = useDispatch();
  const customersId = useSelector(state => state.customerUserId)
  console.log("customersId ==", customersId);



  // closeInput :
  const closeInput = () => {
    setSearchInput("");
    setEnterWord("");
      document.getElementById("search").style.width = "45px"

  }
  const searchHandler=(e)=>{
    setEnterWord(e.target.value)
  }

  // const iconHandler = () => {
  //   alert("im calling ")
  // }

  // Function for View Details:
  const viewDetails = (id) => {
    typeHandler('edit')
    // console.log("userId :",id);
    userId(id)
    dispatch(customerLoader({ userId: id }))
  }

  const getCustomersList = async (dateRange, activity, pageNo) => {

    let body;

    if (pageNo === 1) {
      body = {
        userId: Number(window.localStorage.getItem("user_Id")),
        pageIndex: 1,
        numberPerPage: numberPerPage,
        startDate: dateRange.startDate ? moment(dateRange.startDate).format('YYYY-MM-DD') : null,
        endDate: dateRange.endDate ? moment(dateRange.endDate).format('YYYY-MM-DD') : null,
        searchWord:enterWord,

      }
    }
    else {
      body = {
        userId: Number(window.localStorage.getItem("user_Id")),
        pageIndex: currentPage,
        numberPerPage: numberPerPage,
        startDate: dateRange.startDate ? moment(dateRange.startDate).format('YYYY-MM-DD') : null,
        endDate: dateRange.endDate ? moment(dateRange.endDate).format('YYYY-MM-DD') : null,
        searchWord:enterWord                  

      }

    }
    const response = await GetCustomersListService(body);
    console.log('get::', response)
    if (response.status === 'success') {
      setApiLoader(false);
      setData(response.data.Customers_list);
      setCount(response.count);
    } else {
      // alert
      setApiLoader(false);
      setData([]);
      setCount(0);
    }
  }

  // getting Customers Data List:
  useEffect(() => {
    getCustomersList(selectionRange[0], null, currentPage);
  }, [currentPage,enterWord])

  // function for pagination:
  const handleCurrentPage = (pageindex, perpage) => {
    console.log(pageindex, perpage);
    setCurrentPage(pageindex);
  }
  console.log("data::", data, currentPage);
  return (

    <div>
      {apiLoader ?
        <Loader />
        :
        <div className="contentBody">
          <ContentNavbar
            leadsData={data}
            setSearchInput={setSearchInput}
            menu={menu}
            setShowDateFilter={setShowDateFilter}
            showDateFilter={showDateFilter}
            onApplyFilter={getCustomersList}
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
            roleType="customers"
            count={count}
            listType={"Customers"}
            check={check}
            setCheck={setCheck}
            enterWord={enterWord}
            setEnterWord={searchHandler}
            closeInput={closeInput}
          />

          {toggleBtn ?
            (
              (data?.length === 0 || data === undefined) ?
                <NoData />
                : (
                  <SimpleBarReact style={{ maxHeight: 710 }}>
                    <div>
                      <div className="card-layout">
                        <div>
                          <Grid container spacing={1} className="test">
                            {data.map((data, index) => {
                              return <Grid item xl={3}>
                                <Card className="card" onClick={() => { viewDetails(data.UserId) }}>
                                  <CardContent className="card-data">
                                    <Grid>
                                      <div className="cardProfileAvatar">
                                        <img src="https://voltuswave.com/wp-content/uploads/2022/06/profile-avatar-1.png" alt="" className="cardBlankAvatarImg" />
                                      </div>
                                      <span className="cardProfileName">{data.FirstName + " " + data.LastName}</span>
                                      <span className="cardAadharNum">{data.Email}</span>
                                      <span className="cardAadharNum">{data.Aadhar}</span>
                                      <span className="cardAadharNum">{data.Mobile}</span>


                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Grid>
                            })}
                          </Grid>
                        </div>

                      </div>
                    </div>
                  </SimpleBarReact>
                )
            )
            :
            <div class="whAppTable">
              <div class="tableList">
                <div className="tableHead">
                  <div className="tableRow">
                    <div className="head-data-"></div>
                    <div className="listItem rightArrow"></div>
                    <div className="listItem userName">NAME</div>
                    <div className="listItem mobile">MOBILE</div>
                    <div className="listItem aadhaar">AADHAR</div>
                    <div className="listItem panCard">PAN CARD</div>
                    <div className="listItem address">RESIDENTIAL ADDRESS</div>
                    <div className="listItem status alignItemsC">Status</div>

                  </div>
                </div>
                {(data?.length === 0 || data === undefined) ?
                  <NoData />
                  :
                  <SimpleBarReact style={{ maxHeight: 660 }}>
                    {data.map((item, index) => {
                      return <div className="tableRow" onClick={() => { viewDetails(item.UserId) }}>
                        <div className="listItem rightArrow">
                          <div class="actionIcons">
                            <img src={imageAssets.angleRight} className="" />
                          </div>
                        </div>
                        <div className="listItem userName" data-head="NAME">
                          <div class="nameEmailItem">
                            <span>{item.FirstName + " " + item.LastName}</span>
                            <span className="personEmail"> {item.Email}</span>
                          </div>

                        </div>
                        <div className="listItem mobile" data-head="Mobile">{item.Mobile}</div>
                        <div className="listItem aadhaar" data-head="AADHAR">{item.Aadhar}</div>
                        <div className="listItem panCard" data-head="PAN CARD">{item.PAN}</div>
                        <div className="listItem address" data-head="RESIDENTIAL ADDRESS">{item.ResidentialAddress}</div>
                        <div className="listItem status alignItemsC" data-head="STATUS">
                          <div className="actionIcons">
                            <div className={`btn rounded-pill activeBtn ${item.Status === "Active" && "Active"
                              || item.Status === "Inactive" && "Inactive"
                              || item.Status === "Deleted" && "Deleted"
                              }`}
                              data-head="STATUS">{item?.Status}</div>
                          </div>
                        </div>
                      </div>
                    })}
                  </SimpleBarReact>
                }
              </div>
              {/* : null} */}
            </div>}
          <Pagination
            current={currentPage}
            pageSize={numberPerPage}
            total={count}
            hideOnSinglePage={true}
            showSizeChanger
            onChange={handleCurrentPage}
            className="pagination-data"
            locale={locale}
            itemRender={pagination.textItemRender}

          />

        </div>

      }
    </div>

  )
}

export default CustomerList

