import React,{useState,useEffect} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import { imageAssets } from '../../../../Assets/AssetsConstants/AssetsConstants';
import DatePicker from '../../../../Components/CommonComponents/dateRangePicker';
import { TKPDashboardServices } from '../../../../Service/Dashboard/PartnerExecutive/tkpDashboardService';
import moment from 'moment';
import { addDays, subDays } from 'date-fns';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
 

function Dashboard() {
  const [selectDate, setSelectDate] = useState(false);
  const [selectRange, setSelectRange] = useState([
    {
      startDate:subDays(new Date() , 7) ,
      endDate: addDays(new Date(), 0),
      key: 'selection',
    }
  ])
  const [usersCount, setUsersCount] = useState([]);
  const [labels, setLabels] = useState([]);
  const [value, setValue] = useState([]);
  const [leadsByWise, setLeadsByWise] = useState("");
  const [tkpDataByMonthly, setTkpDataByMonthly] = useState([]);
  const [swDataByMonthly, setSwDataByMonthly] = useState([]);
  const [apDataByMonthly, setApDataByMonthly] = useState([]);
  const [ipDataByMonthly, setIpDataByMonthly] = useState([]);
  const [tkpDataByYearly, setTkpDataByYearly] = useState([]);
  const [swDataByYearly, setSwDataByYearly] = useState([]);
  const [apDataByYearly, setApDataByYearly] = useState([]);
  const [ipDataByYearly, setipDataByYearly] = useState([]);
  const [swLabels, setSwLabels] = useState([]);
  const [swValue, setSwValue] = useState([]);
  const [apLabels, setApLabels] = useState([]);
  const [apValues, setApValues] = useState([]);
  const [ipLabels, setIpLabels] = useState([]);
  const [ipValues, setIpValues] = useState([]);
  const [check, setCheck] = useState('');
   

  // select calendar Function:
  const dateToggleHandler = () => {
    setSelectDate(!selectDate)
    setCheck('date')
  }

  const startDate = selectRange[0].startDate;
  const endDate = selectRange[0].endDate;

  const fromDate = moment(startDate).format("YYYY-MMM-DD");
  const toDate = moment(endDate).format("YYYY-MMM-DD");

  // Function for filetr:
  const applyFilter = () => {
    getTkpLeadsDataByDateRange()
    setSelectDate(false)
    setCheck("filterBtn")
  
  }

  const dateDifferenceInDays = ()=>{
    const date1 = new Date(fromDate)
    const date2 = new Date(toDate)
    return  Math.floor(
      (Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) -
       Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) /
       (1000 * 60 * 60 * 24)
    )
  }

  console.log("dateDifferenceInDays:",dateDifferenceInDays());

 // logginUserId :
 const loginUserId =  window.localStorage.getItem('user_Id')

  // Function for get tkp leads :
  const getTkpLeadsDataByDateRange = async () =>{
    try {

      let dateRanges={
        type:1,
        fromDate: fromDate,
        toDate : toDate,
        loginUserId: loginUserId 
      }

      const response = await TKPDashboardServices.getTkpLeadsCountByDateRangeService(dateRanges);
      console.log("getTkpLeadsDataByDateRange:",response);
      if(response && response.status === "success" && response.data.isUsersCount){
        
        if(dateDifferenceInDays() > 31){
           setTkpDataByYearly(response.data.tkpYearlyCount)
           setSwDataByYearly(response.data.swYearlyCount)
           setApDataByYearly(response.data.apYearlyCount)
           setipDataByYearly(response.data.ipYearlyCount)
           setLeadsByWise("yearly")
        }else{
          setTkpDataByMonthly(response.data.tkpDailyCount)
          setSwDataByMonthly(response.data.swDailyCount)
          setApDataByMonthly(response.data.apDailyCount)
          setIpDataByMonthly(response.data.ipDailyCount)
          setLeadsByWise("daily")
        }
        setUsersCount(response.data.count[0])

      }else{
        setTkpDataByMonthly([])
      }
      

    } catch (error) {
      console.log(error);
      
    }
  }
   
  console.log("usersCount:::",usersCount);
  
  // Function for converting noraml data to timeline chart format;
  const timeLineData = ()=>{
    let date = [] ;
    let count = [] ;
    let swDate = [];
    let swCount = [];
    let apDate = [];
    let apCount = [];
    let ipDate = [];
    let ipCount = [];

    if(leadsByWise === "yearly"){
      // alert("yearly")
      const timelineDataByMonthly = tkpDataByYearly?.map((item,index)=>{
        date = [...date , moment(item._month_end).format("MMM")];
        count = [...count, item._count]
      })

      const swDataByYearlyResponse = swDataByYearly?.map((item,index)=>{
        swDate = [...swDate , moment(item._month_end).format("MMM")];
        swCount = [...swCount, item._count]
      })

      const apDataByYearlyResponse = apDataByYearly?.map((item,index)=>{
        apDate = [...apDate , moment(item._month_end).format("MMM")];
        apCount = [...apCount, item._count]
      })

      const ipDataByYearlyResponse = ipDataByYearly?.map((item,index)=>{
        ipDate = [...ipDate , moment(item._month_end).format("MMM")];
        ipCount = [...ipCount, item._count]
      })
    }else{
      // alert("daily")
      const timelineMappingData =   tkpDataByMonthly?.map((item,index)=>{
        // console.log(item.dates, item.Total);
         date = [...date,moment(item._from_date).format("MMM-DD")]
         count = [...count,item._count]
  
       })

       const swDataByMonthlyResponse =   swDataByMonthly?.map((item,index)=>{
        // console.log(item.dates, item.Total);

         swDate = [...swDate,moment(item._from_date).format("MMM-DD")]
         swCount = [...swCount,item._count]
  
       })

       const apDataByMonthlyResponse =   apDataByMonthly?.map((item,index)=>{
        // console.log(item.dates, item.Total);

         apDate = [...apDate,moment(item._from_date).format("MMM-DD")]
         apCount = [...apCount,item._count]
  
       })

       const ipDataByMonthlyResponse =   ipDataByMonthly?.map((item,index)=>{
        // console.log(item.dates, item.Total);

         ipDate = [...ipDate,moment(item._from_date).format("MMM-DD")]
         ipCount = [...ipCount,item._count]
  
       })
    }
     
     setLabels(date);
     setValue(count);
     setSwLabels(swDate);
     setSwValue(swCount);
     setApLabels(apDate);
     setApValues(apCount);
     setIpLabels(ipDate);
     setIpValues(ipCount)
    // console.log("labels :",labels , "values :",value);
  
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Time Line Chart',
      },
    },
    scales :{
      y : {
        beginAtZero: true
      }
    }
  };
  
  // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  
   const data = {
    labels,
    datasets: [
      {
        label: 'Approved Turnkey partners',
        data: labels.map((item,index) => value[index]),
        borderColor: 'rgb(174,120,254)',
        backgroundColor: 'rgb(174,120,254)',
      },
      {
        label: 'Approved Skilled Workers',
        data: swLabels.map((item,index) => swValue[index]),
        borderColor: 'rgb(111,129,252)',
        backgroundColor: 'rgb(111,129,252)',
      },
      {
        label: 'Approved Architect partners',
        data: apLabels.map((item,index) => apValues[index]),
        borderColor: 'rgb(235,115,120)',
        backgroundColor: 'rgb(235,115,120)',
      },
      {
        label: 'Approved Interior partners',
        data: ipLabels.map((item,index) => ipValues[index]),
        borderColor: 'rgb(211,85,143)',
        backgroundColor: 'rgb(211,85,143)',
     
      },
   
    ],
  };

  useEffect(() => {
    getTkpLeadsDataByDateRange();
  }, [leadsByWise])
  
  useEffect(() => {
    timeLineData()
  }, [tkpDataByMonthly,swDataByMonthly,apDataByMonthly,ipDataByMonthly,tkpDataByYearly,apDataByYearly,swDataByYearly,ipDataByYearly])
  
  return (
    <div>
      <div className="mainContent">
        <div className="contentHeader">
          <span className="headerTitle">Dashboard</span>
          <div className="groupBtns">
            <button className={`btn dateSelector dashboradDateSelector ${check === "date" ? "ActiveBtn" : ""}`} >
              <input type="button" 
               className="date-selector"
               value={fromDate+ " - "+toDate}
               onClick={dateToggleHandler}
              />
            <img src={imageAssets.calendar} onClick={dateToggleHandler} className="calendarIcon" />
            </button>
            <button className={`btn applyFilterBtn ${check === 'filterBtn' ? "ActiveIcon" :""}`} onClick={applyFilter} >Apply Filter</button>
                <div className="calendar" style={{position:'relative',top:"50px", right:"140px"}}>
                  {selectDate && <DatePicker date={selectRange} handler={setSelectRange} /> }
                </div>
          </div>
        </div>
        <div className="whContentBlock">
          <div className="dashboardWrapper">
            <div className="row">
              <div className="col mb-2">
                <div className="smCard">
                  <div className="cardItem">
                    <div className="cardHead">
                      <span className="cardTopTitle">Turnkey Partners <br /> Approved</span>
                      <span className="leadValue">{usersCount.tkp_Approved ? usersCount.tkp_Approved  : 0 }</span>
                    </div>
                    <div className="cardStatusInfo">
                      <div><span>{usersCount.tkp_Pending ? usersCount.tkp_Pending : 0}</span><span>Pending</span></div>
                      <div><span>{usersCount.tkp_Rejected ? usersCount.tkp_Rejected : 0}</span><span>Rejected</span></div>
                      <div><span>{usersCount.tkp_Processing ? usersCount.tkp_Processing :0}</span><span>Processing</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col mb-2">
                <div className="smCard">
                  <div className="cardItem">
                    <div className="cardHead">
                      <span className="cardTopTitle">Skilled Workers <br /> Approved</span>
                      <span className="leadValue">{usersCount.skilled_worker_Approved ? usersCount.skilled_worker_Approved : 0}</span>
                    </div>
                    <div className="cardStatusInfo">
                      <div><span>{usersCount.skilled_worker_Pending ? usersCount.skilled_worker_Pending : 0}</span><span>Pending</span></div>
                      <div><span>{usersCount.skilled_worker_Rejected ? usersCount.skilled_worker_Rejected : 0}</span><span>Rejected</span></div>
                      <div><span>{usersCount.skilled_worker_Processing ? usersCount.skilled_worker_Processing : 0}</span><span>Processing</span></div>
                      {/* <span>20<br /> Approved</span>
                      <span>30<br /> Rejected</span>
                      <span>40<br /> Processing</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col mb-2">
                <div className="smCard">
                  <div className="cardItem">
                    <div className="cardHead">
                      <span className="cardTopTitle">Architect Partners <br /> Approved</span>
                      <span className="leadValue">{usersCount.architect_partner_Approved ? usersCount.architect_partner_Approved : 0}</span>
                    </div>
                    <div className="cardStatusInfo">
                      <div><span>{usersCount.architect_partner_Pending ? usersCount.architect_partner_Pending : 0}</span><span>Pending</span></div>
                      <div><span>{usersCount.architect_partner_Rejected ? usersCount.architect_partner_Rejected : 0}</span><span>Rejected</span></div>
                      <div><span>{usersCount.architect_partner_Processing ? usersCount.architect_partner_Processing : 0}</span><span>Processing</span></div>
                      {/* <span>20<br /> Approved</span>
                      <span>30<br /> Rejected</span>
                      <span>40<br /> Processing</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col mb-2">
                <div className="smCard">
                  <div className="cardItem">
                    <div className="cardHead">
                      <span className="cardTopTitle">Interior Partners<br /> Approved </span>
                      <span className="leadValue">{usersCount.interior_partner_Approved ? usersCount.interior_partner_Approved : 0}</span>
                    </div>
                    <div className="cardStatusInfo">
                      <div><span>{usersCount.interior_partner_Pending ? usersCount.interior_partner_Pending : 0}</span><span>Pending</span></div>
                      <div><span>{usersCount.interior_partner_Rejected ? usersCount.interior_partner_Rejected : 0}</span><span>Rejected</span></div>
                      <div><span>{usersCount.interior_partner_Processing ? usersCount.interior_partner_Processing : 0}</span><span>Processing</span></div>
                      {/* <span>20<br /> Approved</span>
                      <span>30<br /> Rejected</span>
                      <span>40<br /> Processing</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='TimeLineContainer'> 
              <div className="dashboardCard">
                <Line height="500" width="1506" options={options} data={data} />
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard;