import axios from "axios";      

export default axios.create({
    // baseURL: "http://172.16.10.49:8070",
    //  baseURL : "https://qaapi.wehouse.in"  , // qa
    // baseURL:"http://3.108.246.235:8070",
    baseURL:"https://api.wehouse.in",

    headers: {
        "Content-Type": "application/json"
    }           
})
