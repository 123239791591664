import { createSlice } from "@reduxjs/toolkit";

const yearsOfExperienceList = createSlice({
    name : "Experience",
    initialState :{
        experience :[],
        error:null
    },
    reducers : {
        getYearsOfExperienceSuccess:(state,action)=>{
            console.log(action.payload);
           state.experience = action.payload;
        },
        getYearsOfExperienceFailure:(state,action)=>{
            state.error =  action.payload
        }
    }

})

export const {getYearsOfExperienceSuccess,getYearsOfExperienceFailure} = yearsOfExperienceList.actions;

export default yearsOfExperienceList.reducer ;
