import React, { useState, useEffect } from 'react'
import LeadDetails from '../../MyLeads/LeadDetails/LeadDetails'
import "../GenericMeetingDetails/GenericMeetingDetailsModal/GenericMeetingDetailsModal.css";
import { DefaultEditor } from 'react-simple-wysiwyg';
import ScheduleMeeting from '../../MyLeads/GenericTask/ScheduleMeeting/ScheduleMeeting';
import AddTask from '../../MyLeads/AddTask/AddTask';
import CustomSelect from '../../../../../Components/Select/CustomSelect';




const Reschedule = ({
    selecteddetails,
    handleCancel,
    handleSave,
    rescheduleDetails,
    setRescheduleDetails,
    type
}) => {
    console.log("reschedle", selecteddetails);
    let value = window.localStorage.getItem("userName").split("@")[0];
    const [options, setOptions] = useState([{ label: value, value: value }]);

    useEffect(() => {
        if (type === "meeting") {
            const { leadId, activityName, activityDescription, activityId, appointmentDate, appointmentTime, meetingAttendees } = selecteddetails;
            setRescheduleDetails((prev) => ({
                ...prev,
                previousAppointmentId: activityId,
                leadId: leadId,
                leadActivityType: ["Schedule Meeting"],
                title: activityName,
                description: activityDescription,
                meetingAttendees: meetingAttendees,
                meetingScheduleDate: appointmentDate,
                meetingScheduleTime: appointmentTime,
                salesExecutiveId: Number(window.localStorage.getItem("user_Id"))
            }))
        }
        else if (type === "task") {
            const { leadId, activityName, activityDescription, activityId, activityStartDate, activityDueDate ,uploadedFiles} = selecteddetails;
            console.log(selecteddetails,"selecteddetails")
            setRescheduleDetails((prev) => ({
                ...prev,
                previousAppointmentId: activityId,
                leadId: leadId,
                leadActivityType: ["Add Task"],
                title: activityName,
                description: activityDescription,
                taskAttendees: [],
                taskStartDate: activityStartDate,
                taskEndDate: activityDueDate,
                salesExecutiveId: Number(window.localStorage.getItem("user_Id")),
                uploadedFiles
                
            }))
        }

    }, [])
    console.log("reschedule meeting", rescheduleDetails);

    const handleLeadActivities = (type) => (e) => {
        // alert('check');
        // var dom = new DOMParser().parseFromString(e.target.value, 'text/html');
        // var txt = dom.body.textContent
        console.log("inside the handle lead activity", type, e);

        if (type === "schedulemeetingdate") {
            setRescheduleDetails((values) => ({ ...values, meetingScheduleDate: e !== null ? e.format("YYYY-MM-DD") : null }));
        }
        else if (type === "schedulemeetingtime") {
            setRescheduleDetails((values) => ({ ...values, meetingScheduleTime: e !== null ? e.format("h:mm A") : null }))
        }
        else if (type === "title") {
            setRescheduleDetails((values) => ({ ...values, title: e.target.value }))
        }
        else if (type === "description") {
            setRescheduleDetails((values) => ({ ...values, description: e.target.value }))
        }
        else if (type === "meetingAttendees") {
            setRescheduleDetails((values) => ({ ...values, meetingAttendees: e }))
        }
        else if (type === "taskStartDate") {
            setRescheduleDetails((values) => ({ ...values, taskStartDate: e !== null ? e.format("YYYY-MM-DD") : null }))
        }
        else if (type === "taskEndDate") {
            setRescheduleDetails((values) => ({ ...values, taskEndDate: e !== null ? e.format("YYYY-MM-DD") : null }))
        }
        else if (type === "taskAttendees") {
            setRescheduleDetails((values) => ({ ...values, taskAttendees: e }))
        }

    }


    return (
        <div>
            <div className='row'>
                <div className='row'>
                    <div className='leaddetails-wrapper'>
                        <LeadDetails selectedUser={selecteddetails} type={"generic"} />
                        <div className='col-3'>
                            <div style={{ textAlign: "right" }} >
                                <button
                                    className=
                                    {`btn rounded-pill ${selecteddetails?.leadStatus?.includes("Allocated") ?
                                        "Potential" : selecteddetails?.leadStatus?.concat("-active")}`
                                    }
                                >
                                    {selecteddetails?.leadStatus}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <label>Add Title</label>
                <input
                    type="text"
                    class="form-control"
                    onChange={handleLeadActivities("title")}
                    value={rescheduleDetails?.title}
                    placeholder="Enter Title"
                />
            </div>
            <div className='row'>
                <label>Add Description</label>
                <DefaultEditor
                    onChange={handleLeadActivities("description")}
                    value={rescheduleDetails?.description}
                />
            </div>
            <div class="dtPicker">
                {type === "meeting" ?
                    <ScheduleMeeting
                        handleLeadActivities={handleLeadActivities}
                        leadStatus={rescheduleDetails}
                    />
                    :
                    <>
                        {/* <div className='row'>
                            <label>Add Attendees</label>
                            <CustomSelect
                                options={options}
                                mode={"tags"}
                                placeholder={"Add Attendees"}
                                onChange={handleLeadActivities("taskAttendees")}
                                value={rescheduleDetails?.taskAttendees}
                                showArrow={true}
                            />
                        </div> */}
                        <AddTask
                            leadStatus={rescheduleDetails}
                            handleLeadActivities={handleLeadActivities}
                            setLeadStatus={setRescheduleDetails}
                        />
                    </>
                }
            </div>

            <div className='modalFooter'>
                <button
                    type="button"
                    className="btn cancelBtn"
                    onClick={() => handleCancel()}
                >
                    cancel
                </button>
                <button
                    type="button"
                    className="btn saveBtn"
                    onClick={() => handleSave("rescheduleSave")}
                >
                    Save
                </button>
                <button
                    type="button"
                    className="btn  saveBtnclose"
                    onClick={() => handleSave("reschedulesaveandclose")}
                >
                    Save&close
                </button>



            </div>

        </div>
    )
}

export default Reschedule


// activityDescription: "test2"
// activityDueDate: "2022-07-06T18:30:00.000Z"
// activityId: 16
// activityName: "test1"
// activityStartDate: "2022-07-05T18:30:00.000Z"
// activityState: "Completed"
// activityStateId: 2
// leadCity: "Hyderabad"
// leadEmail: "pamidirahul.20@gmail.com"
// leadId: 179
// leadMobile: "9902073603"
// leadName: "Rahul"
// leadServices: ["Residential Construction", "Commercial Construction"]
// leadcreatedDateTime: "2022-05-12T22:42:03.732Z"
// remarks: "test"