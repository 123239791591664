import React from 'react';
import moment from 'moment';
import LeadDetails from '../../MyLeads/LeadDetails/LeadDetails';
import { dateFormatter, logoHandler, servicetype } from '../../../../../Utilities/helpers';

const AppointmentDetailsModal = ({ selectedAppointment, type, setMainScreen, closeModal  }) => {
    return (
        <div>
            <div className="row">
                <div className="row">
                    <LeadDetails
                        selectedUser={selectedAppointment}
                        type={type}
                        setMainScreen={setMainScreen}
                        closeModal={closeModal}
                    />
                    <div className="col-3">
                        <div style={{ textAlign: "right" }}>
                            <button
                                className={`btn rounded-pill ${
                                    selectedAppointment?.leadStatus?.includes("Allocated")
                                        ? "Potential"
                                        : selectedAppointment?.leadStatus?.concat("-active")
                                }`}
                            >
                                {selectedAppointment?.leadStatus}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-5">
                        <label>Services</label>
                        <div className="fW500">{<span>{servicetype(selectedAppointment?.services)}</span>}</div>
                    </div>
                    <div className="col-5">
                        <label>City</label>
                        <div>
                            <span className="fW500">{selectedAppointment?.leadCity}</span>
                            <br />
                        </div>
                    </div>
                    <div className="col-2">
                        <label>Source</label>
                        <br />
                        <img src={logoHandler(selectedAppointment?.leadSource)} alt="google-logo" height="30" />
                    </div>
                </div>
                <div className="mt-4">
                    <label>Inquiry Message</label>
                    <div className="fW500">{selectedAppointment?.leadMessage}</div>
                </div>
            </div>
            <div className="row">
                <label>Sales Executive Comment</label>
                <div class="fW500">{selectedAppointment?.executiveRemarks?.replace(/(<([^>]+)>|&nbsp;)/gi, "\n")}</div>
            </div>
            <div className="row">
                <label>Title</label>
                <div class="fW500">{selectedAppointment?.taskTitle}</div>
            </div>

            <div className="row">
                <label>Description</label>
                <div class="fW500">{selectedAppointment?.taskDescription?.replace(/(<([^>]+)>|&nbsp;)/gi, "\n")}</div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="row">
                        <div className="col-6">
                            <label>Date</label>
                            <div class="fW500">
                                {selectedAppointment?.appointmentDate &&
                                    dateFormatter(selectedAppointment?.appointmentDate, "D MMM, YYYY")}
                            </div>
                        </div>
                        <div className="col-6">
                            <label>Time</label>
                            <div class="fW500">
                                {selectedAppointment?.appointmentTime &&
                                    moment(selectedAppointment?.appointmentTime, "h:mm A").format("h:mm A")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppointmentDetailsModal

