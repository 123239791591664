import { takeEvery, call, put } from "@redux-saga/core/effects";
import { DropDownServices } from "../../Service/DropDownServices";
import { getProjectTypeListFailure, getProjectTypeListSuccess } from "../Slices/dropDownListSlice";


export const GETPROJECTTYPELIST = () => ({ type: '/projectList' });

function* getProjectTypeList(action) {
    console.log("action project list", action);
    const response = yield DropDownServices.getProjectTypeList();
    console.log("getProjectTypeList::", response);
    if (response.status === "success") {
        const projectList = response.data.map((value) => ({ label: value.projectTypeName, value: value.projectTypeName }));
        console.log("projectlist map", projectList);
        yield put(getProjectTypeListSuccess(projectList))
    } else {
        yield put(getProjectTypeListFailure(response.data.message))
    }


}

function* getProjectTypeListWatcher() {
    yield takeEvery(GETPROJECTTYPELIST().type, getProjectTypeList);
}

export default getProjectTypeListWatcher;