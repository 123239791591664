import { createSlice } from "@reduxjs/toolkit";

const getInteriorPartnersList = createSlice({
    name:"interiorPartners",
    initialState:{
        loader:false,
        interiorPartnersData : [],
        error:null,
        count:0
    },
    reducers:{
        getInteriorPartnersLoader : (state,action)=>{
            console.log("action:::",action.payload);
             state.loader = true
        },
        getInteriorPartnersListSuccess : (state,action)=>{
            console.log("action:",action.payload);
            state.loader = false
            state.interiorPartnersData = action.payload.Interior_partners_list
            state.count = action.payload.count
        },
        getInteriorPartnersListFailure :(state,action)=>{
            state.loader = false
            state.error = action.payload
        }
    }
})

export const {getInteriorPartnersListSuccess,getInteriorPartnersListFailure,getInteriorPartnersLoader} = getInteriorPartnersList.actions;

export default getInteriorPartnersList.reducer;