import { DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GETPARTNERMANAGERSLIST } from '../../../../Features/Saga/getpartnerManagersList';
import moment from 'moment'



const AdvancedFilter = ({
  advancedFilterData, setAdvancedFilterData, type, resetFilter,
  onApplyFilterData, showAdvancedFilterModal, setShowAdvancedFilterModal,

}) => {

  console.log("type--", type, resetFilter);

  console.log('advancedFilterData:', advancedFilterData);
  console.log('showAdvancedFilterModal:', showAdvancedFilterModal);

  const [getPMlist, setGetPMlist] = useState()

  const statusOptions = [
    { label: "All", value: 9 },
    { label: "Draft", value: 7 },
    { label: "Pending", value: 3 },
    { label: "Processing", value: 4 },
    { label: "Approved", value: 6 },
    { label: "Rejected", value: 5 }
  ]

  const statusOptinsForPending = [
    { label: "Pending", value: 3 },
    { label: "Processing", value: 4 },
  ]

  const categoryOptions = [
    { label: "Bronze", value: "Bronze" },
    { label: "Silver", value: "Silver" },
    { label: "Gold", value: "Gold" },
    { label: "Diamond", value: "Diamond" },
    { label: "Platinum", value: "Platinum" },

  ]


  const dispatch = useDispatch();
  const partnerManagersList = useSelector((state) => state.getPartnerManagerListSLice.partnerManagers);
  console.log("partnerManagersList:", partnerManagersList);

  const setPMlist = () => {
    const pmList = partnerManagersList?.map(item => (
      {
        label: `${item._firstname} ${item._lastname}`,
        value: `${item._firstname} ${item._lastname}`
      }
    ))
    setGetPMlist(pmList)
  }
  console.log("pmList--", getPMlist);

  useEffect(() => {
    dispatch(GETPARTNERMANAGERSLIST())
    partnerManagersList?.length > 0 && setPMlist()
  }, [])


  const onSelectStatusFilterKey = (key, tkpType) => {
    console.log("keyy----", key, tkpType);

    if (tkpType === 'pending' && key.includes(9)) {
      setAdvancedFilterData(data => ({ ...data, status: [3, 4] }))
    }
    else if (key.includes(9)) {

      setAdvancedFilterData(data => ({ ...data, status: [3, 4, 5, 6, 7] }))
    }
    else {
      setAdvancedFilterData(data => ({ ...data, status: key }))
    }
  }
  const disablePrevDaysOfSelectedFromDate = (current) => {
    console.log("current-:--", current, "frm--", advancedFilterData.fromScheduleDate);
    return current && current.isBefore(moment(advancedFilterData.fromScheduleDate))
  }
  return (
    <div>
      <div className='row'>
        <div className='col'>
          <label>Status</label>
          <Select
            mode='multiple'
            allowClear
            className='fomr-control'
            style={{ width: "100%" }}
            placeholder="Select Status"
            options={type === "pending" ? statusOptinsForPending : statusOptions}
            onChange={(value) => { onSelectStatusFilterKey(value, type) }}
            value={advancedFilterData.status}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <label>Assigned To</label>
          <Select
            mode='multiple'
            allowClear
            style={{ width: "100%" }}
            placeholder="Please Select"
            options={getPMlist}
            onChange={(value) => { setAdvancedFilterData(data => ({ ...data, assignedTo: value })) }}
            value={advancedFilterData.assignedTo}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <label>Schedule Interview</label>
          <div className='col'>
            <label>From Date:</label>
            <DatePicker
              format={"YYYY-MM-DD"}
              onChange={(date, dateString) => { setAdvancedFilterData(data => ({ ...data, fromScheduleDate: dateString })) }}
              value={advancedFilterData.fromScheduleDate ? moment(advancedFilterData.fromScheduleDate, "YYYY-MM-DD") : null}
            />
          </div>
          <div className='col'>
            <label>To Date:</label>
            <DatePicker
              format={"YYYY-MM-DD"}
              onChange={(date, dateString) => { setAdvancedFilterData(data => ({ ...data, toScheduleDate: dateString })) }}
              value={advancedFilterData.toScheduleDate ? moment(advancedFilterData.toScheduleDate, "YYYY-MM-DD") : null}
              disabledDate={disablePrevDaysOfSelectedFromDate}

            />
          </div>
        </div>
      </div>
      {type === "pending" ? " " :

        <div className='row'>
          <div className='col'>
            <label>Category</label>
            <Select
              mode='multiple'
              allowClear
              style={{ width: "100%" }}
              placeholder="Select Category"
              options={categoryOptions}
              onChange={(value) => { setAdvancedFilterData(data => ({ ...data, category: value })) }}
              value={advancedFilterData.category}

            />
          </div>
        </div>
      }

      <div className='footerBtns'>
        <button type="button" className="btn btn-link btn-sm " onClick={() => resetFilter()}>Reset Filter</button>
        <button type="button" className='btn btn-sm whBtnPrimary' onClick={() => {
          onApplyFilterData(1);
          setShowAdvancedFilterModal(false)
        }}>
          Filter</button>
      </div>
    </div>
  )
}

export default AdvancedFilter;
