import React, { useState, useEffect } from 'react';
import './SwList.css';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { FaMedal } from 'react-icons/fa';
import Searchbar from '../../../Searchbar';
import { GetSkilledWorkersServices, getSWListService } from '../../../../../Service/PartnerExecutiveServices';
import { toast } from 'react-toastify';
import { imageAssets } from '../../../../../Assets/AssetsConstants/AssetsConstants';
import Loader from '../../../../../Components/Loader';
import { Rating } from 'react-simple-star-rating';
import SimpleBarReact from "simplebar-react";
import { dateFormatter, resetSelectedFilterKey, storeSelectedFilterKeys } from '../../../../../Utilities/helpers';
import Pagination from 'rc-pagination';
import { Spin } from 'antd';
import NoData from '../../../../../Components/NoData/NoData';
import locale from 'rc-pagination/es/locale/en_US';
import { pagination } from '../../../../../Utilities/Pagination/paginationUtilites';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { GETPARTNERMANAGERSLIST } from '../../../../../Features/Saga/getpartnerManagersList';
import { Check } from '@material-ui/icons';
import ContentNavbar from '../../../../../Components/contentNavbar';
import AdvancedFilter from '../../AdvancedFilter/advancedFilter';




function SwList({ typeHandler, userId, partnerManager, partnerExecutive, allSW }) {
  const [searchInput, setSearchInput] = useState([]);
  const [enterWord, setEnterWord] = useState("");
  const [toggleBtn, setToggleBtn] = useState(false)
  const [data, setData] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(8);
  const [count, setcount] = useState(0);
  const [swtype, setSwtype] = useState("");
  const [check, setCheck] = useState("list");
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [showAdvancedFilterModal, setShowAdvancedFilterModal] = useState(false);
  const [advancedFilterData, setAdvancedFilterData] = useState({
    status :[],
    assignedTo:[],
    fromScheduleDate:null,
    toScheduleDate:null,
    category:[]
  })
  const [selectedFilterVal, setSelectedFilterVal] = useState([])
  const [isFilterDataCleared, setIsFilterDataCleared] = useState(false)

  const dispatch = useDispatch();

  const partnerManagersList = useSelector((state) => state.getPartnerManagerListSLice.partnerManagers);
  console.log("partnerManagersList:", partnerManagersList);

  // function for view user details by id :
  const Viewdetails = (id, status) => {
    if (status === "Approved") {
      typeHandler(status)
    } else {
      if (status !== "Rejected") {
        typeHandler('edit')

      } else {
        typeHandler("")

      }
    }
    userId(id)

  }

  console.log("selected  userId inside skilled workers list:", userId, allSW);

  // function for show list view:
  const showListView = () => {
    setToggleBtn(false)
    setCheck("list")
  }

  // function for show card view:
  const showCardView = () => {
    setToggleBtn(true)
    setCheck("card")
  }

  const iconHandlerFilter = async () => {
    setCheck('filter')
  }

  const iconHandlerSettings = async () => {
    setCheck('setting')
  }

  const loggedInUserId = Number(window.localStorage.getItem('user_Id')); 

  /*useEffect */
  useEffect(async () => {
    dispatch(GETPARTNERMANAGERSLIST());
    setSwtype(partnerExecutive || partnerManager)
  }, [currentPage, enterWord])

  useEffect(() => {
    // getSkilledWorkersList()
    getSWListBySearchFilter()
  }, [currentPage])
  
  useEffect(() => {
    // getSkilledWorkersList(1)
    getSWListBySearchFilter(1)
  }, [enterWord,isFilterDataCleared])

  /* new function for getting SW list with search & filter */
  const getSWListBySearchFilter  = async (pageNo) => {
    try {
      if(pageNo) setCurrentPage(1)
      const payload = {
        listType: 1,
        searchWord: enterWord,
        fromScheduleDate: advancedFilterData.fromScheduleDate,
        toScheduleDate: advancedFilterData.toScheduleDate,
        status: advancedFilterData.status,
        assignedTo: advancedFilterData.assignedTo,
        category: advancedFilterData.category,
        pageIndex: pageNo === 1 ? 1 : currentPage,
        numberOfRecordsPerPage: numberPerPage,
        loggedinUserId: allSW === "allSW" ? null : [loggedInUserId]
      }
     
 
     const response = await getSWListService(payload)
     console.log("GetSkilledWorkers Response :::", response.data.skilledWorkerData);
     if (response.status === "success" && response.data.getSWListData) {
       setApiLoader(false);
       setData(response.data.skilledWorkerData)
       {enterWord && setCurrentPage(1)}
       setcount(response.count);
       setIsFilterDataCleared(false)

     } else {
       setApiLoader(false);
       setData([])
       setcount(0)
       setIsFilterDataCleared(false)

     }

    } catch (error) {
     console.log(error);
    }

 }

 useEffect(() => {
  showSelectedFilterKeysOnTop()
}, [advancedFilterData])

/* storing object of array data into an array */
let selectedFilterOption = []
const val = Object.keys(advancedFilterData).map(val => selectedFilterOption.push(advancedFilterData[val]))
console.log("selectedFilterOption-----", selectedFilterOption)

/* displaying selected filter options*/
const showSelectedFilterKeysOnTop = () => {
 const allSelectedFilterKeys= storeSelectedFilterKeys(selectedFilterOption)
 console.log("allSelectedFilterKeys--",allSelectedFilterKeys);    
  setSelectedFilterVal(allSelectedFilterKeys)
}

/* handler for remove particular filter data */
const removeSelectedFilterKey = (item) => {
  setIsFilterDataCleared(true)
  setSelectedFilterVal(selectedFilterVal.filter(val => val !== item)) 
  resetSelectedFilterKey(item, advancedFilterData,setAdvancedFilterData)
}

  // closeInput :
  const closeInput = () => {
    // setSearchInput([]);
    setEnterWord("")
    document.getElementById("search").style.width = "45px";

  }

  // function for searchHandler:
  const searchHandler = (e) => {
    setEnterWord(e.target.value)
  }

  const handleCurrentPage = (pageindex, perpage) => {
    console.log(pageindex, perpage);
    setCurrentPage(pageindex);
  }

    //reset filter func:
    const resetFilter=()=>{
      setAdvancedFilterData({
        status: [],
        fromScheduleDate: null,
        toScheduleDate: null,
        assignedTo: [],
        category: []
      })
      setShowAdvancedFilterModal(false)
      setSelectedFilterVal([])
      setCurrentPage(1)
      setIsFilterDataCleared(true)
    }

  return (
    <div>
      {/* { apiLoader ? <Loader />
        : */}
      <div className="contentBody">
        <ContentNavbar
          listType={allSW === "allSW" ? "ALL SW" : "SW"}
          count={count}
          enterWord={enterWord}
          setEnterWord={searchHandler}
          closeInput={closeInput}
          check={check}
          setCheck={setCheck}
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
          showDateFilter={showDateFilter}
          setShowDateFilter={setShowDateFilter}
          showCardView={showCardView}
          showListView={showListView}
          showAdvancedFilterModal={showAdvancedFilterModal}
          setShowAdvancedFilterModal={setShowAdvancedFilterModal}
          content = {
            <AdvancedFilter 
            advancedFilterData={advancedFilterData}
            setAdvancedFilterData={setAdvancedFilterData}
            resetFilter={resetFilter}
            onApplyFilterData={getSWListBySearchFilter}
            setShowAdvancedFilterModal={setShowAdvancedFilterModal}
            />
          }
          selectedFilterData={selectedFilterVal}
          removeFilterData={removeSelectedFilterKey}
          resetFilter={resetFilter}

        />
        {toggleBtn ?
          (data.length === 0 || data === undefined) ? <NoData />
            :
            <SimpleBarReact style={{ maxHeight: 710 }}>
              <div className="card-layout">
                {/* {data.length ? */}
                <div>
                  {/* <Spin spinning={apiLoader}> */}
                  <Grid container spacing={1} >
                    {data?.map((item, index) => {
                      return <Grid item xl={3}>
                        <Card className="card" onClick={() => { Viewdetails(item.userId) }}>
                          <CardContent className="card-data">
                            <Grid>
                              <div className="cardProfileAvatar">
                                {/* <img src={data.profile} className="card-data-profile  " alt="card-data-profile...." /> */}
                                {/* <img src="" className="blankAvatarImg" />
                                    {<FaMedal size="20px" className="medal" />} */}
                                <img src={item.imageUrl ? item.imageUrl : "cardBlankAvatarImg"} alt="" className="cardBlankAvatarImg" />
                                {/* <img arc=""  alt="" className="competitor bronzeMedal"/> */}
                              </div>
                              <span className="cardProfileName">{item.firstName + " " + item.lastName}</span>
                              {/* {item.status === "Approved" && */}
                              <Rating size="17px" readonly={true} allowHalfIcon ratingValue={item.overAllRating} />
                              {/* } */}
                              <div>
                                {/* {item.status === "Approved" && */}
                                <button className={`btn bronze ${item.categoryName === "Bronze" && "bronze" ||
                                  item.categoryName === "Silver" && "silver" ||
                                  item.categoryName === "Gold" && "gold" ||
                                  item.categoryName === "Diamond" && "diamond" ||
                                  item.categoryName === "Platinum" && "platinum"
                                  }`}>
                                  {item.categoryName ? item.categoryName : "Bronze"}
                                </button>
                                {/* } */}
                              </div>
                              <span className="cardProfileExperience">{item.yearsOFExperience + " " + "years of experience"}</span>
                              <p className="card-data-profile-medal">{item.category}</p>
                              <div className="listItem" data-head="Category">
                                <div>
                                  <span className="cardProfileExperience">{item.mobile}</span>
                                </div>
                              </div>
                              <div className="cardProfileExperience" data-head="Category">
                                <div>
                                  <span  >{item.aadhar}</span>
                                </div>
                              </div>
                              <div className="cardProfileExperience" data-head="Category">
                                <div>
                                  <span class="">{item.tkpPartner}</span>
                                </div>
                              </div>
                              {/* <span className="">
                                  <Rating size="25px" readonly={true} allowHalfIcon ratingValue={item.overAllRating}/>
                                  </span> */}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>

                    })}
                  </Grid>
                  {/* </Spin> */}
                </div>
                {/* // :
                    // <div className="noDataFoundBox">
                    //   <img src={nodata} />
                    //   <span>No Data Found</span>
                    // </div>} */}
              </div>
            </SimpleBarReact>
          :
          <div class="whAppTable">
            {/* {data.length ?  */}
            <div class="tableList">
              <div className="tableHead">
                <div className="tableRow">
                  {/* <div className="tkp-head-cb"><input type="checkbox" /></div> */}
                  <div className="listItem userName">Name</div>
                  {/* <div className="listItem alignItemsC">Category</div> */}
                  {/* <div className="listItem" >Address</div> */}
                  <div className="listItem mobile">Mobile</div>
                  <div className="listItem aadhaar">Aadhar</div>
                  <div className="listItem submitted">Scheduled  interview</div>
                  <div className="listItem submitted">{swtype === "partnerManager" ? "Evaluated By" : "Assigned To"}</div>
                  <div className="listItem submitted">Skill Type</div>
                  <div className="listItem status alignItemsC">Status</div>
                </div>
              </div>
              {(data.length === 0 || data === undefined) ? <NoData />
                :
                <SimpleBarReact style={{ maxHeight: 660 }}>
                  {/* <Spin spinning={apiLoader}> */}
                  {data.map((item, index) => {
                    return <div className="tableRow" onClick={() => { Viewdetails(item.userId, item.status) }}>
                      {/* <div className="tkp-table-cb"><input type="checkbox" /></div> */}
                      <div className="listItem userName" data-head="Name">
                        <div class="d-flex">
                          {/* <img src={imageAssets.noData} className="pic" alt="... " /> */}
                          <img src={item.imageUrl ? item.imageUrl : "cardBlankAvatarImg"} className="blankAvatarImg" />
                          <div class="personName">
                            <span>{item.firstName + " " + item.lastName}</span>
                            {/* overall Rating */}
                            <div>
                              {(item.status === "Approved" || item.status === 'Rejected' || item.status === "Processing") &&
                                <Rating size="17px" readonly={true} allowHalfIcon ratingValue={item.overAllRating} />
                              }
                            </div>
                            <div>
                              {(item.status === "Approved" || item.status === "Processing") &&
                                <button className={`btn bronze ${item.categoryName === "Bronze" && "bronze" ||
                                  item.categoryName === "Silver" && "silver" ||
                                  item.categoryName === "Gold" && "gold" ||
                                  item.categoryName === "Diamond" && "diamond" ||
                                  item.categoryName === "Platinum" && "platinum"
                                  }`}>
                                  {item.categoryName ? item.categoryName :'Bronze'}
                                </button>
                              }
                            </div>


                          </div>
                        </div>
                      </div>

                      {/* <div className="tkp-table-a"> {item.address}</div> */}
                      <div className="listItem mobile" data-head="Aadhar"> {item.mobile}</div>
                      <div className="listItem aadhaar" data-head="Aadhar"> {item.aadhar}</div>
                      {/* <div className="tkp-table-s">{dateFormatter(item.submittedTime, "D MMM YYYY")}</div> */}
                      <div className="listItem submitted" data-head="Submitted">{item.interviewDate === null || item.interviewTime === null ? "Not Scheduled" : moment(item.interviewDate).format("D MMM, YYYY") + " " + moment(item.interviewTime, "hh:mm a").format("hh:mm a")}</div>
                      <div className="listItem submitted" data-head="Skill Type">
                        <span>{partnerExecutive === "partnerExecutive" ? <span>{item.evaluatedBy}</span>
                          : allSW === "allSW" ?
                            <span>{(item.status === "Approved" || item.status === 'Rejected' || item.status === 'Processing') ? <span>{item.evaluatedBy}</span> : <span>&#8212;</span>}</span>
                            :
                            <span>{item.assignedBy}</span>}</span></div>
                      <div className="listItem submitted" data-head="Skill Type">{item.skillType}</div>
                      <div className="listItem status alignItemsC" data-head="Status">
                        <div className={`btn rounded-pill   ${item.status === "Draft" && "Draft" ||
                          item.status === "Rejected" && "Rejected" ||
                          item.status === "Processing" && "Processing" ||
                          item.status === "Pending" && "Pending" ||
                          item.status === "Approved" && "Approved"
                          }`}
                          // style={{backgroundColor : item.status === "Active" && "#18b40d" 
                          //     || item.status === "Inactive" && "rgb(116,116,116)" 
                          //     || item.status === "Deleted" && "rgb(245,85,86)"}} 
                          data-head="Status"> {item.status}</div>
                      </div>
                      {/* <div className="listItem">
                          <span className="nameInitial">JS</span>
                          {item.assignedBy}
                        </div> */}
                      {/* <div className="listItem">
                          <img src={imageAssets.menuDots} className="dots-icon" alt />
                        </div> */}
                    </div>
                  })}
                  {/* </Spin> */}
                </SimpleBarReact>
              }

            </div>
            {/* // : <div className="noDataFoundBox">
                  //   <img src={nodata} />
                  //   <span>No Data Found</span>
                  // </div>} */}

          </div>
        }

        {/* 
        <div className="data-result">
                {searchInput.length > 1 && searchInput.map((val, key) => {
                  return <a><p>{val.name}</p></a>
                })}
              </div> */}

        <Pagination
          current={currentPage}
          pageSize={numberPerPage}
          showSizeChanger
          total={count}
          onChange={handleCurrentPage}
          hideOnSinglePage={true}
          className="pagination-data"
          locale={locale}
          itemRender={pagination.textItemRender}
        />

      </div>

    </div>
  )
}
export default SwList;


{/* <div className="contentNavbar">

<div className="">
  <Searchbar closeInput={closeInput} enterWord={enterWord} setEnterWord={searchHandler} check={check} setCheck={setCheck} />
</div>
<div class="groupBtns">
  <button type="button" class="btn toggleBtn">
    <div className={`toggleBtnIcon ${check === "list" ? "ActiveIcon" : ""}`} onClick={showListView}>
    
      <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M7,6H23a1,1,0,0,0,0-2H7A1,1,0,0,0,7,6Z" /><path d="M23,11H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" /><path d="M23,18H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" /><circle cx="2" cy="5" r="2" /><circle cx="2" cy="12" r="2" /><circle cx="2" cy="19" r="2" /></svg>
    </div>
    <div className="vLine"></div>
    <div className={`toggleBtnIcon ${check === "card" ? "ActiveIcon" : ""}`} onClick={showCardView}>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="" height="20"><g id="_01_align_center" data-name="01 align center"><path d="M11,11H0V3A3,3,0,0,1,3,0h8ZM2,9H9V2H3A1,1,0,0,0,2,3Z" /><path d="M24,11H13V0h8a3,3,0,0,1,3,3ZM15,9h7V3a1,1,0,0,0-1-1H15Z" /><path d="M11,24H3a3,3,0,0,1-3-3V13H11ZM2,15v6a1,1,0,0,0,1,1H9V15Z" /><path d="M21,24H13V13H24v8A3,3,0,0,1,21,24Zm-6-2h6a1,1,0,0,0,1-1V15H15Z" /></g></svg>
    </div>
  </button>

  <button type="button" className={`btn btnDefault ${check === 'filter' ? "ActiveIcon" : ""}`} onClick={iconHandlerFilter}>
    <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M1,4.75H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2ZM7.333,2a1.75,1.75,0,1,1-1.75,1.75A1.752,1.752,0,0,1,7.333,2Z" /><path d="M23,11H20.264a3.727,3.727,0,0,0-7.194,0H1a1,1,0,0,0,0,2H13.07a3.727,3.727,0,0,0,7.194,0H23a1,1,0,0,0,0-2Zm-6.333,2.75A1.75,1.75,0,1,1,18.417,12,1.752,1.752,0,0,1,16.667,13.75Z" /><path d="M23,19.25H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2ZM7.333,22a1.75,1.75,0,1,1,1.75-1.75A1.753,1.753,0,0,1,7.333,22Z" /></svg>
  </button>

  <button type="button" className={`btn btnDefault ${check === 'setting' ? "ActiveIcon" : ""}`} onClick={iconHandlerSettings}>
    <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" /><path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" /></svg>
  </button>
</div>
</div> */}


     // :
        // <div>
        //   <div className="noDataFoundBox">
        //   {
        //       apiLoader ? (<Loader/>)
        //         :
        //         <>
        //           <img src={imageAssets.noData} className="no-data-img" />
        //           <span>No Data Found</span>
        //         </>
        //     }
        //   </div>
        // </div>

         /*getting skilled workers list */
//   const getSkilledWorkersList = async (pageNo) => {
//     try {
     
//      let searchData = {
//        listType: 1,
//        searchWord: enterWord,
//        pageIndex:pageNo === 1 ? 1 : currentPage,
//        numberPerPage: numberPerPage,
//        logginUserId: allSW === "allSW" ? null : loggedInUserId
//      }
 
//      const response = await GetSkilledWorkersServices(searchData)
//      // console.log("GetSkilledWorkers Response :::", response);
//      if (response.status === "success" && response.data.getSkilledWorkersList === true) {
//        setApiLoader(false);
//        setData(response.data.skilledWorkers)
//        {enterWord && setCurrentPage(1)}
//        setcount(response.count);
//      } else {
//        setApiLoader(false);
//        setData([])
//        setcount(0)
//      }

//     } catch (error) {
//      console.log(error);
//     }

//  }