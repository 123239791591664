import React from 'react';
import { Divider, Steps } from 'antd';
import moment from 'moment';

const DateWrapper = ({
  date
}) => {
  return (
    <div className='projectDate'>
      <span>{moment(date).format("D")}</span>
      <span>{`${moment(date).format("MMM")} ${moment(date).format("YYYY")}`}</span>
    </div>
  )
}

export default DateWrapper