import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';
import { BiLeftArrowAlt } from 'react-icons/bi';
import './Customers.css';
import CustomerList from './customersList/CustomerList';
import CustomerDetails from './createCustomers/CustomerDetails';
import ProjectDetails from './createCustomers/ProjectDetails';
import PaymentInstallments from './createCustomers/PaymentInstallments';
import { Tabs, Tooltip } from 'antd';
import Addcustomers from './createCustomers/Addcustomers';
import { useDispatch } from 'react-redux';





function Customers() {
  const [changeBtn, setChangeBtn] = useState(false);
  const [active, setActive] = useState(1);
  const [type, setType] = useState();
  const [userId, setUserId] = useState();
  const [newLeadToCustomer, setNewLeadToCustomer] = useState(null);
  const [projectCustomerId, setProjectCustomerId] = useState();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  console.log("location", location);

  const toggleHandler = (navigation) => {
    if (type === "updateproject") {
      history.push("/landing/salesexecutive/projects");
    }
    else {
      setType(navigation)
      // console.log('type:', type);
    }
  }

  const NavItems = [
    {
      id: 1,
      title: 'Customer Details',
      content: <CustomerDetails />
    },
    {
      id: 2,
      title: 'Project Details',
      content: <ProjectDetails />
    },
    {
      id: 3,
      title: 'Payment Installments',
      content: <PaymentInstallments />
    }
  ]
  useEffect(() => {

    if (location.state?.leadDetails) {
      console.log(location.state.userId);
      setNewLeadToCustomer(location.state.leadDetails);
      setType("newlead");
    }
    else if (location.state?.type === "updateproject") {
      setProjectCustomerId(location?.state?.customerId);
      setType("updateproject");
    }
    else if (location.state?.type === "add") {
      setType("add");
    }
  }, [location])


  return (
    <div className="mainContent">
      <div className="contentHeader">
        {(type === "edit" || type === "add")
          ?
          <div>
            {(type === "edit" ?
              <span class="headerTitle">
                {NavItems.map(({ id, title }) => (
                  active === id ? title + " " : ""
                ))}
              </span>
              :
              <span class="headerTitle">
                {NavItems.map(({ id, title }) => (
                  active === id ? title + " " + "Creation" : ""
                ))}
              </span>)}

          </div>
          :
          <span class="headerTitle">Customers</span>
        }
        <button type="button" className="btn whBtnPrimary" >
          {(type === "edit" || type === "add" || type === "newlead" || type === "updateproject")
            ?
            <Tooltip title="Customers">
              <span className="btnText" onClick={() => { toggleHandler(" ") }}>
                <NavLink to="#">
                  <BiLeftArrowAlt size="25px" className="" />
                  Back
                </NavLink>
              </span>
            </Tooltip>
            :
            <span className="btnText" onClick={() => { toggleHandler("add") }}>
              <NavLink to="#">
                <IoMdAdd size="25px" className="" />
                New Customer
              </NavLink>
            </span>
          }
        </button>
      </div>
      <div class="whContentBlock">
        {
          (type === "edit" || type === "add" || type === "newlead" || type === "updateproject")
            ?
            <Addcustomers
              type={type}
              userId={userId}
              toggleHandler={toggleHandler}
              newLead={newLeadToCustomer}
              projectCustomerId={projectCustomerId}
            />
            :
            <CustomerList
              typeHandler={toggleHandler}
              userId={setUserId}
            />
        }
      </div>

    </div>
  )
}
export default Customers;

// setUserId(location.state.userId);
      // dispatch(customerLoader({userId:location.state.userId}));
   // alert("inside the customer");


   // const { TabPane } = Tabs;

// const callBack = (key) => {
//   console.log(key);
// }