import { Menu, Radio, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import ContentNavbar from '../../../../../Components/contentNavbar';
import NoData from '../../../../../Components/NoData/NoData';
import SimpleBarReact from 'simplebar-react';
import { getMyLeadActivityList, rescheduleAppointmentService, updateAppointmentStateService } from '../../../../../Service/MyLeadsService';
import { toast } from 'react-toastify';
import moment from 'moment';
import GenericMeetingDetailsModal from './GenericMeetingDetailsModal/GenericMeetingDetailsModal';
import Pagination from 'rc-pagination';
import { pagination } from '../../../../../Utilities/Pagination/paginationUtilites';
import locale from 'rc-pagination/es/locale/en_US';
import Reschedule from '../Reschedule/Reschedule';
import RescheduleHeader from '../Reschedule/RescheduleHeader/RescheduleHeader';


const GenericMeetingDetails = ({ leadId, activeTab }) => {
    const [filter, setFilter] = useState("");
    const [meetingModal, setMeetingModal] = useState(false);
    const [leadMeetingList, setMeetingList] = useState([]);
    const [selectedMeeting, setSelectedMeeting] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [numberPerPage, setNumberPerPage] = useState(8);
    const [count, setCount] = useState(0);
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [modalType, setModalType] = useState("details");
    const [selectedState, setSelectedState] = useState(null);

    const [rescheduleDetails, setRescheduleDetails] = useState(
        {
            previousAppointmentId: "",
            leadId: "",
            leadActivityType: ["Schedule Meeting"],
            title: "",
            description: "",
            meetingAttendees: [],
            meetingScheduleDate: null,
            meetingScheduleTime: null,
            salesExecutiveId: ""

        }
    )
    const [newAppointmentId, setNewAppointmentId] = useState("");

    const options = [
        {
            label: "All",
            value: ""
        },

        {
            label: "Cold", value: 4

        },
        {
            label: "Hot", value: 5

        },
        {
            label: "Future", value: 6

        },
        {
            label: "Discarded", value: 3

        },
        {
            label: "Lost", value: 7

        }
    ]
    const items = [
        {
            label: <Radio.Group value={filter} options={options} className="items-wrapper" />,
            key: "1"
        }


    ]
    const menu = (
        <Menu items={items} />
    );

    const handleReset = () => {
        setRescheduleDetails(
            {
                previousAppointmentId: "",
                leadId: "",
                leadActivityType: ["Schedule Meeting"],
                title: "",
                description: "",
                meetingAttendees: [],
                meetingScheduleDate: null,
                meetingScheduleTime: null,
                salesExecutiveId: ""

            }
        )
        setModalType("details");
        setNewAppointmentId("");
    }
    const closeModal = () => {
        handleReset();
        setMeetingModal(false);

    }

    const handleModal = (meetingDetails) => {
        if((meetingDetails?.activityState !== "Rescheduled")){
        setSelectedMeeting(meetingDetails);
        setMeetingModal(true);
        }

    }
    // function for pagination:
    const handleCurrentPage = (pageindex, perpage) => {
        console.log(pageindex, perpage);
        setCurrentPage(pageindex);
    }

    const getLeadMeetingList = async (dateRange, activity, pageNo) => {
        try {
            setShowDateFilter(false);
            let body;
            if (pageNo) {
                setCurrentPage(1);
                body = {
                    leadId: leadId,
                    dateFrom: dateRange?.startDate ? moment(dateRange?.startDate).format('YYYY-MM-DD') : null,
                    dateTo: dateRange.endDate ? moment(dateRange?.endDate).format('YYYY-MM-DD') : null,
                    activityType: "Schedule Meeting",
                    pageIndex: pageNo,
                    numberPerPage: numberPerPage
                }
            }
            else {
                body = {
                    leadId: leadId,
                    dateFrom: dateRange?.startDate ? moment(dateRange?.startDate).format('YYYY-MM-DD') : null,
                    dateTo: dateRange.endDate ? moment(dateRange?.endDate).format('YYYY-MM-DD') : null,
                    activityType: "Schedule Meeting",
                    pageIndex: currentPage,
                    numberPerPage: numberPerPage
                }

            }
            const response = await getMyLeadActivityList(body)
            if (response.status === "success") {
                setMeetingList(response.data);
                setCount(response.count);
            }
            else {
                setMeetingList([]);
                // toast.error('something went wrong')
            }

        } catch (error) {
            console.log('meeting ', error);
            setMeetingList([]);
            toast.error('something went wrong')
        }
    }

    const updateState = async (type) => {
        try {
            console.log("inside the update state");
            const response = await updateAppointmentStateService({
                activityId: selectedMeeting?.activityId,
                activityState: selectedState
            })
            if (response.status === "success") {
                toast.success("successfully updated state");
                // setSelectedState(null);
                if (type) {
                    handleCancel();
                }
            }
            else {
                toast.error("Failed to update state");
            }
        } catch (error) {
            console.log("error ", error);
            toast.error("Failed to update state");
        }

    }

    const handleCancel = () => {
        getLeadMeetingList(selectionRange[0], null, 0);
        closeModal();
    }

    const rescheduleMeeting = async (type) => {
        try {
            let body;
            if (newAppointmentId) {
                body = {
                    ...rescheduleDetails,
                    newAppointmentId: newAppointmentId
                }
            }
            else {
                body = {
                    ...rescheduleDetails
                }
            }
            console.log("rescheudle details", body);
            const response = await rescheduleAppointmentService(body);
            if (response.status === "success") {
                if (type === "reschedulesaveandclose") {
                    handleCancel();
                }
                else{
                setNewAppointmentId(response?.newAppointmentId);
                }
                toast.success('Successfully Rescheduled Meeting');
            }
            else {
                toast.error('Failed to Reschedule Meeting');
            }

        } catch (error) {
            toast.error("Failed to Reschedule Meeting");
        }
    }

    const fieldValidation = () => {
        if (rescheduleDetails?.title && rescheduleDetails?.description && rescheduleDetails?.meetingAttendees.length && rescheduleDetails?.meetingScheduleDate && rescheduleDetails?.meetingScheduleTime) {
            return true;
        }
        else {
            return false;
        }
    }


    const handleSave = async (type) => {
        if (type === "detailsSave") {
            updateState();
        }
        else if (type === "detailsSaveandClose") {
            updateState("detailsSaveandClose");
        }
        else if (type === "rescheduleSave" && fieldValidation()) {
            rescheduleMeeting(type);
        }
        else if (type === "reschedulesaveandclose" && fieldValidation()) {
            rescheduleMeeting(type);
        }
        else {
            toast.error("Please Fill all details");
        }

    }





    useEffect(() => {
        getLeadMeetingList(selectionRange[0], null, 0);
    }, [currentPage, activeTab])



    return (
        <div className="whContentBlock">
            <div className='contentBody'>
                <ContentNavbar
                    leadsData={null}
                    setSearchInput={null}
                    menu={menu}
                    setShowDateFilter={setShowDateFilter}
                    showDateFilter={showDateFilter}
                    onApplyFilter={getLeadMeetingList}
                    selectionRange={selectionRange}
                    setSelectionRange={setSelectionRange}
                    count={count}
                    listType="Meetings"
                />
                <div className='whAppTable'>
                    <div className="tableList">
                        <div className="tableHead">
                            <div className="tableRow">
                                <div className="listItem sNo text-center">S.No</div>
                                <div className="listItem startDate">DATE TIME</div>
                                <div className="listItem tastTitle">TASK TITLE</div>
                                <div className="listItem status">STATUS</div>
                            </div>
                        </div>
                        {(leadMeetingList?.length === 0 || leadMeetingList === undefined) ?
                            <NoData />
                            :
                            <>
                                <SimpleBarReact style={{ maxHeight: 640 }}>
                                    {leadMeetingList?.map((item, index) => {
                                        return (
                                            <div className="tableRow" onClick={() => handleModal(item)}>
                                                <div className="listItem sNo text-center" data-head="S.No">
                                                    <span>
                                                        {/* {index + 1} */}
                                                        {

                                                            ((currentPage - 1) * numberPerPage) + index + 1
                                                        }
                                                    </span>
                                                </div>
                                                <div className="listItem startDate" data-head="START DATE">
                                                    {
                                                        moment(item?.appointmentDate).utc().format("D MMM, YYYY ")
                                                    },
                                                    {
                                                        // dateFormatter(item?.appointmentTime, "HH:mm a")
                                                        moment(item?.appointmentTime, 'hh:mm a').format('hh:mm a')
                                                    }

                                                </div>

                                                <div className="listItem tastTitle" data-head="TASK TITLE">
                                                    {item.activityName}
                                                </div>
                                                <div className="listItem status" data-head="STATUS">
                                                    <button className={`btn rounded-pill Active`}
                                                    >
                                                        {
                                                            item?.activityState
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </SimpleBarReact>
                            </>
                        }
                    </div>
                </div>
                <Pagination
                    current={currentPage}
                    pageSize={numberPerPage}
                    total={count}
                    hideOnSinglePage={true}
                    showSizeChanger
                    onChange={handleCurrentPage}
                    className="pagination-data"
                    locale={locale}
                    itemRender={pagination.textItemRender}
                />
            </div>
            <Modal
                visible={meetingModal}
                onOk={handleCancel}
                onCancel={handleCancel}
                footer={null}
                className="customModal"
                title={
                    modalType === "details" ?
                        "My Meeting Details" :
                        <RescheduleHeader
                            setModalType={setModalType}
                        />
                }
            >
                {modalType === "details"
                    ?
                    <GenericMeetingDetailsModal
                        selectedMeeting={selectedMeeting}
                        handleCancel={handleCancel}
                        modalType={modalType}
                        setModalType={setModalType}
                        handleSave={handleSave}
                        selectedState={selectedState}
                        setSelectedState={setSelectedState}

                    />
                    :
                    <Reschedule
                        selecteddetails={selectedMeeting}
                        handleCancel={handleCancel}
                        handleSave={handleSave}
                        rescheduleDetails={rescheduleDetails}
                        setRescheduleDetails={setRescheduleDetails}
                        type={"meeting"}
                    />
                }
            </Modal>

        </div>
    )
}

export default GenericMeetingDetails;

