import { createSlice } from "@reduxjs/toolkit";

const weHouseUserSlice=createSlice({
    name:"wehouseuser",
    initialState:{
        loading:false,
        weHouseUserData:{},
        error:null
    },
    reducers:{
        wehouseUserLoader:(state,action)=>{
            // console.log(action.payload);
            state.loading=true
        },
        getWeHouseUserByIdSuccess:(state,action)=>{
            state.loading=false;
            state.weHouseUserData=action.payload;
        },
        getWeHouseUserByIdFailure:(state,action)=>{
            state.loading=false;
            state.error=action.payload
        }
    }
})


export const {wehouseUserLoader,getWeHouseUserByIdSuccess,getWeHouseUserByIdFailure}=weHouseUserSlice.actions;



export default weHouseUserSlice.reducer;