import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import { count, index } from 'd3-array';
import { SalesCoordinatorDashboardServices } from '../../../../Service/Dashboard/SalesCoordinator/getDashboardLeadsCount';
import moment from 'moment';


function ActivityLog({ timelineLeads, startDate, endDate, applyFilter,leadsByWise }) {
  const [labels, setLabels] = useState([]);
  const [value, setValue] = useState([]);
  const [dailyLeads, setDailyLeads] = useState([]);


  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Time Line Chart',
      },
      scales :{
        y :{
          beginAtZero :true
        }
      }
    },
  };

  // let labels = [];
  // let values = [];

  console.log("inside activity log timelineLeads::", timelineLeads);

  const getTimelineLeads = () => {
    let date = [];
    let count = [];
    
     if(leadsByWise === "yearly"){
      const dailyLeadsData = timelineLeads.map((item, index) => {
        date = [...date, moment(item._month_end).format("MMM") ]
        count = [...count, item._total_count]
      })
     }else{
      const dailyLeadsData = timelineLeads.map((item, index) => {
        date = [...date, moment(item._month).format("MMM-D") ]
        count = [...count, item.total_count]
      })
     }
  

    setLabels(date);
    setValue(count)

    console.log(date);
    console.log(count);
  }

  console.log("labels:", labels);
  console.log("values:", value);

  const data = {
    labels,
    datasets: [
      {
        label: 'Leads',
        data: labels.map((item, index) => value[index]),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },

    ],
  };

  useEffect(() => {
    getTimelineLeads();
  }, [timelineLeads])

  return (
    <div>
      <div className="TimeLineContainer">
        <div className="dashboardCard" >
          <Line data={data} options={options} height="450" width="1506" />
        </div>
      </div>

    </div>
  )
}

export default ActivityLog;