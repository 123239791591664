import { Select } from 'antd';
import React from 'react';


const CustomSelect = ({
    options,
    className,
    mode,
    allowClear,
    placeholder,
    onChange,
    showArrow,
    value,
    disabled
}) => {
    console.log("placeholder", placeholder)
    return (
        <Select
            options={options}
            style={{ width: "100%" }}
            className={className}
            mode={mode}
            allowClear={allowClear}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            showArrow={showArrow}
            disabled={disabled}

        />
    )
}

export default CustomSelect