import React, { useState } from 'react';
import '../../Styles/Landing.css';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';


function Searchbar({enterWord , setEnterWord, check, setCheck,closeInput}) {
    console.log(enterWord , setEnterWord, check, setCheck,closeInput,"propsssssssssssssssss")

    const focusHandler=()=>{
        setCheck('search');
        document.getElementById("search").style.width = "100%";
        
    }

    const onblurHandler=()=>{
        if(enterWord != 0){
            document.getElementById("search").style.width = "100%";
        } 
        else if(!enterWord){
            document.getElementById("search").style.width = "45px";
        }
    }

    return (
        <div>
            <div className="searchIconContainer">
                <div className="serachInput">
                    <div>
                        <input type="text"
                            placeholder={"Search"}
                            value={enterWord}
                            className={`searchField ${check === 'search' ? "SearchIcon" : ""}`}
                            id="search"
                            onChange={setEnterWord}
                            onFocus={focusHandler}
                            onBlur={onblurHandler}
                        />
                        {/* <AiOutlineSearch size="20px" className="searchIcon" /> */}
                        {enterWord && <AiOutlineClose size="18px" color='#dc3545' className="clearIcon" onClick={closeInput} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Searchbar





  // //search Handler:
    // const SearchHandler = (e) => {
    //     var searchWord = e.target.value;
    //     // console.log('searchWord:::', searchWord);
    //     setEnterWord(searchWord)
 
    //     var FilteredData = [];

    //     // turnkey partners search data :
    //     if (type === "tkp") {
    //         var searchWord = e.target.value;
    //         if (searchWord !== "") {
    //             setEnterWord(searchWord)
    //             FilteredData = data.filter((val) => {
    //                 // console.log(val);
    //                 // return val.FirstName.toLowerCase().includes(searchWord.toLowerCase())
    //             })
    //         }
    //         else if(searchWord == ""){
    //             FilteredData = data;
    //             searchInput([]);
    //         }
    //     }
        
    //     // skilled Workers search data :
    //     else if (type === "skilledWrokers") {
    //         var searchWord = e.target.value;
    //         if (searchWord !== "") {
    //             setEnterWord(searchWord)
    //             FilteredData = data.filter((val) => {
    //                 // console.log(val);
    //                 // return val.firstName.toLowerCase().includes(searchWord.toLowerCase())
    //             })
    //         }
    //         else if(searchWord == ""){
    //             FilteredData=data;
    //             searchInput([]);
    //         }
    //     }

    //     // Architech Partners search data:
    //     else if(type === "ArchitechPartners"){
    //         var searchWord =e.target.value;
    //         if(searchWord !== ""){
    //             setEnterWord(searchWord)
    //             FilteredData = data.filter((val)=>{
    //                 // console.log(val);
    //                 // return val.FirstName.toLowerCase().includes(searchWord.toLowerCase())
                    
    //             })
    //         }else{
    //             FilteredData=data;
    //             searchInput([]);            }
    //     }

    //     // Interior Partners search data:
    //     else if(type === "interiorPartners"){
    //         var searchWord =e.target.value;
    //         if(searchWord !== ""){
    //             setEnterWord(searchWord)
    //             FilteredData = data.filter((val)=>{
    //                 // console.log(val);
    //                 // return  val.firstName.toLowerCase().includes(searchWord.toLowerCase())
    //             })
    //         }else{
    //             FilteredData=data;
    //             searchInput([]);            }
    //     }

    //     // Project List search data:
    //     else if(type === "projectsList"){
    //         var searchWord = e.target.value;
    //         if(searchWord !== ""){
    //             setEnterWord(searchWord)
    //             FilteredData = data.filter((val)=>{
    //                 // console.log(val);
    //                 // return val.FirstName.toLowerCase().includes(searchWord.toLowerCase())
    //             })
    //         }
    //         else if(searchWord == ""){
    //             FilteredData = data;
    //          searchInput(FilteredData);
    //         }
    //     }

    //     // Customers list search bar :
    //     else if(type === "customersList"){
    //         var searchWord = e.target.value;
    //         setEnterWord(searchWord)
    //         FilteredData = data.filter((val) =>{
    //             // console.log(val);
    //             // return val.FirstName.toLowerCase().includes(searchWord.toLowerCase())
    //         })
    //     }
    //     console.log("FilteredData::", FilteredData);
    //     searchInput(FilteredData)
    // }