import React, { useState, useEffect } from 'react';
import './Tkplist.css';
import { Rating } from 'react-simple-star-rating';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Loader from '../../../../../Components/Loader';
import { useHistory } from 'react-router';
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css"
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'rc-pagination';
import NoData from '../../../../../Components/NoData/NoData';
import moment from 'moment';
import { GETPARTNERMANAGERSLIST } from '../../../../../Features/Saga/getpartnerManagersList';
import { partnerExecutiveServices } from '../../../../../Service/PartnerExecutiveServices';
import locale from 'rc-pagination/es/locale/en_US';
import { pagination } from '../../../../../Utilities/Pagination/paginationUtilites';
import ContentNavbar from '../../../../../Components/contentNavbar';
import AdvancedFilter from '../../AdvancedFilter/advancedFilter';
import { resetFilter, resetSelectedFilterKey, storeSelectedFilterKeys } from '../../../../../Utilities/helpers';

// import 'rc-select/assets/index.less';

function TkpList({ typeHandler, userId, partnerExecutive, partnerManager, allTKPS }) {
  const [toggleBtn, setToggleBtn] = useState(false);
  const [data, setData] = useState([])
  const [check, setCheck] = useState('list');
  const [apiLoader, setApiLoader] = useState(true);
  const [tkpType, settkpType] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(8);
  const [count, setCount] = useState(0);
  const [enterWord, setEnterWord] = useState('');
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [showAdvancedFilterModal, setShowAdvancedFilterModal] = useState(false);
  const [advancedFilterData, setAdvancedFilterData] = useState({
    status: [],
    assignedTo: [],
    fromScheduleDate: null,
    toScheduleDate: null,
    category: []
  })
  const [selectedFilterVal, setSelectedFilterVal] = useState([])
  const [isFilterDataCleared, setIsFilterDataCleared] = useState(false)




  console.log("compo---", selectedFilterVal);

  console.log('date for filter:', advancedFilterData.scheduleDate);
  console.log("allTKPS:", allTKPS);

  const { TurnkeyPartnersData, loading } = useSelector(state => state.TurnkeyPartners)
  const partnerManagersList = useSelector((state) => state.getPartnerManagerListSLice.partnerManagers);
  console.log("TurnKeyPartnersData==", partnerManagersList);

  // function for show list view:
  const showListView = () => {
    setToggleBtn(false)
    setCheck("list")
  }
  console.log("tkpType : ", tkpType);

  // function for show grid view:
  const showCardView = () => {
    setToggleBtn(true)
    setCheck("card")
  }


  {/*getting filtered data through searchInput */ }
  // console.log('serachinput :::', searchInput);

  const iconHandlerFilter = () => {
    setCheck('filterIcon')
  }

  const iconHandlerSettings = () => {
    setCheck('settingIcon')
  }

  {/*Function for go to view Details page */ }
  const ViewDetails = (id, status) => {
    // history.push('/landing/admin/addWeHouseUsers')
    if (status === "Approved") {
      typeHandler('approved')
      userId(id)
    } else {
      if (status !== "Rejected") {
        typeHandler("edit");
        userId(id);
      } else {
        typeHandler("")
        userId(null)
      }
    }
    // dispatch(TurnkeyPartnersLoader({userId:id}))
  }


  // function for search handler:
  const searchHandler = async (e) => {
    setEnterWord(e.target.value);
  }

  console.log('enter word:', enterWord, check);

  // function for close input:
  const closeInput = () => {
    setEnterWord('')
    closeTkpListBySearch()
    document.getElementById("search").style.width = "45px";
  }

  const loggedinUser = Number(window.localStorage.getItem('user_Id'));



  const closeTkpListBySearch = async () => {
    try {
      let searchData = {
        listType: 1,
        searchWord: enterWord,
        pageIndex: currentPage,
        numberPerPage: numberPerPage,
        logginUserId: allTKPS === "allTKPS" ? null : [loggedinUser]
      }

      const response = await partnerExecutiveServices.GetTurnKeyPartnerService(searchData);
      console.log('gettkpResponse::', response);
      if (response.status === "success" && response.data.gettkplist === true) {
        setApiLoader(false);
        setData(response.data.TKPs_list)
        setCount(response.data.count)
        setCurrentPage(1)

      } else {
        setApiLoader(false);
        setData([])
        setCount(0)
      }

    } catch (error) {
      console.log(error);
    }
  }
  console.log("advancedFilterData-----", advancedFilterData);

  /* new get tkplist func*/
  const getTkpListBySearchFilter = async (pageNo) => {
    try {
      console.log("get list---", advancedFilterData);
      if (pageNo) setCurrentPage(1)
      const payload = {
        listType: 1,
        searchWord: enterWord,
        fromScheduleDate: advancedFilterData.fromScheduleDate,
        toScheduleDate: advancedFilterData.toScheduleDate,
        status: advancedFilterData.status,
        assignedTo: advancedFilterData.assignedTo,
        category: advancedFilterData.category,
        pageIndex: pageNo === 1 ? 1 : currentPage,
        numberPerPage: numberPerPage,
        loggedinUserId: allTKPS === "allTKPS" ? null : [loggedinUser]
      }
      const response = await partnerExecutiveServices.getTKPListService(payload);
      console.log('gettkpResponse::', response);
      if (response.status === "success" && response.data.getTkpListData) {
        setApiLoader(false);
        setData(response.data.tkpData)
        { enterWord && setCurrentPage(1) }
        setCount(response.count)
        setIsFilterDataCleared(false)
      } else {
        setApiLoader(false);
        setData([])
        setCount(0)
        setIsFilterDataCleared(false)

      }

    } catch (error) {
      console.log(error);
    }
  }




  useEffect(() => {
    settkpType(partnerExecutive || partnerManager)
  }, [currentPage])

  useEffect(() => {
    // getTkpList()
    getTkpListBySearchFilter()
  }, [currentPage])

  useEffect(() => {
    // getTkpList(1)
    // showSelectedFilterKey()
    getTkpListBySearchFilter(1)
  }, [enterWord, isFilterDataCleared])

  useEffect(() => {
    dispatch(GETPARTNERMANAGERSLIST())
  }, [])

  useEffect(() => {
    showSelectedFilterKeysOnTop()
  }, [advancedFilterData])

  /* storing object of array data into an array */
  let selectedFilterOption = []
  const val = Object.keys(advancedFilterData).map(val => selectedFilterOption.push(advancedFilterData[val]))
  console.log("selectedFilterOption-----", selectedFilterOption)

  /* displaying selected filter options*/
  const showSelectedFilterKeysOnTop = () => {
    const allSelectedFilterKeys = storeSelectedFilterKeys(selectedFilterOption)
    console.log("allSelectedFilterKeys--", allSelectedFilterKeys);
    setSelectedFilterVal(allSelectedFilterKeys)
  }

  /* handler for remove particular filter data */
  const removeSelectedFilterKey = (item) => {
    setIsFilterDataCleared(true)
    setSelectedFilterVal(selectedFilterVal.filter(val => val !== item))
    console.log("item-rm-", item, "advancedFilterData---", advancedFilterData, advancedFilterData.category.includes(item));
    resetSelectedFilterKey(item, advancedFilterData, setAdvancedFilterData)

  }

  // useEffect(() => {  selectedFilterOption.push(advancedFilterData[val]
  //   console.log('TurnkeyPartnersData:::', TurnkeyPartnersData);
  //   setData(TurnkeyPartnersData)
  //   // setData([]);
  // }, [TurnkeyPartnersData])

  // function for pagination:
  const handleCurrentPage = (pageindex, perpage) => {
    console.log(pageindex, perpage);
    setCurrentPage(pageindex);
  }
  console.log("data::", data, currentPage);  

  return (
    <div>
      {/* {data.length ? */}
      {apiLoader ? <Loader />
        :
        <div className="contentBody">
          <ContentNavbar
            listType={allTKPS === 'allTKPS' ? "ALL TKP" : "TKP"}
            count={count}
            closeInput={closeInput}
            enterWord={enterWord}
            setEnterWord={searchHandler}
            check={check}
            setCheck={setCheck}
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
            showDateFilter={showDateFilter}
            setShowDateFilter={setShowDateFilter}
            showCardView={showCardView}
            showListView={showListView}
            content={
              <AdvancedFilter
                advancedFilterData={advancedFilterData}
                setAdvancedFilterData={setAdvancedFilterData}
                resetFilter={() => resetFilter
                  (setAdvancedFilterData, setShowAdvancedFilterModal,
                  setSelectedFilterVal, setCurrentPage, setIsFilterDataCleared)
                }
                onApplyFilterData={getTkpListBySearchFilter}
                setShowAdvancedFilterModal={setShowAdvancedFilterModal}
              // showAdvancedFilterModal={showAdvancedFilterModal}

              />
            }
            showAdvancedFilterModal={showAdvancedFilterModal}
            setShowAdvancedFilterModal={setShowAdvancedFilterModal}
            selectedFilterData={selectedFilterVal}
            removeFilterData={removeSelectedFilterKey}
            resetFilter={() => resetFilter
              (setAdvancedFilterData, setShowAdvancedFilterModal,
              setSelectedFilterVal, setCurrentPage, setIsFilterDataCleared)
              }
          />
          {/* <SimpleBarReact style={{ maxHeight: 640 }}> */}
          {toggleBtn ?
            <SimpleBarReact style={{ maxHeight: 710 }}>
              <div className="card-layout">
                {/* {data.length ? */}
                <div>

                  {(data.length === 0 || data === undefined) ? <NoData />
                    :
                    <Grid container spacing={1} className="">
                      {/* <>{getTKPCard(data, ImageData)}</> */}

                      {/* original get Tkp list */}

                      {data.map((data, index) => {
                        return <Grid item xl={3}>
                          <Card className="card" onClick={() => { ViewDetails(data.UserId, data.Status) }}>
                            <CardContent className="card-data">
                              <Grid>
                                <div className="cardProfileAvatar">
                                  {console.log("ggggg")}
                                  {/* <img src={imageAssets.noData} className="card-data-profile" alt="card-data-profile...." /> */}
                                  {/* {<FaMedal size="20px" className="medal" />} */}
                                  <img src={data.imageUrl ? data.imageUrl : "cardBlankAvatarImg"} alt="" className="cardBlankAvatarImg" />
                                  {/* <img src="" alt="" className="competitor bronzeMedal" /> */}
                                </div>
                                <span className="cardProfileName">{data.FirstName + " " + data.LastName}</span>
                                {/* {data.Status === "Approved" &&   */}
                                <Rating size="17px"
                                  readonly={true}
                                  allowHalfIcon
                                  ratingValue={data.OverAll}
                                  allowHover={true}
                                // tooltipClassName="rating"
                                />
                                {/* } */}
                                <div>
                                  {/* {data.Status === "Approved" &&   */}
                                  <button className={`btn bronze ${data.CategoryName === "Bronze" && "bronze" ||
                                      data.CategoryName === "Silver" && "silver" ||
                                      data.CategoryName === "Gold" && "gold" ||
                                      data.CategoryName === "Diamond" && "diamond" ||
                                      data.CategoryName === "Platinum" && "platinum"
                                      }`}
                                  > {data.CategoryName ? data.CategoryName : "Bronze"}</button>
                                  {/* } */}
                                </div>
                                <span className="cardProfileExperience">{data.Years_of_Experience + " " + " of experience"}</span>
                                <span className="cardProfileExperience">{data.Educational_qualification}</span>
                                <span className="cardProfileExperience">{data.Mobile}</span>
                                <span className="cardProfileExperience">{data.Aadhar}</span>

                                {/* <p className="card-data-profile-medal"/> */}


                                {/* <div className="listItem" data-head="Category">
                                  <div>
                                    <span class="bronze">Bronze</span>
                                  </div>
                                </div> */}

                                {/* <span className="">
                                  <Rating size="25px"
                                    readonly={true}
                                    allowHalfIcon
                                    ratingValue={data.Overall_Rating}
                                    allowHover={false}
                                  />
                                </span> */}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      })}

                    </Grid>

                  }
                </div>

              </div>

            </SimpleBarReact>
            :
            <div className="whAppTable">
              {/* {data.length ?  */}
              <div className="tableList">
                <div className="tableHead">
                  <div className="tableRow">
                    {/* <div className="listItem checkBox"><input type="checkbox" /></div> */}
                    <div className="listItem userName">Name</div>
                    <div className="listItem mobile">Mobile</div>
                    {/* <div className="listItem alignItemsC">Category</div> */}
                    {/* <div className="tkp-head-data-a" >Address</div> */}
                    <div className="listItem aadhaar">Aadhar</div>
                    {/* <div className="listItem">Submitted</div> */}
                    <div className="listItem sInterview"> Scheduled Interview</div>
                    {/* <div className="listItem">State</div> */}
                    <div className="listItem assigned">{tkpType === "partnerExecutive" ? "Assigned To" : " Evaluated By"}</div>
                    <div className="listItem status alignItemsC">Status</div>
                  </div>
                </div>
                {
                  (data?.length === 0 || data === undefined) ? <NoData />
                    :
                    <SimpleBarReact style={{ maxHeight: 640 }}>
                      {/* <>{getTKPList(data, ImageData)}</> */}
                      {/* <Spin spinning={loading}> */}
                      {data.map((item, index) => {
                        return <div>
                          <div className="tableRow" onClick={() => { ViewDetails(item.UserId, item.Status) }}>
                            {/* <div className="listItem checkBox"><input type="checkbox" /></div> */}
                            <div className="listItem userName" data-head="TKP Name">

                              {/* <img src={imageAssets.noData} className="pic" alt="... " /> */}

                              <div class="d-flex">
                                <img src={item.imageUrl ? item.imageUrl : "blankAvatarImg"} className="blankAvatarImg" />
                                <div class="personName">
                                  <span>{item.FirstName + " " + item.LastName}</span>
                                  <div className="ratings">

                                    {/* <span class="fa-solid fa-star checked"></span>
                              <span class="fa-solid fa-star checked"></span>
                              <span class="fa-solid fa-star checked"></span>
                              <span class="fa-solid fa-star"></span>
                              <span class="fa-solid fa-star"></span> */}
                                    {(item.Status === "Approved" || item.Status === "Rejected" || item.Status === "Processing") &&
                                      <Rating size="17px"
                                        readonly={true}
                                        allowHalfIcon
                                        ratingValue={item.OverAll}
                                        allowHover={true}
                                      // tooltipClassName="rating"
                                      />}

                                  </div>

                                  <div>
                                    {(item.Status === "Approved" || item.Status === "Processing") &&
                                      <button className={`btn  ${item.CategoryName === "Bronze" && "bronze" ||
                                        item.CategoryName === "Silver" && "silver" ||
                                        item.CategoryName === "Gold" && "gold" ||
                                        item.CategoryName === "Diamond" && "diamond" ||
                                        item.CategoryName === "Platinum" && "platinum"
                                        }`}>
                                        {item.CategoryName}
                                      </button>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="listItem alignItemsC" data-head="Category">
                            <div>
                              <span class="bronze">Bronze</span>
                            </div>
                            {item.category}
                          </div> */}

                            {/* <div className="tkp-table-a"> {item.address}</div> */}
                            <div className="listItem mobile" data-head="Mobile"> {item.Mobile}</div>
                            <div className="listItem aadhaar" data-head="Aadhar"> {item.Aadhar}</div>
                            <div className="listItem sInterview" data-head="Scheduled Interview"> {item.InterviewDate === null || item.InterviewTime === null ? "Not Scheduled" : moment(item.InterviewDate).format("D MMM, YYYY") + " " + moment(item.InterviewTime, "hh:mm a").format("hh:mm a")}</div>
                            <div className="listItem assigned" data-head="Evaluated By">
                              {/* <p className="nameInitial"></p> */}
                              {/* {console.log("check",partnerManagersList?.filter((value)=>value._userid === item.AssignedTo),item.AssignedTo)} */}
                              {tkpType === "partnerExecutive" ?
                                // <span>{(partnerManagersList?.filter((value) => value._userid === item.AssignedTo))[0]?._firstname}</span> 
                                <span>{item.EvaluatedBy}</span>
                                : allTKPS === "allTKPS" ?
                                  <span>{item.Status === "Approved" || item.Status === "Rejected" || item.Status === "Processing" ? <span>{item.EvaluatedBy}</span> : <span>&#8212;</span>}</span>
                                  :
                                  <span>{item.AssignedBy}</span>}
                              {/* <span>{item.AssignedTo}</span> */}
                            </div>
                            <div className="listItem status alignItemsC">
                              <div className={`btn rounded-pill   ${item.Status === "Draft" && "Draft" ||
                                item.Status === "Rejected" && "Rejected" ||
                                item.Status === "Processing" && "Processing" ||
                                item.Status === "Pending" && "Pending" ||
                                item.Status === "Approved" && "Approved"

                                }`} data-head="Status">
                                {item.Status}</div>
                            </div>
                          </div>
                        </div>
                      })}
                      {/* </Spin> */}
                    </SimpleBarReact>
                }
              </div>

            </div>
          }

          <Pagination
            current={currentPage}
            pageSize={numberPerPage}
            showSizeChanger
            total={count}
            onChange={handleCurrentPage}
            hideOnSinglePage={true}
            className="pagination-data"
            locale={locale}
            itemRender={pagination.textItemRender}
          />
        </div>

      }
    </div >
  )
}
export default TkpList;


{/* <div className="noDataFoundBox">
          {
            loading ? (<Loader />) */}
{/* :
              <> */}
{/* <img src={imageAssets.noData} className="no-data-img" />
                  <span>No Data Found</span> */}
{/* </>
          }
        </div> */}


{/* <Spin spinning={loading}> */ }


{/* // : <div className="noDataFoundBox">
            //   <img src={imageAssets.noData} />
            //   <span>No Data Found</span>
            // </div> */}

{/* </SimpleBarReact> */ }

{/* <div className="data-result">
        {searchInput.length > 1 && searchInput.map((val, key) => {
          console.log("data result::", val);
          return <a><p>{val.FirstName}</p></a>
        })}
         </div> */}

{/* : */ }
{/* <div className="noDataFoundBox">
              <img src={imageAssets.noData} className="no-data-img" />
              <span>No Data Found</span>
            </div>} */}


//content navbar :
{/* <div className="contentNavbar">
<div className="">
  search bar component
  <Searchbar closeInput={closeInput} enterWord={enterWord} setEnterWord={searchHandler}  check={check} setCheck={setCheck} />
</div>
<div class="groupBtns">
  <button type="button" class="btn toggleBtn">
    <div className={`toggleBtnIcon tkp-toggle-btn-list  ${check === "list" ? "ActiveIcon" : ""}`} onClick={showListView}>
      <img
          src={list}
          className="list-icon "
          className={`app-icon ${check === "list" ? "active" : ""}`}
          onClick={() => { setToggleBtn(false) }}
        />

      <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M7,6H23a1,1,0,0,0,0-2H7A1,1,0,0,0,7,6Z" /><path d="M23,11H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" /><path d="M23,18H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" /><circle cx="2" cy="5" r="2" /><circle cx="2" cy="12" r="2" /><circle cx="2" cy="19" r="2" /></svg>

    </div>

    <div className="vLine"></div>

    <div className={`toggleBtnIcon tkp-toggle-btn-card ${check === "card" ? "ActiveIcon" : " "}`} onClick={showCardView}>
      <img
          src={imageAssets.apps}
          className="app-icon"
          className={`app-icon ${check === "card" ? "active" : ""}`}
          onClick={() => { setToggleBtn(true) }}
        />

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="" height="20"><g id="_01_align_center" data-name="01 align center"><path d="M11,11H0V3A3,3,0,0,1,3,0h8ZM2,9H9V2H3A1,1,0,0,0,2,3Z" /><path d="M24,11H13V0h8a3,3,0,0,1,3,3ZM15,9h7V3a1,1,0,0,0-1-1H15Z" /><path d="M11,24H3a3,3,0,0,1-3-3V13H11ZM2,15v6a1,1,0,0,0,1,1H9V15Z" /><path d="M21,24H13V13H24v8A3,3,0,0,1,21,24Zm-6-2h6a1,1,0,0,0,1-1V15H15Z" /></g></svg>

    </div>
  </button>

  <img
      src={imageAssets.settingsSlider}
      className="tkp-setting-slider-icon"
      onClick={iconHandler}
    />
  <button type="button" class={`btn btnDefault ${check === 'filterIcon' ? "ActiveIcon" : ""}`} onClick={iconHandlerFilter}>
    <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M1,4.75H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2ZM7.333,2a1.75,1.75,0,1,1-1.75,1.75A1.752,1.752,0,0,1,7.333,2Z" /><path d="M23,11H20.264a3.727,3.727,0,0,0-7.194,0H1a1,1,0,0,0,0,2H13.07a3.727,3.727,0,0,0,7.194,0H23a1,1,0,0,0,0-2Zm-6.333,2.75A1.75,1.75,0,1,1,18.417,12,1.752,1.752,0,0,1,16.667,13.75Z" /><path d="M23,19.25H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2ZM7.333,22a1.75,1.75,0,1,1,1.75-1.75A1.753,1.753,0,0,1,7.333,22Z" /></svg>
  </button>
  <img
      src={imageAssets.settings}
      className="tkp-settings-icon"
      onClick={iconHandler}
    />
  <button type="button" class={`btn btnDefault ${check === 'settingIcon' ? "ActiveIcon" : ""}`} onClick={iconHandlerSettings}>
    <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" /><path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" /></svg>
  </button>
</div>
</div> */}



//getting Tkp list :
  // const getTkpList = async(pageNo)=>{
  //    try {
  //     let searchData = {
  //       listType: 1,
  //       searchWord:enterWord ,
  //       pageIndex: pageNo === 1 ? 1 :currentPage,
  //       numberPerPage: numberPerPage,
  //       logginUserId: allTKPS === "allTKPS" ? null : loggedinUser
  //     }

  //     const response = await partnerExecutiveServices.GetTurnKeyPartnerService(searchData);
  //     console.log('gettkpResponse::', response);
  //     if (response.status === "success" && response.data.gettkplist === true) {
  //       setApiLoader(false);
  //       setData(response.data.TKPs_list)
  //       { enterWord && setCurrentPage(1) }
  //       setCount(response.data.count)
  //     } else {
  //       setApiLoader(false);
  //       setData([])
  //       setCount(0)
  //     }

  //    } catch (error) {
  //     console.log(error);
  //    }
  // }