import React, { useState, useEffect } from 'react';
import { Rating } from 'react-simple-star-rating';
import './TkpCreateProfile.css';
import { AddTurnKeyPartnerService, GetPredefinedTkpDataService, UpdateTurneyPartnersService } from '../../../../../../Service/PartnerExecutiveServices';
import { toast } from 'react-toastify';
import { blockInvalidChar } from '../../../../../../Utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import ImageUploading from 'react-images-uploading';
import { YEARSOFEXPERIENCELIST } from '../../../../../../Features/Saga/YearsOfExperienceList';
import { getStatusListType } from '../../../../../../Features/Saga/getStatusList';
import { GETEDUCATIONALLIST } from '../../../../../../Features/Saga/getEducationalList';
import { GETPARTNERMANAGERSLIST } from '../../../../../../Features/Saga/getpartnerManagersList';
import { DatePicker, TimePicker } from 'antd';
import { dateFormatter } from '../../../../../../Utilities/helpers';
import moment from 'moment';
import { educationslList, yearsOfExperienceList, statusListApi } from '../../../../../../Service/CommonDropdownApiCalls/commonDropdownApiCalls';


function TkpCreateProfile({ type, userId, settype }) {
  const [image, setImage] = useState(["cardBlankAvatarImg"]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [aadharCard, setAadharCard] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState();
  const [educationalQualification, setEducationalQualification] = useState("");
  const [teamMembersStrength, setTeamMembersStrength] = useState(null);
  const [predefinedData, setPredefinedData] = useState([]);
  const [checkVar, setCheckVar] = useState(false);
  const [status, setStatus] = useState("Draft");
  const [remarks, setRemarks] = useState("");
  const [interviewDate, setInterviewDate] = useState(null);
  const [interviewTime, setInterviewTime] = useState(null);
  const [assignedTo, setAssignedTo] = useState(0);
  const [assignedBy, setAssignedBy] = useState(0);
  const [uId, setUId] = useState(null);
  const [objUuid, setObjUuid] = useState(null);
  const [objTypeName, setObjTypeName] = useState(null);
  const [imageName, setImageName] = useState("");
  const [expirenceList, setExpirenceList] = useState([]);
  const [educationaliList, setEducationaliList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const maxnumber = 60;

  console.log("date::", interviewDate);
  console.log("time::", interviewTime)
  console.log("assigned to:", assignedTo);
  console.log("assignedBy:", assignedBy);
  console.log('team:',teamMembersStrength);

  // getting data from redux store: 
  const dispatch = useDispatch();
  const partnerManagersList = useSelector((state) => state.getPartnerManagerListSLice.partnerManagers);
  console.log("partnerManagersList:", partnerManagersList);  
  
  console.log("status:::", statusList);
  // userId : 
  window.localStorage.getItem('user_Id');
  // console.log(window.localStorage.getItem('user_Id'));

 /*   function for image uploader: */
  const ImageChanger = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setImage(imageList);
  }
  console.log("image:", image);

  console.log("null:", typeof interviewDate);

/*    function for submit handler: */
  const submitHandler = async (e) => {
    e.preventDefault();

    setCheckVar(true)
    if (valiadteForm()) {
      console.log("submit cheking:", type);
      if (type === "edit") {
        // console.log("added succefully updated data");

        const updateTurnkeyPartnerData = new FormData();
        updateTurnkeyPartnerData.append("userId", uId);
        updateTurnkeyPartnerData.append("firstName", firstName);
        updateTurnkeyPartnerData.append("lastName", lastName);
        updateTurnkeyPartnerData.append("mobile", mobile);
        updateTurnkeyPartnerData.append("aadhar", aadharCard);
        updateTurnkeyPartnerData.append("yearsOfExperience", yearsOfExperience);
        updateTurnkeyPartnerData.append("educationalQualification", educationalQualification);
        updateTurnkeyPartnerData.append("teamMembersStrength", teamMembersStrength);
        updateTurnkeyPartnerData.append("status", status);
        updateTurnkeyPartnerData.append("remarks", remarks);
        updateTurnkeyPartnerData.append("Image", image[0].file ? image[0].file : image[0].data_url);
        updateTurnkeyPartnerData.append("objectUuid", objUuid);
        updateTurnkeyPartnerData.append("objTypeName", objTypeName);
        updateTurnkeyPartnerData.append("imageName", imageName);
        updateTurnkeyPartnerData.append("interviewDate", interviewDate ? moment(interviewDate).format("YYYY-MM-DD") : null);
        updateTurnkeyPartnerData.append("interviewTime", interviewTime);
        updateTurnkeyPartnerData.append("assignedTo", assignedTo);
        updateTurnkeyPartnerData.append("assignedBy", assignedBy);
        console.log("updatedDate:::", updateTurnkeyPartnerData.get("interviewDate"));


        const response = await UpdateTurneyPartnersService(updateTurnkeyPartnerData);
        // console.log('updatedData Res :::', response);
        if (response.status === 'success' && response.data.status === 200) {
          toast.success(response.data.message);
          settype("");
        }
        else {
          toast.error(response.data.message)
        }

      } else {

        let addTurnKeyPartnerData = new FormData();
        addTurnKeyPartnerData.append("firstName", firstName)
        addTurnKeyPartnerData.append("lastName", lastName)
        addTurnKeyPartnerData.append("mobile", mobile)
        addTurnKeyPartnerData.append("aadhar", aadharCard)
        addTurnKeyPartnerData.append("yearsOfExperience", yearsOfExperience)
        addTurnKeyPartnerData.append("educationalQualification", educationalQualification)
        addTurnKeyPartnerData.append("teamMembersStrength", teamMembersStrength)
        addTurnKeyPartnerData.append("status", status)
        addTurnKeyPartnerData.append("remarks", remarks)
        addTurnKeyPartnerData.append("interviewScheduleDate", interviewDate ? moment(interviewDate).format("YYYY-MM-DD") : null)
        addTurnKeyPartnerData.append("interviewScheduleTime", interviewTime)
        addTurnKeyPartnerData.append("assignedTo", assignedTo)
        addTurnKeyPartnerData.append("assignedBy", assignedBy)
        addTurnKeyPartnerData.append("Image", image[0].file)

        console.log("formData ::", typeof moment(interviewDate).format("YYYY-MM-DD"), typeof addTurnKeyPartnerData.get("interviewScheduleDate"));

        const response = await AddTurnKeyPartnerService(addTurnKeyPartnerData);
        console.log("addtkpResponse:::", response);
        if (response.status === "success" && response.data.add_turnkeypartners === true) {
          toast.success(response.data.message);
          settype("");
        } else {
          toast.error(response.data.message)
        }
      }
    } else {
      toast.error("Please fill the required fields")
    }
  }

/*   Function for Get Previous Turnkey partners data  : */
  const GetPreviousTurnkeyPartnersData = async () => {
    if (type === "edit") {
      // console.log('userID :', userId);

      let userid = {
        userid: userId
      }

      const response = await GetPredefinedTkpDataService(userid);
      console.log('getPredefinedTkpData::', response);
      if (response.status === "success" && response.data.status === 200) {
        setPredefinedData(response.data.TkpUser);
        setFirstName(response.data.TkpUser.FirstName);
        setLastName(response.data.TkpUser.LastName);
        setMobile(response.data.TkpUser.mobile);
        setAadharCard(response.data.TkpUser.Aadhar);
        setYearsOfExperience(response.data.TkpUser.Years_of_Experience)
        setEducationalQualification(response.data.TkpUser.Education)
        setTeamMembersStrength(response.data.TkpUser.Team_Strength)
        setStatus(response.data.TkpUser.Status)
        setRemarks(response.data.TkpUser.Remark)
        setImage([{ data_url: response.data.TkpUser.ImageUrl }])
        setUId(response.data.TkpUser.UserId)
        setObjUuid(response.data.TkpUser.UserUuid)
        setObjTypeName(response.data.TkpUser.ObjectType)
        setImageName(response.data.TkpUser.ImageName)
        setInterviewDate(response.data.TkpUser.Interview_Date ? moment(response.data.TkpUser.Interview_Date).format("YYYY-MM-DD") : null)
        setInterviewTime(response.data.TkpUser.Interview_Time)
        setAssignedTo(response.data.TkpUser.Assigned_To)
        // setAssignedBy(response.data.TkpUser.Assigned_By)
      }
    }
    else {

    }
  }
  console.log("updated date :", interviewDate);

 /*  UseEffect: */
  useEffect(async () => {
    GetPreviousTurnkeyPartnersData();
    dispatch(GETPARTNERMANAGERSLIST())
    setAssignedBy(window.localStorage.getItem('user_Id'))

 /*     years of experience list : */
    const response = await yearsOfExperienceList();
    console.log("yearsOfExperienceList:", response);
    setExpirenceList(response.data)

    /*    educational list : */
    const educationaliListresponse = await educationslList();
    console.log("educationaliListresponse:", educationaliListresponse);
    if (educationaliListresponse?.data.isGetEducationList === true) {
      setEducationaliList(educationaliListresponse.data.educationList)
    }

    /*  status list :  */
    const statusListresponse = await statusListApi({ type: "TKP" });
    console.log("statusListresponse", statusListresponse);
    setStatusList(statusListresponse.data)

  }, [])

  // console.log("setExpirenceList:", expirenceList);
  console.log("setEducationaliList:", educationaliList);


  /*    date Handler : */
  const dateHandler = (date, dateString) => {
    console.log(date, "interviewDate:", dateString);
    setInterviewDate(dateString)
  };

  /*  time Handler : */
  const TimeHandler = (time, timeString) => {
    console.log(time, "interviewDate:", timeString);
    setInterviewTime(timeString)
  };



  /*  Form Validations : */
  const valiadteForm = () => {
    var IsValid = true;

    if (firstName === "") {
      IsValid = false;
    }
    if (aadharCard.length !== 12) {
      IsValid = false
    }
    if (mobile.length !== 10) {
      IsValid = false
    }
    // if(!numberpattern.test(mobile)){
    //   IsValid = false

    // }
    if (!yearsOfExperience) {
      IsValid = false
    }
    if (!educationalQualification) {
      IsValid = false
    }

    return IsValid;

  }

  console.log("status:", status);
  return (
    <div className="">
      <form>
        <div className="form-layout">
          <div className="row">
            <div className="col-md-3 pb-3">
              <div className="whCard">
                <div className="cardHeader">
                  <span>TurnKey partner Picture</span>
                </div>
                <div className="profileCardBody">
                  <div className="imgHolder">
                    <ImageUploading
                      value={image}
                      onChange={ImageChanger}
                      maxNumber={maxnumber}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        dragProps
                      }) => (
                        <div>
                          {imageList && imageList.map((image, index) => (
                            <div className="imagePicker" key={index}>
                              <img src={image.data_url ? image.data_url : "cardBlankAvatarImg"} alt="" className="cardBlankAvatarImg" />
                            </div>
                          ))
                          }
                          <label htmlFor="input" className="uploadLabelImg">
                            <span onClick={onImageUpload} {...dragProps} className="img-name">Upload new image</span>
                          </label>
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
              </div>
              <div className="Interview-layout">
                <div className="whCard">
                  <div className="cardHeader">
                    <span>Schedule Interview</span>
                  </div>
                  <div className="interview-date">
                    <label>Select Date</label><br />
                    {/* <input type="date" class="form-control" placeholder="Select Date"
                      value={interviewDate}
                      onChange={(e) => setInterviewDate(e.target.value)}
                    /> */}
                    <div className='date-picker'>
                    <DatePicker onChange={(e)=>setInterviewDate(e !== null ? e.format("YYYY-MM-DD") : null)} format={"YYYY-MM-DD"} 
                    value={interviewDate ? moment(interviewDate, "YYYY-MM-DD") : ""} 
                    disabled={status === 'Processing'}
                    />
                    </div>
                  </div>
                  <div className="interview-time">
                    <label>Select Time</label><br />
                    {/* <input type="time" class="form-control" placeholder="Select Time"
                      value={interviewTime}
                      onChange={(e) => setInterviewTime(e.target.value)}
                    /> */}
                    <TimePicker minuteStep={15} onChange={(e)=>setInterviewTime(e !== null ? e.format("h:mm A") : null )} format={"h:mm A"}
                     value={interviewTime && moment(interviewTime, "h:mm A")} 
                     disabled={status === 'Processing'}

                     />
                  </div>
                  <div className="associated-to">
                    <label>Assigned To</label><br />
                    <select className="form-select" value={assignedTo} onChange={(e) => { 
                      setAssignedTo(e.target.value) 
                    }}
                    disabled={status === 'Processing'}
                    >
                      <option hidden>Choose</option>
                      {/* <option>Santhosh</option>
                          <option>Ritu</option>
                          <option>Sripad</option> */}
                     {partnerManagersList && partnerManagersList.map((item,index)=>(
                             <option key={item._userid} value={item._userid}>{item._firstname + " " + item. _lastname}</option>
                          ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="whCard">
                <div className="cardHeader">
                  <span>TurnKey Partner Details</span>
                  <div className="d-flex">
                    <select value={status}
                      className={`selectStatus ${status === "Draft" && "Draft" ||
                        status === "Processing" && "Processing" ||
                        status === "Pending" && "Pending" ||
                        status === "Rejected" && "Rejected" ||
                        status === "Approved" && "Approved"
                        } `}
                      id="myStatus"
                      disabled
                      onChange={(e) => { setStatus(e.target.value) }}>
                      {statusList && statusList.map((item, id) => (
                        <option key={id} value={item.status} hidden >{item.status}</option>
                      ))}
                      {status}
                    </select>
                    <button type="submit" onClick={submitHandler} className="btn saveBtn">Save</button>
                  </div>
                </div>
                <div className="cardBody">
                  <div class="row">
                    <div class="col">
                      <label>First Name *</label>
                      <input
                        type="text"
                        value={firstName}
                        className="form-control"
                        onChange={(e) => { setFirstName(e.target.value) }}
                        placeholder="Enter First Name"
                      />
                    </div>
                    <div class="col">
                      <label>Last Name</label>
                      <input
                        type="text"
                        value={lastName}
                        className="form-control"
                        onChange={(e) => { setLastName(e.target.value) }}
                        placeholder="Enter Last Name"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label>Mobile *</label>
                      <input
                        type="number"
                        value={mobile}
                        className="form-control"
                        placeholder="Enter Mobile Number"
                        onChange={(e) => setMobile(e.target.value.slice(0, 10))}
                        onKeyDown={blockInvalidChar}
                      />
                    </div>
                    <div className="col">
                      <label>Aadhar Card *</label>
                      <input
                        type="number"
                        value={aadharCard}
                        className="form-control"
                        onChange={(e) => { setAadharCard(e.target.value.slice(0, 12)) }}
                        onKeyDown={blockInvalidChar}
                        placeholder="Enter Aadhar Card"

                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col">
                      <label>Educational Qualification *</label>
                      <select value={educationalQualification}
                        onChange={(e) => { setEducationalQualification(e.target.value) }}
                        className="form-select"
                        placeholder="">
                        <option hidden>Select Educational Qualification</option>
                        {educationaliList && educationaliList.map((item, index) => (
                          <option key={item.qualification_id} value={item.qualification_name}>{item.qualification_name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col">
                      <label>Years Of Experience *</label>
                      <select value={yearsOfExperience}
                        onChange={(e) => { setYearsOfExperience(e.target.value) }}
                        className="form-select">
                        <option hidden>Select Years Of Experience</option>
                        {expirenceList?.map((item, index) => (
                          <option key={index} value={item.years_of_experience}>{item.years_of_experience}</option>
                        ))}

                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label>Team Members Strength </label>
                      <input
                        type="text"
                        value={teamMembersStrength}
                        className="form-control"
                        onChange={(e) => { setTeamMembersStrength(e.target.value) }}
                        placeholder="Enter Team Members Strength"
                      />
                    </div>
                    <div className="col"></div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label>Remarks</label>
                      <textarea
                        type="text"
                        value={remarks}
                        rows="3"
                        className=""
                        onChange={(e) => { setRemarks(e.target.value) }}
                        placeholder="Enter Text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default TkpCreateProfile

{/* <div className="row">
                    <div className="col">
                      <label>Rating-Based on *</label>
                      <div className="row rating">
                        <div className="col">
                          <div className="d-flex">
                            <span className="text">Politeness (Language & Communication)</span>
                            <Rating allowHalfIcon size='25px' onClick={(rate) => { setPolitenessRating(rate) }} ratingValue={politenessRating} />
                          </div>
                          <div className="d-flex">
                            <span className="text">Hospitality</span>
                            <Rating allowHalfIcon size='25px' onClick={(rate) => { setHospitalityRating(rate) }} ratingValue={hospitalityRating} />
                          </div>
                          <div className="d-flex">
                            <span className="text">Site Maintenance</span>
                            <Rating allowHalfIcon size='25px' onClick={(rate) => { setSiteMaintanenaceRating(rate) }} ratingValue={siteMaintanenaceRating} />
                          </div>
                          <div className="d-flex">
                            <span className="text">Willingness to Carry Wehouse</span>
                            <Rating allowHalfIcon size='25px' onClick={(rate) => { setWillingRating(rate) }} ratingValue={willingRating} />
                          </div>
                        </div>
                        <div className="col">
                          <div className='overallScore'>
                            <span>Overall Score</span>
                            <span className='rating'>{overAll}</span>
                            <span>out of start rating</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
{/* <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option> */}
{/* <option value="B.tech">B.tech</option>
                        <option value="B.com">B.com</option>
                        <option value="B.sc">B.sc</option>
                        <option value="B.Arch">B.Arch</option>
                        <option value="Others">Others</option> */}

{/* {checkVar && !adharCard.length && <small>Aadhar is required</small>} */ }
{/* <div class="col">
                      <label>Email *</label>
                      <input
                        type="email"
                        value={email}
                        className="form-control"
                        onChange={(e) => { setEmail(e.target.value) }}
                        placeholder="Enter Email Address"
                      />
                    </div> */}
{/* {checkVar && !lastName.length && <small>Name is required</small>} */ }

{/* <option>Santhosh</option>
                          <option>Ritu</option>
                          <option>Sripad</option> */}
{/* <option value="Processing" >Processing</option>
                      <option value="Pending" >Pending</option>
                      <option value="Rejected" >Rejected</option>
                      <option value="Approved" >Approved</option> */}
{/* <TimePicker onChange={TimeHandler} /> */ }
{/* <DatePicker onChange={dateHandler} /> */ }

    // dispatch(YEARSOFEXPERIENCELIST());
    // dispatch(getStatusListType({ type: "TKP" }));
    // dispatch(GETEDUCATIONALLIST())
  
  // printing the type and user id :
  // console.log(userId, type);

  //  console.log('predefinedData:',predefinedData);

  // const handleClick=(e)=>{
  //   setActive(e.target.value)
  //   console.log('active:',active);
  // }
    // const YerasOfExperienece = useSelector((state) => state.YearsOfExperience.experience)
  // console.log("YerasOfExperienece:", YerasOfExperienece);
  // const educationalList = useSelector((state) => state.getEducationalListSLice.educationalList)
  // console.log("educationalList:",educationalList);

          // console.log("updatedData:::", updateTurnkeyPartnerData.get("Image"));
        // console.log("updatedData:::", updateTurnkeyPartnerData.get("objectUuid"));
        // console.log("updatedData:::", updateTurnkeyPartnerData.get("objTypeName"));
  

      // calculating over all Rtaing:
  // const overAllRating = politenessRating + hospitalityRating + siteMaintanenaceRating + willingRating;
  // const overAll = overAllRating / 4;
  // console.log("overAllRating", overAll);

  // console.log("partnerManagersList:",partnerManagersList);
  // const statusList = useSelector((state) => state.getStatusListSlice.status)
