import { createSlice } from "@reduxjs/toolkit";

const TurnkeyPartnersListSlice = createSlice({
    name :"TurnkeyPartnersList",
    initialState :{
        loading:false,
        TurnkeyPartnersData:[],
        count:0,
        error:null
    },
    reducers :{
        TurnkeyPartnersLoader:(state,action)=>{
            // alert("ok")
               state.loading = true;
        },
        TurnkeyPartnersListSuccess :(state,action)=>{
            state.loading=false;
            state.TurnkeyPartnersData = action.payload.Tkps_list;
            state.count=action.payload.count;
        },
        TurnkeyPartnersListFailure :(state,action) =>{
            state.loading=false;
            state.error=action.payload;
        }
    }

})

export const {TurnkeyPartnersLoader,TurnkeyPartnersListSuccess,TurnkeyPartnersListFailure} = TurnkeyPartnersListSlice.actions

export default TurnkeyPartnersListSlice.reducer;