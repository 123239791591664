import { createSlice } from "@reduxjs/toolkit";

const getProjectTypeListSlice = createSlice({
    name:"dropDown",
    initialState :{
        projectTypeList:[],
        error:null
    },
    reducers: {
    getProjectTypeListSuccess : (state,action)=>{
        console.log("success",action.payload);
        state.projectTypeList = action.payload
    },
    getProjectTypeListFailure : (state,action)=>{
        state.error = action.payload
    }
}
})

export const {getProjectTypeListSuccess,getProjectTypeListFailure} = getProjectTypeListSlice.actions;

export default getProjectTypeListSlice.reducer;