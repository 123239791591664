import { createSlice } from "@reduxjs/toolkit";

const getEucationalList=createSlice({
    name:"educationalList",
    initialState:{
        educationalList :[],
        error:null
    },
    reducers :{
        getEducationaListSuccess :(state,action)=>{
            state.educationalList = action.payload
        },
        getEducationaListFailure :(state,action)=>{
            state.error = action.payload
        }
    }
})

export const {getEducationaListSuccess,getEducationaListFailure} = getEucationalList.actions;

export default getEucationalList.reducer;