import React, { useState, useEffect } from 'react';
import './IpList.css';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Searchbar from '../../../Searchbar';
import { GetInteriorPartnersServices, partnerExecutiveServices } from '../../../../../Service/PartnerExecutiveServices';
import { toast } from 'react-toastify';
import { imageAssets } from '../../../../../Assets/AssetsConstants/AssetsConstants';
import Loader from '../../../../../Components/Loader';
import SimpleBarReact from "simplebar-react";
import { dateFormatter, resetFilter, resetSelectedFilterKey, storeSelectedFilterKeys } from '../../../../../Utilities/helpers';
import Pagination from 'rc-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getInteriorPartnersLoader } from '../../../../../Features/Slices/interiorPartnersListSlice';
import { Spin } from 'antd';
import NoData from '../../../../../Components/NoData/NoData';
import locale from 'rc-pagination/es/locale/en_US';
import { pagination } from '../../../../../Utilities/Pagination/paginationUtilites';
import { GETPARTNERMANAGERSLIST } from '../../../../../Features/Saga/getpartnerManagersList';
import moment from 'moment';
import { Rating } from 'react-simple-star-rating'
import ContentNavbar from '../../../../../Components/contentNavbar';
import AdvancedFilter from '../../AdvancedFilter/advancedFilter';

function IpList({ typeHandler, userId, type, ipType, allIp }) {
  const [searchInput, setSearchInput] = useState([]);
  const [enterWord, setEnterWord] = useState("");
  const [toggleBtn, setToggleBtn] = useState(false);
  const [data, setData] = useState([]);
  const [apiLoader, setApiLoader] = useState(true);
  const [check, setCheck] = useState("list");
  const [currentPage, setCurrentPage] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(8);
  const [count, setCount] = useState(0);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [advancedFilterData, setAdvancedFilterData] = useState({
    status: [],
    assignedTo: [],
    fromScheduleDate: null,
    toScheduleDate: null,
    category: []
  })
  const [showAdvancedFilterModal, setShowAdvancedFilterModal] = useState(false);
  const [selectedFilterVal, setSelectedFilterVal] = useState([])
  const [isFilterDataCleared, setIsFilterDataCleared] = useState(false)

  // usedispatch:
  const dispatch = useDispatch();

  // getting data from redux:
  const { interiorPartnersData, loader } = useSelector((state) => state.InteriorPartnersList);
  console.log("result:", interiorPartnersData, count, loader);

  const iconHandler = () => {
    alert("im calling ")
  }

  // viewDetails function :
  const viewDetails = (id, status) => {
    if (status === "Approved") {
      typeHandler('approved')
      userId(id)
    } else if (status === "Rejected") {
      typeHandler('')
      userId(id)
    } else {
      typeHandler('edit')
      userId(id)
    }

  }

  // function for show list view:
  const showListView = () => {
    setToggleBtn(false)
    setCheck("list")
  }

  // function for show card view:
  const showCardView = () => {
    setToggleBtn(true)
    setCheck("card")
  }

  const iconHandlerFilter = async () => {
    setCheck('filter')
  }

  const iconHandlerSettings = async () => {
    setCheck('setting')
  }
  console.log("allIp:", allIp);

  const loggedInUser = window.localStorage.getItem('user_Id'); 


  useEffect(async () => {
    dispatch(GETPARTNERMANAGERSLIST())
  }, [currentPage, enterWord])


  useEffect(() => {
    // getInteriorsList()
    getInteriorsListBySearchFilter()
  }, [currentPage])

  useEffect(() => {
    // getInteriorsList(1)       
    getInteriorsListBySearchFilter(1)
  }, [enterWord, isFilterDataCleared])

  /* new get IP list function*/
  const getInteriorsListBySearchFilter = async (pageNo) => {
    try {
      if(pageNo) setCurrentPage(1)
      const payload = {
        listType: 1,
        searchWord: enterWord,
        fromScheduleDate: advancedFilterData.fromScheduleDate,
        toScheduleDate: advancedFilterData.toScheduleDate,
        status: advancedFilterData.status,
        assignedTo: advancedFilterData.assignedTo,
        category: advancedFilterData.category,
        pageIndex: pageNo === 1 ? 1 : currentPage,
        numberOfRecordsPerPage: numberPerPage,
        loggedinUserId: allIp === "allIp" ? null : [loggedInUser]
      }

      const response = await partnerExecutiveServices.getIPListService(payload);
      // console.log('getInteriorPartnersList :::', response);
      if (response && response.status === "success" && response.data.getIPListData) {
        setApiLoader(false);
        setData(response.data.interiorPartnerData);
        setCount(response.count)
        setIsFilterDataCleared(false)

        //  toast.success('users list rendered successfully')
      } else {
        setApiLoader(false);
        setData([])
        setCount(0)
        setIsFilterDataCleared(false)

        // toast.error('failed to fetch the users list')
      }
    }
    catch (err) {
      setApiLoader(false);
      // console.log("error : ",err);
    }
  }

  useEffect(() => {
    showSelectedFilterKeysOnTop()
  }, [advancedFilterData])

  /* storing object of array data into an array */
  let selectedFilterOption = []
  const val = Object.keys(advancedFilterData).map(val => selectedFilterOption.push(advancedFilterData[val]))
  console.log("selectedFilterOption-----", selectedFilterOption)

  /* displaying selected filter options*/
  const showSelectedFilterKeysOnTop = () => {
    const allSelectedFilterKeys = storeSelectedFilterKeys(selectedFilterOption)
    console.log("allSelectedFilterKeys--", allSelectedFilterKeys);
    setSelectedFilterVal(allSelectedFilterKeys)
  }

  /* handler for remove particular filter data */
  const removeSelectedFilterKey = (item) => {
    setIsFilterDataCleared(true)
    setSelectedFilterVal(selectedFilterVal.filter(val => val !== item))
    const updatedFilteredKeys = resetSelectedFilterKey(item, advancedFilterData, setAdvancedFilterData)

  }

  // function for closeInput:
  const closeInput = () => {
    setEnterWord("")
    document.getElementById("search").style.width = "45px";

  }

  // function for searchHandler:
  const searchHandler = (e) => {
    setEnterWord(e.target.value)
  }


  // function for pagination:
  const handleCurrentPage = (pageindex, perpage) => {
    console.log(pageindex, perpage);
    setCurrentPage(pageindex);
  }
  console.log("data::", data, currentPage);
 

  return (
    <div>
      {/* {data.length ? */}
      {/* {loader ? <Loader /> */}
      {apiLoader ? <Loader />
        :
        <div className="contentBody">
          <ContentNavbar
            listType={allIp === "allIp" ? "ALL IP" : "IP"}
            count={count}
            enterWord={enterWord}
            setEnterWord={searchHandler}
            closeInput={closeInput}
            check={check}
            setCheck={setCheck}
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
            showDateFilter={showDateFilter}
            setShowDateFilter={setShowDateFilter}
            showCardView={showCardView}
            showListView={showListView}
            showAdvancedFilterModal={showAdvancedFilterModal}
            setShowAdvancedFilterModal={setShowAdvancedFilterModal}
            content={
              <AdvancedFilter
                advancedFilterData={advancedFilterData}
                setAdvancedFilterData={setAdvancedFilterData}
                resetFilter={() => resetFilter
                  (setAdvancedFilterData, setShowAdvancedFilterModal,
                  setSelectedFilterVal, setCurrentPage, setIsFilterDataCleared)
                }
                onApplyFilterData={getInteriorsListBySearchFilter}
                setShowAdvancedFilterModal={setShowAdvancedFilterModal}
              />
            }
            selectedFilterData={selectedFilterVal}
            removeFilterData={removeSelectedFilterKey}
            resetFilter={() => resetFilter
              (setAdvancedFilterData, setShowAdvancedFilterModal,
              setSelectedFilterVal, setCurrentPage, setIsFilterDataCleared)
            }

          />
          <div>
            {toggleBtn ?
              (data.length === 0 || data === undefined) ? <NoData />
                :
                <SimpleBarReact style={{ maxHeight: 710 }}>
                  <div className="card-layout">
                    {/* {data.length ? */}
                    <div>
                      {/* <Spin spinning={loader}> */}
                      <Grid container spacing={1} >
                        {data.map((data, index) => {
                          return <Grid item xl={3}>
                            <Card className="card" onClick={() => { viewDetails(data.userId) }}>
                              <CardContent className="card-data">
                                <Grid>
                                  <div className="cardProfileAvatar">
                                    {/* <img src={data.profile} className="card-data-profile" alt="card-data-profile...." /> */}
                                    {/* <img src="" className="blankAvatarImg" />
                                  {<FaMedal size="20px" className="medal" />} */}
                                    <img src={data.imageUrl ? data.imageUrl : "cardBlankAvatarImg"} alt="" className="cardBlankAvatarImg" />
                                    {/* <img src="" alt="" className="competitor bronzeMedal"/> */}
                                  </div>
                                  <span className="cardProfileName">{data.firstName + " " + data.lastName}</span>
                                  <Rating size="17px" readonly={true} allowHalfIcon ratingValue={data.overAllRAting} />
                                  <span className="cardProfileExperience">{data.yearsOfExperience + " " + " of experinece"}</span>
                                  <div>
                                    {/* {item.status === "Approved" && */}
                                    <button className={`btn bronze ${data.categoryName === "Bronze" && "bronze" ||
                                      data.categoryName === "Silver" && "silver" ||
                                      data.categoryName === "Gold" && "gold" ||
                                      data.categoryName === "Diamond" && "diamond" ||
                                      data.categoryName === "Platinum" && "platinum"
                                      }`}>
                                      {data.categoryName ? data.categoryName : "Bronze"}
                                    </button>
                                    {/* } */}
                                  </div>
                                  <span className="cardProfileExperience">{data.aadharCard}</span>
                                  {/* <span className="cardProfileExperience">{data.educationQualification}</span> */}
                                  <span className="cardProfileExperience">{data.mobile}</span>

                                  {/* <p className="card-data-profile-medal">{data.category}</p> */}
                                  {/* <div className="listItem" data-head="Category">
                                  <div>
                                    <span class="bronze">Bronze</span>
                                  </div>
                                </div> */}
                                  {/* <span className="">
                                  <Rating size="25px" readonly={true} allowHalfIcon ratingValue={data.ratingOverall} />
                                </span> */}
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        })}
                      </Grid>
                      {/* </Spin> */}
                    </div>
                    {/* :
                    <div className="noDataFoundBox">
                      <img src={nodata} className="no-data-img" />
                      <span>No Data Found</span>
                    </div>} */}
                  </div>
                </SimpleBarReact>
              :
              <div class="whAppTable">
                {/* {data.length ? */}
                <div class="tableList">
                  <div className="tableHead">
                    <div className="tableRow">
                      {/* <div className="listItem"><input type="checkbox" /></div> */}
                      <div className="listItem userName">Name</div>
                      {/* <div className="listItem alignItemsC">Category</div> */}
                      {/* <div className="listItem">Address</div> */}
                      <div className="listItem mobile">Mobile</div>
                      <div className="listItem aadhaar">Aadhar</div>
                      <div className="listItem submitted">Scheduled Interview</div>
                      <div className="listItem submitted">{ipType === "partnerManager" ? "Evaluated By" : "Assigned To"}</div>
                      <div className="listItem status alignItemsC">Status</div>
                      {/* <div className="listItem" >Assigned To</div> */}
                    </div>
                  </div>
                  {(data.length === 0 || data === undefined) ? <NoData />
                    :
                    <SimpleBarReact style={{ maxHeight: 660 }}>
                      {/* <Spin spinning={loader}> */}
                      {data?.map((item, index) => {
                        return <div className="tableRow" onClick={() => { viewDetails(item.userId, item.status) }}>
                          {/* <div className="tkp-table-cb"><input type="checkbox" /></div> */}
                          <div className="listItem userName" data-head="Name">
                            <div class="d-flex">
                              {/* <img src={imageAssets.noData} className="pic" alt="... " /> */}
                              <img src={item.imageUrl ? item.imageUrl : "blankAvatarImg"} className="blankAvatarImg" />
                              <div class="personName">
                                <span>{item.firstName + " " + item.lastName}</span>
                                <div>
                                  {(item.status === "Approved" || item.status === "Rejected" || item.status === "Processing") &&
                                    <Rating size="20px" readonly={true} allowHalfIcon ratingValue={item.overAllRAting} />
                                  }
                              </div>
                              <div>
                              {(item.status === "Approved" || item.status === "Processing") && 
                                     <button className={`btn bronze ${item.categoryName === "Bronze" && "bronze" ||
                                     item.categoryName === "Silver" && "silver" ||
                                     item.categoryName === "Gold" && "gold" ||
                                     item.categoryName === "Diamond" && "diamond" ||
                                     item.categoryName === "Platinum" && "platinum"
                                     }`}>
                                     {item.categoryName ? item.categoryName : "Bronze" }
                                   </button>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="listItem alignItemsC" data-head="Category">
                          <div>
                            <span class="bronze">Bronze</span>
                          </div>
                        </div> */}
                          {/* <div className="listItem"> {item.address}</div> */}
                          <div className="listItem mobile" data-head="Mobile"> {item.mobile}</div>
                          <div className="listItem aadhaar" data-head="Aadhar"> {item.aadharCard}</div>
                          <div className="listItem submitted">{item.interviewDate === null || item.interviewTime === null ?  'Not Scheduled' : moment(item.interviewDate).format('YYYY MMM DD') + " " + moment(item.interviewTime, 'hh:mm a').format('hh:mm a')}</div>
                          <div className="listItem submitted" data-head="Aadhar">{
                            (ipType === 'partnerManager') ?
                              (item.status === "Approved" || item.status === "Rejected" || item.status === "Processing") ?
                                <span>{item.assignedToName}</span>
                                : <span>&#8212;</span> : <span>{item.assignedToName}</span>}</div>
                          <div className="listItem status alignItemsC" data-head="Status">
                            <div className={`btn rounded-pill   ${item.status === "Draft" && "Draft" ||
                              item.status === "Rejected" && "Rejected" ||
                              item.status === "Processing" && "Processing" ||
                              item.status === "Pending" && "Pending" ||
                              item.status === "Approved" && "Approved"
                              }`}
                              data-head="Status"> {item.status}</div>
                          </div>
                          {/* <div className="listItem">
                            <span className="nameInitial">JS</span>
                            {item.assignedBy}
                          </div> */}
                          {/* <div className="tkp-table-dots">
                            <img src={imageAssets.menuDots} className="dots-icon" alt />
                          </div> */}
                        </div>
                      })}
                      {/* </Spin> */}
                    </SimpleBarReact>
                  }
                </div>
                {/* : <div className="noDataFoundBox">
                      <img src={nodata} />
                      <span>No Data Found</span>
                    </div>} */}
              </div>
            }
            {/* <div className="data-result">
                {searchInput.length > 1 && searchInput.map((val, key) => {
                  return <a><p>{val.name}</p></a>
                })}
              </div> */}
          </div>
          <Pagination
            current={currentPage}
            pageSize={numberPerPage}
            onChange={handleCurrentPage}
            total={count}
            showSizeChanger
            hideOnSinglePage={true}
            className="pagination-data"
            locale={locale}
            itemRender={pagination.textItemRender}
          />
        </div>
      }
    </div>

  )
}
export default IpList;

 // :
        // <div>
        //   <div className="noDataFoundBox">
        //     {
        //       apiLoader ? (<Loader />)
        //         :
        //         <>
        //           <img src={imageAssets.noData} className="no-data-img" />
        //           <span>No Data Found</span>
        //         </>
        //     }
        //   </div>
        // </div>

         // prev get interior partner list Api :
  // const getInteriorsList = async (pageNo) => {
  //   try {

  //     let searchData = {
  //       listType: 1,
  //       searchWord: enterWord,
  //       pageIndex: pageNo === 1 ? 1 : currentPage,
  //       numberPerPage: numberPerPage,
  //       logginUserId: allIp === "allIp" ? null : loggedInUser
  //     }

  //     const response = await partnerExecutiveServices.GetInteriorPartnersServices(searchData);
  //     // console.log('getInteriorPartnersList :::', response);
  //     if (response.status === "success" && response.data.getInteriorDesignersList) {
  //       setApiLoader(false);
  //       setData(response.data.interiorDesigners);
  //       setCount(response.data.count)
  //       //  toast.success('users list rendered successfully')
  //     } else {
  //       setApiLoader(false);
  //       setData([])
  //       setCount(0)
  //       // toast.error('failed to fetch the users list')
  //     }
  //   }
  //   catch (err) {
  //     setApiLoader(false);
  //     // console.log("error : ",err);
  //   }
  // }