import React, { useState, useEffect, useRef } from 'react';
import { AddArchitechPartnersService, GetPredefinedArchitectPartnersDataService, UpdateArchitectPartnersService } from '../../../../../../Service/PartnerExecutiveServices';
// import './TkpCreateProfile.css';
import { toast } from 'react-toastify';
import { blockInvalidChar, blockInvalidSpclChar } from '../../../../../../Utilities/helpers';
import { YEARSOFEXPERIENCELIST } from '../../../../../../Features/Saga/YearsOfExperienceList';
import { useDispatch, useSelector } from 'react-redux';
import { getStatusListType } from '../../../../../../Features/Saga/getStatusList';
import ImageUploading from 'react-images-uploading';
import { GETEDUCATIONALLIST } from '../../../../../../Features/Saga/getEducationalList';
import { GETPARTNERMANAGERSLIST } from '../../../../../../Features/Saga/getpartnerManagersList';
import { DatePicker, TimePicker } from 'antd';
import { educationslList, statusListApi, yearsOfExperienceList } from '../../../../../../Service/CommonDropdownApiCalls/commonDropdownApiCalls';
import moment from 'moment';

function ApCreateProfile({ type, userId, toggleHandler  }) {
  const [images, setImages] = useState(["cardBlankAvatarImg"]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [adharCard, setAdharCard] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [educationalQualification, setEducationalQualification] = useState("");
  const [architechLicensing, setArchitechLicensing] = useState("");
  const [firmNumber, setFirmNumber] = useState("");
  const [teamMembersStrength, setTeamMembersStrength] = useState(null);
  const [checkVar, setCheckVar] = useState(false);
  const [previousData, setPreviousData] = useState([]);
  // const [status, setStatus] = useState("Active");
  const [status, setStatus] = useState("Draft");
  const [remarks, setRemarks] = useState("");
  const [uid, setUid] = useState(null);
  const [objUuid, setObjUuid] = useState(null);
  const [objTypeName, setObjTypeName] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [interviewDate, setInterviewDate] = useState(null);
  const [interviewTime, setInterviewTime] = useState(null);
  const [assignedTo, setAssignedTo] = useState(null);
  const [expirenceList, setExpirenceList] = useState([]);
  const [educationaliList, setEducationaliList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [assignedBy, setAssignedBy] = useState(null);
  const [category, setCategory] = useState(null);
  const maxnumber = 60;

  // console.log(type,userId);

  const dispatch = useDispatch();
  const YearsOfExperience = useSelector((state => state.YearsOfExperience.experience));
  // console.log("YearsOfExperience::", YearsOfExperience);
  const getStatusList = useSelector((state) => state.getStatusListSlice.status)
  console.log("getStatusList:", getStatusList);
  const educationalList = useSelector((state) => state.getEducationalListSLice.educationalList)
  // console.log("educationalList:",educationalList);
  const partnerManagersList = useSelector((state) => state.getPartnerManagerListSLice.partnerManagers);
  console.log("partnerManagersList:", partnerManagersList);

  // Function for upload images:
  const ImageChanger = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setImages([...imageList]);
  }
  console.log("images:", images);
  
  // Function for form submittion:
  const submitHandler = async (e) => {
    e.preventDefault();

    setCheckVar(true)
    if (valiadteForm()) {
      if (type === 'edit') {

        let UpdatedArchitechPartnersData = new FormData();
        UpdatedArchitechPartnersData.append("userId", uid)
        UpdatedArchitechPartnersData.append("firstName", firstName)
        UpdatedArchitechPartnersData.append("lastName", lastName)
        UpdatedArchitechPartnersData.append("mobile", mobile)
        UpdatedArchitechPartnersData.append("aadhar", adharCard)
        UpdatedArchitechPartnersData.append("yearsOfExperience", yearsOfExperience)
        UpdatedArchitechPartnersData.append("educationalQualification", educationalQualification)
        UpdatedArchitechPartnersData.append("teamMembersStrength", teamMembersStrength)
        UpdatedArchitechPartnersData.append("status", status)
        UpdatedArchitechPartnersData.append("remarks", remarks)
        UpdatedArchitechPartnersData.append("objTypeName", objTypeName)
        UpdatedArchitechPartnersData.append("objectUuid", objUuid)
        UpdatedArchitechPartnersData.append("imageName", imageName)
        UpdatedArchitechPartnersData.append("Image", images[0].file ? images[0].file : images[0].data_url)
        UpdatedArchitechPartnersData.append("interviewDate", interviewDate ? moment(interviewDate).format("YYYY-MM-DD") : null)
        UpdatedArchitechPartnersData.append("interviewTime", interviewTime)
        UpdatedArchitechPartnersData.append("assignedTo", assignedTo)
        UpdatedArchitechPartnersData.append("assignedBy", assignedBy)
        UpdatedArchitechPartnersData.append("category", category)
        UpdatedArchitechPartnersData.append("architechLicensing", architechLicensing)
        UpdatedArchitechPartnersData.append("firmNumber", firmNumber)


        // console.log('UpdatedArchitechPartnersData', UpdatedArchitechPartnersData.get("objTypeName"));
        // console.log('UpdatedArchitechPartnersData', UpdatedArchitechPartnersData.get("imageName"));
        // console.log('UpdatedArchitechPartnersData', UpdatedArchitechPartnersData.get("Image"));
        console.log('UpdatedArchitechPartnersData', UpdatedArchitechPartnersData.get("interviewDate"));



        const response = await UpdateArchitectPartnersService(UpdatedArchitechPartnersData);
        //  console.log('UpdatedArchitechPartnersData:',response);
        if (response?.status === "success" && response.data.updateArchitectPartners === true) {
          toast.success(response.data.message);
          toggleHandler("");
        } else {
          toast.error(response.data.message)
        }
      }
      else {

        let ArchitechPartnersData = new FormData();
        ArchitechPartnersData.append("firstName", firstName)
        ArchitechPartnersData.append("lastName", lastName)
        ArchitechPartnersData.append("mobile", mobile)
        ArchitechPartnersData.append("aadhar", adharCard)
        ArchitechPartnersData.append("yearsOfExperience", yearsOfExperience)
        ArchitechPartnersData.append("educationalQualification", educationalQualification)
        ArchitechPartnersData.append("teamMembersStrength", teamMembersStrength)
        ArchitechPartnersData.append("architechLicensing", architechLicensing)
        ArchitechPartnersData.append("firmNumber", firmNumber)
        ArchitechPartnersData.append("status", status)
        ArchitechPartnersData.append("remarks", remarks)
        ArchitechPartnersData.append("Image", images[0].file)
        ArchitechPartnersData.append("interviewDate", interviewDate ? moment(interviewDate).format("YYYY-MM-DD") : null)
        ArchitechPartnersData.append("interviewTime", interviewTime)
        ArchitechPartnersData.append("assignedTo", assignedTo)
        ArchitechPartnersData.append("assignedBy", assignedBy)
      
        console.log('Add Architech Partner :::', ArchitechPartnersData.get('Image' , 'interviewDate' , 'interviewTime' ,'assignedTo'));
        const response = await AddArchitechPartnersService(ArchitechPartnersData);
        // console.log("addApResponse",response);
        if (response.status === "success" && response.data.add_architectpartners === true) {
          toast.success("users data is submitted successfully");
          toggleHandler("");
        } else {
          toast.error("Error in saving user data")
        }
      }
    } else {
      toast.error("Please fill the required fields")

    }
  }

  // Function for get previous architect partners data:
  const GetPreviousArchitectPartnersData = async () => {

    if (type === "edit") {
      console.log('userId of Ap', userId);

      let ArchitectPartnerId = {
        UserId: userId
      }

      const response = await GetPredefinedArchitectPartnersDataService(ArchitectPartnerId);
      console.log("GetPreviousArchitectPartnersData", response.data.ArchitectPartnerById);
      if (response?.status === "success" && response.data.status === 200) {
        setPreviousData(response.data.ArchitectPartnerById)
        setFirstName(response.data.ArchitectPartnerById.FirstName)
        setLastName(response.data.ArchitectPartnerById.LastName)
        setMobile(response.data.ArchitectPartnerById.Mobile)
        setAdharCard(response.data.ArchitectPartnerById.Aadhar)
        setYearsOfExperience(response.data.ArchitectPartnerById.Years_of_Experience)
        setEducationalQualification(response.data.ArchitectPartnerById.Educational_Qualification)
        setArchitechLicensing(response.data.ArchitectPartnerById.Architech_Licensing)
        setFirmNumber(response.data.ArchitectPartnerById.Firm_number)
        setTeamMembersStrength(response.data.ArchitectPartnerById.Team_Strength)
        setStatus(response.data.ArchitectPartnerById.Status)
        setRemarks(response.data.ArchitectPartnerById.Remark)
        setUid(response.data.ArchitectPartnerById.UserId)
        setImages([{data_url: response.data.ArchitectPartnerById.imageUrl}])
        setObjUuid(response.data.ArchitectPartnerById.userUuid)
        setImageName(response.data.ArchitectPartnerById.imageName)
        setObjTypeName(response.data.ArchitectPartnerById.objectType)
        setInterviewDate(response.data.ArchitectPartnerById.interviewDate ? moment(response.data.ArchitectPartnerById.interviewDate).format("YYYY-MM-DD") : null)
        setInterviewTime(response.data.ArchitectPartnerById.interviewTime)
        setAssignedTo(response.data.ArchitectPartnerById.assignedTo)
         // setPolitenessRating(response.data.ArchitectPartnerById.Rating_Politeness)
        // setHospitalityRating(response.data.ArchitectPartnerById.Rating_Hospitality)
        // setSiteMaintanenaceRating(response.data.ArchitectPartnerById.Rating_Site_Maintenance)
        // setWillingRating(response.data.ArchitectPartnerById.Rating_Willingness_To_Carry_WeHouse)
        // setOverallRating(response.data.ArchitectPartnerById.Overall_Rating)
      }
      else {
        //
      }
    }
  }



  // useEffect :
  useEffect(() => {
    GetPreviousArchitectPartnersData()
    dispatch(YEARSOFEXPERIENCELIST())
    dispatch(getStatusListType())
    dispatch(GETEDUCATIONALLIST())
    dispatch(GETPARTNERMANAGERSLIST())
  }, [])

  const userID =  window.localStorage.getItem('user_Id')
  console.log('assignedBy:',assignedBy);
  console.log('assignedTo:',assignedTo);

  useEffect(async () => {
    console.log("image:", images);


     setAssignedBy(userID)
    //  years of experience list :
    const response = await yearsOfExperienceList();
    console.log("yearsOfExperienceList:", response);
    setExpirenceList(response.data)

    //   educational list :
    const educationaliListresponse = await educationslList();
    console.log("educationaliListresponse:", educationaliListresponse);
    if (educationaliListresponse?.data.isGetEducationList === true) {
      setEducationaliList(educationaliListresponse.data.educationList)
    }

    // status list : 
    const statusListresponse = await statusListApi();
    console.log("statusListresponse", statusListresponse);
    setStatusList(statusListresponse.data)

  }, [])

  // date Handler :
  const dateHandler = (date, dateString) => {
    console.log(date, "interviewDate:", dateString);
    setInterviewDate(dateString)
  };

  // time Handler :
  const TimeHandler = (time, timeString) => {
    console.log(time, "interviewDate:", timeString);
    setInterviewTime(timeString)
  };

  //  Form Validations :
  const valiadteForm = () => {
    var IsValid = true;

    if (!firstName.length) {
      IsValid = false;
    }
    // if (!lastName.length) {
    //   IsValid = false;
    // }
    if (adharCard.length !== 12) {
      IsValid = false
    }
    if (!educationalQualification.length) {
      IsValid = false
    }
    if (mobile.length !== 10) {
      IsValid = false
    }
    if (!architechLicensing) {
      IsValid = false
    }
    if (!firmNumber) {
      IsValid = false
    }
    if (!yearsOfExperience) {
      IsValid = false
    }
    return IsValid;

  }

  return (
    <div className="whContentBlock">
      <div className="contentBody detail">
        <form>
          <div className="form-layout">
            <div className="row">
              <div className="col-md-3 pb-3">
                <div className="whCard">
                  <div className="cardHeader">
                    <span>Architect partner Picture</span>
                  </div>
                  <div className="profileCardBody">
                    <div className="imageHolder">
                      <ImageUploading
                        value={images}
                        onChange={ImageChanger}
                        maxNumber={maxnumber}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          dragProps
                        }) => (
                          <div>
                            {imageList && imageList.map((image, index) => {
                              console.log(image)
                              return <div className="imagePicker" key={index} >
                                <img src={image.data_url ? image.data_url : "cardBlankAvatarImg"} alt="" className="cardBlankAvatarImg" />
                              </div>
                            })}
                            <label htmlFor="input" className="uploadLabelImg">
                              <span onClick={onImageUpload} {...dragProps} className="img-name">Upload new image</span>
                            </label>
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </div>
                </div>
                <div className="Interview-layout">
                  <div className="whCard">
                    <div className="cardHeader">
                      <span>Schedule Interview</span>
                    </div>
                    <div className="interview-date">
                      <label>Select Date</label><br />
                      <DatePicker onChange={(e)=>setInterviewDate(e !== null ? e.format("YYYY-MM-DD") : null)} format={"YYYY-MM-DD"} 
                      value={interviewDate ? moment(interviewDate , 'YYYY-MM-DD'): ""}
                      disabled={status === 'Processing'}
                      />
                    </div>
                    <div className="interview-time">
                      <label>Select Time</label><br />
                      <TimePicker onChange={(e)=>setInterviewTime(e !== null  ? e.format('h:mm A') : null)} format={"h:mm A"} 
                      value={interviewTime ? moment(interviewTime, "h:mm A") : "" }  minuteStep={15}
                      disabled={status === 'Processing'}
                      />
                    </div>
                    <div className="associated-to">
                      <label>Assigned To</label><br />
                      <select className="form-select" value={assignedTo} onChange={(e)=>setAssignedTo(e.target.value)}
                      disabled={status === 'Processing'}
                                            >
                        <option hidden>Choose</option>
                        {partnerManagersList && partnerManagersList.map((item, index) => (
                          <option key={item._userid} value={item._userid}>{item._firstname + "" + item._lastname}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="whCard">
                  <div className="cardHeader">
                    <span>Architect Partner Details</span>
                    <div className="d-flex">
                      <select value={status}
                      disabled
                        className={`selectStatus ${status === "Draft" && "Draft" ||
                          status === "Processing" && "Processing" ||
                          status === "Pending" && "Pending" ||
                          status === "Rejected" && "Rejected" ||
                          status === "Approved" && "Approved"
                          } `}
                        onChange={(e) => { setStatus(e.target.value) }}>
                        <option value="Draft" >{status}</option>
                      </select>
                      <button type="submit" onClick={submitHandler} className="btn saveBtn">Save</button>
                    </div>
                  </div>
                  <div className="cardBody">
                    <div className="row">
                      <div className="col">
                        <label>First Name *</label>
                        <input
                          type="text"
                          value={firstName}
                          className="form-control"
                          onChange={(e) => { setFirstName(e.target.value) }}
                          placeholder="Enter First Name"
                        />
                      </div>
                      <div className="col">
                        <label>Last Name</label>
                        <input
                          type="text"
                          value={lastName}
                          className="form-control"
                          onChange={(e) => { setLastName(e.target.value) }}
                          placeholder="Enter Last Name"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <label>Mobile *</label>
                        <input
                          type="number"
                          value={mobile}
                          className="form-control"
                          onChange={(e) => { setMobile(e.target.value.slice(0, 10)) }}
                          placeholder="Enter Mobile Number"
                          onKeyDown={blockInvalidChar}
                        />
                      </div>
                      <div className="col">
                        <label>Adhar Card *</label>
                        <input
                          type="number"
                          value={adharCard}
                          className="form-control"
                          onChange={(e) => { setAdharCard(e.target.value.slice(0, 12)) }}
                          onKeyDown={blockInvalidChar}
                          placeholder="Enter Adhar Card"
                        />
                      </div>
                    </div>
                    <div className="form-dataRow">
                      <div className="row">
                        <div className="col">
                          <label>Educational Qualification *</label>
                          <select value={educationalQualification} onChange={(e) => { setEducationalQualification(e.target.value) }} className="form-select">
                            <option hidden>Select Educational Qualification </option>
                            {educationaliList && educationaliList.map((item, index) => (
                              <option key={item.qualification_id} value={item.qualification_name}>{item.qualification_name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="col">
                          <label>Years Of Experience *</label>
                          <select value={yearsOfExperience} onChange={(e) => { setYearsOfExperience(e.target.value) }} className="form-select">
                            <option hidden>Select Years Of Experience</option>
                            {expirenceList.map((item, index) => (
                              <option key={index} value={item.years_of_experience}>{item.years_of_experience}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-dataRow">
                      <div className="row">
                        <div className="col">
                          <label>Architect Licensing *</label>
                          <div className="form-field">
                            <input
                              type="text"
                              value={architechLicensing}
                              className="form-control"
                              onChange={(e) => { setArchitechLicensing(e.target.value) }}
                              onKeyDown={blockInvalidSpclChar}
                              placeholder="Enter Architecht Licensing"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <label>Firm Number *</label>
                          <div className="form-field">
                            <input
                              type="text"
                              value={firmNumber}
                              className="form-control"
                              onChange={(e) => { setFirmNumber(e.target.value) }}
                              onKeyDown={blockInvalidSpclChar}
                              placeholder="Enter Firm Number "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-dataRow">
                      <div className="row">
                        <div className="col">
                          <label>Team Members Strength</label>
                          <input
                            type="text"
                            value={teamMembersStrength}
                            className="form-control"
                            onChange={(e) => { setTeamMembersStrength(e.target.value) }}
                            placeholder="Enter Your Team Strength"
                            style={{ width: "49%" }}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-dataRow">
                      <div className="">
                        <label>Remarks</label>
                        <textarea
                          type="text"
                          value={remarks}
                          className=" "
                          rows="3"
                          onChange={(e) => { setRemarks(e.target.value) }}
                          placeholder="Enter Text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ApCreateProfile;

{/* <div className="row">
                      <div className="col mt-4">
                        <label>Rating-Based on *</label>
                        <div className="row rating">
                          <div className="col">
                            <div className="d-flex">
                              <p className="text" >Politeness (Language & Communication)</p>
                              <Rating allowHalfIcon size='25px' onClick={(rate) => { setPolitenessRating(rate) }} ratingValue={politenessRating} />
                            </div>
                            <div className="d-flex">
                              <p className="text" >Hospitality</p>
                              <Rating allowHalfIcon size='25px' onClick={(rate) => { setHospitalityRating(rate) }} ratingValue={hospitalityRating} />
                            </div>
                            <div className="d-flex">
                              <p className="text" >Site Maintenance</p>
                              <Rating allowHalfIcon size='25px' onClick={(rate) => { setSiteMaintanenaceRating(rate) }} ratingValue={siteMaintanenaceRating} />
                            </div>
                            <div className="d-flex">
                              <p className="text" >Willingness to Carry Wehouse</p>
                              <Rating allowHalfIcon size='25px' onClick={(rate) => { setWillingRating(rate) }} ratingValue={willingRating} />
                            </div>
                          </div>
                          <div className="col">
                            <div className='overallScore'>
                              <span>Overall Score</span>
                              <span className='rating'>{overAll}</span>
                              <span>out of start rating</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
{/* <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option> */}
{/* <option value="B.tech">B.tech</option>
                            <option value="B.com">B.com</option>
                            <option value="B.sc">B.sc</option>
                            <option value="B.Arch">B.Arch</option>
                            <option value="Others">Others</option> */}
{/* <div class="col">
                          <label>Email *</label>
                          <input
                            type="email"
                            value={email}
                            className="form-control"
                            onChange={(e) => { setEmail(e.target.value) }}
                            placeholder="Enter Email Address"
                          />
                      </div> */}
{/* {getStatusList && getStatusList.map((item, index) => (
                          <option key={index} value={item.status}>{item.status}</option>
                        ))} */}
// className={`selectStatus ${status === "Active" && "Active"
//   || status === "Inactive" && "Inactive"
//   || status === "Deleted" && "Deleted"
//   }`}
{/* <option>Santhosh</option>
                          <option>Ritu</option>
                          <option>Sripad</option> */}

{/* <input type="time" class="form-control" placeholder="Select Time"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                      /> */}
{/* <input type="date" class="form-control" placeholder="Select Date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}  
                      /> */}
{/* <input type="file" name="image-upload" id="input" accept="image/*" className="user-image" onChange={imageHandler} />
                    <label htmlFor="input" className="image-upload">
                      <span className="img-name">Upload capture image</span>
                    </label> */}
                // if (!politenessRating) {
    //   IsValid = false
    // }
    // if (!hospitalityRating) {
    //   IsValid = false
    // }
    // if (!siteMaintanenaceRating) {
    //   IsValid = false
    // }
    // if (!willingRating) {
    //   IsValid = false
    // }


        // let ArchitechPartnersData = {
        //   // image: image,
        //   FirstName: firstName,
        //   LastName: lastName,
        //   Mobile:mobile,
        //   Aadhar: adharCard,
        //   Years_of_Experience: yearsOfExperience,
        //   Educational_Qualification: educationalQualification,
        //   Architech_Licensing: architechLicensing,
        //   Firm_number: firmNumber,
        //   Team_Members_Strength: teamMembersStrength,
        //   // Rating_Politeness: politenessRating,
        //   // Rating_Hospitality: hospitalityRating,
        //   // Rating_Site_Maintenance: siteMaintanenaceRating,
        //   // Rating_Wiilingness_To_Carry_Wehouse: willingRating,
        //   // Rating_Overall: overAll,
        //   Status: status,
        //   Remarks: remarks
        // }
              // let UpdatedArchitechPartnersData = {
        //   // image: image,
        //   UserId: userId,
        //   FirstName: firstName,
        //   LastName: lastName,
        //   Mobile: mobile,
        //   Aadhar: adharCard,
        //   Years_of_Experience: yearsOfExperience,
        //   Educational_Qualification: educationalQualification,
        //   Architech_Licensing: architechLicensing,
        //   Firm_number: firmNumber,
        //   Team_Members_Strength: teamMembersStrength,
        //   // Rating_Politeness: politenessRating,
        //   // Rating_Hospitality: hospitalityRating,
        //   // Rating_Site_Maintenance: siteMaintanenaceRating,
        //   // Rating_Wiilingness_To_Carry_Wehouse: willingRating,
        //   // Overall_Rating: overAll,
        //   Status: status,
        //   Remarks: remarks
        // }
      //  console.log("objUUId::",objUuid);


  //  console.log(previousData);

  // calculating over all rating:
  // const overAllRating = politenessRating + hospitalityRating + siteMaintanenaceRating + willingRating;
  // const overAll = overAllRating / 4;
  // console.log("overAllRating",overAll);

    // const [politenessRating, setPolitenessRating] = useState(0);
  // const [hospitalityRating, setHospitalityRating] = useState(0);
  // const [siteMaintanenaceRating, setSiteMaintanenaceRating] = useState(0);
  // const [willingRating, setWillingRating] = useState(0);
  // const [overallRating, setOverallRating] = useState();
