import { createSlice } from "@reduxjs/toolkit";

const getSkillTypeList = createSlice({
    name:"skillType",
    initialState :{
        skillType:[],
        error:null
    },
    reducers: {
    getSkillTypeListSuccess : (state,action)=>{
        state.skillType = action.payload
    },
    getSkillTypeListFailure : (state,action)=>{
        state.error = action.payload
    }
}
})

export const {getSkillTypeListSuccess,getSkillTypeListFailure} = getSkillTypeList.actions;

export default getSkillTypeList.reducer;