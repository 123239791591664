import axios from 'axios';
import  instanceAll  from './CommonAxiosInstance';

export const postSigninService= async (body)=>
{
try {
  let response = await  instanceAll.post("/signin",body);

//   console.log("signinREs:::",response);
     if (response?.status===200 && response.data.status === 200)
        {
            return{
                status:"success",
                data:response.data,
            }
        }
      else {
          return{
              status:'failed',
              data :response.data
          }
      }
}catch(err){
    console.log("Error ::: ", err);
    if(err){
         return {
             status:"Error",
             error : err
         }
    }
}
}