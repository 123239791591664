import React from 'react'

const SaveAndCancel = (
    {
        handleSave,
        handleCancel,
        isTaskAdding
    }
) => {
    console.log("isTaskingAdding", isTaskAdding);
    return (
        <>
            <button type="button" className="btn cancelBtn" onClick={() => handleCancel("cancel")}>cancel</button>
            <button type="button" className="btn saveBtn" onClick={() => handleSave("save")}>Save</button>
            {isTaskAdding === "genericdetails" ? null :
                <button type="button" className="btn saveBtnclose" onClick={() => handleSave("save&close")}>Save&close</button>
            }
            {
                (isTaskAdding === true || isTaskAdding === undefined) ? null :
                    <button type="button" className="btn addtask" onClick={() => handleSave("addtask")}>Create Activity</button>
            }
        </>
    )
}

export default SaveAndCancel