import React from 'react';
import {Spin} from 'antd';

const Loader = () => {
    return (
        <>
            <Spin size="large" indicator={
                // <div>
                //     <i className="fa-solid fa-circle-notch fa-spin"></i>
                // </div>
                <div className="loaderBox">
                    <div className="loaderEllipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            }
            />

        </>
    )
}

export default Loader
