import { createSlice } from "@reduxjs/toolkit";

const getCategoriesList=createSlice({
    name:"categories",
    initialState:{
        categories:[],
        error:null
    },
    reducers :{
        getCategoriesListSuccess :(state,action)=>{
            state.categories = action.payload
        },
        getCategoriesListFailuer :(state,action)=>{
            state.error = action.payload
        }
    }
})

export const {getCategoriesListSuccess,getCategoriesListFailuer} = getCategoriesList.actions;

export default getCategoriesList.reducer;