import { takeEvery,call,put, takeLatest } from "@redux-saga/core/effects";
import { partnerExecutiveServices } from "../../Service/PartnerExecutiveServices";
import { getArchitectPartnersSuccess,getArchitectPartnersFailure,getArchitectPartnersLoader } from "../Slices/architectPartnersListSlice";

export function* getArchitectPartnersList(action){
    // console.log("get architect partnerr:",action)
    alert("inside the get architect partner ");
    const response = yield (partnerExecutiveServices.GetArchitechPartnersService(action.payload))
    // console.log("GetArchitechPartnersService::",response);
     if(response && response.status === "success" && response.data.getarchitectlist === true){
         
         yield put(getArchitectPartnersSuccess({Architect_Partners_List:response.data.Architechs_list , count : response.data.count}))
     }
     else{
         yield put(getArchitectPartnersFailure(response.error))
     }
}

function* getArchitectPartnersListWatcher(){
    yield takeLatest(getArchitectPartnersLoader().type,getArchitectPartnersList)
}

export default getArchitectPartnersListWatcher;