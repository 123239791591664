import React, { useState, useEffect } from 'react';
// import profile from '../../../../../../Assets/images/profile.png';
// import './TkpCreateProfile.css';
import { AddInteriorPartnersService, GetInteriorpartnersByIdDataService, UpdateInteriorPartnersService } from '../../../../../../Service/PartnerExecutiveServices';
import { toast } from 'react-toastify';
import { blockInvalidChar, blockInvalidSpclChar } from '../../../../../../Utilities/helpers';
import { YEARSOFEXPERIENCELIST } from '../../../../../../Features/Saga/YearsOfExperienceList';
import { useDispatch, useSelector } from 'react-redux';
import { getStatusListType } from '../../../../../../Features/Saga/getStatusList';
import ImageUploading from 'react-images-uploading';
import { GETEDUCATIONALLIST } from '../../../../../../Features/Saga/getEducationalList';
import { GETPARTNERMANAGERSLIST } from '../../../../../../Features/Saga/getpartnerManagersList';
import { DatePicker, TimePicker } from 'antd';
import { educationslList, statusListApi, yearsOfExperienceList } from '../../../../../../Service/CommonDropdownApiCalls/commonDropdownApiCalls';
import moment from 'moment';

function IpCreateProfile({ type, userId, typeHandler  }) {
    const [image, setImage] = useState(["cardBlankAvatarImg"]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [aadharCard, setAadharCard] = useState("");
    const [yearsOfExperience, setYearsOfExperience] = useState(0);
    const [educationalQualification, setEducationalQualification] = useState("");
    const [interiorLicensing, setInteriorLicensing] = useState("");
    const [firmNumber, setFirmNumber] = useState("");
    const [teamMembersStrength, setTeamMembersStrength] = useState(null);
    const [checkVar, setCheckVar] = useState(false);
    const [previousData, setPreviousData] = useState([]);
    // const [status, setStatus] = useState("Active");
    const [status, setStatus] = useState("Draft");
    const [remarks, setRemarks] = useState("");
    const [interviewDate, setInterviewDate] = useState(null);
    const [interviewTime, setInterviewTime] = useState(null);
    const [uId, setUId] = useState(null);
    const [objUuid, setObjUuid] = useState(null);
    const [objTypeName, setObjTypeName] = useState("");
    const [imageName, setImageName] = useState("");
    const maxNumber = 60;
    const [educationalList, setEducationalList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [experienceList, setExperienceList] = useState([]);
    const [assignedTo, setAssignedTo] = useState(null);
    const [assignedBy, setAssignedBy] = useState(null);

    const dispatch = useDispatch();
    const partnerManagersList = useSelector((state) => state.getPartnerManagerListSLice.partnerManagers);
    console.log("partnerManagersList:", partnerManagersList);

    // Function for upload images:
    const ImageChanger = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setImage([...imageList]);
    }
    console.log("images:", image);
    //  console.log("objUUId::",objUuid);



    const submitHandler = async (e) => {
        e.preventDefault();

        setCheckVar(true)
        if (valiadteForm()) {
            if (type === "edit") {
                const updateInteriorPartnersData = new FormData();
                updateInteriorPartnersData.append("userId", uId);
                updateInteriorPartnersData.append("firstName", firstName);
                updateInteriorPartnersData.append("lastName", lastName);
                updateInteriorPartnersData.append("mobile", mobile);
                updateInteriorPartnersData.append("aadhar", aadharCard);
                updateInteriorPartnersData.append("yearsOfExperience", yearsOfExperience);
                updateInteriorPartnersData.append("educationalQualification", educationalQualification);
                updateInteriorPartnersData.append("interiorLicensing", interiorLicensing);
                updateInteriorPartnersData.append("firmNumber", firmNumber);
                updateInteriorPartnersData.append("teamMembersStrength", teamMembersStrength);
                updateInteriorPartnersData.append("status", status);
                updateInteriorPartnersData.append("remarks", remarks);
                updateInteriorPartnersData.append("Image", image[0].file ? image[0].file : image[0].data_url);
                updateInteriorPartnersData.append("objectUuid", objUuid);
                updateInteriorPartnersData.append("objTypeName", objTypeName);
                updateInteriorPartnersData.append("imageName", imageName);
                updateInteriorPartnersData.append("interviewDate", interviewDate ? moment(interviewDate).format("YYYY-MM-DD") : null);
                updateInteriorPartnersData.append("interviewTime", interviewTime);
                updateInteriorPartnersData.append("assignedTo", assignedTo);
                updateInteriorPartnersData.append("assignedBy", assignedBy);


                // console.log("UpdatingInteriorPartnersData:", UpdatingInteriorPartnersData);

                const response = await UpdateInteriorPartnersService(updateInteriorPartnersData);
                // console.log("UpdatingInteriorPartnersData:", response);
                if (response.status === "success" && response.data.updateInteriorDesigner === true) {
                    toast.success(response.data.message)
                    typeHandler("");

                } else {
                    toast.error(response.data.message)
                }
            }
            else {
                const addInteriorPartnersData = new FormData();
                addInteriorPartnersData.append("firstName", firstName);
                addInteriorPartnersData.append("lastName", lastName);
                addInteriorPartnersData.append("mobile", mobile);
                addInteriorPartnersData.append("aadhar", aadharCard);
                addInteriorPartnersData.append("yearsOfExperience", yearsOfExperience);
                addInteriorPartnersData.append("educationalQualification", educationalQualification);
                addInteriorPartnersData.append("interiorLicensing", interiorLicensing);
                addInteriorPartnersData.append("firmNumber", firmNumber);
                addInteriorPartnersData.append("teamMembersStrength", teamMembersStrength);
                addInteriorPartnersData.append("status", status);
                addInteriorPartnersData.append("remarks", remarks);
                addInteriorPartnersData.append("interviewDate", interviewDate? moment(interviewDate).format("YYYY-MM-DD") : null);
                addInteriorPartnersData.append("interviewTime", interviewTime);
                addInteriorPartnersData.append("assignedTo", assignedTo);
                addInteriorPartnersData.append("assignedBy", assignedBy);
                addInteriorPartnersData.append("Image", image[0].file);


                // console.log("usersData:", InteriorParnersData)
                const response = await AddInteriorPartnersService(addInteriorPartnersData);
                // console.log('InteriorPArtners Response:::', response);
                if (response.status === "success" && response.data.addInteriorDesigner === true) {
                    toast.success('users data is submitted successfully');
                    typeHandler("");
                } else {
                    toast.error("Error in saving the users data")
                }

            }
        } else {
            toast.error("Please fill the required fields")
        }
    }

    // Get Previous Interior Partners Data:
    const GetinteriorPartnersDataById = async () => {
        if (type === "edit") {
            let InteriorPartnerId = {
                UserId: userId
            }

            const response = await GetInteriorpartnersByIdDataService(InteriorPartnerId);
            // console.log("GetinteriorPartnersDataById:", response);
            if (response.status === "success") {
                setPreviousData(response.data.InteriorDesignerById)
                setFirstName(response.data.InteriorDesignerById.FirstName);
                setLastName(response.data.InteriorDesignerById.LastName)
                setAadharCard(response.data.InteriorDesignerById.Aadhar)
                setYearsOfExperience(response.data.InteriorDesignerById.Years_of_Experience)
                setEducationalQualification(response.data.InteriorDesignerById.Educational_Qualification)
                setInteriorLicensing(response.data.InteriorDesignerById.Interior_Licensing)
                setFirmNumber(response.data.InteriorDesignerById.Firm_number)
                setTeamMembersStrength(response.data.InteriorDesignerById.Team_Strength)
                setStatus(response.data.InteriorDesignerById.Status)
                setRemarks(response.data.InteriorDesignerById.Remarks)
                setMobile(response.data.InteriorDesignerById.Mobile)
                setImage([{ data_url: response.data.InteriorDesignerById.imageUrl }])
                setUId(response.data.InteriorDesignerById.UserId)
                setObjUuid(response.data.InteriorDesignerById.userUuid)
                setObjTypeName(response.data.InteriorDesignerById.objectType)
                setImageName(response.data.InteriorDesignerById.imageName)
                setInterviewDate(response.data.InteriorDesignerById.interviewDate ? moment(response.data.InteriorDesignerById.interviewDate).format("YYYY-MM-DD") : null)
                setInterviewTime(response.data.InteriorDesignerById.interviewTime)
                setAssignedTo(response.data.InteriorDesignerById.assignedToId)
                    // setPolitenessRating(response.data.InteriorDesignerById.Rating_Politeness)
                // setHospitalityRating(response.data.InteriorDesignerById.Rating_Hospitality)
                // setSiteMaintanenaceRating(response.data.InteriorDesignerById.Rating_Site_Maintenance)
                // setWillingRating(response.data.InteriorDesignerById.Rating_Willingness_To_Carry_WeHouse)
                // setOverAllRating(response.data.InteriorDesignerById.Overall_Rating)
            }
        }
    }

    const loggedUserId = window.localStorage.getItem('user_Id');

    // UseEffect:
    useEffect(() => {
        GetinteriorPartnersDataById()
        dispatch(YEARSOFEXPERIENCELIST())
        dispatch(getStatusListType())
        dispatch(GETEDUCATIONALLIST())
        dispatch(GETPARTNERMANAGERSLIST())
        setAssignedBy(loggedUserId)
    }, [])

    console.log('assignedby:',assignedBy);
    console.log('assignedTo:',assignedTo);

    useEffect(async () => {

        //  years of experience list :
        const response = await yearsOfExperienceList();
        console.log("yearsOfExperienceList:", response);
        setExperienceList(response.data)

        //   educational list :
        const educationaliListresponse = await educationslList();
        console.log("educationaliListresponse:", educationaliListresponse);
        if (educationaliListresponse?.data.isGetEducationList === true) {
            setEducationalList(educationaliListresponse.data.educationList)
        }

        // status list : 
        const statusListresponse = await statusListApi();
        console.log("statusListresponse", statusListresponse);
        setStatusList(statusListresponse.data)

    }, [])

    // date Handler :
    const dateHandler = (date, dateString) => {
        console.log(date, "interviewDate:", dateString);
        setInterviewDate(dateString)
    };

    // time Handler :
    const TimeHandler = (time, timeString) => {
        console.log(time, "interviewDate:", timeString);
        setInterviewTime(timeString)
    };

    //  Form Validations :
    const valiadteForm = () => {
        var IsValid = true;

        if (!firstName.length) {
            IsValid = false;
        }
        // if (!lastName.length) {
        //   IsValid = false;
        // }
        if (aadharCard.length !== 12) {
            IsValid = false
        }
        if (mobile.length !== 10) {
            IsValid = false
        }
        if (!educationalQualification.length) {
            IsValid = false
        }
        if (!interiorLicensing) {
            IsValid = false
        }
        if (!firmNumber) {
            IsValid = false
        }
        if (!yearsOfExperience) {
            IsValid = false
        }
        return IsValid;

    }

    return (
        <div className="whContentBlock">
            <div className="contentBody detail">
                <form>
                    <div className="form-layout">
                        <div className="row">
                            <div className="col-md-3 pb-3">
                                <div className="whCard">
                                    <div className="cardHeader">
                                        <span>Interior partner Picture</span>
                                    </div>
                                    <div className="profileCardBody">
                                        <div className="imageHolder">
                                            <ImageUploading
                                                value={image}
                                                onChange={ImageChanger}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    dragProps,
                                                }) => (
                                                    <div>
                                                        {imageList && imageList.map((image, index) => {
                                                            console.log(image)
                                                            return <div className="imagePicker" key={index} >
                                                                <img src={image.data_url ? image.data_url : "cardBlankAvatarImg"} alt="" className="cardBlankAvatarImg" />
                                                            </div>
                                                        })}
                                                        <label htmlFor="input" className="uploadLabelImg">
                                                            <span onClick={onImageUpload} {...dragProps} className="img-name">Upload new image</span>
                                                        </label>
                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </div>
                                    </div>
                                </div>
                                <div className="Interview-layout">
                                    <div className="whCard">
                                        <div className="cardHeader">
                                            <span>Schedule Interview</span>
                                        </div>
                                        <div className="interview-date">
                                            <label>Select Date</label><br />
                                            <DatePicker onChange={(e)=>{setInterviewDate(e === null ? null : e.format("YYYY-MM-DD"))}} format={"YYYY-MM-DD"} value={interviewDate ? moment(interviewDate , 'YYYY-MM-DD') : ""}
                                            disabled={status === 'Processing'}
                                                                  />
                                        </div>
                                        <div className="interview-time">
                                            <label>Select Time</label><br />
                                            <TimePicker onChange={(e)=>{setInterviewTime(e === null  ? null :  e.format('hh:mm a'))}} minuteStep={15} format={'hh:mm a'} 
                                            value={interviewTime ? moment(interviewTime , "hh:mm a"): ""}
                                            disabled={status === 'Processing'}
                                            />
                                        </div>
                                        <div className="associated-to">
                                            <label>Assigned To</label><br />
                                            <select className="form-select" value={assignedTo} onChange={(e)=>{setAssignedTo(e.target.value)}}
                                            disabled={status === 'Processing'}
                                                                  >
                                                <option hidden>Choose</option>
                                                {partnerManagersList && partnerManagersList.map((item, index) => (
                                                    <option key={item._userid} value={item._userid}>{item._firstname + "" + item._lastname}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="whCard">
                                    <div className="cardHeader">
                                        <span>Interior Partner Details</span>
                                        <div className="d-flex">
                                            <select value={status}
                                                className={`selectStatus ${status === "Draft" && "Draft" ||
                                                    status === "Processing" && "Processing" ||
                                                    status === "Pending" && "Pending" ||
                                                    status === "Rejected" && "Rejected" ||
                                                    status === "Approved" && "Approved"
                                                    } `}
                                                onChange={(e) => { setStatus(e.target.value) }}>
                                                <option value="Draft" >{status}</option>
                                            </select>
                                            <button type="submit" onClick={submitHandler} className="btn saveBtn">Save</button>
                                        </div>
                                    </div>
                                    <div className="cardBody">
                                        <div>
                                            <div className="row">
                                                <div className="col">
                                                    <label>First Name *</label>
                                                    <div className="form-field">
                                                        <input
                                                            type="text"
                                                            value={firstName}
                                                            className="form-control"
                                                            onChange={(e) => { setFirstName(e.target.value) }}
                                                            placeholder="Enter First Name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <label>Last Name</label>
                                                    <div className="form-field">
                                                        <input
                                                            type="text"
                                                            value={lastName}
                                                            className="form-control"
                                                            onChange={(e) => { setLastName(e.target.value) }}
                                                            placeholder="Enter Last Name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <label>Mobile *</label>
                                                    <input
                                                        type="number"
                                                        value={mobile}
                                                        className="form-control"
                                                        onChange={(e) => { setMobile(e.target.value.slice(0, 10)) }}
                                                        placeholder="Enter Mobile Number"
                                                        onKeyDown={blockInvalidChar}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <label>Aadhar Card *</label>
                                                    <div className="form-field">
                                                        <input
                                                            type="number"
                                                            value={aadharCard}
                                                            className="form-control"
                                                            onChange={(e) => { setAadharCard(e.target.value.slice(0, 12)) }}
                                                            onKeyDown={blockInvalidChar}
                                                            placeholder="Enter Aadhar Number"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                        <div className="form-dataRow">
                                            <div className="row">
                                                <div className="col">
                                                    <label>Educational Qualification *</label>
                                                    <div className="form-field">
                                                        <select value={educationalQualification} onChange={(e) => { setEducationalQualification(e.target.value) }} className="form-select">
                                                            <option hidden>select Educational Qualification</option>
                                                            {educationalList && educationalList.map((item, index) => (
                                                                <option key={item.qualification_id} value={item.qualification_name}>{item.qualification_name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <label>Years Of Experience *</label>
                                                    <div className="form-field">
                                                        <select value={yearsOfExperience} onChange={(e) => { setYearsOfExperience(e.target.value) }} className="form-select">
                                                            <option hidden>select Years Of Experience</option>
                                                            {experienceList.map((item, index) => (
                                                                <option key={index} value={item.years_of_experience}>{item.years_of_experience}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-dataRow">
                                            <div className="row">
                                                <div className="col">
                                                    <label>Interior Licensing *</label>
                                                    <div className="form-field">
                                                        <input
                                                            type="text"
                                                            value={interiorLicensing}
                                                            className="form-control"
                                                            onChange={(e) => { setInteriorLicensing(e.target.value) }}
                                                            placeholder="Enter Interior Licensing Number"
                                                            onKeyDown={blockInvalidSpclChar}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <label>Firm Number *</label>
                                                    <div className="form-field">
                                                        <input
                                                            type="text"
                                                            value={firmNumber}
                                                            className="form-control"
                                                            onChange={(e) => { setFirmNumber(e.target.value) }}
                                                            placeholder="Enter Firm Number"
                                                            onKeyDown={blockInvalidSpclChar}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-dataRow">
                                            <div className="row">
                                                <div className="col">
                                                    <label>Team Members Strength</label>
                                                    <div className="form-field">
                                                        <input
                                                            type="text"
                                                            value={teamMembersStrength}
                                                            className="form-control"
                                                            onChange={(e) => { setTeamMembersStrength(e.target.value) }}
                                                            placeholder="Enter Team Members Strength"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-dataRow">
                                            <div className="row">
                                                <div className="col">
                                                    <label>Remarks</label>
                                                    <textarea
                                                        type="text"
                                                        value={remarks}
                                                        className=" "
                                                        rows="3"
                                                        onChange={(e) => { setRemarks(e.target.value) }}
                                                        placeholder="Enter Text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div >
        </div >
    )
}

export default IpCreateProfile;

// let UpdatingInteriorPartnersData = {
//     UserId: userId,
//     FirstName: firstName,
//     LastName: lastName,
//     Aadhar: aadharCard,
//     Years_of_Experience: yearsOfExperience,
//     Educational_Qualification: educationalQualification,
//     Team_Strength: teamMembersStrength,
//     // Rating_Politeness: politenessRating,
//     // Rating_Hospitality: hospitalityRating,
//     // Rating_Site_Maintenance: siteMaintanenaceRating,
//     // Rating_Willingness_To_Carry_WeHouse: willingRating,
//     // Overall_Rating: overAll,
//     Interior_Licensing: interiorLicensing,
//     Firm_number: firmNumber,
//     Status: status,
//     Remarks: remarks,
//     Mobile: mobile
// }

{/* <div className="row">
                                            <div className="col mt-4"  >
                                                <label>Rating-Based on *</label>
                                                <div className="row rating">
                                                    <div className="col">
                                                        <div className="d-flex">
                                                            <span className="text" >Politeness (Language & Communication)</span>
                                                            <Rating iconsCount={5} allowHalfIcon size='25px' onClick={(rate) => { setPolitenessRating(rate) }} ratingValue={politenessRating} />
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="text" >Hospitality</span>
                                                            <Rating allowHalfIcon size='25px' onClick={(rate) => { setHospitalityRating(rate) }} ratingValue={hospitalityRating} />
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="text">Site Maintenance</span>
                                                            <Rating allowHalfIcon size='25px' onClick={(rate) => { setSiteMaintanenaceRating(rate) }} ratingValue={siteMaintanenaceRating} />
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="text" >Willingness to Carry Wehouse</span>
                                                            <Rating allowHalfIcon size='25px' onClick={(rate) => { setWillingRating(rate) }} ratingValue={willingRating} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='overallScore'>
                                                            <span>Overall Score</span>
                                                            <span className='rating'>{overAll}</span>
                                                            <span>out of start rating</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div> */}

{/* <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option> */}

{/* <option value="B.tech">B.tech</option>
                                                            <option value="B.com">B.com</option>
                                                            <option value="B.sc">B.sc</option>
                                                            <option value="B.Arch">B.Arch</option>
                                                            <option value="Others">Others</option> */}

{/* <div class="col">
                                                    <label>Email *</label>
                                                    <input
                                                        type="email"
                                                        value={email}
                                                        className="form-control"
                                                        onChange={(e) => { setEmail(e.target.value) }}
                                                        placeholder="Enter Email Address"
                                                    />
                                                </div> */}
{/* {getStatus && getStatus.map((item, index) => (
                                                    <option key={index} value={item.status}>{item.status}</option>
                                                ))} */}
// className={`selectStatus ${status === "Active" && "Active"
//     || status === "Inactive" && "Inactive"
//     || status === "Deleted" && "Deleted"
//     }`}
{/* <option>Santhosh</option>
                                                    <option>Ritu</option>
                                                    <option>Sripad</option> */}
{/* <input type="time" class="form-control" placeholder="Select Time"
                                                value={time}
                                                onChange={(e) => setTime(e.target.value)}
                                            /> */}
{/* <input type="date" class="form-control" placeholder="Select Date"
                                                value={date}
                                                onChange={(e) => setDate(e.target.value)}
                                            /> */}

{/* <img src={image} alt="" id="img" className="cardBlankAvatarImg" /> */ }
{/* <input type="file" name="image-upload" id="input" accept="image/*" className="user-image" onChange={imageHandler} />
                                        <label htmlFor="input" className="image-upload">
                                            <span className="img-name">Upload capture image</span>
                                        </label> */}
                      // if (!politenessRating) {
        //     IsValid = false
        // }
        // if (!hospitalityRating) {
        //     IsValid = false
        // }
        // if (!siteMaintanenaceRating) {
        //     IsValid = false
        // }
        // if (!willingRating) {
        //     IsValid = false
        // }


    // calculating over all Rtaing:
    // const overAllRating = politenessRating + hospitalityRating + siteMaintanenaceRating + willingRating;
    // const overAll = overAllRating / 4;
    // console.log("overAllRating", overAll);

                    // let InteriorParnersData = {
                //     // image: image,
                //     _firstname: firstName,
                //     _lastname: lastName,
                //     _aadhar: adharCard,
                //     _years_of_experience: yearsOfExperience,
                //     _educational_qualification: educationalQualification,
                //     _interior_licensing: interiorLicensing,
                //     _firm_number: firmNumber,
                //     _team_members_strength: teamMembersStrength,
                //     // _rating_politeness: politenessRating,
                //     // _rating_hospitality: hospitalityRating,
                //     // _rating_site_maintenance: siteMaintanenaceRating,
                //     // _rating_willingness_to_carry_wehouse: willingRating,
                //     // _rating_overall: overAll,
                //     _status: status,
                //     _remarks: remarks,
                //     _mobile: mobile
                // }
        // const YearsOfExperience = useSelector((state) => state.YearsOfExperience.experience);
    // console.log("YearsOfExperience::",YearsOfExperience);
    // const getStatus = useSelector((state) => state.getStatusListSlice.status);
    // console.log("statusList:", getStatus);
    // const educationalList = useSelector((state) => state.getEducationalListSLice.educationalList)
    // console.log("educationalList:", educationalList);

      // const [politenessRating, setPolitenessRating] = useState(0);
    // const [hospitalityRating, setHospitalityRating] = useState(0);
    // const [siteMaintanenaceRating, setSiteMaintanenaceRating] = useState(0);
    // const [willingRating, setWillingRating] = useState(0);
    // const [overallRating, setOverAllRating] = useState(0);