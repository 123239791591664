import React,{useState} from 'react';
import './Users.css';
import { BrowserRouter as Router, Route, NavLink, Switch } from 'react-router-dom';
import CreateUser from './createuser/CreateUser';
import { IoMdAdd } from 'react-icons/io';
import {BiLeftArrowAlt} from 'react-icons/bi';
import UserLIst from './usersList/UserLIst';

function Users() {
  const [toggleBtn, setToggleBtn] = useState(false);
 
  const toggleHandler=()=>{
    setToggleBtn(!toggleBtn)
  }

  return (
    <div className="users-layout">
      <Router>
        <div className="users-head-label">
        <p className="users-font">Users</p>
          <div className="adduser-btn" style={{ marginLeft: toggleBtn ? "83%" : "83%" }} onClick={toggleHandler}>
            {toggleBtn ?   <p className="back-to-user-list-text">
              <NavLink to="/users/createUser">
              <BiLeftArrowAlt size="25px" className=""/>
                Back to User List
              </NavLink>
            </p> :   <p className="adduser-text">
              <NavLink to="/users/createUser">
                <IoMdAdd size="25px" className=""/>
                 Add New User
              </NavLink>
            </p>}
          
          </div>
        </div>
      </Router>
      {toggleBtn ? <CreateUser/> : <UserLIst/>  }
    </div>
  )
}

export default Users;