import { Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getActivityStates, updateAppointmentStateService } from '../../../../../../Service/MyLeadsService';
import LeadDetails from '../../../MyLeads/LeadDetails/LeadDetails';
import "./GenericMeetingDetailsModal.css"



const GenericMeetingDetailsModal = ({
    selectedMeeting,
    handleCancel,
    setModalType,
    modalType,
    handleSave,
    selectedState,
    setSelectedState
}
) => {
    console.log("meeting", selectedMeeting);

    const [options, setOptions] = useState([]);


    const getMeetingStates = async () => {
        try {
            const response = await getActivityStates({ activityTypeId: 2 });
            console.log('response', response);
            if (response.status === "success") {
                const options = response?.data?.map((value) => ({ label: value.activityStateName, value: value.activityStateId }));
                setOptions(options);

            }
            else {
                setOptions([]);
            }

        } catch (error) {
            console.log("error", error);
            setOptions([]);
        }
    }

    const handleSelect = (e) => {
        console.log('value', e)
        setSelectedState(e);

    }


    console.log("options", options, selectedState);


    useEffect(() => {
        getMeetingStates();
        setSelectedState(selectedMeeting?.activityState);
    }, [selectedMeeting])



    return (
        <div><div className='row' >
            <div className='leaddetails-wrapper'>
                <LeadDetails selectedUser={selectedMeeting} type="generic" />

                <div className='col-2'>
                    <button
                        className='btn whBtnPrimary'
                        onClick={() => setModalType("reschedule")}
                        // disabled={selectedMeeting?.activityState === "Rescheduled" ?true :false}
                    >
                        Reschedule
                    </button>
                </div>
                <div className='col-3'>
                    <Select
                        options={options}
                        value={selectedState}
                        style={{ width: "100%" }}
                        placeholder="select status"
                        onChange={handleSelect}
                    />
                </div>
            </div>
        </div>
            <div className='row'>
                <label>Sales Executive Comment</label>
                <div class="fW500">{selectedMeeting?.remarks?.replace(/(<([^>]+)>|&nbsp;)/ig, '\n')}</div>
            </div>

            <div className='row'>
                <label>Title</label>
                <div class="fW500">{selectedMeeting?.activityName}</div>
            </div>

            <div className='row'>
                <label>Description</label>
                <div class="fW500">{selectedMeeting?.activityDescription?.replace(/(<([^>]+)>|&nbsp;)/ig, '\n')}</div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <div className='row'>
                        <div className='col-6'>
                            <label> Date</label>
                            <div class="fW500">{moment(selectedMeeting?.appointmentDate).utc().format("D MMM, YYYY")}</div>
                        </div>
                        <div className='col-6'>
                            <label>Time</label>
                            <div class="fW500">{moment(selectedMeeting?.appointmentTime, "hh:mm a").format("hh:mm a")}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modalFooter'>
                <button type="button" className="btn cancelBtn" onClick={() => handleCancel()}>cancel</button>
                <button type="button" className="btn saveBtn" onClick={() => handleSave("detailsSave")}>Save</button>
                <button type="button" className="btn  saveBtnclose" onClick={() => handleSave("detailsSaveandClose")}>Save&close</button>

            </div>
        </div>
    )
}

export default GenericMeetingDetailsModal

