import React, { useState, useEffect } from 'react';
import { Rating } from 'react-simple-star-rating';
import './SwCreateProfile.css';
import { AddSkilledWorkerService, GetSkiledWorkersByIdDataService, partnerExecutiveServices, UpdateSkilledWorkrsService } from '../../../../../../Service/PartnerExecutiveServices';
import { toast } from 'react-toastify';
import { blockInvalidChar } from '../../../../../../Utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { YEARSOFEXPERIENCELIST } from '../../../../../../Features/Saga/YearsOfExperienceList';
import { getStatusListType } from '../../../../../../Features/Saga/getStatusList';
import { GETSKILLTYPELIST } from '../../../../../../Features/Saga/getSkillTypeList';
import ImageUploading from 'react-images-uploading';
import { GETPARTNERMANAGERSLIST } from '../../../../../../Features/Saga/getpartnerManagersList';
import { DatePicker, Select, TimePicker } from 'antd';
import { skilltypeListApi, statusListApi, yearsOfExperienceList } from '../../../../../../Service/CommonDropdownApiCalls/commonDropdownApiCalls';
import moment from 'moment';

function SwCreateProfile({ type, userId, toogleHandler }) {
  const [image, setImage] = useState(["cardBlankAvatarImg"]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [tkpPartner, setTkpPartner] = useState("");
  const [skillType, setSkillType] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState(0);
  const [pinCode, setPinCode] = useState("");
  // const [status, setStatus] = useState("Active");
  const [status, setStatus] = useState("Draft");
  const [checkVar, setCheckVar] = useState(false);
  const [previousData, setPreviousData] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [objUuid, setObjUuid] = useState(null);
  const [objTypeName, setobjTypeName] = useState("");
  const [imageName, setImageName] = useState("");
  const [profileImg, setProfileImg] = useState(null);
  const [skillstype, setSkillstype] = useState([]);
  const [experiencelist, setExperiencelist] = useState([]);
  const [assignedTo, setAssignedTo] = useState(null);
  const [assignedBy, setAssignedBy] = useState(null);
  const [interviewDate, setInterviewDate] = useState(null);
  const [interviewTime, setInterviewTime] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [tkpListDropdown, setTkpListDropdown] = useState([]);
  const maxnumber = 60;
  const {Option} = Select;
  const [size, setSize] = useState('middle');
  const [refferdBy, setRefferedBy] = useState('');
  const [tkpName, setTkpName] = useState('');
  const [UserId, setUserId] = useState(null)


  // console.log(type, userId);

  // get experience list from redux:
  const dispatch = useDispatch();
  const YearsOfExperience = useSelector((state) => state.YearsOfExperience.experience);
  // const statusList = useSelector((state) => state.getStatusListSlice.status)
  const skillTypeList = useSelector((state) => state.getSkillTypeSlice.skillType)
  //  console.log("YearsOfExperience::",YearsOfExperience);
  // console.log("statusList:", statusList);
  //  console.log("skillTypeList:",skillTypeList);
  const partnerManagersList = useSelector((state) => state.getPartnerManagerListSLice.partnerManagers);
  console.log("partnerManagersList:", partnerManagersList);

  // Function for upload images:
  const ImageChanger = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setImage([...imageList]);
  }
  console.log("images:", image);
  console.log("assignedTo:", assignedTo);


  // submit handler based on condiation :
  const submitHandler = async (e) => {
    e.preventDefault();
    // console.log('submitting type:', type);

    setCheckVar(true)
    if (valiadteForm()) {
      if (type === "edit") {
        const updateSkillWorkerdata = new FormData();
        updateSkillWorkerdata.append("userId",UserId );
        updateSkillWorkerdata.append("firstName", firstName);
        updateSkillWorkerdata.append("lastName", lastName);
        updateSkillWorkerdata.append("mobile", mobile);
        updateSkillWorkerdata.append("aadhar", aadhar);
        updateSkillWorkerdata.append("yearsOfExperience", yearsOfExperience);
        updateSkillWorkerdata.append("skillType", skillType);
        updateSkillWorkerdata.append("pincode", pinCode);
        updateSkillWorkerdata.append("status", status);
        updateSkillWorkerdata.append("remarks", remarks);
        updateSkillWorkerdata.append("Image", image[0].file ? image[0].file : image[0].data_url);
        updateSkillWorkerdata.append("objectUuid", objUuid);
        updateSkillWorkerdata.append("objTypeName", objTypeName);
        updateSkillWorkerdata.append("imageName", imageName);
        updateSkillWorkerdata.append("interviewDate", interviewDate ? moment(interviewDate).format("YYYY-MM-DD") : null);
        updateSkillWorkerdata.append("interviewTime", interviewTime);
        updateSkillWorkerdata.append("assignedTo", assignedTo);
        updateSkillWorkerdata.append("assignedBy", assignedBy);
        updateSkillWorkerdata.append("refferedBy", refferdBy);
        updateSkillWorkerdata.append("tkpName", tkpName);


        // console.log("UpadatedSkilled Workers data::", UpdatedSkilledWorkersData);

        const response = await UpdateSkilledWorkrsService(updateSkillWorkerdata)
        // console.log('UpdateSkilledWorkrsService', response);
        if (response.status === "success" && response.data.updateSkilledWorker === true) {
          toast.success(response.data.message)
          toogleHandler("")
        } else {
          toast.error(response.data.message)
        }

      } else {

        const addSkilledWorkerData = new FormData();
        addSkilledWorkerData.append("firstName", firstName);
        addSkilledWorkerData.append("lastName", lastName);
        addSkilledWorkerData.append("mobile", mobile);
        addSkilledWorkerData.append("aadhar", aadhar);
        addSkilledWorkerData.append("yearsOfExperience", yearsOfExperience);
        addSkilledWorkerData.append("skillType", skillType);
        addSkilledWorkerData.append("pincode", pinCode);
        addSkilledWorkerData.append("status", status);
        addSkilledWorkerData.append("remarks", remarks);
        addSkilledWorkerData.append("Image", image[0].file);
        addSkilledWorkerData.append("interviewDate", interviewDate ? moment(interviewDate).format("YYYY-MM-DD") : null);
        addSkilledWorkerData.append("interviewTime", interviewTime);
        addSkilledWorkerData.append("assignedTo", assignedTo);
        addSkilledWorkerData.append("assignedBy", assignedBy);
        addSkilledWorkerData.append("refferedBy", refferdBy);
        addSkilledWorkerData.append("tkpName", tkpName);

        // console.log("usersData:", SkilledWorkersData)
        const response = await AddSkilledWorkerService(addSkilledWorkerData);
        // console.log("addSkilledWorkers Res:::", response);
        if (response.status === "success" && response.data.addSkilledWorker === true) {
          toast.success('users data is submitted succesfully');
          toogleHandler("")
          
        }
        else {
          toast.error("Error in saving the users data")
        }
      }
    }
    else {
      toast.error("Please fill the required fields")
    }
  }

  console.log('previosdata: ', previousData);

  // Get Previous Values of Skilled Workers:
  const GetPreviousSkilledWorkers = async () => {
    if (type === "edit") {
      // console.log('SWuserID', userId);

      let SkilledWorkersId = {
        UserId: userId
      }

      const response = await GetSkiledWorkersByIdDataService(SkilledWorkersId);
      // console.log("GetSkiledWorkersByIdDataService::", response);
      if (response.data.status === 200) {
        setPreviousData(response.data.SkilledWorkersById)
        setUserId(response.data.SkilledWorkersById.UserId)
        setFirstName(response.data.SkilledWorkersById.FirstName);
        setLastName(response.data.SkilledWorkersById.LastName);
        setMobile(response.data.SkilledWorkersById.Mobile)
        setAadhar(response.data.SkilledWorkersById.Aadhar)
        setSkillType(response.data.SkilledWorkersById.Skill_Type)
        setYearsOfExperience(response.data.SkilledWorkersById.Years_of_Experience)
        setPinCode(response.data.SkilledWorkersById.Pincode)
        // setPolitenessRating(response.data.SkilledWorkersById.Rating_Politeness)
        // setHospitalityRating(response.data.SkilledWorkersById.Rating_Hospitality)
        // setSiteMaintanenaceRating(response.data.SkilledWorkersById.Rating_Site_Maintenance)
        // setWillingRating(response.data.SkilledWorkersById.Rating_Willingness_To_Carry_WeHouse)
        // setOverAllRating(response.data.SkilledWorkersById.Overall_Rating)
        setStatus(response.data.SkilledWorkersById.Status)
        setRemarks(response.data.SkilledWorkersById.Remark)
        setImage([{ data_url: response.data.SkilledWorkersById.imageUrl }])
        setObjUuid(response.data.SkilledWorkersById.userUuid);
        setobjTypeName(response.data.SkilledWorkersById.objectType)
        setImageName(response.data.SkilledWorkersById.imageName)
        setInterviewDate(response.data.SkilledWorkersById.interviewDate ? moment(response.data.SkilledWorkersById.interviewDate).format("YYYY-MM-DD") : null)
        setInterviewTime(response.data.SkilledWorkersById.interviewTime)
        // setAssignedBy(response.data.SkilledWorkersById.assignedBy)
        setAssignedTo(response.data.SkilledWorkersById.assignedToId)
        setRefferedBy(response.data.SkilledWorkersById.refferedBy)
        setTkpName(response.data.SkilledWorkersById.tkpId)

      }
    }
  }
  console.log("date and time", interviewDate, interviewTime);

  /* get skills type list  */
  const skillsTypeList = async () => {
    try {
      const skilltyperesponse = await skilltypeListApi();
      console.log("skilltyperesponseList:", skilltyperesponse);
      setSkillstype(skilltyperesponse.data)
    } catch (error) {
      console.log(error);
    }
  }

  //  years of experience list :
  const yearsOfExperieneceList = async () => {
    try {

      const response = await yearsOfExperienceList();
      console.log("yearsOfExperienceList:", response);
      setExperiencelist(response.data)
    } catch (error) {
      console.log(error);
    }
  }

  /*  status list :  */
  const getStatusList = async () => {
    try {
      const statusListresponse = await statusListApi({ type: "TKP" });
      console.log("statusListresponse", statusListresponse);
      setStatusList(statusListresponse.data)
    } catch (error) {
      console.log(error);
    }
  }

  // get Tkp Dropdown List :
  const getTkpDropdownList = async () => {
    try {
      const response = await partnerExecutiveServices.getTkpListDropdownService();
      console.log("getTkpDropdownList:", response);
      if (response && response.status === 'success' && response.data.isTkpDashboardList) {
        setTkpListDropdown(response.data.tkpDashboardList)
      }

    } catch (error) {
      console.log(error);
    }
  }
  console.log("tkpList Dropdown:", tkpListDropdown);

  /*    UseEffect  */
  useEffect(() => {
    GetPreviousSkilledWorkers()
    // dispatch(YEARSOFEXPERIENCELIST())
    // dispatch(getStatusListType())
    // dispatch(GETSKILLTYPELIST())
    dispatch(GETPARTNERMANAGERSLIST())
    setAssignedBy(window.localStorage.getItem('user_Id'));
    skillsTypeList();
    yearsOfExperieneceList();
    getStatusList();
    getTkpDropdownList()

  }, [])

  console.log("skillstype", skillstype);


  /*   date Handler : */
  const dateHandler = (date, dateString) => {
    console.log(date, "interviewDate:", dateString);
    setInterviewDate(dateString)
  };

  /* time Handler : */
  const TimeHandler = (time, timeString) => {
    console.log(time, "interviewDate:", timeString);
    setInterviewTime(timeString)
  };

  // select handler :
  const handleSizeChange = (e) =>{
    setSize(e.target.value)
  }
  console.log(size);

  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
    setTkpName(value)
  };

  /*   Form Validations : */
  const valiadteForm = () => {
    var IsValid = true;

    if (!firstName.length) {
      IsValid = false;
    }
    // if (!lastName.length) {
    //   IsValid = false;
    // }
    if (mobile.length !== 10) {
      IsValid = false
    }
    if (aadhar.length !== 12) {
      IsValid = false
    }
    if (!refferdBy) {
      IsValid = false
    }
   if(refferdBy ===  "Tkp partner"){
    if(!tkpName){
      IsValid = false
    }
   }
    if (!skillType) {
      IsValid = false
    }
    if (!yearsOfExperience) {
      IsValid = false
    }
    return IsValid;

  }
  return (
    <div className="whContentBlock">
      <div className="contentBody detail">
        <form>
          <div className="form-layout">
            <div className="row">
              <div className="col-md-3 pb-3">
                <div className="whCard">
                  <div className="cardHeader">
                    <span>Skilled Worker Picture</span>
                  </div>
                  <div className="profileCardBody">
                    <div className="imgHolder">
                      <ImageUploading
                        value={image}
                        onChange={ImageChanger}
                        maxNumber={maxnumber}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          dragProps
                        }) => (
                          <div>
                            {imageList && imageList.map((image, index) => {
                              console.log(image)
                              return <div className="imagePicker" key={index} >
                                <img src={image.data_url ? image.data_url : "cardBlankAvatarImg"} alt="" className="cardBlankAvatarImg" />
                              </div>
                            })}
                            <label htmlFor="input" className="uploadLabelImg">
                              <span onClick={onImageUpload} {...dragProps} className="img-name">Upload new image</span>
                            </label>
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </div>
                </div>
                <div className="Interview-layout">
                  <div className="whCard">
                    <div className="cardHeader">
                      <span>Schedule Interview</span>
                    </div>
                    <div className="interview-date">
                      <label>Select Date</label><br />
                      <DatePicker onChange={(e) => setInterviewDate(e !== null ? e.format("YYYY-MM-DD") : null)} format={"YYYY-MM-DD"} 
                      value={interviewDate ? moment(interviewDate, "YYYY-MM-DD") : ""} 
                      disabled={status === 'Processing'}

                      />
                    </div>
                    <div className="interview-time">
                      <label>Select Time</label><br />
                      <TimePicker onChange={(e) => setInterviewTime(e !== null ? e.format("h:mm A") : null)} format={"h:mm A"} minuteStep={15} 
                      value={interviewTime ? moment(interviewTime, "h:mm A") : ""} 
                      disabled={status === 'Processing'}

                      />

                    </div>
                    <div className="associated-to">
                      <label>Assigned To</label><br />
                      <select className="form-select" value={assignedTo} 
                      onChange={(e) => { setAssignedTo(e.target.value) }}
                      disabled={status === 'Processing'}
                      >
                        <option hidden>Choose</option>
                        {partnerManagersList && partnerManagersList.map((item, index) => (
                          <option key={item._userid} value={item._userid}>{item._firstname + "" + item._lastname}</option>
                        ))}
                      </select>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="whCard">
                  <div className="cardHeader">
                    <span>Skilled Worker Details</span>
                    <div className="d-flex">
                      <select value={status}
                        disabled
                        className={`selectStatus ${status === "Draft" && "Draft" ||
                          status === "Processing" && "Processing" ||
                          status === "Pending" && "Pending" ||
                          status === "Rejected" && "Rejected" ||
                          status === "Approved" && "Approved"
                          } `}
                        onChange={(e) => { setStatus(e.target.value) }}>
                        {/* <option value="Draft"  >Draft</option> */}
                        {statusList && statusList.map((item, index) => (
                          <option key={index} value={item.status}>{item.status}</option>
                        ))}
                      </select>
                      <button type="submit" onClick={submitHandler} className="btn saveBtn">Save</button>
                    </div>
                  </div>
                  <div className="cardBody">
                    <div>
                      <div className="row">
                        <div className="col">
                          <label>First Name *</label>
                          <div className="form-field">
                            <input
                              type="text"
                              value={firstName}
                              className="form-control"
                              onChange={(e) => { setFirstName(e.target.value) }}
                              placeholder="Enter First Name"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <label>Last Name</label>
                          <div className="form-field">
                            <input
                              type="text"
                              value={lastName}
                              className="form-control"
                              onChange={(e) => { setLastName(e.target.value) }}
                              placeholder="Enter Last Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <label>Mobile *</label>
                          <input
                            type="number"
                            value={mobile}
                            className="form-control"
                            onChange={(e) => { setMobile(e.target.value.slice(0, 10)) }}
                            placeholder="Enter Mobile Number"
                            onKeyDown={blockInvalidChar}
                          />
                        </div>
                        <div className="col">
                          <label>Aadhar Card *</label>
                          <div className="form-field">
                            <input
                              type="number"
                              value={aadhar}
                              onChange={(e) => { setAadhar(e.target.value.slice(0, 12)) }}
                              className="form-control"
                              onKeyDown={blockInvalidChar}
                              placeholder="Enter Aadhar Card"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-dataRow">
                      <div className="row">
                        <div className="col">
                          <label>Referred By *</label>
                          <div className="form-field">
                            <select value={refferdBy} onChange={(e) => { setRefferedBy(e.target.value) }} className="form-select" >
                              <option hidden>Select Partner</option>
                              <option value="Wehouse">Wehouse</option>
                              <option value="Tkp partner">Tkp partner</option>
                            </select>
                          </div>
                        </div>
                        <div className="col">
                          <label>TkpName *</label>
                          {/* <input
                            type="text"
                            value={tkpName}
                            onChange={(e) => setTkpName(e.target.value)}
                            className="form-control"
                            disabled={tkpPartner === "" || tkpPartner === "Wehouse"}
                          /> */}
                           <div className='col'>
                           <Select
                          size={size}
                          value={tkpName}
                          showSearch
                          showArrow
                          placeholder="Select Tkp Partner"
                          onChange={handleChange}
                          optionFilterProp="children"
                          disabled={refferdBy === "" || refferdBy === "Wehouse"}
                          style={{width:"535px"}}
                          >
                          {tkpListDropdown && tkpListDropdown.map((item,index) => (
                            <Option key={item.user_id} value={item.user_id}>{item.firstname + item.lastname}</Option>
                          ))}
                         </Select>
                           </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-dataRow">
                      <div className="row">
                        <div className="col">
                          <label>Type of Skills *</label>
                          <div className="form-field">
                            <select value={skillType} onChange={(e) => { setSkillType(e.target.value) }} className="form-select">
                              <option hidden>Select Your Skill</option>
                              {skillstype && skillstype.map((item, index) => (
                                <option key={item.skill_id} value={item.skill_name}>{item.skill_name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col">
                          <label>Years Of Experience *</label>
                          <div className="form-field">
                            <select value={yearsOfExperience} onChange={(e) => { setYearsOfExperience(e.target.value) }} className="form-select" >
                              <option hidden>Select Years Of Experience</option>
                              {experiencelist.map((item, index) => (
                                <option key={index} value={item.years_of_experience}>{item.years_of_experience}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-dataRow">
                      <div className="row">
                        <div className="col">
                          <label>Areas of Operations(Pincode)</label>
                          <div className="form-field">
                            <input
                              type="number"
                              value={pinCode}
                              className="form-control"
                              onChange={(e) => { setPinCode(e.target.value.slice(0, 6)) }}
                              onKeyDown={blockInvalidChar}
                              placeholder="Enter Pincode"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-dataRow">
                      <div className="">
                        <label>Remarks</label>
                        <textarea
                          type="text"
                          value={remarks}
                          className=" "
                          rows="3"
                          onChange={(e) => { setRemarks(e.target.value) }}
                          placeholder="Enter Text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SwCreateProfile;

// const [politenessRating, setPolitenessRating] = useState(0);
// const [hospitalityRating, setHospitalityRating] = useState(0);
// const [siteMaintanenaceRating, setSiteMaintanenaceRating] = useState(0);
// const [willingRating, setWillingRating] = useState(0);
// const [overallRating, setOverAllRating] = useState();
{/* <div className="col mt-4">
                      <label>Rating-Based on *</label>
                      <div className="row rating">
                        <div className="col">
                          <div className="d-flex">
                            <span className="text">Politeness (Language & Communication)</span>
                            <Rating allowHalfIcon size='25px' onClick={(rate) => { setPolitenessRating(rate) }} ratingValue={politenessRating} />
                          </div>
                          <div className="d-flex">
                            <span className="text">Hospitality</span>
                            <Rating allowHalfIcon size='25px' onClick={(rate) => { setHospitalityRating(rate) }} ratingValue={hospitalityRating} />
                          </div>
                          <div className="d-flex">
                            <span className="text">Site Maintenance</span>
                            <Rating allowHalfIcon size='25px' onClick={(rate) => { setSiteMaintanenaceRating(rate) }} ratingValue={siteMaintanenaceRating} />
                          </div>
                          <div className="d-flex">
                            <span className="text">Willingness to Carry Wehouse</span>
                            <Rating allowHalfIcon size='25px' onClick={(rate) => { setWillingRating(rate) }} ratingValue={willingRating} />
                          </div>
                        </div>
                        <div className="col">
                          <div className='overallScore'>
                            <span>Overall Score</span>
                            <span className='rating'>{overAll}</span>
                            <span>out of start rating</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
{/* <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option> */}
{/* <option value="Permission Agents">Permission Agents</option>
                              <option value="Borewell">Borewell</option>
                              <option value="Electrical Line Men">Electrical Line Men</option>
                              <option value="Soil Test" >Soil Test</option>
                              <option value="Geo Test" >Geo Test</option>
                              <option value="Surveyors" >Surveyors</option>
                              <option value="Excavation" >Excavation</option>
                              <option value="Centering" >Centering</option>
                              <option value="Mason" >Mason</option>
                              <option value="Electrical" >Electrical</option>
                              <option value="Plumbing" >Plumbing</option>
                              <option value="Waterproofing" >Waterproofing</option>
                              <option value="Painting" >Painting</option>
                              <option value="Flooring" >Flooring</option>
                              <option value="Carpenter" >Carpenter</option>
                              <option value="M.S Fabricator" >M.S Fabricator</option>
                              <option value="S.S Fabricator" >S.S Fabricator</option>
                              <option value="False - Cieling" >False - Cieling</option>
                              <option value="Scaffolding" >Scaffolding</option>
                              <option value="Glass Vendor" >Glass Vendor</option> */}
{/* <div class="col">
                          <label>Email *</label>
                          <input
                            type="email"
                            value={email}
                            className="form-control"
                            onChange={(e) => { setEmail(e.target.value) }}
                            placeholder="Enter Email Address"
                          />
                        </div> */}
{/* {statusList.map((item,index)=>(
                          <option key={index} value={item.status}>{item.status}</option>
                        ))} */}
// className={`selectStatus ${status === "Active" && "Active"
//   || status === "Inactive" && "Inactive"
//   || status === "Deleted" && "Deleted"
//   }`}
{/* <option>Santhosh</option>
                          <option>Ritu</option>
                          <option>Sripad</option> */}
{/* <input type="time" class="form-control" placeholder="Select Time" 
                       value={time} 
                       onChange={(e)=>setTime(e.target.value)}
                       /> */}
{/* <img src={image} id="img" alt="" className="cardBlankAvatarImg" /> */ }
{/* <input type="file" name="image-upload" id="input" accept="image/*" className=""  />
                    <label htmlFor="input" className="uploadLabel">
                      <span className="img-name">Upload capture image</span>
                    </label> */}

{/* <input type="date" class="form-control" placeholder="Select Date" 
                       value={date} 
                       onChange={(e)=>setDate(e.target.value)}
                        /> */}
              // if (!politenessRating) {
    //   IsValid = false
    // }
    // if (!hospitalityRating) {
    //   IsValid = false
    // }
    // if (!siteMaintanenaceRating) {
    //   IsValid = false
    // }
    // if (!willingRating) {
    //   IsValid = false
    // }



         // let SkilledWorkersData = {
        //   // image: image,
        //   _firstname: firstName,
        //   _lastname: lastName,
        //   _mobile:mobile,
        //   _aadhar: aadhar,
        //   _years_of_experience: yearsOfExperience,
        //   // _rating_politeness: politenessRating,
        //   // _rating_hospitality: hospitalityRating,
        //   // _rating_site_maintenance: siteMaintanenaceRating,
        //   // _rating_willingness_to_carry_wehouse: willingRating,
        //   // _rating_overall: overAll,
        //   _tkp_partner: tkpPartner,
        //   _skill_type: typeOfSkill,
        //   _pincode: areasOfOperation,
        //   _status: status,
        //   _remark:remarks
        // }

         // console.log('updated data succesfully');

        // let UpdatedSkilledWorkersData = {
        //   // image: image,
        //   UserId: userId,
        //   FirstName: firstName,
        //   LastName: lastName,
        //   Aadhar: aadhar,
        //   Mobile:mobile,
        //   Tkp_Partner: tkpPartner,
        //   Skill_Type: skillType,
        //   Pincode: pinCode,
        //   Years_of_Experience: yearsOfExperience,
        //   // Rating_Politeness: politenessRating,
        //   // Rating_Hospitality: hospitalityRating,
        //   // Rating_Site_Maintenance: siteMaintanenaceRating,
        //   // Rating_Willingness_To_Carry_WeHouse: willingRating,
        //   // Overall_Rating: overAll,
        //   Status: status,
        //   Remarks:remarks
        // }

        // calculating over all Rtaing:
  // const overAllRating = politenessRating + hospitalityRating + siteMaintanenaceRating + willingRating;
  // const overAll = overAllRating / 4;
  // console.log("overAllRating", overAll);