import React from 'react';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import { disabledDate } from '../../../../../../Utilities/helpers';


const ScheduleCall = (
  {
    handleLeadActivities,
    leadStatus,
    setLeadStatus
  }
) => {



  return (

    <div className='col'>
      <div className='col-md-6 mx-0.5'>
        <label>Date</label>
        <DatePicker
          onChange={handleLeadActivities("schedulecalldate")}
          format={"YYYY-MM-DD"}
          value={leadStatus?.callScheduleDate && moment(leadStatus?.callScheduleDate, "YYYY-MM-DD")}
          disabledDate={disabledDate}
        />
      </div>
      <div className='col-md-6 mx-1'>
        <label>Time</label>
        <TimePicker format={"h:mm A"} minuteStep={15} onChange={handleLeadActivities("schedulecalltime")} value={leadStatus?.callScheduleTime && moment(leadStatus?.callScheduleTime, "h:mm A")} />
      </div>
    </div>

  )
}

export default ScheduleCall;


