import { Tabs, Tooltip } from "antd"
import React, { useState } from "react"
import GenericLeadDetails from "./GenericLeadDetails/GenericLeadDetails"
import { BiLeftArrowAlt } from "react-icons/bi"
import { Link, useLocation } from "react-router-dom"
import GenericTaskDetails from "./GenericTaskDetails/GenericTaskDetails"
import GenericCallDetails from "./GenericCallDetails/GenericCallDetails"
import GenericMeetingDetails from "./GenericMeetingDetails/GenericMeetingDetails"
const { TabPane } = Tabs

const GenericActivity = ({ selectedUser, type, setMainScreen, mainScreen }) => {
    const location = useLocation()
    const [activeTab, setActiveTab] = useState("LeadDetails")
    console.log("location", selectedUser)

    const handleTabs = (value) => {
        console.log("tab name", value)
        setActiveTab(value)
    }

    const tabName = location && location.pathname.split("/").pop()
    let activityTypeNameVar =
        (tabName == "mytasks" && "Add Task") ||
        (tabName == "mycalls" && "Schedule Call") ||
        (tabName == "myappointments" && "Schedule Meeting")
    const [activityTypeName, setActivityTypeName] = useState(activityTypeNameVar)
    const tabDetails = [
        {
            key: "LeadDetails",
            tabName: "Lead Details",
            component: (
                <GenericLeadDetails
                    leadId={selectedUser?.leadId}
                    activeTab={activeTab}
                    activityTypeName={activityTypeName}
                    setActivityTypeName={setActivityTypeName}
                />
            )
        },
        {
            key: "Task",
            tabName: "Task",
            component: <GenericTaskDetails leadId={selectedUser?.leadId} activeTab={activeTab} />
        },
        {
            key: "Call",
            tabName: "Call",
            component: <GenericCallDetails leadId={selectedUser?.leadId} activeTab={activeTab} />
        },
        {
            key: "Appointments",
            tabName: "Appointments",
            component: <GenericMeetingDetails leadId={selectedUser?.leadId} activeTab={activeTab} />
        }
    ]
    console.log("activityTypeName", activityTypeName)
    return (
        <div className="mainContent">
            <div className="contentHeader">
                <span className="headerTitle">
                    Lead<label>({selectedUser?.leadName})</label>
                </span>
                {/* <Link to={`/landing/salesexecutive/${location?.state?.type}`}> */}
                <button
                    type="button"
                    className="btn whBtnPrimary"
                    onClick={() => {
                        setMainScreen("main")
                    }}
                >
                    <Tooltip title={type}>
                        <span>
                            <BiLeftArrowAlt size="22px" className="" /> Back
                        </span>
                    </Tooltip>
                </button>
                {/* </Link> */}
            </div>
            <div>
                <Tabs activeKey={activeTab} onChange={handleTabs}>
                    {tabDetails?.map((details) => {
                        return (
                            <TabPane tab={details?.tabName} key={details?.key}>
                                {details?.component}
                            </TabPane>
                        )
                    })}
                </Tabs>
            </div>
        </div>
    )
}

export default GenericActivity
