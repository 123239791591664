import { takeEvery,call,put } from "@redux-saga/core/effects";
import { DropDownServices } from "../../Service/DropDownServices";
import { getSkillTypeListFailure, getSkillTypeListSuccess } from "../Slices/getSkillTypeSlice";

export const GETSKILLTYPELIST =()=>({type:'/skillType'});

export function* getSkillTypeList(action){
   const response = yield DropDownServices.getSkillTypeService();
   console.log("getSkillTypeList::",response);
   if(response.status === "success"){
       yield put(getSkillTypeListSuccess(response.data.skillTypes))
   }else{
       yield put(getSkillTypeListFailure(response.data.message))
   }

    
}

function* getSkillTypeListWatcher(){
   yield takeEvery(GETSKILLTYPELIST().type,getSkillTypeList);
}

export default getSkillTypeListWatcher;