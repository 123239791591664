import { takeEvery,call,put ,takeLatest} from "@redux-saga/core/effects";
import { partnerExecutiveServices } from "../../Service/PartnerExecutiveServices";
import { getInteriorPartnersLoader } from "../Slices/interiorPartnersListSlice";
import { TurnkeyPartnersLoader,TurnkeyPartnersListSuccess,TurnkeyPartnersListFailure } from "../Slices/turnkeyPartnersListSlice";
// import { getInteriorPartnersList } from "./interiorPArtnersList";

// export const TKPLIST=(payload)=>({type:"/gettkplist",payload:payload})
export function* getTurnkeyPartners(action){
//    console.log("getTurnkeyPartnersById inside saga ",action.payload);
    // alert("get tkp partner generator"); 
    //  yield put(TurnkeyPartnersLoader());
   const response = yield (partnerExecutiveServices.GetTurnKeyPartnerService(action.payload));
//    console.log('saga response for get tkp list :',response);
    
   if(response?.status ==="success" && response?.data.gettkplist){
       yield put(TurnkeyPartnersListSuccess({Tkps_list:response.data.TKPs_list,count:response.count}))
   }else{
       yield put(TurnkeyPartnersListFailure(response?.error))   
   }
}

function* TurnkeyPartnersWatcher(){
    yield takeEvery(TurnkeyPartnersLoader().type,getTurnkeyPartners)
    console.log("tkp watcher",);
    // yield takeLatest("/gettkplist",getTurnkeyPartners)

}

export default TurnkeyPartnersWatcher;
