import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Toaster.css";


function Toaster() {
    return (
        <div>
            <ToastContainer
                autoClose={900}
                position="bottom-right"
            />
        </div>
    )
}

export default Toaster;