import React, { useState, useEffect } from 'react';
import { BiRightIndent, BiLeftIndent, } from 'react-icons/bi';
import "antd/dist/antd.css";
import { Link, NavLink } from 'react-router-dom';
import '../PartnerExecutive/PeSidebar.css';
import { useLocation } from 'react-router-dom';
import { GetMenuService } from '../../Service/GetMenuService';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {fal} from '@fortawesome/pro-light-svg-icons';
import {fad} from '@fortawesome/pro-duotone-svg-icons';
import {fat} from '@fortawesome/pro-thin-svg-icons';
import SimpleBarReact from 'simplebar-react';
import { labelChanger } from '../../Utilities/helpers';


library.add(fas, far, fab,fal,fad,fat,fad)

const { SubMenu } = Menu;

function Sidebar(props) {
  //Dynamic Menu Data :
  const location = useLocation();
  // console.log('location:', location);

  const [collapsed, setCollapsed] = useState(false);
  const [selected, setSelected] = useState("0")
  const [current, setCurrent] = useState("1");
  // const [data, setData] = useState([]);
  const [menuResponse, setMenuResponse] = useState({ menuData: { Menu: [] } })


  //getting UserId from localStorage:
  let UID = localStorage.getItem('user_Id');
  // console.log("UID:::", UID);


  // const getMenus =async  ()=>{
  //   try {
  //     const userId = {
  //       userid: UID
  //     }

  //     // console.log('userId :', userId)
  //     const menuResponse = await GetMenuService(userId);
  //     console.log('menu data response:', menuResponse);
  //     if(menuResponse && menuResponse.status === "success" && menuResponse.menuData.get_menu){
  //       setMenuResponse(menuResponse);
  //     }
  //   } catch (error) { 
  //      console.log(error);
  //   }
  // }

  // Dynamic Menu Responses:
  useEffect(() => {
    (async () => {

      const userId = {
        userid: UID
      }

      // console.log('userId :', userId)
      const menuResponse = await GetMenuService(userId);
      console.log('menu data response:', menuResponse);
      if(menuResponse && menuResponse.status === "success" && menuResponse.menuData.get_menu){
        setMenuResponse(menuResponse);
      }
    })()

    // getMenus()
  }, [])
  
//  console.log("menu",menuResponse)

  let routeObj = {
    // partner Executive Screens :
    "1": "/landing/partnerexecutive/dashboard",
    "2": "/landing/partnerexecutive/turnkeyPartners",
    "3": "/landing/partnerexecutive/skilledWorkers",
    "4": "/landing/partnerexecutive/architechPartners",
    "5": "/landing/partnerexecutive/interiorPartners",
    // "6": "/landing/partnerexecutive/setupInterviews",
    "7": "/landing/partnerexecutive/myTrips",

    // sales Executive screens :
    "8": "/landing/salesexecutive/dashboard",
    "9":"/landing/salesexecutive/myleads",
    "10": "/landing/salesexecutive/mytasks",
    "11":"/landing/salesexecutive/mycalls",
    "12":"/landing/salesexecutive/myappointments",
    "13":"/landing/salesexecutive/customers",
    "14": "/landing/salesexecutive/projects",

    //Admin screens :
    "15":"/landing/admin/wehouseUsers",

    // sales coordinator screens :
    "16":"/landing/salescoordinator/dashboard",
    "17":"/landing/salescoordinator/rawleads",
    "32":"/landing/salescoordinator/customers",
    "33":"/landing/salescoordinator/projects",




    // partner Manager screens:
    "18": "/landing/partnermanager/dashboard",
    "19": "/landing/partnermanager/tkpAllUsers",
    "20":"/landing/partnermanager/tkpPendingApprovals",
    "21": "/landing/partnermanager/allSkilledWorkers",
    "22":"/landing/partnerManager/skilledWorkerPending",
    "23":"/landing/partnermanager/allArchitectPartners",
    "24":"/landing/partnerManager/architectPartnersPending",
    "25":"/landing/partnermanager/allInteriorPartners",
    "26":"/landing/partnermanager/interiorPartnersPending",
    // "27": "/landing/partnermanager/myCalender",
    "37": "/landing/partnermanager/tkpAppoinment",
    
    /* Project managment officer */
    "38":"/landing/pmo/dashboard",
    "39":"/landing/pmo/projects",
    // "40":"/landing/pmo/customers",

    /* customer screens */
    "41":"/landing/customer/projects",
    "42":"/landing/customer/buildingProgresss",
    "44":"/landing/customer/drawings",
    "45": "/landing/customer/paymentProcess",

    /* project Coordinator */
    "48":"/landing/pc/dashboard",
    "49":"/landing/pc/projects",
    
  
    /* project manager screens */
    "50":"/landing/pm/dashboard",
    "51":"/landing/pm/projects",
    // "52":"/landing/pm/customers"
  






  }

  const menuScreens = menuResponse.menuData.Menu;

  {
    menuScreens.map(menu => {
      if (menu.Menu_Screen) {
        menu.Menu_Screen.map(subMenu => {
          return subMenu['route'] = routeObj[subMenu.Menu_Screen_ID.toString()] ? routeObj[subMenu.Menu_Screen_ID.toString()] : '/landing/notYetSet'
        })
      }
    })
  }

  const collpaseHandler = () => {
    setCollapsed(!collapsed)
  }
  const clickHandler = (e) => {
    console.log('click :::', e)
    setCurrent(e.key)
  }

  return (
    <div className="leftSidePanel">
        <Menu
          inlineCollapsed={collapsed}
          mode="inline"
          onClick={clickHandler}
          defaultOpenKeys={['0']}
          defaultSelectedKeys={[menuScreens?.[0]?.Menu_Screen?.[0]?.Menu_Screen_ID]}
          className="menu-bar"
          selectedKeys={[current]}
          style={{ background: "white",
          width:collapsed ? "80px" :"297px"}}
        >
          <div className="sidebarHead">
            <div className="headTitle">
              {collapsed ? <div className='hideTitle'></div> :
                <div className="showTitle"></div>}
                {
                collapsed ? <BiRightIndent onClick={collpaseHandler} size="25px" className="pe-open-arrow" />
                : <BiLeftIndent onClick={collpaseHandler} size="25px" className="pe-close-arrow" />
                }
            </div>

          </div>
          {/* <SimpleBarReact style={{maxHeight:800}}> */}
            {menuScreens.map((role, id) => (
              console.log('role=>>>>>',role , id),
              <SubMenu key={id} title={role?.Menu_category_Label} className="item" icon={<i className={role?.Menu_Category_Icon}></i>}>
                {role.Menu_Screen.map((roles, id) => (
                  console.log('roles?.Menu_Screen_ID----:',roles?.Menu_Screen_ID),
                  <Menu.Item key={roles?.Menu_Screen_ID} className="item">
                    <i className={roles?.Menu_Screen_Icon}></i>
                    <NavLink to={roles.route}>
                      {labelChanger(roles.Menu_Screen_Label)}
                     </NavLink>
                    </Menu.Item>
                ))}

              </SubMenu>
            ))}
            {/* </SimpleBarReact> */}
        </Menu>
      </div>
  )
}

export default Sidebar;


{/* {roles.Menu_Screen_Label.includes("Dashboard")?
                      "Dashboard":
                      roles.Menu_Screen_Label && roles.Menu_Screen_Label.includes("My Appointments") ? 
                      "My Appointments" : 
                      roles.Menu_Screen_Label
                      }                      */}


         // <Menu.Item key={id}  className="item" onClick={navigateUrl.bind(this, roles.Menu_Screen_ID)}>{roles.Menu_Screen_Label}</Menu.Item>

  // console.log("log ::::",menuScreens?.[0]?.Menu_Category_ID,menuResponse);
  // console.log("log1::::",menuScreens?.[0]?.Menu_Screen?.[1]?.Menu_Screen_ID);
  
  // const navigateUrl = (id) => {
  //   window.location.href = routeObj[id.toString()]
  // }

   // gettin userId from redux store:
  // const id = useSelector(state => state.userId)
  // console.log("id:::",id);
