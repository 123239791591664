
import axios from 'axios';
import instanceAll from './CommonAxiosInstance';

export const validateOTPService=async(body)=>{
    try{
        console.log(body,"body in otp validate serviceeeeeeee")

        const response = await instanceAll.post("/validate-otp", body, {
            headers: { 'Content-Type': 'application/json' }
        });

        console.log(response,"otpENteredddddddddddd")
        if(response.data.status===200){

            return{
                status:"Success",
                data:response.data.message,
            }

        }
        else {
            return{
                status:"Failed",
                data:response.data.message
            }
        }

    }
    catch(error){
        return (
            {
                status: "Failed",
                message: error
            }
        )

    }
}