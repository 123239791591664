import React from 'react'
import {ArrowLeftOutlined} from '@ant-design/icons';
import "../../../MyLeads/AddTask/AddTaskHeader.css";

const RescheduleHeader = ({setModalType,type}) => {
    return (
        <div className='addTaskWrapper' onClick={() => setModalType("details")}>
            <ArrowLeftOutlined />
            {type === "reassign"?
             <h4>Reassign</h4>:
            <h4>Reschedule</h4>
            }
        </div>
    )
}

export default RescheduleHeader


 {/* <img src={imageAssets.angleLeft} alt="left" onClick={()=>taskadding(false)}/> */}