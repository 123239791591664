import instanceAll from './CommonAxiosInstance';

// get Designation list API :
const getDesignationListService = async () => {
    // console.log('inside the get designation list service');
    try {
        const response = await instanceAll.post("/designations-list");
        console.log("response", response);
        if (response?.status === 200 && response?.data?.getDesignationList) {
            return ({
                status: 'success',
                data: response?.data?.desginationList
            })
        }
        else {
            return ({
                status: "failed",

            })

        }
    }
    catch (error) {
        console.log(error);
        return ({
            status: "failed",
            message: error
        })

    }
}

// get Years Of Experiance API:
const getYearsOfExperienceListService = async () => {
    try {
        const YearsOfExperineceResponse = await instanceAll.post("/get-experiences");
        //    console.log("YearsOfExperineceResponse:::",YearsOfExperineceResponse);
        if (YearsOfExperineceResponse.status === 200 && YearsOfExperineceResponse.data.isgetExperiences) {
            return {
                status: "success",
                data: YearsOfExperineceResponse.data.experiencesList
            }
        } else {
            return {
                status: "failed",
                data: YearsOfExperineceResponse.data.message
            }
        }
    } catch (err) {
        console.log("err:", err);
    }
}

//  get Associated roles API :
const getAssociatedRolesListService = async () => {
    try {
        const rolesResponse = await instanceAll.post("/get-roles");
        //  console.log("rolesReponse::",rolesResponse);
        if (rolesResponse.status === 200 && rolesResponse.data.isgetRoles === true) {
            const data = rolesResponse.data.roles.map((value) => ({ value: value.role_names, label: value.role_names }))
            return {
                status: "success",
                data: data
            }
        } else {
            return {
                status: "failed",
                data: rolesResponse.data
            }
        }
    } catch (err) {
        console.log("err:", err);
    }
}

// get status List API:
const getStatusListService = async (body) => {
    try {
        const response = await instanceAll.post("/get-status", body);
        //   console.log("getStatusListService",response);
        if (response.status === 200) {
            return {
                status: "success",
                data: response.data.list
            }
        } else {
            return {
                status: "failed",
                data: response.data
            }
        }
    } catch (error) {
        console.log("error", error);
    }
}

// get skill type List API :
const getSkillTypeService = async () => {
    try {
        const response = await instanceAll.post("/get-skill-types");
        console.log("getSkillTypeService::", response);
        if (response.status === 200 && response.data.status === 200) {
            return {
                status: "success",
                data: response.data.skillTypes
            }

        }
    }
    catch (error) {
        console.log(error);
    }
}


// get Education list API:
const getEducationListService = async () => {
    try {
        const response = await instanceAll.post('/get-education-list');
        //  console.log("getEducationListService:",response);
        if (response.status === 200 && response.data.status === 200) {
            return {
                status: "success",
                data: response.data
            }
        } else {
            return {
                status: "failed",
                data: response.data
            }
        }

    } catch (error) {
        console.log(error);
    }
}

// getPartnermanagers List API :
const getPartnerManagersListService = async () => {
    try {
        const response = await instanceAll.post("/get-partner-manager-list");
        // console.log("getPartnerManagersListService:",response);
        if (response.status === 200) {
            return {
                status: "success",
                data: response.data
            }
        } else {
            return {
                status: "failed",
                data: response.data
            }
        }

    } catch (error) {
        console.log(error);
    }
}

// get categoriList API:
const getCategoriesListService = async () => {
    try {
        const response = await instanceAll.post("/get-categories");
        console.log("getCategoriesListService:", response);
        if (response.status === 200) {
            return {
                status: "success",
                data: response.data
            }
        } else {
            return {
                status: "failed",
                data: response.data
            }
        }

    } catch (error) {
        console.log(error);
    }
}

/* get project type list */
const getProjectTypeList = async () => {
    try {
        const response = await instanceAll.post("/get-project-type-list");
        if (response.status === 200 && response.data.isGetProjectList) {
            return (
                {
                    status: "success",
                    data: response.data.projectTypeList
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    data: [],
                    message: "No data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error,
                data: []
            }
        )

    }
}
/* get floor list */
const getFloorListService = async (body) => {
    try {
        const response = await instanceAll.post("/get-floor-list", body);
        if (response.status === 200 && response.data.isGetFloorNumber) {
            const floorList = response.data.floorList.map((value) => ({ label: value.floorName, value: value.floorName }));
            return (
                {
                    status: "success",
                    data: floorList
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    data: []
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                data: []
            }
        )

    }
}
export const DropDownServices = {
    getDesignationListService,
    getYearsOfExperienceListService,
    getAssociatedRolesListService,
    getStatusListService,
    getSkillTypeService,
    getEducationListService,
    getPartnerManagersListService,
    getCategoriesListService,
    getProjectTypeList,
    getFloorListService
}