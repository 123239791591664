import React, { useState, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { postSignupService } from '../Service/SignupServices';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { emailSignupService } from '../Service/SignupServices';
import { mobileSignupService } from '../Service/SignupServices';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import hidePwdImg from '../Assets/images/hide-pswd.svg';
import showPwdImg from '../Assets/images/show-pswd.svg';
import { imageAssets } from '../Assets/AssetsConstants/AssetsConstants';
import { checkEmail } from '../Utilities/helpers';

const md5 = require('md5');

function SignUp() {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [gender, setGender] = useState("");
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [countryCode, setCountryCode] = useState('+91')
    const [captcha, setCaptcha] = useState("");
    const [checkVar, setCheckVar] = useState(false);
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [numError, setNumError] = useState(false);
    const [emailError, setEmailError] = useState(false);


    //page redirect:
    const history = useHistory();

    // Signin Handler:
    const signinHandle = () => {
        history.push('/signin')
    }
    
    //Password Encryption :
    const encryptedPassword = md5(password);

    // RegExpressions:
    const emailValiadte = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const pswdValidate = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/

    // // checkEmail:
    // const checkEmail = async () => {
    //     if (emailValiadte.test(email)) {
    //         let data = {
    //             email: email
    //         }
    //         const response = await emailSignupService(data)
    //         console.log('check-email---', response)
    //         if (response.status === 'success') {
    //             if (response.data.emailExists) {
    //                 // alert("email is already exist")
    //                 setEmailError(true)
    //             }
    //             else {
    //                 setEmailError(false)
    //             }
    //         }
    //     }
    // }

    // checkMobile:
    const checkMobile = async () => {
        if (number) {
            let data = {
                phone: number
            }
            const response = await mobileSignupService(data)
            console.log('check-mobile--', response)
            if (response.status === 'success') {
                if (response.data.mobileavail) {
                    // alert("mobile number is already exist")
                    setNumError(true)
                }
                else {
                    setNumError(false)
                }
            }
        }
    }
    // SubmitHandler:
    const submitHandler = async (e) => {
        e.preventDefault()
        setCheckVar(true);
        if (valiadteForm()) {

            let data = {
                firstname: name,
                lastname: surname,
                gender: gender,
                dob: `${year}-${month}-${day}`,
                countrycode: countryCode,
                phone: number,
                email: email,
                password: encryptedPassword,
                captcha: captcha
            }
            console.log("data:,",data)

            // submit when new email
            const response = await emailSignupService(data)
            console.log("emailResponse :",response)
            if (response.data.emailExists === false) {

                // submit when new mobilenumber:
                const response = await mobileSignupService(data)
                if (response.data.mobileavail === false) {

                     // signup service :
                    const response = await postSignupService(data)
                    if (response.data.status && response.data.status === 200) {
                        history.push('/verify', { phone: number , userName:name+ " " +surname})
                    }
                    else {
                        alert("SignUp fails")
                    }
                    console.log("onsubmit-----", response)
                }

            }
        }
    }

    // Validation:
    const valiadteForm = () => {
        var IsValid = true;

        if (name.length < 1) {
            IsValid = false;
        }
        if (surname.length < 1) {
            IsValid = false;
        }
        if (!emailValiadte.test(email)) {
            IsValid = false;
        }
        if (number.length < 1) {
            IsValid = false;
        }
        if (!pswdValidate.test(password)) {
            IsValid = false;
        }
        if (!day) {
            IsValid = false;
        }
        if (!month) {
            IsValid = false;
        }
        if (!year) {
            IsValid = false;
        }
        if (!gender) {
            IsValid = false;
        }

        return IsValid;

    }

    //Date Of Birth :
    const months = ['January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August', 'September', 'October',
        'November', 'December'];


    const years = new Date().getFullYear();

    useEffect(() => {

    }, [month])

     // Setup Recaptcha :
    function onChange(value) {
        setCaptcha(value)
        console.log("recaptcha value",value);
    }

    return (
        <div className="whSignupBg">
            {/* <div className="container"> */}
                <div className="whSignupBgOverlay">
                    {/* <div className="col-xl-6 logo-layout">
                        <img src={sideimg} className="side-logo" width="100%" alt="logo is loading ...." />
                    </div> */}
                    <div className="whSignupPanel">
                        <div className="whSigupContainer">
                            <div className="whIcon">
                            <img src={imageAssets.WehouseLogo} className="hippi-signin-icon"  alt="0hippi" />
                                {/* <img src="https://wehouse-images.s3.ap-south-1.amazonaws.com/wh-logo.png" className="logo" alt="loading...." /> */}
                                {/* <svg width="282" height="66" viewBox="0 0 282 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M127.413 64.9579H122.367L118.447 55.0439L115.112 45.0857L111.823 55.135L107.993 64.9579H102.944L91.362 33.4147H98.03L105.465 56.1715L113.126 33.4147H117L124.887 56.1715L132.096 33.4147H138.767L127.413 64.9579Z" fill="#F8991D"/>
                                    <path d="M148.19 42.0658C140.979 42.0658 136.383 46.9324 136.383 53.6913C136.383 60.8104 140.938 65.5417 148.595 65.5417C152.126 65.6215 155.55 64.3293 158.148 61.9366L154.634 58.4668C152.958 59.8377 150.85 60.5711 148.685 60.5371C144.809 60.5371 142.422 58.5538 142.06 55.8058H159.41C160.265 46.6592 155.624 42.0617 148.188 42.0617L148.19 42.0658ZM142.15 51.2539C142.528 49.9259 143.354 48.7696 144.487 47.9809C145.621 47.1923 146.992 46.82 148.369 46.9269C151.523 46.9269 153.777 48.3692 154.137 51.2539H142.15Z" fill="#F8991D"/>
                                    <path d="M167.793 33.4147V45.762C168.589 44.6808 169.638 43.8113 170.848 43.2299C172.058 42.6486 173.392 42.373 174.733 42.4275C181.042 42.4275 183.836 46.7061 183.836 53.2414V64.9579H178.339V53.2869C178.339 49.2319 176.22 47.519 173.291 47.519C172.526 47.5438 171.774 47.7224 171.08 48.0443C170.385 48.3661 169.763 48.8246 169.25 49.3924C168.737 49.9601 168.343 50.6255 168.093 51.3487C167.843 52.0719 167.741 52.8381 167.793 53.6016V64.9565H162.296V33.4147H167.793Z" fill="#1A1818"/>
                                    <path d="M198.031 42.2909C195.742 42.2996 193.507 42.9862 191.608 44.2641C189.709 45.5419 188.232 47.3537 187.362 49.4707C186.492 51.5876 186.269 53.9149 186.721 56.1585C187.173 58.4021 188.279 60.4616 189.901 62.0768C191.522 63.6921 193.586 64.7907 195.831 65.234C198.077 65.6773 200.403 65.4454 202.517 64.5676C204.631 63.6897 206.437 62.2053 207.708 60.3017C208.978 58.3982 209.656 56.1608 209.656 53.8721C209.722 52.3299 209.466 50.7909 208.905 49.353C208.343 47.9152 207.488 46.61 206.395 45.5205C205.301 44.4311 203.993 43.5813 202.553 43.0253C201.113 42.4692 199.573 42.2191 198.031 42.2909ZM198.076 60.406C194.065 60.406 192.038 57.2522 192.038 53.8721C192.038 50.5376 194.108 47.2927 198.076 47.2927C198.906 47.3229 199.722 47.5177 200.477 47.8658C201.231 48.2139 201.909 48.7084 202.471 49.3204C203.032 49.9325 203.467 50.65 203.749 51.4314C204.031 52.2127 204.156 53.0424 204.115 53.8721C204.115 57.2509 202.087 60.406 198.076 60.406Z" fill="#1A1818"/>
                                    <path d="M218.175 42.7422V54.3676C218.175 57.7477 220.023 60.3163 223.538 60.3163C224.323 60.2955 225.097 60.1163 225.812 59.7896C226.527 59.4628 227.169 58.9952 227.699 58.4147C228.229 57.8342 228.636 57.1528 228.897 56.4111C229.157 55.6695 229.265 54.8829 229.215 54.0985V42.7435H234.669V65.0035H229.757L229.397 61.985C228.455 63.0479 227.296 63.8958 225.997 64.4712C224.699 65.0465 223.292 65.3359 221.872 65.3195C216.554 65.3195 212.678 61.3087 212.678 54.4159V42.7449L218.175 42.7422Z" fill="#1A1818"/>
                                    <path d="M253.145 48.6453C252.409 47.9632 251.542 47.4362 250.598 47.0955C249.654 46.7547 248.65 46.6073 247.648 46.6619C245.034 46.6619 243.593 47.4735 243.593 48.8703C243.593 50.3126 244.9 51.1241 247.733 51.3036C251.924 51.5796 257.242 52.5209 257.242 58.4226C257.242 62.3424 254.043 65.7225 247.688 65.7225C245.789 65.8539 243.885 65.5669 242.11 64.8817C240.334 64.1965 238.731 63.1299 237.413 61.7572L240.117 57.8361C242.23 59.7332 244.94 60.8331 247.778 60.9457C249.848 60.9912 251.789 59.9091 251.789 58.2874C251.789 56.7554 250.527 56.1246 247.372 55.9411C243.181 55.625 238.179 54.093 238.179 49.04C238.179 43.9029 243.496 42.1017 247.553 42.1017C249.117 41.9978 250.686 42.2029 252.17 42.7054C253.655 43.208 255.026 43.998 256.205 45.0305L253.145 48.6453Z" fill="#1A1818"/>
                                    <path d="M270.677 42.0658C263.467 42.0658 258.871 46.9324 258.871 53.6913C258.871 60.8104 263.425 65.5417 271.083 65.5417C274.614 65.6217 278.039 64.3295 280.637 61.9366L277.122 58.4668C275.446 59.8375 273.339 60.5709 271.174 60.5371C267.298 60.5371 264.911 58.5538 264.549 55.8058H281.899C282.755 46.6592 278.114 42.0617 270.679 42.0617L270.677 42.0658ZM264.64 51.2539C265.018 49.9259 265.844 48.7696 266.978 47.9809C268.111 47.1923 269.483 46.82 270.859 46.9269C274.013 46.9269 276.267 48.3692 276.628 51.2539H264.64Z" fill="#1A1818"/>
                                    <path d="M35.8538 23.8858L27.1983 40.0563L17.8126 23.8858H0.874146L18.5703 56.1826L18.4682 56.3744L23.2163 65.2464H30.9747L35.8249 56.184L44.6585 39.6809L36.2086 23.8858H35.8538Z" fill="#1A1818"/>
                                    <path d="M62.1186 40.0563L53.4631 23.8858H36.2099L44.6598 39.674L58.3423 65.2395H65.8964L70.7466 56.1771L100.378 0H84.7533L62.1186 40.0563Z" fill="#F8991D"/>
                                    <path d="M47.4728 57.1984H41.1443V65.2395H47.4728V57.1984Z" fill="#1A1818"/>
                                </svg> */}
                            </div>
                            <div className="form-layout">
                                <h1 className="titleLg">Sign Up</h1>
                                <div className="form-data">
                                    <form onSubmit={submitHandler}>
                                        <div className="row">
                                            <div className="col">
                                                <div className="inputField">
                                                    <label >Name *</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        name="name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                    {checkVar && !name.length && <small>Name is required</small>}
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="inputField">
                                                    <label >Surname</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        name="surname"
                                                        value={surname}
                                                        onChange={(e) => setSurname(e.target.value)}
                                                    />
                                                    {checkVar && !surname.length && <small>Surname is required</small>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="inputField mobileField">
                                            <label >Mobile number *</label>
                                            <div className="mobileNumField">
                                                <PhoneInput
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    defaultCountry="IN"
                                                    value={countryCode}
                                                    onChange={(e) => setCountryCode(e)}
                                                />
                                                <input type="number" className="phone-field"
                                                    value={number} onChange={(e) => setNumber(e.target.value)}
                                                    onBlur={checkMobile}
                                                />
                                            </div>
                                            {numError ? <small>Mobile number is already exists</small> : null}
                                            {checkVar && !number.length && <small>Enter valid mobile Number</small>}
                                        </div>
                                        <div className="inputField">
                                            <label>Email *</label>
                                            <input type="email"
                                                className="form-control"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                onBlur={()=> checkEmail(email, setEmailError)}
                                            />
                                            {emailError ? <small className='errorMsg'>Email is alreday exists</small> : null}
                                        </div>

                                        <div className="inputField">
                                            <label>Password*</label>
                                            <div className="pswdField">
                                            <input type={isRevealPwd ? "text" : "password"}
                                                className="form-control"
                                                name="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <img
                                                className="eye-icon"
                                                title={isRevealPwd ? "Hide password" : "Show password"}
                                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                                                alt="eye.."
                                            />
                                            </div>
                                            <p>{checkVar && !pswdValidate.test(password) && <small>Min 8 characters,<br />At least one uppercase [A-Z] and lowercase [a-z],<br />One number [0-9]<br />A special character[!,@,#,$]</small>}</p>
                                        </div>

                                        <div className="inputField signUpDOB">
                                            <label >Date of Birth *</label>
                                            <div className="row">
                                                <div className="col">
                                               
                                                        <select id="day" onChange={(day) => { setDay(day.target.value) }} className="form-select" name="day">
                                                            <option value="days">Day </option>
                                                            {
                                                                [...Array(31)].map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={index + 1}>{index + 1}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                               
                                                    {checkVar && !day && <small>Date is required</small>}
                                                </div>
                                                <div className="col">
                                                
                                                        <select id="month" onChange={(month) => { setMonth(month.target.value) }} className="form-select" name="month">
                                                            <option value={''}>{'Month'} </option>
                                                            {months.map((item, index) => {
                                                                return (

                                                                    <option key={index} value={index + 1}>{item}</option>
                                                                )
                                                            })
                                                            }
                                                        </select>
                                                
                                                {checkVar && !month && <small>Month is required </small>}
                                                </div>
                                                <div className="col">
                                                
                                                        <select id="year" onChange={(year) => { setYear(year.target.value) }} className="form-select">
                                                            <option value="year">Year </option>
                                                            {
                                                                [...Array(100)].map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={years - index}>{years - index}</option>
                                                                    )
                                                                })
                                                            }

                                                        </select>
                                                
                                                {checkVar && !year && <small>Year is required</small>}
                                                </div>
                                            </div>             
                                        </div>

                                        <div className="inputField">                      
                                        <label>Gender *</label>
                                        <div className="row">
                                            <div className="col">
                                                <div className="genderField">
                                                    <div className="radioBtnField">
                                                        <div>Male</div> 
                                                        <input type="radio" value="Male" className="" name="gender" onClick={() => { setGender('Male') }} />
                                                    </div>
                                                </div>
                                            </div>               
                                            <div className="col">
                                                <div className="genderField">
                                                    <div className="radioBtnField">
                                                        <div>Female</div>  
                                                        <input type="radio" value="female" className="" name="gender" onClick={() => { setGender('Female') }} />
                                                    </div>
                                                </div>
                                            </div>               
                                            <div className="col">
                                                <div className="genderField">
                                                    <div className="radioBtnField">
                                                        <div>Others</div>  
                                                        <input type="radio" value="others" className="" name="gender" onClick={() => { setGender('others') }} />
                                                    </div>
                                                </div>            
                                            </div>

                                        </div>
                                        {checkVar && !gender && <small>Gender is required</small>}
                                        </div>                     

                                        <p className="termsInfo">
                                            By clicking Sign Up, you agree to our <a href="123">Terms, Data Policy </a>and <a href="123">Cookie Policy.</a>
                                            <br/>
                                            You may receive OTP notification from us and can opt out at any time.
                                        </p>
                                        <ReCAPTCHA
                                            sitekey="6Ldb3l8UAAAAAO8xBJ1q_GLfDn2kkcQNBCqC02GU"
                                            onChange={onChange}
                                            className="captcha"
                                        />
                                        
                                        <button type="submit" className="signUpBtn">Sign Up</button>

                                        <p className="termsInfo signInLink">
                                            Already have an account? <Link to={'/signin'} onClick={signinHandle}>Sign In</Link>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        // </div>
    )
}

export default SignUp
