import React, { useState } from 'react';
import { BrowserRouter as Router, NavLink } from 'react-router-dom'
import { IoMdAdd } from 'react-icons/io';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { orange } from '@material-ui/core/colors';
import { Nav } from 'react-bootstrap';
import AddArchitechPartner from './AddArchitechpartners/AddArchitechPartner';
import ApList from './ApList/ApLIst';
import { Tooltip } from 'antd';

function ArchitechPartners() {
  const [toggleBtn, setToggleBtn] = useState(false);
  const [type, setType] = useState("");
  const [userId, setUserId] = useState("");
  const [partnerExecutive, setPartnerExecutive] = useState('partnerExecutive');
  
  const toggleHandler = (type) => {
    setType(type)
    // console.log(type);
  }

  return (
    <div className="mainContent">
      <div className="contentHeader">
        {(type === "edit" || type === "add") ? 
         <div>
           {(type === "edit" ? <span class="headerTitle">Architect Partner Details</span> 
            :
            <span class="headerTitle">Add Architect Partner</span> )}
         </div>
          :
        <span class="headerTitle">Architect Partners</span>
        }
        <button type="button" className="btn whBtnPrimary">
          {(type === "edit" || type === "add") ?
           <Tooltip title="Architect Partners">
            <span className="btnText" onClick={()=>{toggleHandler("")}}>
              <NavLink to="#">
                <BiLeftArrowAlt size="20px" className="" />
                Back 
              </NavLink>
            </span> 
            </Tooltip>
            : 
              <span className="btnText" onClick={()=>{toggleHandler("add")}}>
              <IoMdAdd size="20px" className="" />
              Architech Partner
            </span>
            } 
        </button>
      </div>
      <div className="whContentBlock">
      {(type === "edit" || type === "add") ? <AddArchitechPartner type={type} userId={userId}toggleHandler={toggleHandler} /> : <ApList partnerExecutive={partnerExecutive} typeHandler={toggleHandler} userId={setUserId}/>}
      </div>
    </div>
  )
}

export default ArchitechPartners;