import React, { useEffect, useState } from 'react'
import CustomerDetails from './CustomerDetails';
import ProjectDetails from './ProjectDetails';
import PaymentInstallments from './PaymentInstallments';
import { Tabs } from 'antd';

const { TabPane } = Tabs;


function Addcustomers({ 
    type, 
    userId, 
    toggleHandler, 
    newLead,
    projectCustomerId 
  }) 
{
    console.log('new lead', newLead,projectCustomerId);
    const [activeTab, setActiveTab] = useState("Customer");
    const [customerIdFromApi, setCustomerIdFromApi] = useState("");
    const [projectDataFromApi,setProjectDataFromApi]=useState({});
  console.log("new lead",newLead);

    const handleTabs = (value) => {
        console.log("tab name", value);
        setActiveTab(value);
    }
    useEffect(() => {
        if (type === "updateproject") {
            setCustomerIdFromApi(projectCustomerId);
            handleTabs("Project");
        }
    }, [])

    return (
        <div className='timelineTabs customers'>
            <Tabs activeKey={activeTab}>
                <TabPane tab="Customer Details" key="Customer">
                    <CustomerDetails
                        handleTabs={handleTabs}
                        type={type}
                        userId={userId}
                        toggleHandler={toggleHandler}
                        newLead={newLead}
                        setCustomerIdFromApi={setCustomerIdFromApi}
                        customerIdFromApi={customerIdFromApi}
                    />
                </TabPane>
                <TabPane tab="Project Details" key="Project">
                    <ProjectDetails
                        toggleHandler={toggleHandler}
                        handleTabs={handleTabs}
                        type={type}
                        newLead={newLead}
                        setCustomerIdFromApi={setCustomerIdFromApi}
                        customerIdFromApi={customerIdFromApi}
                        projectDetailsForPayments={setProjectDataFromApi}
                    />
                </TabPane>
                <TabPane tab="Payment Installments" key="Payments">
                    <PaymentInstallments
                     toggleHandler={toggleHandler}
                     handleTabs={handleTabs}
                     projectDetailsForPayments={projectDataFromApi}
                    />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Addcustomers;


    // const callBack = (key) => {
    //     console.log(key);
    // }

    // const NavItems = [
    //     {
    //         id: 1,
    //         title: 'Customer Details',
    //         content: <CustomerDetails />
    //     },
    //     {
    //         id: 2,
    //         title: 'Project Details',
    //         content: <ProjectDetails />
    //     },
    //     {
    //         id: 3,
    //         title: 'Payment Installments',
    //         content: <PaymentInstallments />
    //     }
    // ]