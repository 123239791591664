import React, { useState, useEffect } from 'react';
import './ApList.css';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { GetArchitechPartnersService, partnerExecutiveServices } from '../../../../../Service/PartnerExecutiveServices';
import Searchbar from '../../../Searchbar';
import { toast } from 'react-toastify';
import { imageAssets } from '../../../../../Assets/AssetsConstants/AssetsConstants';
import Loader from '../../../../../Components/Loader';
import SimpleBarReact from 'simplebar-react';
import { dateFormatter, resetFilter, resetSelectedFilterKey, storeSelectedFilterKeys } from '../../../../../Utilities/helpers';
import Pagination from 'rc-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getArchitectPartnersLoader } from '../../../../../Features/Slices/architectPartnersListSlice';
import { Spin } from 'antd';
import NoData from '../../../../../Components/NoData/NoData';
import locale from 'rc-pagination/es/locale/en_US';
import { pagination } from '../../../../../Utilities/Pagination/paginationUtilites';
import { GETPARTNERMANAGERSLIST } from '../../../../../Features/Saga/getpartnerManagersList';
import moment from 'moment';
import { Rating } from 'react-simple-star-rating';
import ContentNavbar from '../../../../../Components/contentNavbar';
import AdvancedFilter from '../../AdvancedFilter/advancedFilter';


function ApList({ typeHandler, userId, partnerExecutive, partnerManager, allAp }) {
  const [searchInput, setSearchInput] = useState([]);
  const [enterWord, setEnterWord] = useState("");
  const [toggleBtn, setToggleBtn] = useState(false);
  const [data, setData] = useState([]);
  const [apiLoader, setApiLoader] = useState(true);
  const [check, setCheck] = useState("list");
  const [currentPage, setCurrentPage] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(8);
  const [count, setCount] = useState(0);
  const [apType, setApType] = useState("");
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [advancedFilterData, setAdvancedFilterData] = useState({
    status :[],
    assignedTo:[],
    fromScheduleDate:null,
    toScheduleDate:null,
    category:[]
  })
  const [showAdvancedFilterModal, setShowAdvancedFilterModal] = useState(false);
  const [selectedFilterVal, setSelectedFilterVal] = useState([])
  const [isFilterDataCleared, setIsFilterDataCleared] = useState(false)

  //  dispatch :
  const dispatch = useDispatch();

  //   getting data fron redux store :
  const { architectPartnersData, loading } = useSelector((state) => state.ArchitectPartnersList)
  console.log("getArchitectPartnersList::", architectPartnersData, "count:", typeof count, "loading:", loading);

  const partnerManagersList = useSelector((state) => state.getPartnerManagerListSLice.partnerManagers);
  console.log("partnerManagersList:", partnerManagersList);

  const viewDetails = (id, status) => {
    console.log('user id and status :', id, status);
    if (status === "Approved") {
      typeHandler('approved')
      userId(id)
    } else {
      if (status !== "Rejected") {
        typeHandler("edit");
        userId(id);
      } else {
        typeHandler("")
        userId(null)
      }
    }
  }

  // function for show list view:
  const showListView = () => {
    setToggleBtn(false)
    setCheck("list")
  }

  // function for show card view:
  const showCardView = () => {
    setToggleBtn(true)
    setCheck("card")
  }

  const iconHandlerFilter = async () => {
    setCheck('filter')
  }

  const iconHandlerSettings = async () => {
    setCheck('setting')
  }

  console.log( "all ap type", allAp);
  const loggedInUser = window.localStorage.getItem('user_Id');

  

  //getting Architech Partners list:
  useEffect(async () => {
    dispatch(GETPARTNERMANAGERSLIST())
  }, [currentPage,enterWord]);

  console.log('ApType:', apType);

  useEffect(() => {
    setApType(partnerExecutive || partnerManager)
  }, [partnerExecutive, partnerManager])

  useEffect(() => {
    // getArchitectList()
    getArchitectBySearchFilter()
  }, [currentPage])

  useEffect(() => {
    // getArchitectList(1)       /* Prev get list func*/
    getArchitectBySearchFilter(1)
  }, [enterWord,isFilterDataCleared])

  console.log("isFilterDataCleared---",isFilterDataCleared,"advancedFilterData----",advancedFilterData);

  /*new get AP list function with search & filter*/
  const getArchitectBySearchFilter=async(pageNo)=>{
    try {
      if(pageNo) setCurrentPage(1)
      const payload = {
        listType: 1,
        searchWord: enterWord,
        fromScheduleDate: advancedFilterData.fromScheduleDate,
        toScheduleDate: advancedFilterData.toScheduleDate,
        status: advancedFilterData.status,
        assignedTo: advancedFilterData.assignedTo,
        category: advancedFilterData.category,
        pageIndex: pageNo === 1 ? 1 : currentPage,
        numberOfRecordsPerPage: numberPerPage,
        loggedinUserId: allAp === "allAp" ? null : [loggedInUser] 
      }

      const response = await partnerExecutiveServices.getAPListService(payload);
      console.log('apListResponse:', response);
      if (response && response.status === 'success' && response.data.getArchitectListData) {
        setApiLoader(false);
        setData(response.data.architectData)
        // {enterWord && setCurrentPage(1)}
        setCount(response.count)
        setIsFilterDataCleared(false)

      } else {
        setApiLoader(false);
        setData([])
        setCount(0)
        setIsFilterDataCleared(false)

      }
    } catch (error) {
      console.log("error:",error);
    }
  }

  useEffect(() => {
    showSelectedFilterKeysOnTop()
  }, [advancedFilterData])

  /* storing object of array data into an array */
  let selectedFilterOption = []
  const val = Object.keys(advancedFilterData).map(val => selectedFilterOption.push(advancedFilterData[val]))
  console.log("selectedFilterOption-----", selectedFilterOption)

  /* displaying selected filter options*/
  const showSelectedFilterKeysOnTop = () => {
   const allSelectedFilterKeys= storeSelectedFilterKeys(selectedFilterOption)
   console.log("allSelectedFilterKeys--",allSelectedFilterKeys);    
    setSelectedFilterVal(allSelectedFilterKeys)
  }

  /* handler for remove particular filter data */
  const removeSelectedFilterKey = (item) => {
    setIsFilterDataCleared(true)
    setSelectedFilterVal(selectedFilterVal.filter(val => val !== item))
    const updatedFilteredKeys = resetSelectedFilterKey(item, advancedFilterData,setAdvancedFilterData,setIsFilterDataCleared)

}

  // useEffect(() => {
  //   console.log(architectPartnersData);
  //   setData(architectPartnersData);
  //   // setData([]);
  // }, [architectPartnersData])

  // function for closeInput:
  const closeInput = () => {
    setEnterWord("")
    document.getElementById("search").style.width = "45px";

  }

  // function for searchHandler:
  const searchHandler = (e) => {
    setEnterWord(e.target.value)
  }

  // function for pagination:
  const handleCurrentPage = (pageindex, perpage) => {
    console.log(pageindex, perpage);
    setCurrentPage(pageindex);
  }
  console.log("data::", data, currentPage);    

  return (
    <div>
      {/* {data?.length ? */}
      {/* {loading ? <Loader /> */}
      {apiLoader ? <Loader />
        :
        <div className="contentBody">
         <ContentNavbar
          listType={ allAp === "allAp" ? "ALL AP" : "AP"}
          count={count}
          enterWord={enterWord}
          setEnterWord={searchHandler}
          closeInput={closeInput}
          check={check}
          setCheck={setCheck}
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
          showDateFilter={showDateFilter}
          setShowDateFilter={setShowDateFilter}
          showCardView={showCardView}
          showListView={showListView}
          showAdvancedFilterModal={showAdvancedFilterModal}
          setShowAdvancedFilterModal={setShowAdvancedFilterModal}
          content={
            <AdvancedFilter 
            advancedFilterData={advancedFilterData}
            setAdvancedFilterData={setAdvancedFilterData}
            resetFilter={() => resetFilter
              (setAdvancedFilterData, setShowAdvancedFilterModal,
              setSelectedFilterVal, setCurrentPage, setIsFilterDataCleared)
            }
            onApplyFilterData={getArchitectBySearchFilter}
            setShowAdvancedFilterModal={setShowAdvancedFilterModal}
            />
          }
          selectedFilterData={selectedFilterVal}
          removeFilterData={removeSelectedFilterKey}
          resetFilter={() => resetFilter
            (setAdvancedFilterData, setShowAdvancedFilterModal,
            setSelectedFilterVal, setCurrentPage, setIsFilterDataCleared)
          }
          />

          <div>
            {toggleBtn ?
              <SimpleBarReact style={{ maxHeight: 710 }}>
                <div className="card-layout">
                  {/* {data.length ? */}
                  {/* <Spin spinning={}> */}
                  <div>
                    {/* <Spin spinning={loading}> */}
                    {
                      (data.length === 0 || data === undefined) ? <NoData />
                        :
                        <Grid container spacing={1} >
                          {data?.map((data, index) => {
                            return <Grid item xl={3}>
                              <Card className="card" onClick={() => { viewDetails(data.UserId, data.Status) }}>
                                <CardContent className="card-data">
                                  <Grid>
                                    <div className="cardProfileAvatar">
                                      {/* <img  className="card-data-profile" alt="card-data-profile...." /> */}
                                      {/* <img src={data.imageUrl} className="blankAvatarImg" />
                                  {<FaMedal size="20px" className="medal" />} */}
                                      <img src={data.imageUrl ? data.imageUrl : "cardBlankAvatarImg"} alt="" className="cardBlankAvatarImg" />
                                      {/* <img src="" alt="" className="competitor bronzeMedal" /> */}
                                    </div>
                                    <span className="cardProfileName">{data.FirstName + " " + data.LastName}</span>
                                    <div>
                                      <Rating size="20px"
                                       readonly={true} 
                                       allowHalfIcon
                                       ratingValue={data.OverallRating}
                                       />
                                  </div>                                    
                                  <span className="cardProfileExperience">{data.Years_of_Experience + " " + "years of experience"}</span>
                                    {/* <span className="cardProfileExperience">{data.Educational_qualification}</span> */}
                                    <div>
                                  {/* {item.status === "Approved" && */}
                                    <button className={`btn bronze ${data.categoryName === "Bronze" && "bronze" ||
                                      data.categoryName === "Silver" && "silver" ||
                                      data.categoryName === "Gold" && "gold" ||
                                      data.categoryName === "Diamond" && "diamond" ||
                                      data.categoryName === "Platinum" && "platinum"
                                      }`}>
                                      {data.categoryName ? data.categoryName : "Bronze" }
                                    </button>
                                  {/* } */}
                                </div>
                                    <span className="cardProfileExperience">{data.Aadhar}</span>
                                    <span className="cardProfileExperience">{data.Mobile}</span>


                                    {/* <p className="card-data-profile-medal">{data.category}</p> */}
                                    {/* <div className="listItem" data-head="Category">
                                  <div>
                                    <span class="bronze">Bronze</span>
                                  </div>
                                </div> */}
                                    {/* <span className="">
                                  <Rating size="25px" readonly={true} allowHalfIcon ratingValue={data.Overall_Rating} />
                                </span> */}
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          })}
                        </Grid>

                    }
                  </div>
                  {/* </Spin> */}
                  {/* :
                    <div className="noDataFoundBox">
                      <img src={nodata} className="no-data-img" />
                      <span>No Data Found</span>
                    </div>} */}
                </div>
              </SimpleBarReact>
              :
              <div class="whAppTable">
                {/* {data.length ? */}
                <div class="tableList">
                  <div className="tableHead">
                    <div className="tableRow">
                      {/* <div className="tkp-head-cb"><input type="checkbox" /></div> */}
                      <div className="listItem userName">Name</div>
                      {/* <div className="listItem alignItemsC">Category</div> */}
                      {/* <div className="listItem" >Address</div> */}
                      <div className="listItem mobile">Mobile</div>
                      <div className="listItem aadhaar">Aadhar</div>
                      <div className="listItem submitted">Scheduled Interview</div>
                      <div className="listItem submitted">{apType === "partnerManager" ? "Evaluated By" : "Assigned To"}</div>
                      <div className="listItem status alignItemsC">Status</div>
                      {/* <div className="listItem" >Assigned To</div> */}
                    </div>
                  </div>
                  {
                    (data.length === 0 || data === undefined) ? <NoData />
                      :
                      <SimpleBarReact style={{ maxHeight: 660 }}>
                        {/* <Spin spinning={loading} > */}
                        {data.map((item, index) => {
                          return <div className="tableRow" onClick={() => { viewDetails(item.UserId, item.Status) }}>
                            {/* <div className="tkp-table-cb"><input type="checkbox" /></div> */}
                            <div className="listItem userName" data-head="Name">
                              <div class="d-flex">
                                {/* <img src={imageAssets.noData} className="pic" alt="... " /> */}
                                <img src={item.imageUrl ? item.imageUrl : "blankAvatarImg"} className="blankAvatarImg" />
                                <div class="personName">
                                  <span>{item.FirstName + " " + item.LastName}</span>
                                  <div>
                                    {(item.Status === 'Approved' || item.Status === 'Rejected' || item.Status === 'Processing') &&
                                      <Rating size="20px" readonly={true} allowHalfIcon ratingValue={item.OverallRating} />}
                                  </div>
                                  <div >
                                    {(item.Status === 'Approved' || item.Status === 'Processing') &&
                                      <button className={`btn bronze ${item.CategoryName === "Bronze" && "bronze" ||
                                        item.CategoryName === "Silver" && "silver" ||
                                        item.CategoryName === "Gold" && "gold" ||
                                        item.CategoryName === "Diamond" && "diamond" ||
                                        item.CategoryName === "Platinum" && "platinum"
                                        }`}>
                                        {item.CategoryName ? item.CategoryName : "Bronze"}
                                      </button>}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="listItem alignItemsC" data-head="Category">
                          <div>
                            <span class="bronze">Bronze</span>
                          </div>
                        </div> */}
                            <div className="listItem mobile" data-head="Mobile"> {item.Mobile}</div>
                            <div className="listItem aadhaar" data-head="Aadhar"> {item.Aadhar}</div>
                            <div className="listItem submitted" data-head="Submitted"> {item.InterviewDate === null || item.InterviewTime === null ? 'Not Scheduled' : moment(item.InterviewDate).format("D MMM, YYYY") + " " + moment(item.InterviewTime, 'hh:mm a').format('hh:mm a')}</div>
                            <div className="listItem submitted" data-head="">{partnerExecutive === 'partnerExecutive' ?
                              <span>{item.EvaluatedBy}</span> :
                              <span>
                                {allAp === 'allAp' ? <span> {(item.Status === 'Approved' || item.Status === 'Rejected' || item.Status === 'Processing') ? <span>{item.EvaluatedBy}</span> : <span>&#8212;</span>} </span> :
                                  <span>{item.assignedBy}</span>}
                              </span>
                            }</div>
                            <div className="listItem status alignItemsC" data-head="Status">
                              <div className={`btn rounded-pill   ${item.Status === "Draft" && "Draft" ||
                                item.Status === "Rejected" && "Rejected" ||
                                item.Status === "Processing" && "Processing" ||
                                item.Status === "Pending" && "Pending" ||
                                item.Status === "Approved" && "Approved"
                                }`}
                                data-head="Status"> {item.Status}</div>
                            </div>
                            {/* <div className="listItem">
                          <span className="nameInitial">JS</span>
                          {item.assignedBy}
                        </div> */}
                            {/* <div className="tkp-table-dots">
                          <img src={imageAssets.menuDots} className="dots-icon" alt />
                        </div> */}
                          </div>
                        })}
                        {/* </Spin> */}
                      </SimpleBarReact>
                  }

                </div>
                {/* : <div className="noDataFoundBox">
                      <img src={nodata} />
                      <span>No Data Found</span>
                    </div>} */}
              </div>
            }
            {/* <div className="data-result">
                {searchInput.length > 1 && searchInput.map((val, key) => {
                  return <a><p>{val.name}</p></a>
                })}
              </div> */}
            <Pagination
              current={currentPage}
              pageSize={numberPerPage}
              showSizeChanger
              onChange={handleCurrentPage}
              total={count}
              hideOnSinglePage={true}
              className="pagination-data"
              locale={locale}
              itemRender={pagination.textItemRender}
            />
          </div>

        </div>

     } 
    </div>

  )
}
export default ApList;
{/* :
        <div>
          <div className="noDataFoundBox">
            {
              apiLoader ? (<Loader />
              )
                :
                <>
                  <img src={imageAssets.noData} className="no-data-img" />
                  <span>No Data Found</span>
                </>
            }
          </div>
        </div> */}

      //   <div className="contentNavbar">
      //   <div className="">
     
      //     <Searchbar closeInput={closeInput} enterWord={enterWord} setEnterWord={searchHandler} check={check} setCheck={setCheck} />
      //   </div>
      //   <div class="groupBtns">
      //     <button type="button" class="btn toggleBtn">
      //       <div className={`toggleBtnIcon ${check === "list" ? "ActiveIcon" : " "}`} onClick={showListView}>
          
      //         <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M7,6H23a1,1,0,0,0,0-2H7A1,1,0,0,0,7,6Z" /><path d="M23,11H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" /><path d="M23,18H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" /><circle cx="2" cy="5" r="2" /><circle cx="2" cy="12" r="2" /><circle cx="2" cy="19" r="2" /></svg>
      //       </div>
      //       <div className="vLine"></div>
      //       <div className={`toggleBtnIcon ${check === "card" ? "ActiveIcon" : " "}`} onClick={showCardView}>
            
      //         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="" height="20"><g id="_01_align_center" data-name="01 align center"><path d="M11,11H0V3A3,3,0,0,1,3,0h8ZM2,9H9V2H3A1,1,0,0,0,2,3Z" /><path d="M24,11H13V0h8a3,3,0,0,1,3,3ZM15,9h7V3a1,1,0,0,0-1-1H15Z" /><path d="M11,24H3a3,3,0,0,1-3-3V13H11ZM2,15v6a1,1,0,0,0,1,1H9V15Z" /><path d="M21,24H13V13H24v8A3,3,0,0,1,21,24Zm-6-2h6a1,1,0,0,0,1-1V15H15Z" /></g></svg>
      //       </div>
      //     </button>
      
      //     <button type="button" class={`btn btnDefault ${check === "filter" ? "ActiveIcon" : ""}`} onClick={iconHandlerFilter}>
      //       <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M1,4.75H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2ZM7.333,2a1.75,1.75,0,1,1-1.75,1.75A1.752,1.752,0,0,1,7.333,2Z" /><path d="M23,11H20.264a3.727,3.727,0,0,0-7.194,0H1a1,1,0,0,0,0,2H13.07a3.727,3.727,0,0,0,7.194,0H23a1,1,0,0,0,0-2Zm-6.333,2.75A1.75,1.75,0,1,1,18.417,12,1.752,1.752,0,0,1,16.667,13.75Z" /><path d="M23,19.25H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2ZM7.333,22a1.75,1.75,0,1,1,1.75-1.75A1.753,1.753,0,0,1,7.333,22Z" /></svg>
      //     </button>
      
      //     <button type="button" class={`btn btnDefault ${check === "setting" ? "ActiveIcon" : ""}`} onClick={iconHandlerSettings}>
      //       <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" /><path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" /></svg>
      //     </button>
      //   </div>
      // </div>

/*Prev Get List Func*/
      // const getArchitectList= async (pageNo)=>{
      //   try {
      //     let searchData = {
      //         listType: 1,
      //         searchWord:enterWord,
      //         pageIndex:pageNo === 1 ? 1 : currentPage, 
      //         numberPerPage: numberPerPage,
      //         logginUserId: allAp === "allAp" ? null : loggedInUser 
      //     }
    
      //     const response = await partnerExecutiveServices.GetArchitechPartnersService(searchData);
      //     console.log('apListResponse:', response);
      //     if (response.status === 'success' && response.data.getarchitectlist) {
      //       setApiLoader(false);
      //       setData(response.data.Architechs_list)
      //       {enterWord && setCurrentPage(1)}
      //       setCount(response.data.count)
    
      //     } else {
      //       setApiLoader(false);
      //       setData([])
      //       setCount(0)
      //     }
      //   } catch (error) {
      //     console.log("error:",error);
      //   }
      // }