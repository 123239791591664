import axios from "axios";
import instanceAll from './CommonAxiosInstance';

// Reset password Request Services Api:
export const postForgotService= async (body)=>
{
   
    // try{
    //     return axios.post('https://qaapi.wehouse.in/ResetPasswordRequest',body,
    //     {
    //         headers:{
    //             'Content-Type': 'application/json',
    //         }
    //     }).then(function (response)
    //     {
    //         console.log('respone-->',response);
    //         if (response.status===200)
    //         {
    //             return{
    //                 status:"sucess",
    //                 data:response.data
    //             }
    //         }
    //     }).catch(function (error){
    //         console.log(error)
    //     })
    
    //  }catch(err){
    //   console.log('err')
    //   return{status:"error"}
    //  }

        // Adding baseURL to ResetPasswordRequest Api services:

    try {
        let response = await  instanceAll.post("/ResetPasswordRequest",body);
        console.log("ResetPasswordRequest:::",response);
           if (response?.status===200)
              {
                  return{
                      status:"success",
                      data:response.data,
                  }
              }
            else {
                console.log("Something Went Wrong");
            }
      }catch(err){
          console.log(err);
      }

 }

//  Reset Password Api:
 export const resetPasswordServices = async (body)=>{
    //  try {
    //      return axios.post('https://qaapi.wehouse.in/ResetPassword',body, 
    //      {
    //          headers:{
    //             'Content-Type': 'application/json',
    //          }
    //      }).then(function (response)
    //      {
    //          console.log('passwordRespone-->',response);
    //          if (response.status===200)
    //          {
    //              return{
    //                  status:"sucess",
    //                  data:response.data
    //              }
    //          }
    //      }).catch(function (error){
    //         console.log(error)
    //     })
    //  }catch(err){
    //    console.log("error")
    //    return{status:"error"}
    //  }

        // Adding baseURL to ResetPassword Api services:

    try {
        let response = await  instanceAll.post("/ResetPassword",body);
        console.log("ResetPassword:::",response);
           if (response?.status===200)
              {
                  return{
                      status:"success",
                      data:response.data,
                  }
              }
            else {
                console.log("Something Went Wrong");
            }
      }catch(err){
          console.log(err);
      }
 }