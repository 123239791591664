import instanceAll from './CommonAxiosInstance';

// Add wehouseUsers Api :
const AddWehouseUserServices = async (body) => {
  try {
    let response = await instanceAll.post("/addUser", body);
    console.log("AddWehouseUsers:::", response);
    if (response.status === 200 && response.data.isUserCreated) {
      return {
        status: "success",
        data: response.data
      }
    }
    else {
      return {
        staus: "failed",
        data: response.data
      }
    }

  } catch (err) {
    console.log(err);
  }
}

// get wehouse users list Api:
const getWehosueUsersList = async (body) => {
  // console.log("we house user list",body);
  try {
    let response = await instanceAll.post("/get-users-list", body);
    console.log("GetWehouseUsers:::", response);
    if (response.data.status === 200) {
      return {
        status: "success",
        data: response?.data,
        count: response?.data.count,
        message: response?.data.message

      }
    }

  } catch (err) {
    console.log(err);
  }
}





//get wehouse users by id:

const getWeHouseUser = async (body) => {
  try {
    console.log("getWeHouseUser----");
    let response = await instanceAll.post("/get-wehouse-user-ById", body);
    console.log('get wehouse user--', response);
    if (response.status === 200) {
      return ({
        status: "success",
        data: response.data
      })
    }
    else {
      return ({
        status: "error",
        message: " failed to update"
      })
    }

  }
  catch (error) {
    return ({
      status: -1,
      message: error
    })
  }
}

const updateWeHouseUser = async (body) => {
  console.log("update ", body);
  try {
    let response = await instanceAll.post("/update-Wehouse-User", body)
    console.log("update wehouse user:: ", response);
    if (response.status === 200 && response?.data?.updateUser) {
      return ({
        status: "success",
        data: response?.data
      })
    }
     else if(response.status === 200 && response.data.isdata && response.data.isCityChanged){
      return({
        status:"success" ,
        data: response?.data
      })

    }
    else if(response.status === 200 && response.data.isDesignationChange){
       return (
        {
          status : "success",
          data : response?.data
        }
       )
    } 
    else if(response.status === 200 && response?.data?.isdata){
       return({
        status:"success",
        data: response?.data
       })
    }
    else {
      return ({
        status: "error",
        message: " failed to update "
      })
    }

  }
  catch (error) {
    return ({
      status: -1,
      message: error
    })

  }
}

/* get Reporting managers List */
const getReportingManagersService = async (body) => {
  try {
    const response = await instanceAll.post("/get-reporting-manager-list", body);
    if (response.status === 200 && response.data.isGetReportingManagerList) {
      return (
        {
          status: "success",
          options: response.data.options

        }
      )
    }
    else {
      return (
        {
          status: "failed",
          options: []

        }
      )
    }

  } catch (error) {
    return (
      {
        status: "failed",
        message: error
      }
    )
  }
}

// get wehouse users list Api:
const getWehosueUsersListBySearch = async (body) => {
  // console.log("we house user list",body);
  try {
    let response = await instanceAll.post("/get-users-by-search", body);
    console.log("GetWehouseUsersBySearch:::", response);
    if (response.data.status === 200) {
      return {
        status: "success",
        data: response?.data,
        count: response?.data.count,
        message: response?.data.message

      }
    }else{
      return(
        {
          status:"failed",
          data :'No data found',
          message :response?.data.message,
        }
      )
    }

  } catch (err) {
    console.log(err);
  }
}

const wehouseUsersApi = {
  AddWehouseUserServices,
  getWehosueUsersList,
  getWeHouseUser,
  updateWeHouseUser,
  getReportingManagersService,
  getWehosueUsersListBySearch
}

export default wehouseUsersApi;