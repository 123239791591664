import { Menu, Radio, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import ContentNavbar from '../../../../../Components/contentNavbar';
import NoData from '../../../../../Components/NoData/NoData';
import { dateFormatter } from '../../../../../Utilities/helpers';
import SimpleBarReact from 'simplebar-react';
import { getMyLeadActivityList } from '../../../../../Service/MyLeadsService';
import { toast } from 'react-toastify';
import moment from 'moment';
import GenericCallDetailsModal from './GenericCallDetailsModal/GenericCallDetailsModal';
import Pagination from 'rc-pagination';
import { pagination } from '../../../../../Utilities/Pagination/paginationUtilites';
import locale from 'rc-pagination/es/locale/en_US';

const GenericCallDetails = ({ leadId, activeTab }) => {
    console.log("call lead id", leadId);
    const [callModal, setCallModal] = useState(false);
    const [selectedCall, setSelectedCall] = useState({});
    const [leadCallList, setLeadCallList] = useState([]);
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [numberPerPage, setNumberPerPage] = useState(8);
    const [count, setCount] = useState(0);
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [showDateFilter, setShowDateFilter] = useState(false);
    const options = [
        {
            label: "All",
            value: ""
        },

        {
            label: "Cold", value: 4

        },
        {
            label: "Hot", value: 5

        },
        {
            label: "Future", value: 6

        },
        {
            label: "Discarded", value: 3

        },
        {
            label: "Lost", value: 7

        }
    ]
    const items = [
        {
            label: <Radio.Group value={filter} options={options} className="items-wrapper" />,
            key: "1"
        }


    ]
    const menu = (
        <Menu items={items} />
    );
    const getLeadCallList = async (dateRange, activity, pageNo) => {
        try {
            setShowDateFilter(false);
            let body;
            if (pageNo) {
                setCurrentPage(1);
                body = {
                    leadId: leadId,
                    dateFrom: dateRange?.startDate ? moment(dateRange?.startDate).format('YYYY-MM-DD') : null,
                    dateTo: dateRange.endDate ? moment(dateRange?.endDate).format('YYYY-MM-DD') : null,
                    activityType: "Schedule Call",
                    pageIndex: pageNo,
                    numberPerPage: numberPerPage
                }
            }
            else {
                body = {
                    leadId: leadId,
                    dateFrom: dateRange?.startDate ? moment(dateRange?.startDate).format('YYYY-MM-DD') : null,
                    dateTo: dateRange.endDate ? moment(dateRange?.endDate).format('YYYY-MM-DD') : null,
                    activityType: "Schedule Call",
                    pageIndex: currentPage,
                    numberPerPage: numberPerPage
                }

            }
            const response = await getMyLeadActivityList(body)
            if (response.status === "success") {
                setLeadCallList(response.data);
                setCount(response.count);
            }
            else {
                setLeadCallList([]);
                // toast.error('something went wrong')
            }

        } catch (error) {
            console.log('call ', error);
            setLeadCallList([]);
            toast.error('something went wrong')
        }
    }

    // function for pagination:
    const handleCurrentPage = (pageindex, perpage) => {
        console.log(pageindex, perpage);
        setCurrentPage(pageindex);
    }


    const closeModal = () => {
        setCallModal(false);

    }
    const handleCancel = () => {
        getLeadCallList(selectionRange[0], null, 0);
        closeModal();
    }
    const handleModal = (callDetails) => {

        setSelectedCall(callDetails);
        setCallModal(true);

    }
    useEffect(() => {
        getLeadCallList(selectionRange[0], null, 0);
    }, [currentPage, activeTab])




    return (
        <div className="whContentBlock">
            <div className='contentBody'>
                <ContentNavbar
                    leadsData={null}
                    setSearchInput={null}
                    menu={menu}
                    setShowDateFilter={setShowDateFilter}
                    showDateFilter={showDateFilter}
                    onApplyFilter={getLeadCallList}
                    selectionRange={selectionRange}
                    setSelectionRange={setSelectionRange}
                    count={count}
                    listType="Calls"
                />
                <div className='whAppTable'>
                    <div className="tableList">
                        <div className="tableHead">
                            <div className="tableRow">
                                <div className="listItem sNo text-center">S.No</div>
                                <div className="listItem startDate">DATE TIME</div>
                                <div className="listItem tastTitle">TASK TITLE</div>
                                <div className="listItem status">STATUS</div>
                            </div>
                        </div>
                        {(leadCallList?.length === 0 || leadCallList === undefined || leadCallList === null) ?
                            <NoData />
                            :
                            <>
                                <SimpleBarReact style={{ maxHeight: 640 }}>
                                    {leadCallList?.map((item, index) => {
                                        return (
                                            <div className="tableRow" onClick={() => handleModal(item)}>
                                                <div className="listItem sNo text-center" data-head="S.No">
                                                    <span>
                                                        {/* {index + 1} */}
                                                        {

                                                            ((currentPage - 1) * numberPerPage) + index + 1
                                                        }
                                                    </span>
                                                </div>
                                                <div className="listItem startDate" data-head="START DATE">
                                                    {
                                                        dateFormatter(item?.appointmentDate, "D MMM, YYYY ")
                                                    },
                                                    {
                                                        // dateFormatter(item?.appointmentTime, "HH:mm a")
                                                        moment(item?.appointmentTime, 'hh:mm a').format('hh:mm a')
                                                    }

                                                </div>

                                                <div className="listItem tastTitle" data-head="TASK TITLE">
                                                    {item.activityName}
                                                </div>
                                                <div className="listItem status" data-head="STATUS">
                                                    <button className={`btn rounded-pill  Active`}
                                                    >
                                                        {
                                                            item?.activityState
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </SimpleBarReact>
                            </>
                        }
                    </div>
                </div>
                <Pagination
                    current={currentPage}
                    pageSize={numberPerPage}
                    total={count}
                    hideOnSinglePage={true}
                    showSizeChanger
                    onChange={handleCurrentPage}
                    className="pagination-data"
                    locale={locale}
                    itemRender={pagination.textItemRender}
                />
            </div>
            <Modal
                visible={callModal}
                onOk={handleCancel}
                onCancel={handleCancel}
                footer={null}
                className="customModal"
                title={"My call Details"}
            >
                <GenericCallDetailsModal
                    selectedCall={selectedCall}
                    handleCancel={handleCancel}
                />
            </Modal>

        </div>
    )
}

export default GenericCallDetails


