import { createSlice } from "@reduxjs/toolkit";

const getRolesListSlice=createSlice({
     name:"Roles",
     initialState :{
         roles:[],
         error:null
     },
     reducers:{
         getRolesSuccess : (state,action)=>{
             console.log("action:",action.payload);
             state.roles = action.payload
         },
         getRolesFailure : (state,action)=>{
             state.error = action.payload
         }
     },

})

export const {getRolesSuccess,getRolesFailure} = getRolesListSlice.actions;

export default getRolesListSlice.reducer;