import React, { useState } from 'react';
import { BrowserRouter as Router, NavLink } from 'react-router-dom'
import { IoMdAdd } from 'react-icons/io';
import { BiLeftArrowAlt } from 'react-icons/bi';
import AddIp from './AddInteriorPartners/AddIp';
import IpList from './IpLIst/IpList';
import { Tooltip } from 'antd';

function InteriorPartners() {
  const [toggleBtn, setToggleBtn] = useState(false);
  const [type, setType] = useState('');
  const [userId, setUserId] = useState('');

  console.log(userId);

  const typeHandler = (type) => {
    setType(type)
    // console.log(type);
  }

  return (
    <div className="mainContent">
      <div className="contentHeader">
        {(type === "edit" || type === "add") ?
            <div>
              {(type === "edit" ? <span class="headerTitle">Interior Partner Details</span>
              : <span class="headerTitle"> Add Interior Partner</span>
              )}
            </div>
            :
         <span class="headerTitle">Interior Partners</span>
        }
        <button type="button" className="btn whBtnPrimary">
          {(type === "edit" || type === "add") ?
          <Tooltip title="Interior Partners">
            <span className="btnText" onClick={()=>{typeHandler("")}}>
              <NavLink to="#">
                <BiLeftArrowAlt size="20px" className=""/>
                Back 
              </NavLink>
            </span>
            </Tooltip> 
             :
              <span className="btnText" onClick={()=>{typeHandler("add")}}>
                <IoMdAdd size="20px" className=""/>
                Interior Partner
              </span>
           }
        </button>
      </div>
      <div className="whContentBlock">
      {(type === "edit" || type === "add") ? <AddIp type={type} userId={userId} typeHandler={typeHandler}/> :
       <IpList typeHandler={typeHandler} userId={setUserId}/>}
      </div>
    </div>
  )
}

export default InteriorPartners;