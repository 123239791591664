import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Searchbar from '../../Searchbar';
import { toast } from 'react-toastify';
import Loader from '../../../../Components/Loader';
import { useHistory } from 'react-router-dom';
import { dateFormatter, resetFilter, resetSelectedFilterKey, storeSelectedFilterKeys } from '../../../../Utilities/helpers';
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css"
import { useDispatch, useSelector } from 'react-redux';
import { TurnkeyPartnersLoader } from '../../../../Features/Slices/turnkeyPartnersListSlice';
import { Modal, Button } from 'antd';
import TkpApprovalsModal from './TkpApprovalsModal';
import { Link, NavLink } from 'react-router-dom';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { partnerManagerApis } from '../../../../Service/PartnerManagerServices';
import Pagination from 'rc-pagination';
import { partnerExecutiveServices } from '../../../../Service/PartnerExecutiveServices';
import NoData from '../../../../Components/NoData/NoData';
import locale from 'rc-pagination/es/locale/en_US';
import { pagination } from '../../../../Utilities/Pagination/paginationUtilites';
import ContentNavbar from '../../../../Components/contentNavbar';
import AdvancedFilter from '../../partnerExecutive/AdvancedFilter/advancedFilter';
import { Rating } from 'react-simple-star-rating';


function TkpApprovals({ typeHandler, userId, partnerExecutive, partnerManager }) {
  const [searchInput, setSearchInput] = useState([]);
  const [toggleBtn, setToggleBtn] = useState(false);
  const [data, setData] = useState([])
  const [check, setCheck] = useState('list');
  const [apiLoader, setApiLoader] = useState(true);
  const [ratingValue, setRatingValue] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [outcome, setOutcome] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [rejecteMsg, setRejecteMsg] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(8);
  const [politenessRating, setPolitenessRating] = useState(0);
  const [hospitalityRating, setHospitalityRating] = useState(0);
  const [siteMaintanenaceRating, setSiteMaintanenaceRating] = useState(0);
  const [willingRating, setWillingRating] = useState(0);
  const [overAllRating, setoverAllRating] = useState(0);
  const [tkpType, settkpType] = useState("");
  const [count, setCount] = useState(1);
  const [checkActive, setCheckActive] = useState("");
  const [enterWord, setEnterWord] = useState("")
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [advancedFilterData, setAdvancedFilterData] = useState({
    status :[],
    assignedTo:[],
    fromScheduleDate: null,
    toScheduleDate: null,
    category:[]
  })
  const [showAdvancedFilterModal, setShowAdvancedFilterModal] = useState(false);
  const [selectedFilterVal, setSelectedFilterVal] = useState([])
  const [isFilterDataCleared, setIsFilterDataCleared] = useState(false)

  const history = useHistory();
  const dispatch = useDispatch();

  // getting data from redux state :
  const { TurnkeyPartnersData, loading } = useSelector(state => state.TurnkeyPartners)
  console.log("TurnKeyPartnersData==", TurnkeyPartnersData, loading);

  console.log("checkActive link:::", checkActive);

  {/*getting filtered data through searchInput */ }
  // console.log('serachinput :::', searchInput);

  const UserId = Number(window.localStorage.getItem("user_Id"));
  // console.log(UserId);

  // sending selectedUserdata through navigation state:
  const onboardingProcess = () => {
    history.push("/landing/partnerManager/tkpOnboardingProcess",
      { selectedUserId: selectedUser.UserId, navigationType: "tkpPendingApprovals" })
  }

  console.log("selectedUSer:", selectedUser);

  window.localStorage.setItem('selectedUserId', selectedUser.UserId)

  // function for show modal:
  const showModal = (index) => {
    if (data[index].Status === "Rejected") {
      setIsModalVisible(false)
    } else {
      setIsModalVisible(true)
    }
    console.log('index:', index);
    setSelectedUser(data[index])
    setPolitenessRating(data[index].Politeness)
    setHospitalityRating(data[index].Hospitality)
    setSiteMaintanenaceRating(data[index].SiteMaintenance)
    setWillingRating(data[index].Willingness)
    setoverAllRating(data[index].OverAll)
  }

  // function for show list view:
  const showListView = () => {
    setToggleBtn(false)
    setCheck("list")
  }

  // function for show card view:
  const showCardView = () => {
    setToggleBtn(true)
    setCheck("card")
  }

  // function for show list view:
  const filterHandler = () => {
    setCheck("filter")
  }

  // function for show card view:
  const settingsHandler = () => {
    setCheck("setting")
  }

  //  Function for cancel:
  const handleCancel = () => {
    setIsModalVisible(false)
    resetHandler();
    setRejecteMsg("")

    setPolitenessRating(0)
    setHospitalityRating(0)
    setSiteMaintanenaceRating(0)
    setWillingRating(0)

    // getTkpApprovedList()        /*Prev get pending list function*/
    getTkpPendingListBySearchFilter()

  }

  const handleOk = () => {
    // setIsModalVisible(false)
  }

  // Function for View Details:
  const ViewDetails = (id) => {
    console.log("userId:", id);
  }

  const resetHandler = () => {
    setOutcome("")
  }

  const outComeHandler = (status) => {
    setOutcome(status)
    if (status === "approved") {
      addRatingsHandler()
    } else {
      tkpRejectHandler()
    }
  }

  console.log('outcome:', outcome);

  // calculating over all rating:
  const totalRating = politenessRating + hospitalityRating + siteMaintanenaceRating + willingRating;
  const overAll = totalRating / 4;
  console.log("overAllRating", overAll);

  //  console.log("politenessRating:",politenessRating);
  //  console.log("hospitalityRating:",hospitalityRating);
  //  console.log("siteMaintanenaceRating:",siteMaintanenaceRating);
  //  console.log("willingRating:",willingRating);


  // Add star ratings :
  const addRatingsHandler = async (e) => {
    console.log("add rating handler", politenessRating)

    if (formValidation()) {

      let ratingsData = {
        userId: selectedUser.UserId,
        politenessRating: politenessRating,
        hospitalityRating: hospitalityRating,
        sitemaintenanceRating: siteMaintanenaceRating,
        willingnessRating: willingRating,
        overallRating: overAll
      }

      const ratingsResponse = await partnerManagerApis.addTkpRatingsService(ratingsData);
      console.log("ratingsResponse:", ratingsResponse);
      if (ratingsResponse?.addTKPRatings === true) {
        onboardingProcess()
      }
      setCheckActive("active")
    } else {
      toast.error("Please give ratings")
    }
    dispatch(TurnkeyPartnersLoader({ listType: 0, pageIndex: currentPage, numberPerPage: numberPerPage }))

  }

  // function for reject the user:
  const tkpRejectHandler = async (e) => {
    e.preventDefault()

    if (formValidation() && rejecteMsg !== "") {
      if (outcome === "rejected") {

        let rejectedUserData = {
          userId: selectedUser.UserId,
          pmId: Number(UserId),
          remarks: rejecteMsg,
          politeness: politenessRating,
          hospitality: hospitalityRating,
          siteMaintenance: siteMaintanenaceRating,
          willingnessTocarryWehouse: willingRating,
          overAll: overAll
        }

        console.log("rejectedUserData::", rejectedUserData);
        const response = await partnerManagerApis.tkpOnboardingProcessReject(rejectedUserData)
        console.log("tkpOnboardingProcessReject", response);
        if (response.status === "success" && response.data.isRejected === true) {
          toast.success("Rejected Successfuly")
          // getTkpApprovedList()
          getTkpPendingListBySearchFilter()
        } else {
          // 
        }
      }
      handleCancel()
    } else {
      toast.error("Please fill the mandatory field")
    }
  }


  {/*Function for go to view Details page */ }
  // const ViewDetails = (id) => {
  //   // history.push('/landing/admin/addWeHouseUsers')
  //   typeHandler("edit");
  //   userId(id);
  //   setIsModalVisible(true)
  //   // dispatch(TurnkeyPartnersLoader({userId:id}))
  // }


  //getting Tkp list :
  // useEffect(async () => {
  //   dispatch(TurnkeyPartnersLoader({listType:0,pageIndex:currentPage ,numberPerPage:numberPerPage}))
  // }, [currentPage])  

  useEffect(async () => {
    settkpType(partnerExecutive || partnerManager)
    tkpRejectHandler()
  }, [currentPage, enterWord])

useEffect(() => {
  // getTkpApprovedList()
  getTkpPendingListBySearchFilter()
}, [currentPage])

useEffect(() => {
  // getTkpApprovedList(1)
  getTkpPendingListBySearchFilter(1)
}, [enterWord,isFilterDataCleared])

console.log("isFilterDataCleared---",isFilterDataCleared);
/*new tkp pending list function with search & filter data*/

const getTkpPendingListBySearchFilter =async (pageNo)=>{
  try {
    console.log("pageNo---",pageNo);
    if(pageNo) setCurrentPage(1)
       const payload={
        listType: 0,
        searchWord:enterWord ,
        fromScheduleDate: advancedFilterData.fromScheduleDate,
        toScheduleDate: advancedFilterData.toScheduleDate,
        status:advancedFilterData.status,
        assignedTo:advancedFilterData.assignedTo,
        category:[],  
        pageIndex: pageNo === 1 ? 1 :currentPage,
        numberPerPage: numberPerPage,
        loggedinUserId:[UserId] 
      }
    const response = await partnerExecutiveServices.getTKPListService(payload);
    console.log('gettkpResponse::', response);
    if (response.status === "success" && response.data.getTkpListData) {
      setApiLoader(false);
      setData(response.data.tkpData)
      // {enterWord && setCurrentPage(1)}
      setCount(response.count)
      setIsFilterDataCleared(false)
      setShowAdvancedFilterModal(false)
    } else {
      setApiLoader(false);
      setData([]);
      setCount(0)
      setIsFilterDataCleared(false)
      setShowAdvancedFilterModal(false)
      // toast.error('failed to fetch the users list')
    }
  } catch (error) {
     console.log(error);
  }
}

useEffect(() => {
  showSelectedFilterKeysOnTop()
}, [advancedFilterData])

/* storing object of array data into an array */
let selectedFilterOption = []
const val = Object.keys(advancedFilterData).map(val => selectedFilterOption.push(advancedFilterData[val]))
console.log("selectedFilterOption-----", selectedFilterOption)

/* displaying selected filter options*/
const showSelectedFilterKeysOnTop = () => {
 const allSelectedFilterKeys= storeSelectedFilterKeys(selectedFilterOption)
 console.log("allSelectedFilterKeys--",allSelectedFilterKeys);    
  setSelectedFilterVal(allSelectedFilterKeys)
}

/* handler for remove particular filter data */
const removeSelectedFilterKey = (item) => {
  setIsFilterDataCleared(true)
  setSelectedFilterVal(selectedFilterVal.filter(val => val !== item)) 
  resetSelectedFilterKey(item, advancedFilterData,setAdvancedFilterData)

}


  // function for searchHandler :
  const searchHandler = (e) => {
    setEnterWord(e.target.value)
  }


  // function for closeInput:
  const closeInput = () => {
    setEnterWord("")
    // closeTkpListBySearch()
  }

  // function for pagination:
  const handleCurrentPage = (pageindex, perpage) => {
    console.log(pageindex, perpage);
    setCurrentPage(pageindex);
  }
  console.log("data::", data, currentPage);

  
  //   form validation :
  const formValidation = () => {
    let IsValid = true;

    if (!politenessRating) {
      IsValid = false
    }
    if (!hospitalityRating) {
      IsValid = false
    }
    if (!siteMaintanenaceRating) {
      IsValid = false
    }
    if (!willingRating) {
      IsValid = false
    }
    return IsValid
  }


  return (
    <div>
      {apiLoader ? <Loader /> :
        <div>
          <div className="mainContent">
            <div className="contentHeader">
              <span class="headerTitle">Turnkey Partner Approvals</span>
            </div>
          </div>
          <div className="whContentBlock">
              <div className="contentBody">
              <ContentNavbar 
                 listType="TKP Approvals"
                 count={count}
                 closeInput={closeInput}
                 enterWord={enterWord}
                 setEnterWord={searchHandler}
                 check={check}
                 setCheck={setCheck}
                 selectionRange={selectionRange}
                 setSelectionRange={setSelectionRange}
                 showDateFilter={showDateFilter}
                 setShowDateFilter={setShowDateFilter}
                 showCardView={showCardView}
                 showListView={showListView}
                 showAdvancedFilterModal={showAdvancedFilterModal}
                 setShowAdvancedFilterModal={setShowAdvancedFilterModal}
                 content={
                  <AdvancedFilter 
                  advancedFilterData={advancedFilterData}
                  setAdvancedFilterData={setAdvancedFilterData}
                  type="pending"
                  resetFilter={() => resetFilter
                    (setAdvancedFilterData, setShowAdvancedFilterModal,
                    setSelectedFilterVal, setCurrentPage, setIsFilterDataCleared)
                  }
                  onApplyFilterData={getTkpPendingListBySearchFilter}
                  setIsFilterDataCleared={setIsFilterDataCleared}
                  />
                 }
                 selectedFilterData={selectedFilterVal}
                 removeFilterData={removeSelectedFilterKey}
                 resetFilter={() => resetFilter
                  (setAdvancedFilterData, setShowAdvancedFilterModal,
                  setSelectedFilterVal, setCurrentPage, setIsFilterDataCleared)
                }
 
              />
              {/* <SimpleBarReact style={{ maxHeight: 640 }}> */}
              {toggleBtn ?
                <SimpleBarReact style={{ maxHeight: 710 }}>
                  <div className="card-layout">
                    {/* {data.length ? */}
                    <div>
                      {(data.length === 0 || data === undefined) ? <NoData /> :
                        <Grid container spacing={1} className="">
                          {data.map((data, index) => {
                            return <Grid item xl={3}>
                              <Card className="card">
                                <CardContent className="card-data">
                                  <Grid>
                                    <div className="cardProfileAvatar">
                                      {/* <img src={imageAssets.noData} className="card-data-profile" alt="card-data-profile...." /> */}
                                      {/* <img src="" className="blankAvatarImg" />
                                       {<FaMedal size="20px" className="medal" />} */}
                                      <img src={data.imageUrl ? data.imageUrl : "cardBlankAvatarImg"} alt="" className="cardBlankAvatarImg" />
                                      {/* <img src="" alt="" className="competitor bronzeMedal"/> */}
                                    </div>
                                    <span className="cardProfileName">{data.FirstName + " " + data.LastName}</span>
                                    <Rating size={"20px"}
                                      ratingValue={data.OverAll}
                                      readonly
                                      allowHalfIcon
                                      allowHover={true}
                                    />
                                    <div>
                                      {/* {data.Status === "Approved" &&   */}
                                      <button className={`btn bronze ${data.categoryName === "Bronze" && "bronze" ||
                                        data.categoryName === "Silver" && "silver" ||
                                        data.categoryName === "Gold" && "gold" ||
                                        data.categoryName === "Diamond" && "diamond" ||
                                        data.categoryName === "Platinum" && "platinum"
                                        }`}
                                      >{data.CategoryName ? data.CategoryName : "Bronze"}</button>
                                      {/* } */}
                                    </div>
                                    <span className="cardProfileExperience">{data.Years_of_Experience + " " + "years of experience"}</span>
                                    <span className="cardProfileExperience">{data.Educational_qualification}</span>
                                    <span className="cardProfileExperience">{data.Mobile}</span>
                                    <span className="cardProfileExperience">{data.Aadhar}</span>
                                    <div>
                                      <button className={`btn rounded-pill evaluateBtn`} onClick={() => showModal(index)}>Evaluate</button>
                                    </div>
                                    {/* <p className="card-data-profile-medal">{data.category}</p> */}
                                    {/* <div className="listItem" data-head="Category">
                                       <div>
                                         <span class="bronze">Bronze</span>
                                       </div>
                                     </div> */}
                                    <span className="">
                                      {/* <Rating size="25px" readonly={true}
                                         allowHalfIcon
                                         ratingValue={data.Overall_Rating}
                                       /> */}
                                    </span>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          })}
                        </Grid>
                      }
                    </div>
                    {/* : */}
                    {/* <div className="noDataFoundBox">
             <img src={imageAssets.noData} className="no-data-img" />
             <span>No Data Found</span>
             </div>} */}
                  </div>
                </SimpleBarReact>
                :
                <div className="whAppTable">
                  {/* {data.length ?  */}
                  <div className="tableList">
                    <div className="tableHead">
                      <div className="tableRow">
                        {/* <div className="listItem checkBox"><input type="checkbox" /></div> */}
                        <div className="listItem">Name</div>
                        {/* <div className="listItem alignItemsC">Category</div> */}
                        {/* <div className="tkp-head-data-a" >Address</div> */}
                        <div className="listItem">Aadhar</div>
                        <div className="listItem">Scheduled Interview</div>
                        {/* <div className="listItem">State</div> */}
                        <div className="listItem">Assigned By</div>
                        <div className="listItem alignItemsC">Status</div>
                        <div className="listItem alignItemsC">Actions</div>

                      </div>
                    </div>
                    {(data.length === 0 || data === undefined) ? <NoData /> :
                      <SimpleBarReact style={{ maxHeight: 640 }}>
                        {data.map((item, index) => {
                          return <div>
                            <div className="tableRow" onClick={() => { ViewDetails(item.UserId) }}>
                              {/* <div className="listItem checkBox"><input type="checkbox" /></div> */}
                              <div className="listItem" data-head="TKP Name">
                                {/* <img src={imageAssets.noData} className="pic" alt="... " /> */}
                                <div class="d-flex">
                                  <img src={item.imageUrl ? item.imageUrl : "blankAvatarImg"} className="blankAvatarImg" />
                                  <div class="personName">
                                    <span>{item.FirstName + " " + item.LastName}</span>
                                    <div className="ratings">
                                      {item.Status === 'Processing' &&
                                        <Rating size="20px"
                                          readonly={true}
                                          allowHalfIcon
                                          ratingValue={item.OverAll}
                                        />

                                      }
                                    </div>
                                    <div>
                                  {item.Status === "Processing" &&  
                                  <button className={`btn bronze ${item.CategoryName === "Bronze" && "bronze" ||
                                      item.CategoryName === "Silver" && "silver" ||
                                      item.CategoryName === "Gold" && "gold" ||
                                      item.CategoryName === "Diamond" && "diamond" ||
                                      item.CategoryName === "Platinum" && "platinum"
                                      }`}
                                  >{item.CategoryName ? item.CategoryName : "Bronze"}</button>
                                 } 
                                  </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="tkp-table-a"> {item.address}</div> */}
                              <div className="listItem" data-head="Aadhar"> {item.Aadhar}</div>
                              <div className="listItem" data-head="Submitted"> {dateFormatter(item.InterviewDate, "D MMM, YYYY") + " " + item.InterviewTime}</div>
                              {/* <div className="listItem" data-head="Status"> {item.status}</div> */}
                              <div className="listItem" data-head="Assigned by">
                                {item.AssignedBy}
                              </div>
                              <div className="listItem alignItemsC">
                                <div className={`btn rounded-pill ${item.Status === "Draft" && "Draft"
                                  || item.Status === "Processing" && "Processing"
                                  || item.Status === "Pending" && "Pending"
                                  || item.Status === "Rejected" && " Rejected"
                                  || item.Status === "Approved" && "Approved"}`} data-head="Status"
                                // style={{
                                //   backgroundColor:item.Status ==="Draft" && "#373d42"
                                //   || item.Status === "Processing" && "#007281"
                                //   || item.Status === "Pending" && "#ab7400"
                                //   || item.Status === "Rejected" && " #e42a33"
                                //   || item.Status === "Approved" && "#00a43b"

                                // }}
                                > {item.Status}</div>
                              </div>
                              <div className="listItem alignItemsC">
                                <div>
                                  <button className={`btn rounded-pill evaluateBtn`} onClick={() => showModal(index)}>Evaluate</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        })}
                      </SimpleBarReact>
                    }

                  </div>
                </div>
              }
              <Pagination
                current={currentPage}
                pageSize={numberPerPage}
                total={count}
                showSizeChanger
                onChange={handleCurrentPage}
                hideOnSinglePage={true}
                className="pagination-data"
                locale={locale}
                itemRender={pagination.textItemRender}
              />
            </div>
            <div>
              <div className="noDataFoundBox">
                {
                  loading ? (<Loader />)
                    :
                    <>
                      {/* <img src={imageAssets.noData} className="no-data-img" />
                 <span>No Data Found</span> */}
                    </>
                }
              </div>
            </div>
            {/* } */}
          </div>
          <Modal
            title="Turnkey Partner Details"
            visible={isModalVisible}
            onCancel={handleCancel}
            onOk={handleOk}
            className="pm-approval-modal"
            footer={[
              <Button key="approve" className="approveBtn" onClick={() => outComeHandler("approved")}
                style={{
                  backgroundColor: outcome === "approved" ? "green" : " ",
                  color: outcome === "approved" ? "white" : "green",
                  borderColor: outcome === "approved" ? "white" : "green",
                }}
              >Approve</Button>,
              <Button key="reject" className="RejectBtn" onClick={() => outComeHandler("rejected")}
                style={{
                  backgroundColor: outcome === "rejected" ? "rgb(237,41,57)" : " ",
                  color: outcome === "rejected" ? "white" : "rgb(237,41,57)",
                  borderColor: outcome === "rejected" ? "white" : "rgb(237,41,57)",
                }}
              >Reject</Button>,
              <div>

                {outcome === "approved" ?
                  <div className="onboarding-text">
                    {checkActive === "active" ?
                      <Link onClick={onboardingProcess} id="onBoardLink" className="onboard-text">onboarding Process<BsBoxArrowUpRight size="20px" /></Link>
                      : ""}
                  </div>
                  :
                  <div>
                    {outcome === "rejected" ?
                      <div className="rejeced-text">
                        <div>
                          <label style={{ float: "left" }}>Remarks *</label>
                        </div>
                        <textarea className="text-area-reject" value={rejecteMsg} onChange={(e) => { setRejecteMsg(e.target.value) }} />
                        <Button onClick={tkpRejectHandler} className="rejectedBtn-Submit">Save</Button>
                      </div> : " "
                    }
                  </div>
                }
              </div>

            ]}
          >
            <TkpApprovalsModal
              selectedUser={selectedUser}
              politenessRating={politenessRating}
              PRHandler={setPolitenessRating}
              hospitalityRating={hospitalityRating}
              HRHandler={setHospitalityRating}
              siteMaintanenaceRating={siteMaintanenaceRating}
              SMRHandler={setSiteMaintanenaceRating}
              willingRating={willingRating}
              WRHandler={setWillingRating}
              overallRating={overAll}
            // OARHandler={setOverallRating}
            />
          </Modal>
        </div>
      }

    </div>

  )
}
export default TkpApprovals;



/* Prev Tkp Pending list Func*/

// const getTkpApprovedList =async (pageNo)=>{
//   try {
//        let searchData ={
//         listType: 0,
//         searchWord:enterWord,
//         pageIndex: pageNo === 1 ? 1 : currentPage,
//         numberPerPage: numberPerPage ,
//         logginUserId:UserId 
//        }
//     const response = await partnerExecutiveServices.GetTurnKeyPartnerService(searchData);
//     console.log('gettkpResponse::', response);
//     if (response.status === "success" && response.data.gettkplist === true) {
//       setApiLoader(false);
//       setData(response.data.TKPs_list)
//       {enterWord && setCurrentPage(1)}
//       setCount(response.data.count)
//     } else {
//       setApiLoader(false);
//       setData([]);
//       setCount(0)
//       // toast.error('failed to fetch the users list')
//     }
//   } catch (error) {
//      console.log(error);
//   }
// }