import { DatePicker, Divider, Slider, Spin } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import CustomSelect from '../../../../../Components/Select/CustomSelect'
import PMOServices from '../../../../../Service/PMOServices'
import Options from '../../../../../Utilities/Options/Options';
import { TbLoader } from 'react-icons/tb';


const ScheduleModal = (
    {
        projectDetails,
        handleCancel,
        scheduleInfo,
        setScheduleInfo,
        spinner,
        setSpinner,
        description,
        setDescription
    }
) => {
    console.log("schedule modal", projectDetails);
    console.log("dates", scheduleInfo);

    const monthsAndDateChanges = (type) => async (e) => {
        console.log("type", type, e);
        let body;
        if (type === "preMonths") {
            body = {
                projectId: projectDetails?.projectId,
                preStartDate: scheduleInfo?.preStartDate,
                preMonths: e,//months changed 
                consStartDate: scheduleInfo?.conStartDate,
                typeOfAction: "pre"
            }
            setSpinner(true);
            const response = await updatedScheduleDate(body);
            console.log("values", response.data);
            if (response.status === "success") {
                console.log("values", response.data);
                setScheduleInfo((prevstate) => ({
                    ...prevstate,
                    preStartDate: response.data[0].start_date ? moment(response.data[0].start_date) : null,
                    preEndDate: response.data[0].end_date ? moment(response.data[0].end_date) : null,
                    conStartDate: response.data[1].start_date ? moment(response.data[1].start_date) : null,
                    conEndDate: response.data[1].end_date ? moment(response.data[1].end_date) : null,
                    preProjectDuration: e
                }))
                let temp = description;
                temp[0].preChangeValidator = true;
                setDescription([...temp]);
            }
            else {
                toast.error("Failed To change the Months");
            }
        }
        else if (type === "preStartDate") {
            body = {
                projectId: projectDetails?.projectId,
                preStartDate: e.format("YYYY-MM-DD"), // pre construction start date changes
                preMonths: scheduleInfo?.preProjectDuration,
                consStartDate: scheduleInfo?.conStartDate,
                typeOfAction: "pre"
            }

            setSpinner(true);
            const response = await updatedScheduleDate(body);
            if (response.status === "success") {
                console.log("values2", response.data);
                setScheduleInfo((prevstate) => ({
                    ...prevstate,
                    preStartDate: response.data[0].start_date ? moment(response.data[0].start_date) : null,
                    preEndDate: response.data[0].end_date ? moment(response.data[0].end_date) : null,
                    conStartDate: response.data[1].start_date ? moment(response.data[1].start_date) : null,
                    conEndDate: response.data[1].end_date ? moment(response.data[1].end_date) : null,
                }))
                let temp = description;
                temp[0].preChangeValidator = true;
                setDescription([...temp]);
            }
            else {
                toast.error("Faild to Change the Pre construction start Date");
            }
        }
        else if (type === "consStartDate") {
            body = {
                projectId: projectDetails?.projectId,
                preStartDate: scheduleInfo?.preStartDate,
                preMonths: scheduleInfo?.preProjectDuration,
                consStartDate: e.format("YYYY-MM-DD"),// construction start date changes
                typeOfAction: "cons"
            }
            setSpinner(true);
            const response = await updatedScheduleDate(body);
            if (response.status === "success") {
                console.log("value3", response.data);
                setScheduleInfo((prevstate) => ({
                    ...prevstate,
                    preStartDate: response.data[0].start_date ? moment(response.data[0].start_date) : null,
                    preEndDate: response.data[0].end_date ? moment(response.data[0].end_date) : null,
                    conStartDate: response.data[1].start_date ? moment(response.data[1].start_date) : null,
                    conEndDate: response.data[1].end_date ? moment(response.data[1].end_date) : null,
                }))
                let temp = description;
                temp[1].consChangeValidator = true;
                setDescription([...temp]);
            }
            else {
                toast.error("Failed to Change the construction Start Date ");
            }
        }
        else if (type === "preDesription") {
            let { name, value } = e.target;
            console.log("pre decription ", e.target.value, e.target.name);
            let temp = description;
            temp[0].preDescription = value
            setDescription([...temp]);
        }
        else if (type === "consDescription") {
            let { name, value } = e.target;
            let temp = description;
            temp[1].consDescription = value;
            setDescription([...temp]);

        }
    }

    /* for months and date changes api hit method */
    const updatedScheduleDate = async (body) => {
        console.log("body", body);
        try {
            const response = await PMOServices.updateScheduleDate(body);
            if (response.status === "success") {
                setSpinner(false);
                return (
                    {
                        status: "success",
                        data: response.data
                    }
                )
            }
            else {
                setSpinner(false);
                return (
                    {
                        status: "failed",
                        data: []
                    }
                )
            }


        } catch (error) {
            setSpinner(false);
            return (
                {
                    status: "failed",
                    data: []
                }
            )
        }

    }

    const validation = () => {
        let proceed = false;
        if (description[0].preChangeValidator && description[1].consChangeValidator) {
            if (description[0].preDescription && description[1].consDescription) {
                proceed = true;
            }
            else {
                proceed = false;
            }
        }
        else if (description[0].preChangeValidator) {
            if (description[0].preDescription) {
                proceed = true;
            }
            else {
                proceed = false;
            }

        }
        else if (description[1].consChangeValidator) {
            if (description[1].consDescription) {
                proceed = true;
            }
            else {
                proceed = false;
            }
        }
        return proceed;
    }

    const commitUpdatedScheduleDates = async (typeOfAction) => {
        try {
            if ((typeOfAction === "save" && validation()) || (typeOfAction === "cancel")) {
                let body = {
                    projectId: projectDetails?.projectId,
                    preDescription: description[0].preDescription === "" ? null :description[0].preDescription,
                    consDescription: description[1].consDescription === "" ? null : description[1].consDescription,
                    updatedId: Number(window.localStorage.getItem("user_Id")),
                    typeOfAction: typeOfAction === "save" ? 1 : 0
                }
                const response = await PMOServices.logsScheduleSave(body);
                if (response.status === "success") {
                    handleCancel(projectDetails?.projectId);
                    typeOfAction === "save" ?
                        toast.success("Successfully Added the Description") :
                        toast.success("Successfully Reverted the changes");

                }
                else {
                    typeOfAction === "save" ?
                        toast.error("Failed to Add  description") :
                        toast.error("Failed to Revert the Changes");
                }
            }
            else {
                toast.warning("No Changes Found or please Add Description");
            }
        }
        catch (error) {
            typeOfAction === "save" ?
                toast.error("Failed to Add  description") :
                toast.error("Failed to Revert the Changes");
        }
    }

    const projectType = (typeOfProject, noOfFloors) => {
        if (typeOfProject && !noOfFloors) {
            return (typeOfProject);
        }
        else {
            return (typeOfProject + "+" + noOfFloors);

        }
    }

    console.log("description", description);




    return (
        <Spin
            spinning={spinner}
            indicator={
                <div className="loaderBox1">
                    <div className="loaderEllipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            }
        >
            <div>
                <div className='row'>
                    <div className={"col"} style={{ textAlign: "right" }} >
                        <button className={`btn rounded-pill Future-active`}>
                            {
                                projectType(projectDetails?.projectType)
                            }
                        </button>
                    </div>
                </div>
                <div>
                    <div className='row'>
                        <div className='col'>
                            <label>Title</label>
                            <input type="text" className="form-control" value={"Pre-Construction"} disabled />

                        </div>
                        <div className='col'>
                            <label>Months</label>
                            <CustomSelect
                                options={Options.monthsOptions}
                                className={"form-control"}
                                mode={"-"}
                                placeholder={"Select No Of Months"}
                                onChange={monthsAndDateChanges("preMonths")}
                                value={scheduleInfo?.preProjectDuration}
                                allowClear={false}
                            />

                        </div>

                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label>Start Date</label>
                            <DatePicker
                                format={"YYYY-MM-DD"}
                                value={scheduleInfo?.preStartDate ? moment(scheduleInfo?.preStartDate, "YYYY-MM-DD") : ""}
                                onChange={monthsAndDateChanges("preStartDate")}
                            />

                        </div>
                        <div className='col'>
                            <label>Due Date</label>
                            <DatePicker
                                format={"YYYY-MM-DD"}
                                value={scheduleInfo?.preEndDate ? moment(scheduleInfo?.preEndDate, "YYYY-MM-DD") : ""}
                                disabled
                            />

                        </div>

                    </div>
                    {description[0].preChangeValidator &&
                        <div className='row'>
                            <div>
                                <label>Description</label>
                                <textarea
                                    name='preDescription'
                                    value={description[0].preDescription}
                                    onChange={monthsAndDateChanges("preDesription")}
                                />
                            </div>

                        </div>
                    }
                </div>
                <Divider type="horizontal" />
                <div>
                    <div className='row'>
                        <div className='col'>
                            <label>Title</label>
                            <input
                                type="text"
                                className="form-control"
                                value={"Construction Schedule"}
                                disabled
                            />

                        </div>
                        <div className='col'>
                            <label>Months</label>
                            <CustomSelect
                                options={Options.monthsOptions}
                                className={"form-control"}
                                mode={"-"}
                                placeholder={"Select No Of Months"}
                                // onChange={monthsAndDateChanges("construction")}
                                value={scheduleInfo?.conProjectDuration}
                                disabled={true}
                            />

                        </div>

                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label>Start Date</label>
                            <DatePicker
                                format={"YYYY-MM-DD"}
                                value={scheduleInfo?.conStartDate ? moment(scheduleInfo?.conStartDate, "YYYY-MM-DD") : ""}
                                onChange={monthsAndDateChanges("consStartDate")}
                            />

                        </div>
                        <div className='col'>
                            <label>Due Date</label>
                            <DatePicker
                                format={"YYYY-MM-DD"}
                                value={scheduleInfo?.conEndDate ? moment(scheduleInfo?.conEndDate, "YYYY-MM-DD") : null}
                                disabled
                            />

                        </div>

                    </div>
                    {description[1].consChangeValidator &&
                        <div className='row'>
                            <div>
                                <label>Description</label>
                                <textarea
                                    value={description[1].consDescription}
                                    onChange={monthsAndDateChanges("consDescription")}
                                />
                            </div>

                        </div>
                    }

                </div>
                <div className='modalFooter'>
                    <button
                        type="button"
                        className="btn cancelBtn"
                        onClick={() => commitUpdatedScheduleDates("cancel")}
                    >
                        cancel
                    </button>
                    <button
                        type="button"
                        className="btn saveBtn"
                        onClick={() => commitUpdatedScheduleDates("save")}
                    >
                        Save
                    </button>
                </div>
            </div>
        </Spin>
    )
}

export default ScheduleModal


// useEffect(() => {
//     alert("OK");
//     setScheduleInfo({
//         preStartDate: moment(projectDetails?.preConstructionStartDate).format("YYYY-MM-DD"),
//         preEndDate: moment(projectDetails?.preConstructionEndDate).format("YYYY-MM-DD"),
//         conStartDate: moment(projectDetails?.constructionStartDate).format("YYYY-MM-DD"),
//         conEndDate: moment(projectDetails?.constructionEndDate).format("YYYY-MM-DD"),
//         conProjectDuration: projectDetails?.projectDuration,
//         preProjectDuration: projectDetails?.projectPermissions?.includes("No") ? 1 : 2

//     })

// },[viewModal,typeOfComponent])

// const [scheduleInfo, setScheduleInfo] = useState({
//     preStartDate: null,
//     preEndDate: null,
//     conStartDate: null,
//     conEndDate: null,
//     preProjectDuration: "",
//     conProjectDuration: ""
// })

{/* <div className='row'>
                    <div className='col'>
                        <label>Progress Slider</label>
                        <Slider
                            defaultValue={scheduleInfo?.preConstructionPre ?? 0}
                            onChange={handleDaysFromMonths("percentage")}
                            value={scheduleInfo?.preConstructionPre}
                        />
                    </div>
                    <div className='col'>
                        <label>Percentage</label>
                        <input
                            type="number"
                            disabled
                            value={scheduleInfo.preConstructionPre}
                            className={"form-control"}
                        />
                    </div>
                </div> */}


/* previous date changer */
/* for select changes */
//    const handleDaysFromMonths = (type) => (e) => {
//     console.log("months", type, e);
//     if (type === "preconstruction") {
//         const value = moment(scheduleInfo?.preStartDate).add(((e * 30) - 1), "days");
//         console.log("value", value);
//         const consstartDate = moment(value).add(1, "days");
//         const consEndDate = moment(consstartDate).add(((scheduleInfo?.conProjectDuration * 30) - 1), "days");
//         setScheduleInfo((values) => (
//             {
//                 ...values,
//                 preProjectDuration: e,
//                 preEndDate: value.format("YYYY-MM-DD"),
//                 conStartDate: consstartDate.format("YYYY-MM-DD"),
//                 conEndDate: consEndDate.format("YYYY-MM-DD")
//             }));
//     }
//     else if (type === "construction") {
//         const value = moment(scheduleInfo?.conStartDate).add(((e * 30) - 1), "days");
//         console.log("value", value);
//         setScheduleInfo((values) => ({ ...values, conProjectDuration: e, conEndDate: value.format("YYYY-MM-DD") }));
//     }
//     else if (type === "percentage") {
//         setScheduleInfo((values) => ({ ...values, preConstructionPre: e }))
//     }
// }
// /* for date changes */
// const handleDates = (type) => (e) => {
//     console.log("dates", type, e);
//     if (type === "pre") {
//         const value = e.format("YYYY-MM-DD");
//         const endPreDate = moment(value).add(((scheduleInfo?.preProjectDuration * 30) - 1), "days");
//         console.log("pre date", value);
//         const consstartDate = moment(endPreDate).add(1, "days");
//         const consEndDate = moment(consstartDate).add(((scheduleInfo?.conProjectDuration * 30) - 1), "days");
//         setScheduleInfo((values) => ({
//             ...values,
//             preStartDate: value,
//             preEndDate: endPreDate.format("YYYY-MM-DD"),
//             conStartDate: consstartDate.format("YYYY-MM-DD"),
//             conEndDate: consEndDate.format("YYYY-MM-DD")
//         }));
//     }
//     else if (type === "cons") {
//         const value = e.format("YYYY-MM-DD");
//         const endConsDate = moment(value).add(((scheduleInfo?.conProjectDuration * 30) - 1), "days");
//         console.log("cons date", value);
//         setScheduleInfo((values) => ({ ...values, conStartDate: value, conEndDate: endConsDate }))
//     }

// }

// const updateConstructionDuration = async () => {
//     console.log("update construction details");
//     try {
//         let body = {
//             ...scheduleInfo,
//             projectId: projectDetails?.projectId
//         }
//         const response = await PMOServices.updateProjectDurationService(body);
//         if (response.status === "success") {
//             toast.success("successfully Updated the Project Durations");
//             handleCancel(projectDetails?.projectId);

//         }
//         else {
//             toast.error("Failed to Update Construction Durations");
//         }

//     } catch (error) {
//         toast.error('Failed to Update Construction Durations');

//     }
// }
