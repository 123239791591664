import { put, takeEvery } from "@redux-saga/core/effects";
import { DropDownServices } from "../../Service/DropDownServices";
import { getEducationaListFailure, getEducationaListSuccess } from "../Slices/getEducationalListSLice";

export const GETEDUCATIONALLIST=()=>({type:"/getEducationaList"});

export function* getEducattionalList(action){
    const response = yield DropDownServices.getEducationListService();
    // console.log("getEducattionalList:",response);
    if(response.status ==="success" && response.data.status === 200){
        yield put(getEducationaListSuccess(response.data.educationList))
    }else{
        yield put(getEducationaListFailure(response.data.message))
    }
}

function* getEducattionalListWatcher(){
    yield takeEvery(GETEDUCATIONALLIST().type,getEducattionalList)
}

export default getEducattionalListWatcher;