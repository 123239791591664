import React,{useState} from 'react';
import {DateRangePicker,defaultStaticRanges} from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  endOfMonth,
  endOfYear,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
  addWeeks
} from "date-fns"; 

const DatePicker = ({date,handler,className}) => {
    console.log("date:",handler);
  

  return (
    <div>
       <DateRangePicker 
        ranges={date}
        // onChange={handler}
        onChange={(item)=>handler([item.selection])}
        showSelectionPreview={true}
        months={2}
        direction={"horizontal"}
        dateDisplayFormat="d MMM yyyy"
        className={className && className}
        staticRanges={[
          ...defaultStaticRanges,
          // {
          //   label: "Last Two Weeks",
          //   range: () => ({
          //     startDate: addWeeks(new Date(),-2),
          //     endDate: startOfDay(new Date())
          //   }),
          //   isSelected(range) {
          //     const definedRange = this.range();
          //     return (
          //       isSameDay(range.startDate, definedRange.startDate) &&
          //       isSameDay(range.endDate, definedRange.endDate)
          //     );
          //   }
          // },
          {
            label: "This Year",
            range: () => ({
              startDate: startOfYear(new Date()),
              endDate: endOfDay(new Date())
            }),
            isSelected(range) {
              const definedRange = this.range();
              // console.log("defined range",definedRange)
              return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
              );
            }
          },
          {
            label: "Last Year",
            range: () => ({
              startDate: startOfYear(addYears(new Date(), -1)),
              endDate: endOfYear(addYears(new Date(), -1))
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
              );
            }
          },
          
          
        ]}
       />
    </div>
  )
}

export default DatePicker;