import { takeEvery,call,put } from "@redux-saga/core/effects";
import { partnerExecutiveServices } from "../../Service/PartnerExecutiveServices";
import { getInteriorPartnersListSuccess,getInteriorPartnersListFailure,getInteriorPartnersLoader } from "../Slices/interiorPartnersListSlice";

export function* getInteriorPartnersList (action){
    console.log("action::",action);
  const response  = yield(partnerExecutiveServices.GetInteriorPartnersServices(action.payload));
  // console.log("GetInteriorPartnersServices",response);
  if(response && response.status === "success" && response.data.getInteriorDesignersList ){
      yield put(getInteriorPartnersListSuccess({Interior_partners_list:response.data.interiorDesigners, count:response.data.count}))
  }else{
      yield put(getInteriorPartnersListFailure(response.error))
  }

}

function* getInteriorPartnersListWatcher(){
  yield takeEvery(getInteriorPartnersLoader().type,getInteriorPartnersList)
}

export default getInteriorPartnersListWatcher;