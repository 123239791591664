import { Avatar } from 'antd';
import React from 'react';
import NoData from '../../../../../Components/NoData/NoData';
import { dateConversionValidation } from '../../../../../Utilities/helpers';
import AppointmentsCard from '../AppointmentsCard/AppointmentsCard';


const AppointmentsDetails = ({ appointmentsData, date, handleModal, type }) => {
   console.log(appointmentsData,"appointmentsData")
   const sortedAppointments = [...(appointmentsData || [])].sort((a, b) => {
    const dateA = new Date(a.appointmentDate);
    const dateB = new Date(b.appointmentDate);
    return dateB - dateA;
  });
 

    return (
        <div className="rightBlock">
            <div className='appointmentAvatars'>
                <span className='todayDate'>

                    {type === "myappointments" ?
                         dateConversionValidation(date)
                        :
                        date.toLocaleDateString('en-us', { weekday: "long", day: "numeric", month: "long", year: "numeric" })
                    }
                </span>
                <div className='avatarItems'>
                    <Avatar.Group
                        maxCount={3}
                    >
                        {
                            appointmentsData?.map((value) => {
                                return (
                                    <Avatar>
                                        {value?.leadName?.charAt(0)}
                                    </Avatar>

                                )
                            })
                        }
                    </Avatar.Group>
                    <span style={{fontWeight: '500', color: '#777' }}> Total {appointmentsData?.length} appointments</span>

                </div>
            </div>
            <div className='appointmentList'>

                {
                    sortedAppointments?.length === 0 ?
                        <NoData message={"No Appointments Found"}  />
                        :

                        sortedAppointments?.map((details, index) => {
                            return (
                                <AppointmentsCard details={details} poistion={index} handleModal={handleModal} />
                            )
                        })

                }

            </div>
        </div>
    )
}

export default AppointmentsDetails;

 // console.log("date",typeof date[0].startDate ,date[0]?.startDate === date[0]?.endDate);