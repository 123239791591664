import { takeEvery ,call , put} from "@redux-saga/core/effects";
import { DropDownServices } from "../../Service/DropDownServices";
import { getRolesSuccess,getRolesFailure } from "../Slices/RolesListSLice";


export const ROLESLISTTYPE=()=>({type:"/rolesList"});

export function* getAssociatedRolesgenerator(action){
    console.log("action :",action.payload);
   const rolesResponse = yield DropDownServices.getAssociatedRolesListService();
    //  console.log("rolesResponse:",rolesResponse);
     if(rolesResponse.status === "success"){
        let data = rolesResponse.data.roles.map((role)=>{
             return({value:role.role_names,label:role.role_names})
         })
         yield put(getRolesSuccess(data))
        //  console.log("data:",data);
     }else{
         console.log("Something Went Wrong");
     }

} 

function* getAssociatedRolesWatcher(){
  yield  takeEvery(ROLESLISTTYPE().type,getAssociatedRolesgenerator)
}

export default getAssociatedRolesWatcher;