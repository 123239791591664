import React from 'react';
import { DefaultEditor } from 'react-simple-wysiwyg';


const TaskAndDescription = ({ leadStatus, setLeadStatus, handleLeadActivities }) => {
    /*handle text editor changes */
    console.log("taskDescription--", leadStatus.taskDescription)
    
    return (
        <div>
            <div className="row">
                <div className="col">
                    <label>Title</label>
                    <input
                        type="text"
                        className="form-control"
                        value={leadStatus?.taskName}
                        onChange={handleLeadActivities("taskTitle")}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label>Description</label>
                    {/* RICH TEXT EDITOR */}
                    <DefaultEditor
                        value={
                            leadStatus?.taskDescription.replace(/(<div>)/gi, "\n") ||
                            leadStatus?.taskDescription.replace(/&nbsp;/gi, "")
                        }
                        onChange={handleLeadActivities("taskDescription")}
                    />
                </div>
            </div>
        </div>
    )
}

export default TaskAndDescription