import React,{useState} from 'react';
import { AiOutlineClose, AiOutlinePoweroff, AiOutlineSearch } from 'react-icons/ai';
import noData from '../../../../Assets/images/no-data.png';
import settings from '../../../../Assets/images/settings.svg';
import settingsSlider from '../../../../Assets/images/settings-sliders.svg';
import list from '../../../../Assets/images/list.svg';
import apps from '../../../../Assets/images/apps.svg';
import trash from '../../../../Assets/images/trash.svg';
import edit from '../../../../Assets/images/edit.png';
import './UserList.css';
import axios from 'axios';
import { imageAssets } from '../../../../Assets/AssetsConstants/AssetsConstants';


const usersData =[
    {
        name:"KiranKumar",
        mobile:9987654321,
        designation:"Project Manager",
        associatedRole:"user",
        status:"active",
    },
    {
        name:"Sai Rohith",
        mobile:9987654321,
        designation:"Project Manager",
        associatedRole:"user",
        status:"active",
    },
    {
        name:"Sai Kiran",
        mobile:9987654321,
        designation:"Project Manager",
        associatedRole:"user",
        status:"active",
    },
    {
        name:"Nikhil",
        mobile:9987654321,
        designation:"Project Manager",
        associatedRole:"user",
        status:"active",
    },
    {
        name:"KiranKumar",
        mobile:9987654321,
        designation:"Project Manager",
        associatedRole:"user",
        status:"active",
    }
]

function UserLIst() {
    const [changeBtn, setChangeBtn] = useState(false);
    const [searchInput, setSearchInput] = useState([]);
    const [enterWord, setEnterWord] = useState("");
    const [data, setData] = useState(usersData);

    //search Handler:
   const SearchHandler = (e) => {
    var searchWord = e.target.value;
    setEnterWord(searchWord)
    var FilteredData = usersData.filter((val) => {
    //   return val.name.toLowerCase().includes(searchWord.toLowerCase())
    })
    if (searchWord === "") {
      setSearchInput([]);
    }
    else {
      setSearchInput(FilteredData)
    }
  }
  // closeInput :
  const closeInput = () => {
    setSearchInput([]);
    setEnterWord("")
  }

   const iconHandler =()=>{
     alert("im calling ")
   }

   //delete Handler :
      const deleteHandler=(e,id)=>{   
       alert(' are you sure!  do you want delete')  
      setData( data.filter((val,ind)=> ind !== id));

   }

    return (
        <div className="user-main-layout">
            {data.length ?
                <div className="user-list-layout">
                    <div className="user-list-layout-head">
                        <div className="serach-user-layout">
                        <div className="serachInput">
                            <div>
                                <input type="text"
                                        placeholder="Search"
                                        value={enterWord}
                                        className="searchField"
                                        onChange={SearchHandler}
                                />
                                  <AiOutlineSearch size="20px" className="searchIcon" />
                                {enterWord  &&  <AiOutlineClose size="18px" className="clearIcon" onClick={closeInput} />}
                            </div>
                        </div>
                        </div>
                        <div className="users-toggle-btn">
                            <img
                                src={imageAssets.apps}
                                className="app-icon"
                                onClick={iconHandler}

                            />
                            <div className="vertical-line"></div>
                            <img
                                src={imageAssets.List}
                                className="list-icon"
                                onClick={iconHandler}

                            />
                        </div>

                        <img
                            src={imageAssets.settingsSlider}
                            className="setting-slider-icon"
                            onClick={iconHandler}
                        />
                        <img
                            src={imageAssets.settings}
                            className="settings-icon"
                            onClick={iconHandler}
                        />
                    </div>
                    <hr></hr>
                    {
                        data.length ? <div>
                            <div className="user-table-head-row">
                                <div className="user-table-head">
                                    <div className="user-head-data-"></div>
                                    <div className="user-head-data-cb" ><input type="checkbox" /></div>
                                    <div className="user-head-data-n" >NAME</div>
                                    <div className="user-head-data-mn" >MOBILE NUMBER</div>
                                    <div className="user-head-data-d" >DESIGNATION</div>
                                    <div className="user-head-data-ar" >ASSOCIATED ROLE</div>
                                    <div className="user-head-data-s" >STATUS</div>
                                    <div className="user-head-data-ac" >ACTIONS</div>
                                </div>
                            </div>
                            {data.map((item, index) => {
                                return <div className="user-table-data-row">
                                    <div className="user-table-data-arrow"></div>
                                    <div className="user-table-data-cb" ><input type="checkbox"/></div>
                                    <div className="user-table-data-n" >{item.name}</div>
                                    <div className="user-table-data-mn" >{item.mobile}</div>
                                    <div className="user-table-data-d" >{item.designation}</div>
                                    <div className="user-table-data-ar" >{item.associatedRole}</div>
                                    <div className="user-table-data-s" >{item.status}</div>
                                    <div className="user-table-data-ac" >
                                        <div className="user-action-icons">
                                            <img src={imageAssets.edit1} className="user-edit-icon" onClick={iconHandler} />
                                            <img src={imageAssets.trash} className="user-delete-icon" onClick={(e)=> deleteHandler(e,index)} />
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div> : null}

                </div> : <div className="noDataFoundBox">
                    <img src={imageAssets.noData} className="no-data-img" alt="no data...." />
                    <span>No Data Found</span>
                </div>}

        </div>
    )
}

export default UserLIst