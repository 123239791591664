import { createSlice } from "@reduxjs/toolkit";

const getArchitectPartnersList = createSlice({
    name:"ArchitectPartners",
    initialState: {
        loading:false,
        architectPartnersData : [],
        error : null,
        count : 0
    },
    reducers :{
        getArchitectPartnersLoader : (state,action)=>{
            console.log("architectPartners redux data::",action.payload);
             state.loading = true
        },
        getArchitectPartnersSuccess : (state,action)=>{
            console.log("action",action);
            state.loading = false
            state.architectPartnersData = action.payload.Architect_Partners_List
            state.count = action.payload.count
        },
        getArchitectPartnersFailure : (state,action)=>{
            state.loading = false
            state.error = action.payload
        }
    }
})

export const {getArchitectPartnersLoader,getArchitectPartnersSuccess,getArchitectPartnersFailure} =  getArchitectPartnersList.actions;

export default getArchitectPartnersList.reducer;