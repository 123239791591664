import React from 'react'
import './AddTkp.css';
import '../tkpList/Tkplist.css'
import TkpCreateProfile from './TkpCreateProfile/TkpCreateProfile';


function AddTkp({ type, userId, settype }) {
    return (
        <div className="contentBody detail">
            <TkpCreateProfile type={type} userId={userId} settype={settype} />
        </div>
    )
}

export default AddTkp

//   const tabItems = [
//     {
//       id: 1,
//       title: 'Details',
//       content: <TkpCreateProfile type={type} userId={userId} settype={settype}/>,
//     },
//     {
//       id: 2,
//       title: 'Address',
//       content: 'Address content',
//     },
//     {
//       id: 3,
//       title: 'Bank Details',
//       content: 'Bank Details content',
//     },
//     {
//       id: 4,
//       title: 'Project Portfolio',
//       content: 'Project Portfolio content',
//     },
//   ];
// usestate :
// const [active, setActive] = useState(1);

// console.log('type ::', type , "userId:",userId);
{/* <div className="addTkp-navbar">
                <div className="button-group">
                    {tabItems.map(({id,title}) => (
                        <button className='button'
                            key={title}
                            active={active === id }
                            onClick={() => setActive(id)}
                            style={{borderBottom : active === id  && '2px solid orange',
                            color: active === id && 'orange',
                        }}
                        >{title}</button>
                    ))}
                </div>
            </div> */}
{/* {tabItems.map(({id,content}) => {
                return active === id  ? content  : "" ;
            })} */}

{/* no need tabs that why using component directly */ }