import { put, takeEvery } from "@redux-saga/core/effects";
import { DropDownServices } from "../../Service/DropDownServices";
import { getPartnerManagersListFailure, getPartnerManagersListSuccess } from "../Slices/getPartnerManagerListSLice";
export const GETPARTNERMANAGERSLIST = ()=>({type:"/partnerManagers"});

function* getPartnerManagerList(action){
    // alert("inside the get partner manager list");
    const response = yield DropDownServices.getPartnerManagersListService();
    // console.log("getPartnerManagerList:",response);
    if(response.status === "success" && response.data.isGetPartnerManagerList){
        yield put(getPartnerManagersListSuccess(response.data.partnerManagerList))
    }else{
        yield put(response.data.message)
    }
}

function* getPartnerManagerListWatcher(){
    yield takeEvery(GETPARTNERMANAGERSLIST().type,getPartnerManagerList)
}

export default getPartnerManagerListWatcher;