import { DropDownServices } from "../DropDownServices";


export const yearsOfExperienceList = async () => {
    const yearsOfExperienceResponse = await DropDownServices.getYearsOfExperienceListService();
    console.log("yearsOfExperienceList:", yearsOfExperienceResponse);
    if (yearsOfExperienceResponse?.status === "success") {
        return ({

            data: yearsOfExperienceResponse.data
        })
    } else {

    }
}

export const educationslList = async () => {
    const educationalResponse = await DropDownServices.getEducationListService();
    console.log("educationslList:", educationalResponse);
    if (educationalResponse?.status === "success") {
        return ({
            data: educationalResponse.data
        })
    } else {

    }
}

export const statusListApi = async (body) => {
    const statusResponse = await DropDownServices.getStatusListService(body);
    console.log("statusList:", statusResponse);
    if (statusResponse?.status === "success") {
        return ({
            data: statusResponse.data
        })
    } else {

    }
}

export const designationListApi = async () => {
    const designationResponse = await DropDownServices.getDesignationListService();
    console.log("designationResponse:", designationResponse);
    if (designationResponse?.status === "success") {
        return (
            {
                data: designationResponse.data
            }
        )
    } else {
        return (
            {
                data: "No data found"
            }
        )
    }
}

export const rolesListApi = async () => {
    const rolesResponse = await DropDownServices.getAssociatedRolesListService();
    console.log("rolesResponse:", rolesResponse);
    if (rolesResponse.status === "success") {
        return (
            {
                data: rolesResponse.data
            }
        )
    } else {

    }
}

export const skilltypeListApi = async () => {
    const skilltypeResponse = await DropDownServices.getSkillTypeService();
    console.log("skilltypeResponse:", skilltypeResponse);
    if (skilltypeResponse.status === "success") {
        return (
            {
                data: skilltypeResponse.data
            }
        )
    } else {

    }
}

export const categoriesListApi = async () => {
    const categoriesListResponse = await DropDownServices.getCategoriesListService();
    console.log("categoriesListResponse::", categoriesListResponse);
    if (categoriesListResponse.status === 'success') {
        return (
            {
                data: categoriesListResponse.data
            }
        )
    } else {
      
    }
}