import React, { useState } from 'react';
import { Calendar, Menu } from 'antd';
import { FaUserTie } from 'react-icons/fa';
import { RiUser2Fill } from 'react-icons/ri';
import { CgProfile } from 'react-icons/cg';
import { AiOutlineMenu } from 'react-icons/ai';
import { FiUsers } from 'react-icons/fi';
import {BiRightIndent,BiLeftIndent,BiUserPlus, BiBuilding} from 'react-icons/bi';
import {VscGraphLine} from 'react-icons/vsc';
import {FiUserPlus} from 'react-icons/fi';
import "antd/dist/antd.css";
import { Link } from 'react-router-dom';
import './AdminSidebar.css';
import graph from '../../../Assets/images/graph.svg';
import building from  '../../../Assets/images/building.png';
import list from  '../../../Assets/images/list.svg';
import calender from  '../../../Assets/images/calendar.svg';
import list1 from  '../../../Assets/images/list.svg';
import user from  '../../../Assets/images/user-add.svg';
import user1 from  '../../../Assets/images/user-add-sp.svg';

const { SubMenu } = Menu;

function Sidebar() {
  const [collapsed, setCollapsed] = useState(true);
  const [current, setCurrent] = useState(1);

  const collpaseHandler = () => {
    setCollapsed(!collapsed)
  }

  const currentValue = (e) => {
    setCurrent(e.target.key)
  }

  // // active link ;
  //  $('.item').on('click', function(){
  //    $('.item').removeClass('active');
  //    $(this).addClass('active')
  //  })  


  return (
<    div className="main-layout" >
      <div className="whAppLayout">
        <Menu
          inlineCollapsed={collapsed}
          defaultOpenKeys={['1']}
          mode="inline"
          selectedKeys={[current]}
          className="menu-bar"
          style={{ backgroundColor: collapsed ? "orange" : "white" }}
        >
          <div className="head"  >
            <div className="head-text">
              {collapsed ? <div className='wh'></div> : <div className="wehouse">Admin</div>}
            </div>
            {
                collapsed ? <BiRightIndent onClick={collpaseHandler} className="open-arrow"/>
                 : <BiLeftIndent onClick={collpaseHandler} className="admin-close-arrow"/>
              }
          </div>
            <Menu.Item key="1" className="item" icon={<img src={graph} className="menu-icon" />}><Link to="/landing/maindashboard">Dashboard</Link></Menu.Item>
            <SubMenu key="sub1" title="Partner Executive" icon={<img src={building} className="building-icon"/>} className="item">
            <Menu.Item key="2" className="item" ><Link to="/partnerexecutive/dashboard">Dashboard</Link></Menu.Item>
            <Menu.Item key="3" className="item" ><Link to="/partnerexecutive/turnkeyPartners">Turnkey Partner</Link></Menu.Item>
            <Menu.Item key="4" className="item" ><Link to="/partnerexecutive/skilledWorkers">Skilled Workers</Link></Menu.Item>
            <Menu.Item key="5" className="item" ><Link to="/partnerexecutive/architechPartners">Architech Partners</Link></Menu.Item>
            <Menu.Item key="6" className="item" ><Link to="/partnerexecutive/interiorPartners">Interior Partners</Link></Menu.Item>
            <Menu.Item key="7" className="item" ><Link to="/partnerexecutive/myTrips">My Trips</Link></Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" title="Partner Manager" className="item" icon={<img src={list} className="menu-icon"/>}>
            <Menu.Item key="8"  className="item" ><Link to="/partnermanager/dashboard">Dashboard</Link></Menu.Item>
            <Menu.Item key="9"  className="item" ><Link to="/partnermanager/tkpApprovals">Tkp Approvals</Link></Menu.Item>
            <Menu.Item key="10" className="item" ><Link to="/partnermanager/myCalender">My Calender</Link></Menu.Item>
            <Menu.Item key="11" className="item" ><Link to="/partnermanager/skilledWorkersApprovals">Skilled Workers Approvals</Link></Menu.Item>
          </SubMenu>
          <SubMenu key="sub3" title="TKP Menu" className="item" icon={<img src={calender} className="menu-icon"/>}>
            <Menu.Item key="12" className="item" ><Link to="/tkpmenu/dashboard">Dashboard</Link></Menu.Item>
            <Menu.Item key="13" className="item" ><Link to="/tkpmenu/materialOrders">Material Orders</Link></Menu.Item>
            <Menu.Item key="14" className="item" ><Link to="/tkpmenu/requestSkilledWorkers">Request Skilled Workers</Link></Menu.Item>
          </SubMenu>
          <SubMenu key="sub4" title="Sales Executive"  className="item" icon={<img src={list} className="menu-icon"/>}>
            <Menu.Item key="15" className="item" ><Link to="/salesexecutive/Dashboard">Dashboard</Link></Menu.Item>
            <Menu.Item key="17" className="item" ><Link to="/salesexecutive/customers">Customers</Link></Menu.Item>
            <Menu.Item key="16" className="item" ><Link to="/salesexecutive/projects">Projects</Link></Menu.Item>
          </SubMenu>
          <Menu.Item key="18" className="item" icon={<img src={user} className="menu-icon"/>}><Link to="/users">Users</Link></Menu.Item>
          <Menu.Item key="19" className="item" icon={<img src={user1} className="menu-icon"/>}><Link to="/roles">Roles</Link></Menu.Item>
        </Menu>
      </div>
    </div>
  )

}

export default Sidebar;
