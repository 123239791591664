import axios from 'axios';
import instanceAll from './CommonAxiosInstance';

// Add turnkey partner Api :
export const AddTurnKeyPartnerService = async (body)=>{
    try {
      let response = await  instanceAll.post("/addTurnKeyPartner",body , 
    //      {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //    }}
       );
    //   console.log("add TurnKey Partner Res:::",response);
         if (response.status === 200)
            {
                return{
                    status:"success",
                    data:response.data,
                }
            }
          else {
               return{
                   staus:"failed",
                   data:response.data
               }
          }
    }catch(err){
        console.log(err);
    }

}

// Get turnkey Partner Api:
export const GetTurnKeyPartnerService = async (body)=>{
  try {
    let response = await  instanceAll.post("/get_tkp_list",body);
    // let response = await  instanceAll.post("/tkp-list",body);
    // console.log("get_tkp_list Res:::",response);
       if (response.status === 200)
          {
              return{
                  status:"success",
                  data:response.data,
                  count:Number(response.data.count) 
              }
          }
        else {
            return{
                staus:"failed",
                data:response.data
            }
        }
  }catch(err){
      console.log(err);
  }
}

 // get predefined data from tkp user:
 export const GetPredefinedTkpDataService= async (body)=>{
    //  Adding baseURL to addArchitectPartner Api services :
       try {
         let response = await  instanceAll.post("/getTkpUser",body);
        //  console.log("GetPredefined data res :::",response);
            if (response?.status===200)
               {
                   return{
                       status:"success",
                       data:response.data,
                   }
               }
             else {
                return{
                    staus:"failed",
                    data:response.data
                }
             }
       }catch(err){
           console.log(err);
       }
     }

//  update turnkey Partner Api:
export const UpdateTurneyPartnersService= async (body)=>{
    //  Adding baseURL to addArchitectPartner Api services :
       try {
         let response = await  instanceAll.post("/updateTkpUser",body);
        //  console.log("Update tkp Res:::",response);
            if (response?.status===200)
               {
                   return{
                       status:"success",
                       data:response.data,
                   }
               }
             else {
                return{
                    staus:"failed",
                    data:response.data
                }
             }
       }catch(err){
           console.log(err);
       }
     }

// Add Architech partner Api:
export const AddArchitechPartnersService= async (body)=>{
try {
    let response = await  instanceAll.post("/addArchitectPartner",body);
    // console.log("addArchitectPartner Res:::",response);
       if (response?.status===200)
          {
              return{
                  status:"success",
                  data:response.data,
              }
          }
        else {
            return{
                staus:"failed",
                data:response.data
            }
        }
  }catch(err){
      console.log(err);
  }
}

// Get Architech partner Api :
export const GetArchitechPartnersService = async(body)=>{
 //  Adding baseURL to get_architech_list  Api services :
try {
  let response = await  instanceAll.post("/get_architech_list",body); 
    //  let response = await instanceAll.post("/get-pagination-architect-list",body)
//   console.log("get_architech_list Res:::",response);
     if (response?.status===200)
        {
            return{
                status:"success",
                data:response.data,
            }
        }
      else {
        return{
            staus:"failed",
            data:response.data
        }
      }
}catch(err){
    console.log(err);
}
}

// get PreDefined values of architet partner:
export const GetPredefinedArchitectPartnersDataService = async (body)=>{
    try{
      let response = await instanceAll.post("/get-architect-partners-by-id", body)
        // console.log("GetPredefinedArchitectPartnersData",response);
        if (response?.status===200)
        {
            return{
                status:"success",
                data:response.data,
            }
        }
      else {
         return{
             staus:"failed",
             data:response.data
         }
      }
    }
    catch(err){
        return{
           error:err
        }
    }
}

// Update architect Partners :
export const UpdateArchitectPartnersService= async (body)=>{
    //  Adding baseURL to addArchitectPartner Api services :
       try {
         let response = await  instanceAll.post("/update-architect-partners",body);
        //  console.log("Update Architect Partners:::",response);
            if (response?.status===200)
               {
                   return{
                       status:"success",
                       data:response.data,
                   }
               }
             else {
                return{
                    staus:"failed",
                    data:response.data
                }
             }
       }catch(err){
           console.log(err);
       }
     }

// Add Skilled Workers Api :
export const AddSkilledWorkerService = async (body)=>{
    try{
    let response = await  instanceAll.post("/add-skilled-worker",body); 
    // console.log("add-skilled-worker Res:::",response);
       if (response?.status===200)
          {
              return{
                  status:"success",
                  data:response.data,
              }
          }
        else {
            return{
                staus:"failed",
                data:response.data
            }
        }
  }catch(err){
      console.log(err);
  }

}

//  get Skiled Workers Api :
export const GetSkilledWorkersServices= async(body)=>{
    try{
        let response = await instanceAll.post('/get_skilled_workers_list',body);
        // let response=await instanceAll.post("/get-pagination-skilledworkersList",body);
        console.log('getSKilledWorkersLIst :::',response);
        if(response.status === 200){
            return{
                status:"success",
                data:response.data,
                count:response.data.count
            }
        }
        else{
            return{
                staus:"failed",
                data:response.data
            }
        }
    }catch(error){
        console.log(error);
        return{
            staus:"failed",
            data:[]
        }
    }
}

     // get Previous values of Skiled Workers:
     export const GetSkiledWorkersByIdDataService = async (body)=>{
        try{
          let response = await instanceAll.post("/get-skilled-workers-by-id", body)
            console.log("get-skilled-workers-by-id:::",response);
            if (response?.status===200)
            {
                return{
                    status:"success",
                    data:response.data,
                }
            }
          else {
             return{
                 staus:"failed",
                 data:response.data
             }
          }
        }
        catch(err){
            return{
               error:err
            }
        }
    }


// Update Skiled Workers Api :
export const UpdateSkilledWorkrsService= async (body)=>{
    //  Adding baseURL to addArchitectPartner Api services :
       try {
         let response = await  instanceAll.post("/update-skilled-workers",body);
        //  console.log("update-skilled-workers :::",response);
            if (response?.status === 200)
               {
                   return{
                       status:"success",
                       data:response.data,
                   }
               }
             else {
                return{
                    staus:"failed",
                    data:response.data
                }
             }
       }catch(err){
           console.log(err);
       }
     }


// Add Interior Partners Api :
export const AddInteriorPartnersService = async (body)=>{
    try{
    let response = await  instanceAll.post("/add-interior-designer",body); 
    // console.log("add-imterior partners Res:::",response);
       if (response?.status===200)
          {
              return{
                  status:"success",
                  data:response.data,
              }
          }
        else{
            return{
                status:"failed",
            }
        }
  }catch(err){
      console.log(err);
  }

}

//  get Interior partners Api :
export const GetInteriorPartnersServices= async(body)=>{
    try{
        let response = await instanceAll.post('/get-interior-designers-list',body);
        // let response = await instanceAll.post('/get-pagination-interior-list',body);
        // console.log('getInteriorDesignersLIst :::',response);
        if(response.status === 200){
            return{
                status:"success",
                data:response.data
            }
        }
        else{
            return{
                staus:"failed",
                data:response.data
            }
        }
    }catch(error){
        console.log(error);
    }
}

  // get Previous values of  Interior partners Api :
  export const GetInteriorpartnersByIdDataService = async (body)=>{
    try{
      let response = await instanceAll.post("/get-interior-designers-by-id", body)
        // console.log("get-interior-designers-by-id:::",response);
        if (response?.status===200)
        {
            return{
                status:"success",
                data:response.data,
            }
        }
      else {
         return{
             staus:"failed",
             data:response.data
         }
      }
    }
    catch(err){
        return{
           error:err
        }
    }
}

// Update Interior partners Api :
export const UpdateInteriorPartnersService= async (body)=>{
    //  Adding baseURL to addArchitectPartner Api services :
       try {
         let response = await  instanceAll.post("/update-interior-designers",body);
        //  console.log("update-interior-designers :::",response);
            if (response?.status === 200)
               {
                   return{
                       status:"success",
                       data:response.data,
                   }
               }
             else {
                return{
                    staus:"failed",
                    data:response.data
                }
             }
       }catch(err){
           console.log(err);
       }
     }


// get Tkp list Dropdown :
const getTkpListDropdownService= async ()=>{
    try {
       const response =  await instanceAll.post('/get-tkp-dropdown-list');
       console.log("getTkpListDropdownService:",response);
       if(response && response.status === 200 ){
           return{
               status:"success",
               data:response.data
           }
       }else{
           return {
               status:"failed",
               data:response.data
           }
       }
    } catch (error) {
           console.log(error);
    }
   }


   const getAllPartnersListBySearch =async(body) =>{
       try {
        
        const response = await instanceAll.post('/get-partners-by-search',body);
        if(response?.status === 200){
            return {
                status:"success",
                data: response.data
            }
        }else {
            return {
                status:"failed",
                data: response.data
            }
        }


       } catch (error) {
        console.log(error);
       }
   }
   /* new get tkp list api with search & filter data*/
   export const getTKPListService = async (body)=>{
    try {
      let response = await  instanceAll.post("/get-tkp-search-advancedFilter-data",body);
      console.log("new_get_tkp_list Res:::",response);
         if (response && response.data.status === 200 && response.data.getTkpListData )
            {
                return{
                    status:"success",
                    data:response.data,
                    count:Number(response.data.count) 
                }
            }
          else {
              return{
                  status:"failed",
                  data:response.data
              }
          }
    }catch(err){
        console.log(err);
    }
  }
  export const getAPListService = async (body)=>{
    try {
      let response = await  instanceAll.post("/get-architect-search-filter-list",body);
      console.log("new_get_ap_list Res:::",response);
         if (response && response.data.status === 200 && response.data.getArchitectListData )
            {
                return{
                    status:"success",
                    data:response.data,
                    count:Number(response.data.count) 
                }
            }
          else {
              return{
                  status:"failed",
                  data:response.data
              }
          }
    }catch(err){
        console.log(err);
    }
  }
  export const getSWListService = async (body)=>{
    try {
      let response = await  instanceAll.post("/get-skilledWorker-search-filter-list",body);
      console.log("new_get_sw_list Res:::",response);
         if (response && response.data.status === 200 && response.data.getSWListData )
            {
                return{
                    status:"success",
                    data:response.data,
                    count:Number(response.data.count) 
                }
            }
          else {
              return{
                  status:"failed",
                  data:response.data
              }
          }
    }catch(err){
        console.log(err);
    }
  }
  export const getIPListService = async (body)=>{
    try {
      let response = await  instanceAll.post("/get-interior-partner-search-filter-list",body);
      console.log("new_get_sw_list Res:::",response);
         if (response && response.data.status === 200 && response.data.getIPListData )
            {
                return{
                    status:"success",
                    data:response.data,
                    count:Number(response.data.count) 
                }
            }
          else {
              return{
                  status:"failed",
                  data:response.data
              }
          }
    }catch(err){
        console.log(err);
    }
  }

      export const partnerExecutiveServices = {
        AddTurnKeyPartnerService,
        GetTurnKeyPartnerService,
        GetPredefinedTkpDataService,
        UpdateTurneyPartnersService,
        AddArchitechPartnersService,
        GetArchitechPartnersService,
        GetPredefinedArchitectPartnersDataService,
        UpdateArchitectPartnersService,
        AddSkilledWorkerService,
        GetSkilledWorkersServices,
        GetSkiledWorkersByIdDataService,
        UpdateSkilledWorkrsService,
        AddInteriorPartnersService,
        GetInteriorPartnersServices,
        GetInteriorpartnersByIdDataService,
        UpdateInteriorPartnersService,
        getTkpListDropdownService,
        getAllPartnersListBySearch,
        getTKPListService,
        getAPListService,
        getSWListService,
        getIPListService
     }