import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { imageAssets } from '../../../../Assets/AssetsConstants/AssetsConstants';
import { Bar, Doughnut } from 'react-chartjs-2';
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from "chart.js";
import faker from 'faker';
import { TKPDashboardServices } from '../../../../Service/Dashboard/PartnerExecutive/tkpDashboardService';
import TimeLineChart from './timeLineChart';
import DatePicker from '../../../../Components/CommonComponents/dateRangePicker';
import moment from 'moment';
import { addDays, getDate, subDays } from 'date-fns';
import { skilledWorkersDahboardDataServices } from '../../../../Service/Dashboard/PartnerExecutive/getSwDashboardData';

const percentage = 78;

function Dashboard() {
  const [selectDate, setSelectDate] = useState(false);
  const [selectRange, setSelectRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 0),
      key: 'selection',
    }
  ])

  const [userCount, setUserCount] = useState([]);
  const [tkpListbydate, setTkpListbyDate] = useState([])
  const [dataBywise, setDataBywise] = useState("");
  const [tkpDataByYearly, setTkpDataByYearly] = useState([]);
  const [swDataByMonthly, setSwDataByMonthly] = useState([]);
  const [apDataByYearly, setApDataByYearly] = useState([]);
  const [ipDataByYearly, setIpDataByYearly] = useState([]);
  const [swDataByYearly, setSwDataByYearly] = useState([]);
  const [apListByDate, setApListByDate] = useState([]);
  const [ipListByDate, setIpListByDate] = useState([]);
  const [check, setCheck] = useState("");

  // select calendar Function:
  const dateToggleHandler = () => {
    setSelectDate(!selectDate)
    setCheck('date')
  }


  const startDate = selectRange[0].startDate
  const endDate = selectRange[0].endDate

  const fromDate = moment(startDate).format("YYYY-MMM-DD")
  const toDate = moment(endDate).format("YYYY-MMM-DD")
  console.log(fromDate, toDate);


  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        align: "start",

      },
      title: {
        display: true,
        text: "Overview",
      },

    }
  };



  // overView lables :
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Pending",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 80 })),
        backgroundColor: "rgb(252, 194, 86)",
      },
      {
        label: "Approved",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 80 })),
        backgroundColor: "rgb(63, 231, 195)",
      },
      {
        label: "Rejected",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 80 })),
        backgroundColor: "rgb(214, 84, 36)"
      }
    ]
  };

  // expenses data:
  const ExpensesOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "end",

      },
    }
  };

  const ExpensesData = {
    // labels: ["My Expenses", "Pending Expenses","Approved Expenses", "Reimbursed"],
    datasets: [
      {
        label: labels,
        data: [60, 12, 20, 8],
        backgroundColor: [
          "rgb(120,144,249)",
          "rgb(249,219,131)",
          "rgb(185,174,252)",
          "rgb(249,170,205)",
        ],
        borderColor: [
          "rgb(120,144,249)",
          "rgb(249,219,131)",
          "rgb(185,174,252)",
          "rgb(249,170,205)",
        ],
        borderWidth: 1
      }
    ]
  }

  // incentives graphs :
  const IncentivesLabels = ["Feb", "Mar", "Apr"]
  const IncentivesData = {
    labels: IncentivesLabels,
    datasets: [{
      label: " ",
      data: [20, 40, 55],
      backgroundColor: [
        "rgb(230,104,118)",
        " rgb(49,121,195)",
        "rgb(43,150,57) "
      ],
      barThickness: 10,
      borderColor: [
        " rgb(230,104,118)",
        "rgb(49,121,195)",
        " rgb(43,150,57) "
      ],
      borderWidth: 1,
    }]
  }

  const dateDifferenceInDays = () => {
    const date1 = new Date(fromDate)
    const date2 = new Date(toDate)
    return Math.floor(
      (Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) -
        Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) /
      (1000 * 60 * 60 * 24)
    )
  }

  // logginUserId :
  const loginUserId = window.localStorage.getItem('user_Id')

  const getTkpLeadsByDateRange = async () => {
    try {

      let dateRanges = {
        type: 2,
        fromDate: fromDate,
        toDate: toDate,
        loginUserId: loginUserId
      }

      const leadsresponse = await TKPDashboardServices.getTkpLeadsCountByDateRangeService(dateRanges);
      console.log("tkpLeadsResponse by date range ::", leadsresponse);
      if (leadsresponse.status === "success" && leadsresponse.data.isUsersCount) {
        if (dateDifferenceInDays() > 31) {
          // alert("years")
          setTkpDataByYearly(leadsresponse.data.tkpYearlyCount)
          setSwDataByYearly(leadsresponse.data.swYearlyCount)
          setApDataByYearly(leadsresponse.data.apYearlyCount)
          setIpDataByYearly(leadsresponse.data.ipYearlyCount)
          setDataBywise("yearly")

        } else {
          // alert("day wise")
          setTkpListbyDate(leadsresponse.data.tkpDailyCount)
          setSwDataByMonthly(leadsresponse.data.swDailyCount)
          setApListByDate(leadsresponse.data.apDailyCount)
          setIpListByDate(leadsresponse.data.ipDailyCount)
          setDataBywise("daily")

        }
        setUserCount(leadsresponse.data.count[0])

      } else {
        setTkpListbyDate([])
        setSwDataByMonthly([])
        setApListByDate([])
        setIpListByDate([])
        setTkpDataByYearly([])
        setIpDataByYearly([])
      }

    } catch (error) {
      console.log(error);
    }



  }

  console.log("tkpListByDate:", tkpListbydate);

  // if (dateDifferenceInDays() >= 364 ) {
  //   alert("yearly data")

  // } else {
  //   alert("day wise data")
  // }

  const applyFilter = () => {
    getTkpLeadsByDateRange()
    setSelectDate(false)
    setCheck('filter')
  }

  useEffect(() => {
    // tkpUsersService();
    getTkpLeadsByDateRange()
    dateDifferenceInDays()
  }, [])

  return (
    <div>
      <div className="mainContent">
        <div className="contentHeader">
          <span className="headerTitle">Dashboard</span>
          <div className={`groupBtns`}>
            <button className={`btn dateSelector dashboradDateSelector ${check === 'date' ? "ActiveBtn" : ""}`} >
              <input type="button"
                className="date-selector"
                value={fromDate + " - " + toDate}
                onClick={dateToggleHandler}
              />
              <img src={imageAssets.calendar} onClick={dateToggleHandler} className="calendarIcon" />
            </button>
            <button className={`btn applyFilterBtn ${check === "filter" ? "ActiveBtn" : ""}`} onClick={applyFilter} >Apply Filter</button>
            <div className="calendar" style={{ position: 'relative', top: "50px", right: "140px" }}>
              {selectDate && <DatePicker date={selectRange} handler={setSelectRange} />}
            </div>
          </div>
        </div>
        <div className="whContentBlock">
          <div className="dashboardWrapper">
            <div className="row">
              <div className="col mb-2">
                <div className="smCard">
                  <div className="cardItem">
                    <div className="cardHead">
                      <span className="cardTopTitle">Total <br />Turnkey Partners  </span>
                      <span className="leadValue">{userCount.tkp_Total ? userCount.tkp_Total : 0}</span>
                    </div>
                    <div className="cardStatusInfo">
                      <div><span>{userCount.tkp_Approved ? userCount.tkp_Approved : 0}</span><span>Approved</span></div>
                      <div><span>{userCount.tkp_Rejected ? userCount.tkp_Rejected : 0}</span><span>Rejected</span></div>
                      <div><span>{userCount.tkp_Pending ? userCount.tkp_Pending : 0}</span><span>Pending</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col mb-2">
                <div className="smCard">
                  <div className="cardItem">
                    <div className="cardHead">
                      <span className="cardTopTitle">Total <br /> Skilled Workers</span>
                      <span className="leadValue">{userCount.skilled_worker_Total ? userCount.skilled_worker_Total : 0}</span>
                    </div>
                    <div className="cardStatusInfo">
                      <div><span>{userCount.skilled_worker_Approved ? userCount.skilled_worker_Approved : 0}</span><span>Approved</span></div>
                      <div><span>{userCount.skilled_worker_Rejected ? userCount.skilled_worker_Rejected : 0}</span><span>Rejected</span></div>
                      <div><span>{userCount.skilled_worker_Pending ? userCount.skilled_worker_Pending : 0}</span><span>Pending</span></div>
                      {/* <span>20<br /> Approved</span>
                      <span>30<br /> Rejected</span>
                      <span>40<br /> Pending</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col mb-2">
                <div className="smCard">
                  <div className="cardItem">
                    <div className="cardHead">
                      <span className="cardTopTitle">Total <br />Architect Partners</span>
                      <span className="leadValue">{userCount.architect_partner_Total ? userCount.architect_partner_Total : 0}</span>
                    </div>
                    <div className="cardStatusInfo">
                      <div><span>{userCount.architect_partner_Approved ? userCount.architect_partner_Approved : 0}</span><span>Approved</span></div>
                      <div><span>{userCount.architect_partner_Rejected ? userCount.architect_partner_Rejected : 0}</span><span>Rejected</span></div>
                      <div><span>{userCount.architect_partner_Pending ? userCount.architect_partner_Pending : 0}</span><span>Pending</span></div>
                      {/* <span>20<br /> Approved</span>
                      <span>30<br /> Rejected</span>
                      <span>40<br /> Pending</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col mb-2">
                <div className="smCard">
                  <div className="cardItem">
                    <div className="cardHead">
                      <span className="cardTopTitle">Total <br /> Interior Partners</span>
                      <span className="leadValue">{userCount.interior_partner_Total ? userCount.interior_partner_Total : 0}</span>
                    </div>
                    <div className="cardStatusInfo">
                      <div><span>{userCount.interior_partner_Approved ? userCount.interior_partner_Approved : 0}</span><span>Approved</span></div>
                      <div><span>{userCount.interior_partner_Rejected ? userCount.interior_partner_Rejected : 0}</span><span>Rejected</span></div>
                      <div><span>{userCount.interior_partner_Pending ? userCount.interior_partner_Pending : 0}</span><span>Pending</span></div>
                      {/* <span>20<br /> Approved</span>
                      <span>30<br /> Rejected</span>
                      <span>40<br /> Pending</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="OverView">
                <div className="dashboardCard">
                  <TimeLineChart
                    leadsByWise={dataBywise}
                    tkpDataByMonthly={tkpListbydate}
                    swDataByMonthly={swDataByMonthly}
                    apDataByMonthly={apListByDate}
                    ipDataByMonthly={ipListByDate}
                    tkpDataByYearly={tkpDataByYearly}
                    swDataByYearly={swDataByYearly}
                    apDataByYearly={apDataByYearly}
                    ipDataByYearly={ipDataByYearly}
                  />
                </div>
              </div>
            </div>
            <div className="over-view-layout">
              <div className="row">
                <div className="col-8">
                  <div className="dashboardCard">
                    <div>
                      <Bar height={320} width={1000} data={data} options={options} />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="Act-tar">
                    <label className="head-lables">Actual Vs Target</label>
                    <div>
                      <div className="circle">
                        <CircularProgressbar value={percentage} text={`${percentage}%`} height="100" />
                      </div>
                    </div>
                    <p style={{ marginLeft: "120px", marginTop: "10px" }}> <span className="refreshIconHolder"> <img src={imageAssets.Refresh} style={{ height: "20px" }} /></span> Updated : Yesterday 6:00 PM</p>
                    <div className="row">
                      <div className="col" style={{ marginLeft: "70px" }}>
                        <label>Actual</label>
                        <p style={{ fontWeight: "bold", fontSize: "20px" }}>800</p>
                      </div>
                      <div className="col">
                        <div className="verticalLine"></div>
                      </div>
                      <div className="col">
                        <label>Target</label>
                        <p style={{ fontWeight: "bold", fontSize: "20px" }}>1200</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="inc-exp-layout">
              <div className="row">
                <div className="col-8">
                  <div className="incentives">
                    <div className="row">
                      <div className="col">
                        <div className="incentives-layout">
                          <div className="incentivesTotal">
                            <p className="total">Total</p>
                            <p className="incent">Incentives</p>
                            <p className="ruppes"><span>&#8377;</span> 2,000</p>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="incentivesHead">
                          <p className="incent-text">incentives</p>
                          <div className="row">
                            <div className="col">
                              <div className="dateField">
                                <img src={imageAssets.calendar} height="20px" className="calendar" /> <br />
                                <span className="thisYear">This Year</span>
                                <p className="thisYearMoney"><span>&#8377;</span>1,200</p>
                              </div>
                              <div className="dateField1">
                                <img src={imageAssets.calendar} height="20px" className="calendar" /> <br />
                                <span className="thisMonth">  This Month  </span>
                                <p className="thisMonthMoney"><span>&#8377;</span>800</p>
                              </div>
                            </div>
                            <div className="col">
                              <div className="row">
                                <Bar data={IncentivesData} style={{ marginLeft: "-80px" }} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="expenses">
                    <label className="head-lables">Expenses</label>
                    <div style={{ width: "300px" }}>
                      <Doughnut data={ExpensesData} style={{ marginLeft: "130px" }} options={ExpensesOptions} />
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="exp-cube">
                          <p className="cube my-exp"></p>
                          <span>My Expenses</span>
                        </div>
                        <div className="exp-cube">
                          <p className="cube pending-exp" ></p>
                          <span>Pending Expenses</span>
                        </div>
                      </div>
                      <div className="col">
                        <div className="exp-cube">
                          <p className="cube aproved-exp"></p>
                          <span>Approved Expenses</span>
                        </div>
                        <div className="exp-cube">
                          <p className="cube reimbursed"></p>
                          <span>Reimbursed</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard