import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import moment from 'moment';

function TimeLineChart({ leadsByWise ,tkpDataByMonthly,swDataByMonthly,
  apDataByMonthly,ipDataByMonthly,tkpDataByYearly,swDataByYearly,apDataByYearly,ipDataByYearly}) {

  const [labels,setLabels] = useState([]);
  const [value,setValue] = useState([]);
  const [swLabels, setSwLabels] = useState([]);
  const [swValue, setSwValue] = useState([]);
  const [apLabels, setApLabels] = useState([]);
  const [apValues, setApValues] = useState([]);
  const [ipLabels, setIpLabels] = useState([]);
  const [ipValues, setIpValues] = useState([]);




  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  console.log("leadsByWise:",leadsByWise);
  console.log(tkpDataByMonthly);
  console.log('sw ,timeLineChart Monthly inside timeline chart',swDataByMonthly);
  console.log('sw ,timeLineChart yearly inside timeline chart',swDataByYearly);


  //   function for converting normal data to timeLine chart data format:
  const timeLineData = () => {
    let date = [];
    let count = [];
    let swDate = [];
    let swCount = [];
    let apDate = [];
    let apCount = [];
    let ipDate = [];
    let ipCount = [];

    if(leadsByWise === "yearly"){
      const tkpListTimeLineChartDataByMonthly = tkpDataByYearly?.map((item,index) =>{
        // console.log(item);
        date = [...date, moment(item. _month_end).format("MMM")]
        count = [...count,item._count]
     })

     const swListTimeLineChartData = swDataByYearly?.map((item,index) => {
      // console.log("sw items :",item);
      swDate = [...swDate , moment(item._month_end).format('MMM')]
      swCount = [...swCount , item._count]
    })

    const apListTimeLineChartData = apDataByYearly?.map((item,index) => {
      // console.log("ap items :",item);
      apDate = [...apDate , moment(item._month_end).format('MMM')]
      apCount = [...apCount , item._count]
    })

    const ipListTimeLineChartData = ipDataByYearly?.map((item,index) => {
      // console.log("ap items :",item);
      ipDate = [...ipDate , moment(item._month_end).format('MMM')]
      ipCount = [...ipCount , item._count]
    })

    }else{
      const tkpListTimeLineChartData = tkpDataByMonthly?.map((item, index) => {
        // console.log("item:", item);
        date = [...date,moment(item._from_date).format("MMM-D")]
        count = [...count, item._count === null ? 0 : item._count]
      })  

      const swListTimeLineChartData = swDataByMonthly?.map((item,index) => {
        // console.log("sw items :",item);
        swDate = [...swDate , moment(item._from_date).format('MMM-D')]
        swCount = [...swCount , item._count]
      })

      const apTimeLineChartData = apDataByMonthly?.map((item,index) => {
           apDate = [...apDate , moment(item._from_date).format('MMM-D') ]
           apCount = [...apCount , item._count]
      })

      const ipTimeLineChartData =  ipDataByMonthly?.map((item,index) =>{
         ipDate = [...ipDate , moment(item._from_date).format('MMM-D')]
         ipCount = [...ipCount , item._count]
      })

      
    }
    setLabels(date);
    setValue(count)
    setSwLabels(swDate);
    setSwValue(swCount);
    setApLabels(apDate);
    setApValues(apCount);
    setIpLabels(ipDate);
    setIpValues(ipCount)
    console.log(labels,value );
  }

  // time line options  :
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Time Line Chart',
      },
    },
    scales :{
       y : {
        beginAtZero : true
       }
    }
  };

console.log("labels:",labels);
console.log("value:",value);
console.log("swLabel:",swLabels);
console.log("swValue:",swValue);

  // const labels = ['Aug-1', 'Aug-2', 'Aug-3', 'Aug-4', 'Aug-5', 'Aug-6', 'Aug-7', 'Aug-7', 'Aug-8', 'Aug-9', 'Aug-10',];

  const data = {
    labels,
    datasets: [
      {
        label: 'Total Turnkey partners',
        data: labels.map((item,index) => value[index]),
        borderColor: 'rgb(174,120,254)',
        backgroundColor: 'rgb(174,120,254)',
      },
      {
        label: 'Total SKilled Workers',
        data: swLabels.map((item,index) => swValue[index]),
        borderColor: 'rgb(111,129,252)',
        backgroundColor: 'rgb(111,129,252)',
      },
      {
        label: 'Total Architect Partners',
        data: apLabels.map((item,index) => apValues[index]),
        borderColor: 'rgb(238,152,97)',
        backgroundColor: 'rgb(238,152,97)',
      },
      {
        label: 'Total Interior Partners',
        data: ipLabels.map((item,index) => ipValues[index]),
        borderColor: 'rgb(201,112,175)',
        backgroundColor: 'rgb(201,112,175)',
      },


    ],
  };

  useEffect(() => {
    timeLineData()
  }, [tkpDataByMonthly,swDataByMonthly,apDataByMonthly,ipDataByMonthly, tkpDataByYearly, apDataByYearly,leadsByWise])


  return (
    <div>
      <div className="TimeLineContainer">
        <div className="dashboardCard" >
          <Line data={data} options={options} height="350" width="1506" />
        </div>
      </div>
    </div>
  )
}

export default TimeLineChart;