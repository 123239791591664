import moment from "moment";
import React from "react";
import { imageAssets } from "../Assets/AssetsConstants/AssetsConstants";
import { emailSignupService } from "../Service/SignupServices";
import { faL } from "@fortawesome/pro-thin-svg-icons";

/*  avoid e,+,- values in number field: */
export const blockInvalidChar = (e) => { ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault() };
export const dateFormatter = (date, format) => {
    return moment(date).utc().format(format);

}
export const blockInvalidSpclChar = (e) => { ['@', '#', '+', '$', '%', '^', '&', '*', '(', ')', '-'].includes(e.key) && e.preventDefault() };
export const emailBlockChars = (e) => { ['#', '+', '$', '%', '^', '&', '*', '(', ')', '-'].includes(e.key) && e.preventDefault() }

/* get services list with in array format */
export const servicesConversion = (services) => {

    console.log("services", services);

    try {
        //  JSON.parse(JSON.parse(JSON.stringify(services)))
        // let value = Object.values(JSON.parse(JSON.parse(JSON.stringify(services))))?.map((value) => value);
        let value = Object.values(services)?.map((value) => value);
        return value;

    }

    catch (error) {

        console.log(error);

        let value = services?.replace(/[{}""]/g, '');

        console.log("value", value?.split(","));

        return value?.split(",");

    }




}




/* handle raw leads service for lists */

export const servicetype = (service) => {
    // console.log('service',Object.values(service).join(","),Object.keys(service));
    // console.log("services ---->",typeof Object.values(service));
    try {
        // JSON.parse(JSON.parse(JSON.stringify(service)))
        if (typeof Object.values(service)[0] === 'string' || typeof Object.values(service)[0] === "undefined") {
            console.log("98274827", service)
            if ((Object.values(service)[0] === "") || (service?.length === 0)) {
                return "Nil"
            }
            return Object.values(service)?.join(",")
        }
        // return "object";
    }

    catch (error) {

        return "string";




    }

}
/* logo handler to set image */
export const logoHandler = (name) => {
    if (name?.toLowerCase().includes("wehouse")) {
        return (
            imageAssets.wehouseFav
        )
    }
    else if (name?.toLowerCase().includes("google")) {
        return (
            imageAssets.GoogleLogo
        )
    }

}
/* date conversion and comparing the date are equal */
export const dateConversionValidation = (date) => {
    console.log("date", date);
    const startDate = moment(date[0].startDate).format('MMMM Do YYYY');
    const endDate = moment(date[0].endDate).format("MMMM Do YYYY");
    console.log("date", endDate, startDate);
    if (startDate === endDate) {
        return startDate
    }
    else {
        return (`${startDate} - ${endDate}`)
    }

}

export const labelChanger = (name) => {
    if (name.includes("Dashboard")) {
        return "Dashboard";
    }
    else if (name.includes("My Appointments")) {
        return "My Appointments";
    }
    else if (name.includes("Projects")) {
        return "Projects";
    }
    else if (name.includes("Customers")) {
        return "Customers";
    }
    else if (name.includes("Customer")) {
        return "Customer"
    }
    else {
        return name;
    }

}

/* disable dates before today  */
export const disabledDate = (current) => {
    console.log("disabled date", current);
    return current && current < moment().subtract(1, "days");
}
/* project type classname */
export const projectTypeClassName = (projectType, floorNumber) => {
    let projectTypeClassName;
    console.log("type ", typeof projectType);
    if (projectType !== "Others") {
        // alert("ok");
        switch (projectType) {
            case "G":
                projectTypeClassName = "g1"
                break;
            case 'G + 1':
                projectTypeClassName = "g1"
                break;
            case "G + 2":
                projectTypeClassName = "g2"
                break;
            case "G + 3":
                projectTypeClassName = "g3"
                break;
            case "G + 4":
                projectTypeClassName = "g4"
                break;
            case "G + 5":
                projectTypeClassName = "g5"
                break;

        }
    }
    else {
        // alert("others");
        switch (floorNumber) {
            case 'G + 1':
                projectTypeClassName = "g1"
                break;
            case "G + 2":
                projectTypeClassName = "g2"
                break;
            case "G + 3":
                projectTypeClassName = "g3"
                break;
            case "G + 4":
                projectTypeClassName = "g4"
                break;
            case "G + 5":
                projectTypeClassName = "g5"
                break;

        }

    }
    return projectTypeClassName;

}

/* button status */
export const  approveButtonStatusPC = (isApproved, currentTaskStatus, prevTaskStatus,ticket=false) => {
    let buttonStatus;
    console.log(currentTaskStatus,"currentTaskStatus------>")
    if (isApproved === 5) {
        if (currentTaskStatus === 'In Progress' || currentTaskStatus === "Completed" || currentTaskStatus === "Content" || currentTaskStatus==="Unblocked" || currentTaskStatus==="Blocked") {
            buttonStatus = true;
        }
        else if(ticket && currentTaskStatus===prevTaskStatus){
            buttonStatus=true;

        }
        else {
            buttonStatus = false;
        }
    }
    else if (isApproved === 6) {
        buttonStatus = false;
    }
    
    return buttonStatus;
}

export const completeButtonStatusPC = (isApproved, currentTaskStatus, prevTaskStatus,ticket=false) => {
    let buttonStatus;
   
    if (isApproved === 5) {
        buttonStatus = true;
    }
    else if (isApproved === 6) {
        if (currentTaskStatus === "Completed" || prevTaskStatus === "Completed" || prevTaskStatus === "Pending") {
            buttonStatus = true;
        }
        
        else {
            buttonStatus = false;
        }
    }
    else if (isApproved === null) {
        if (prevTaskStatus === "Pending") {
            buttonStatus = true;
        }
    }
   
        if(ticket && currentTaskStatus==="Running" && prevTaskStatus ==="Pending"){
            buttonStatus=false
    }
    
    return buttonStatus;
}

export const inprogressButtonStatusPC = (isApproved, currentTaskStatus, prevTaskStatus,ticket=false) => {
    let buttonStatus;
    console.log("first",ticket)
    if (isApproved === 5) {
        buttonStatus = true;
    }
    else if (isApproved === 6) {
       
        if (currentTaskStatus === "In progress" || prevTaskStatus === "Running" || currentTaskStatus === "Completed" || prevTaskStatus === "Completed") {
            buttonStatus = true;
        }
        if(ticket){
            if(currentTaskStatus === "Running"){buttonStatus =true}
            console.log("hello running")
        }
    }
    else if (isApproved === null) {
        if (prevTaskStatus === "Pending") {
            buttonStatus = false;
        }
    }

    return buttonStatus;

}

export const plusButtonStatusPc = (isApproved, currentTaskStatus, prevTaskStatus) => {
    let buttonStatus;
  if (isApproved === 6) {
        buttonStatus = false;
        if(currentTaskStatus === "Completed"){
            buttonStatus=true
        }
        else if(currentTaskStatus==="Unblocked"){
            buttonStatus=false
        }
        
    }
    
    else {
        buttonStatus = false;
    }
    return buttonStatus;
}

export const taskDescriptionStatusPC = (isApproved, currentTaskStatus, prevTaskStatus) => {
    let buttonStatus=false;
    return buttonStatus;

}

// item.milestoneStatus === null && "#FF0000" ||
// item.milestoneStatus === "Completed" && "#52C41A" ||
// item.milestoneStatus === "Running" && "#3590FF" ||
// item.milestoneStatus === "pending" && "#E3E4E8"

export const milestoneStatusPC = (isApproved, currentTaskStatus, prevTaskStatus) => {
    let buttonStatus;

    if ((isApproved === 6 && currentTaskStatus === 3) || (isApproved === 5 && currentTaskStatus === 4)) {
        // buttonStatus = "#FF0000";
        buttonStatus=true;
    }
    else {
        // switch (prevTaskStatus) {
        //     case "Pending":
        //         // buttonStatus = "#E3E4E8";
        //         but
        //         break;
        //     case "Running":
        //         buttonStatus = "#3590FF";
        //         break;

        //     case "Completed":
        //         buttonStatus = "#52C41A";
        //         break;


        // }
        buttonStatus=false;
    }
    return buttonStatus;

}




/* Reset/Remove Selected Filter Key Value In Partner Excutive/Partner Manager */

export const resetSelectedFilterKey = (selectedKey, advancedFilterData, setAdvancedFilterData) => {
    console.log("help---ad--", advancedFilterData.toScheduleDate, selectedKey)

    selectedKey = selectedKey === 'Pending' ? 3 : selectedKey === 'Processing' ? 4 :
        selectedKey === 'Rejected' ? 5 : selectedKey === 'Approved' ? 6 : selectedKey === 'Draft' ? 7 :
            selectedKey === "All" ? 9 : selectedKey
   
    if (advancedFilterData.status.includes(selectedKey)) {
        setAdvancedFilterData(data => ({ ...data, status: advancedFilterData.status.filter(v => v !== selectedKey) }))
    }
    else if (advancedFilterData.assignedTo.includes(selectedKey)) {
        setAdvancedFilterData(data => ({ ...data, assignedTo: advancedFilterData.assignedTo.filter(v => v !== selectedKey) }))

    }
    else if (advancedFilterData.category.includes(selectedKey)) {
        setAdvancedFilterData(data => ({ ...data, category: advancedFilterData.category.filter(v => v !== selectedKey) }))

    }
    else if (advancedFilterData.fromScheduleDate === selectedKey) {
        setAdvancedFilterData(data => ({ ...data, fromScheduleDate: null }))

    }
    else if (advancedFilterData.toScheduleDate === selectedKey) {
        setAdvancedFilterData(data => ({ ...data, toScheduleDate: null }))
    }
   
    return advancedFilterData

}

/* Keep All selected Filter Keywords Together For Display at the Top of the Navbar*/

export const storeSelectedFilterKeys = (selectedFilterOption) => {
    let selectedData = selectedFilterOption?.flat(1).map(i => (
        i === 3 ? 'Pending' : i === 4 ? 'Processing' :
            i === 5 ? 'Rejected' : i === 6 ? 'Approved' : i === 7 ? 'Draft' :
                i === 9 ? "All" : i
    ))
    selectedData = selectedData.filter(v => v !== null)
    return selectedData
}


/* Reset All Selected Filter options */
export const resetFilter = (setAdvancedFilterData,setShowAdvancedFilterModal,setSelectedFilterVal,setCurrentPage,setIsFilterDataCleared)=>{
    setAdvancedFilterData({
        status: [],
        fromScheduleDate: null,
        toScheduleDate: null,
        assignedTo: [],
        category: []
      })
      setShowAdvancedFilterModal(false)
      setSelectedFilterVal([])
      setCurrentPage(1)
      setIsFilterDataCleared(true)

}
// checkEmail:

export const emailValiadte = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const checkEmail = async (email,setEmailError) => {
    if (emailValiadte.test(email)) {
        let data = {
            email: email
        }
        const response = await emailSignupService (data)
        console.log('check-email---', response)
        if (response.status === 'success') {
            if (response.data.emailExists) {
                // alert("email is already exist")
                setEmailError(true)
            }
            else {
                setEmailError(false)
            }
        }
    }
}

// function for clear email allready exist message when email is empty:
// export const EmailErrorClear =(email,setEmailError)=>{
//     React.useEffect(()=>{
//         console.log('email in email error:',email , email.length);

//         !email.length && setEmailError(false)
//     },[email])
// }
