import instanceAll from './CommonAxiosInstance';

const tkpOnboardingProcessAddress = async (body)=>{
    try{
        const response = await instanceAll.post('/turnkey-partner-onboard-address',body);
        // console.log("tkpAddress Responce:::",response);
        if(response.status === 200){
            return{
                status:"success",
                data:response.data
            }
        }else{
             return{
                 status:"failed",
                 data:response.data
             }    
        }
    }catch(error){
         return{
             error:error
         }
    }
}

// turnkey-partner-onboarding-bankDetails:
const tkpOnboardingProcessBankDetails = async (body)=>{
    try{
        const response = await instanceAll.post('/turnkey-partner-onboard-bankdetails',body);
        // console.log("tkpBankDetails Responce:::",response);
        if(response.status === 200){
            return{
                status:"success",
                data:response.data
            }
        }else{
             return{
                 status:"failed",
                 data:response.data
             }    
        }
    }catch(error){
         return{
             error:error
         }
    }
}

// turnkey-partner-onboarding-Project_Portfolio:
const tkpOnboardingProcessProjectPortfolio = async (body)=>{
    try{
        const response = await instanceAll.post('/tkp-onboard-project',body);
        // console.log("/tkp-onboard-project Responce:::",response);
        if(response.status === 200){
            return{
                status:"success",
                data:response.data
            }
        }else{
             return{
                 status:"failed",
                 data:response.data
             }    
        }
    }catch(error){
         return{
             error:error
         }
    }
}


//  turnkey-partner-onboarding-reject Api :
 const tkpOnboardingProcessReject = async (body)=>{
    try{
        const response = await instanceAll.post('/turnkey-partner-onboarding-reject',body);
        // console.log("tkpReject Responce:::",response);
        if(response.status === 200){
            return{
                status:"success",
                data:response.data
            }
        }else{
             return{
                 status:"failed",
                 data:response.data
             }    
        }
    }catch(error){
         return{
             error:error
         }
    }
}

const addTkpRatingsService= async (body)=>{
    try{
    const response =  await instanceAll.post("/add-tkp-star-ratings",body);
    console.log("addTkpRatingsService:",response);
    if(response.status === 200){
        return{
            status:"success",
            data:response.data
        }
    }else{
        return{
            status:"failed",
            data:response.data
        }
    }

    }catch(error){
        return{
            error:error
        }
    }
}


/* Get tkp onboarding address */
const getTkpOnboardingAddressDetails = async (body)=>{
    try{
        console.log("body-add--",body);
        const response = await instanceAll.post('/get-tkp-onboarding-addres-by-id',body);
        console.log("get-tkpAddress Responce:::",response);
        if(response && response?.data?.status === 200){
            return{
                status:"success",
                data:response.data
            }
        }else{
             return{
                 status:"failed",
                 data:response.data
             }    
        }
    }catch(error){
         return{
             error:error
         }
    }
}

/* Get tkp onboarding bank */
const getTkpOnboardingBankDetails = async (body)=>{
    try{
        console.log("body-add--",body);
        const response = await instanceAll.post('/get-tkp-onboarding-bank-details-by-id',body);
        console.log("get-tkp-bank Responce:::",response);
        if(response && response?.data?.status === 200){
            return{
                status:"success",
                data:response.data
            }
        }else{
             return{
                 status:"failed",
                 data:response.data
             }    
        }
    }catch(error){
         return{
             error:error
         }
    }
}

/* Get tkp onboarding project list */
const getTkpOnboardingProjectDetails = async (body)=>{
    try{
        console.log("body-add--",body);
        const response = await instanceAll.post('/get-tkp-project-portfolio-list',body);
        console.log("get-tkp-project Responce:::",response);
        if(response && response?.data?.status === 200){
            return{
                status:"success",
                data:response.data
            }
        }else{
             return{
                 status:"failed",
                 data:response.data
             }    
        }
    }catch(error){
         return{
             error:error
         }
    }
}

/* Get tkp Appoinment list */
const getAllPartnerManagerAppoinments = async (body)=>{
    try{
        console.log("body-add--",body);
        const response = await instanceAll.post('/get-all-partner-manager-appoinment-list',body);
        console.log("get-tkp-appoinment Responce:::",response);
        if(response && response?.data?.status === 200){
            return{
                status:"success",
                data:response.data
            }
        }else{
             return{
                 status:"failed",
                 data:response.data
             }    
        }
    }catch(error){
         return{
             error:error
         }
    }
}

/* Tkp approved */

const approvedTkp = async (body)=>{

    try{
    
    console.log("body-add--",body);
    
    const response = await instanceAll.post('/approved-tkp',body);
    
    console.log("appro-tkp-project Responce:::",response);
    
    if(response && response?.status === 200){
    
    return{
    
    status:"success",
    
    data:response.data ,

    isApproved:true
    
    }
    
    }else{
    
    return{
    
    status:"failed",
    
    data:response.data ,

    isApproved:false

    
    }
    
    }
    
    }catch(error){
    
    return{
    
    error:error
    
    }
    
    }
    
    }

/* Get tkp Project portfolio list */
const getProjectPortfolioList = async (body)=>{
    try{
        console.log("body-add--",body);
        const response = await instanceAll.post('/get-project-protfolio-type-list',body);
        console.log("get-tkp-appoinment Responce:::",response);
        if(response && response?.data?.status === 200){
            return{
                status:"success",
                data:response.data.getTkpProjectPortfolioList.map(val=>({label:val.projectType,value:val.projectType}))
            }
        }else{
             return{
                 status:"failed",
                 data:response.data
             }    
        }
    }catch(error){
         return{
             error:error
         }
    }
}

/* Update tkp Project portfolio  */
const updateProjectPortfolio = async (body)=>{
    try{
        console.log("body-add--",body);
        const response = await instanceAll.post('/update-tkp-onboarding-project-details',body);
        console.log("update-tkp-appoinment Responce:::",response);
        if(response && response?.data?.status === 200){
            return{
                status:"success",
                data:response.data
            }
        }else{
             return{
                 status:"failed",
                 data:response.data
             }    
        }
    }catch(error){
         return{
             error:error
         }
    }
}

/* get tkp Project portfolio details by id */
const getProjectPortfolioById = async (body)=>{
    try{
        const response = await instanceAll.post('/get-project-protfolio-byId',body);
        console.log("get-project-protfolio-byId:::",response);
        if(response && response?.data?.status === 200){
            return{
                status:"success",
                data:response.data
            }
        }else{
             return{
                 status:"failed",
                 data:response.data
             }    
        }
    }catch(error){
         return{
             error:error
         }
    }
}




export const partnerManagerApis ={
    tkpOnboardingProcessAddress,
    tkpOnboardingProcessReject,
    tkpOnboardingProcessBankDetails,
    tkpOnboardingProcessProjectPortfolio,
    addTkpRatingsService,
    getTkpOnboardingAddressDetails,
    getTkpOnboardingBankDetails,
    getTkpOnboardingProjectDetails,
    approvedTkp,
    getAllPartnerManagerAppoinments,
    getProjectPortfolioList,
    updateProjectPortfolio,
    getProjectPortfolioById,
}