import { createSlice } from "@reduxjs/toolkit";

const getStatusList  = createSlice({
    name:"status",
    initialState :{
        status:[],
        error : null
    },
    reducers : {
        getStatusListSuccess : (state,action)=>{
            // console.log("status action:",action.payload);
            state.status = action.payload
        },
        getStatusListFailure : (state,action)=>{
            state.error = action.payload
        }
    }
})

export const {getStatusListSuccess,getStatusListFailure} = getStatusList.actions;

export default getStatusList.reducer;