import React from 'react'
import { useHistory } from 'react-router-dom';

const PlusIconWithLength = (
    {
        length,
        handler,
        screenType
    }
) => {
    const history =useHistory();
    console.log("length", length);
    return (
        <span onClick={
            screenType !== "customer" ?
                handler :
                ()=>history.push("/landing/customer/buildingProgresss")
        }
            className="plusIconWithLength"
        >
            (  + {((length))})
        </span>
    )
}

export default PlusIconWithLength