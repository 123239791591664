import React from 'react';
import '../Styles/Landing.css';
import MainNavbar from '../Pages/navbar/MainNavbar';
import SidebarRoutes from '../Pages/Sidebar/SidebarRoutes';
import { useLocation } from 'react-router-dom';

export default function Landing() {
    const location = useLocation();
    // console.log("location-----",location);
    // const userName = location.state.userName? location.state.userName : null;
    // console.log('userName',window.localStorage.getItem('userName'));
    return (
        <>
           <MainNavbar userName={window.localStorage.getItem('userName')}/>
           <SidebarRoutes/>
        </>

    )
}
