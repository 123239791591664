import { DatePicker, TimePicker, Select } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { disabledDate } from '../../../../../../Utilities/helpers';


const ScheduleMeeting = (
  { 
  handleLeadActivities, 
  leadStatus, 
  setLeadStatus 
 }
) => {
  console.log("Schedule meeting", leadStatus?.date2)
  console.log(window.localStorage.getItem("userName").split("@")[0])
  let value = window.localStorage.getItem("userName").split("@")[0];
  const [options, setOptions] = useState([{ label: value, value: value }]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  console.log("selected options", selectedOptions);
  return (
    <>
      <div className='row'>
        <div className='col'>
          <label>Add Attendees</label>
          <Select
            options={options}
            style={{ width: "100%" }}
            mode="tags"
            allowClear
            placeholder={"select Attendees"}
            // onChange={handleOptions}
            onChange={handleLeadActivities("meetingAttendees")}
            value={leadStatus?.meetingAttendees}
          />
        </div>
      </div>
      <div className='col'>
        <div className='col-md-6 mx-0.5'>
          <label>Date</label>
          <DatePicker
           onChange={handleLeadActivities("schedulemeetingdate")} 
           format={"YYYY-MM-DD"} 
           value={leadStatus?.meetingScheduleDate && moment(leadStatus?.meetingScheduleDate,"YYYY-MM-DD")} 
           disabledDate={disabledDate}
           />
        </div>
        <div className='col-md-6 mx-1'>
          <label>Time</label>
          <TimePicker 
          format={"h:mm A"}  
          minuteStep={15}  
          onChange={handleLeadActivities("schedulemeetingtime")} 
          value={leadStatus?.meetingScheduleTime && moment(leadStatus?.meetingScheduleTime, "h:mm A")} 
          />
        </div>
      </div>
    </>
  )
}

export default ScheduleMeeting;

 // const handleOptions = (option) => {
  //   console.log("options", option);
  //   setSelectedOptions(option);
  //   setLeadStatus((values) => ({ ...values, meetingAttendees: option }))
  // }

            {/* <input type="text" class="form-control" value={leadStatus?.title} />
           */}