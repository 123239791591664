import { takeEvery, call, put } from 'redux-saga/effects';
import { GetCustomersDataByIdService } from '../../Service/SeCustomersServices';
import { customerLoader, getCustomerByIDFailure, getCustomerByIDSuccess } from '../Slices/CustomersSlice';




export function* getCustomerById(action) {
    // console.log("inside the get customer by id saga", action);

    const response = yield GetCustomersDataByIdService(action.payload);
    // console.log(response);

    if (response?.status === "success") {
        yield put(getCustomerByIDSuccess(response.data.CustomerData));
    }
    else if (response?.status === "Error") {
        yield put(getCustomerByIDFailure(response?.error))
    }

}


function* CustomerByIdWatcher() {
    // console.log("inside the customer by id watcher");
    yield takeEvery(customerLoader().type, getCustomerById)
}

export default CustomerByIdWatcher;