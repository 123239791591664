import { createSlice } from "@reduxjs/toolkit";


const designationListSlice=createSlice({
    name:"designations",
    initialState:{
        designations:[],
        error:null
    },
    reducers:{
        getdesignationsSuccess:(state,action)=>{
            console.log(action.payload);
                state.designations=action.payload;

        },
        getdesignationFailure:(state,action)=>{
            state.error=action.payload;
        }
    }
})

export const {getdesignationsSuccess,getdesignationFailure}=designationListSlice.actions;

export default  designationListSlice.reducer;