import { Height } from '@material-ui/icons';
import React from 'react'
import Slider from 'react-slick';
import "./ImageModal.css";

const ImageModal = (
    {
    images
    }
) => {
    console.log("images-ca-",images);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className='image-modal-wrapper mainImage'>
            <Slider {...settings}>
            {
                images?.map((values)=>{
                    return(
                        <div>
                            <img src={ values?.dataURL?._image_urls || values } alt='pic1' className='bigDummyImage' />
                         </div>
                    )
                })
            }
            </Slider>
        </div>
    )
}

export default ImageModal